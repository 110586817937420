import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { SeminarTemplate } from '../parts/SeminarTemplate';
import { getAllSeminarsWithFiltersFetch } from '../../../redux/thunks/seminarsThunks';
import { Country } from 'country-state-city';
import { belts, languages, trainingStyle } from '../../../constants';

const SeminarsPendingByInstructor = () => {
    const { seminars, pagination, isLoading } = useSelector(state => state.seminars)
    const [filters] = useState({
        sort_by: "",
        sort_as: "desc",
        gender: "",
        location: "",
        price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
        limit: ""
    })

    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map(item => ({ value: item.name.toLowerCase(), label: item.name })) || [];
    const priceOptions = ["$$$$$", "$$$$", "$$$", "$$", "$"].map(item => ({ label: item, value: item }));
    const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({ label: title, value: title }))
    const beltOptions = belts.map(({ title, key }) => ({ label: title, value: title }))
    const languageOptions = languages.map(({ title, key }) => ({ label: title, value: title }))
    const sortOptions = ['id', 'locations'].map(item => ({ label: item, value: item }));

    const filtersData = [
        { label: "Location", query: "location", options: locationOptions, all: true },
        { label: "Seminar Price", query: "price", options: priceOptions, all: true },
        { label: "Gi or No-Gi", query: "trainingStyle", options: trainingStyleOptions, all: true },
        { label: "Belt Rank", query: "belt", options: beltOptions },
        { label: "Language", query: "language", options: languageOptions, all: true },
    ];
    return (
        <>
          
                <SeminarTemplate
                    pageType='pending'
                    filterLinks={[
                        { label: 'Seminars', link: '/planed-by-insctructor' },
                        { label: 'Camps', link: '/planed-by-insctructor/camps' }
                    ]}
                    firsScreenOptions={{
                        title: <>Pending <span className='color-primary'>SEMINARS / Camps</span></>,
                        description: 'Discover the seminars and camps from instructors that needs academies',
                        placeholder: 'What seminar or camp do you want to find?',
                        imageBg: require('../../../assets/img/pending-seminars-bg.png')
                    }}
                    filters={filters}
                    filtersData={filtersData}
                    sotrBy={sortOptions}

                    baseQuery='status=pending&role=instructor'
                    fethFuntion={getAllSeminarsWithFiltersFetch}
                    seminars={seminars}
                    pagination={pagination}
                    isLoading={isLoading}
                />
        </>
    )
}

export default SeminarsPendingByInstructor
