
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CalendarIcon } from '../../../assets/img/calendar.svg';
import { ReactComponent as AjustIcon } from "../../../assets/img/icon-ajust.svg";
import ReschedulePrivateLessonModal from "../../../components/modals/ReschedulePrivateLessonModal";
import { getUser } from "../../../redux/slices/applicationSlice";
import { acceptRequestsPrivateLessonsStepFetch, acceptRequestsPrivateLessonsFetch } from "../../../redux/thunks/privateLessonsThunks";
import { PLConfirmUsers } from "./PrivateLessonParts/PLConfirmUsers";
import { PLStepOne } from "./PrivateLessonParts/PLStepOne";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";



const SidebarHeader = ({ status, statusMap, users = [], accepts = [] }) => {
    return (
        <>
            <div className='row-group gap--sm pb-24 border-bottom'>
                <span className='seminar__status'>Status:</span>
                <span className={classNames("seminar__dot", {
                    "--done": status === "done",
                    "--rejected": status === "rejected"
                })} />
                {statusMap[status] && (
                    <span className='chat-item__name'>{statusMap[status].label}</span>
                )}
            </div>
            {/* <div className='row-group gap--sm pb-24 border-bottom'>
                <span className='seminar__status'>Participants:</span>
                {
                    !!users?.length && (
                        <div className='row-group gap--xs'>
                            {users?.map(user => (
                                <div key={user.id} className='participant'>
                                    {accepts.includes(user.id) && <span className='sign'></span>}
                                    <img src={getImageSrc(user.photo)} alt="user" />
                                </div>
                            ))}
                        </div>
                    )
                }
            </div> */}
        </>
    )
}


export const ChatSidebarPrivateLesson = ({ chatData, academyAccept }) => {
    const dispatch = useDispatch()
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false)
    const statuses = ['pending', 'discussing_details', 'confirm-step', 'paid', 'canceled', 'accepted'];

    const { chatId } = useParams()
    const user = useSelector(getUser)
    const privateLesson = chatData.privateLesson
    const status = privateLesson?.status

    const handleAccept = async () => {
        const res = await dispatch(acceptRequestsPrivateLessonsStepFetch(privateLesson?.id)).unwrap()
        if (res?.success) {
            dispatch(getChatByIdFetch({ chatId: chatData?.id }))
        }
    }

    const handleApprove = async () => {
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(privateLesson?.id)).unwrap()
        if (res?.success) {
            dispatch(getChatByIdFetch({ chatId: chatData?.id }))
        }

    }

    const statusMap = {
        rejected: {
            label: "Request Rejected",
        },
        canceled: {
            label: "Request Canceled",
        },
        pending: {
            label: "Pending Request",
            actions: user?.role === "instructor" && (
                <div className="row-group gap--xs">
                    <button className="btn btn--sm btn--default flex-1 fs-14">Reject</button>
                    <button onClick={handleApprove} className="btn btn--sm btn--primary fs-14">Accept
                        Request</button>
                </div>
            )
        },
        'discussing_details': {
            label: "Discussing Lesson Details",
            actions: (
                <div className="flex gap-8 ">
                    <button onClick={() => setOpenRescheduleModal(true)}
                        className="btn btn--sm btn--default fs-14">
                        <span className="ico reschedule-invert-color">
                            <CalendarIcon />
                        </span>
                        RESCHEDULE
                    </button>
                    <button onClick={handleAccept} className="btn btn--sm btn--primary flex-1 fs-14">Accept
                        Details</button>
                </div>
            ),
        },
        "confirm-step": {
            label: "Confirmation",
            // actions: (user?.role === "student"
            //     ? <Link to={`/request-private-session/${privateLesson?.instructorId}/${chatData?.id}/summary`} className="btn btn--sm btn--primary flex-1 fs-14">Confirm</Link>
            //     : <button onClick={handleAccept} className="btn btn--sm btn--primary flex-1 fs-14">Confirm</button>
            // ),
            actions: (<button onClick={handleAccept} className="btn btn--sm btn--primary flex-1 fs-14">Confirm</button>),
        },
        paid: {
            label: "Paid",
            actions: (
                <Link className='btn btn--primary btn--lg w-full' to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/design-text`}>
                    <span className="ico">
                        <AjustIcon />
                    </span>
                    <span className="info text-20">Adjust Design & Text</span>
                </Link>
            )
        },
        accepted: {
            label: "Confirmed",
            button: null
        }
    };

    const isAccepted = (user?.role === "student" && privateLesson?.isConfirmedStudent) ||
        (user?.role === "instructor" && privateLesson?.isConfirmedInstructor)

    console.log(isAccepted);


    return (
        <>
            <aside className='chat-sidebar' >
                <div className="chat-sidebar__header">
                    <h6 className="heading">Private Lesson Info</h6>
                    {/* <div className="row-group gap--xxl">
                        <button className="btn btn--sm btn--square">
                            <span className="ico">
                                <DotsIcon />
                            </span>
                        </button>
                    </div> */}
                </div>

                {
                    <div className="chat-sidebar__body">
                        <SidebarHeader
                            status={status}
                            privateLesson={privateLesson}
                            statusMap={statusMap}
                            accepts={academyAccept?.accepts?.map(item => item.userId)}
                            users={chatData?.users}
                        />

                        <PLStepOne
                            useStep={status === "confirm-step"}
                            currentStatus={status}
                            status={["location", "pending", "rejected"]}
                            data={privateLesson}
                        />
                        {status === "confirm-step" && <PLConfirmUsers />}
                        <div className='chat-sidebar__footer'>
                            {
                                !isAccepted && statusMap[status]?.actions
                            }
                        </div>
                    </div>
                }
            </aside>

            <ReschedulePrivateLessonModal
                open={openRescheduleModal}
                handleClose={() => setOpenRescheduleModal(false)}
                data={privateLesson.dates}
                id={privateLesson.id}
                onSubmit={() => dispatch(getChatByIdFetch({ chatId: chatData?.id }))}
            />
        </>
    )
}