import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as SearchIcon } from '../../../assets/img/ico-search-loupe.svg';
import { useTabs } from "../../../hooks/useTabs";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getUnreadMessages } from "../../../redux/slices/chatsSlice";
import ChatItem from "./ChatItem";

const tabs = [
    {
        id: "all",
        name: "All"
    },
    {
        id: "seminars",
        name: "Seminars"
    },
    {
        id: "camps",
        name: "Camps"
    },
    {
        id: "private",
        name: "Private Lessons"
    },
    {
        id: "requests",
        name: "Requests"
    }
]

export const AllChats = () => {
    const {
        chats,
        pagination
    } = useSelector(state => state.chats)
    const { chatId } = useParams();
    const user = useSelector(getUser)
    const [search, setSearch] = useState("")
    const { activeTab, setActiveTab } = useTabs(tabs)
    const currentChat = chats?.length ? chats?.find(({ id }) => id + '' === chatId) : null;

    const getFilteredChats = (chats) => {
        return chats
            .filter(Boolean)
            .filter(chat => getRecipient(chat)?.fullName?.toLowerCase()?.includes(search.toLowerCase()))
    }

    const getRecipient = (chat) => {
        return chat?.users?.find(recipient => recipient?.id !== user?.id)
    }

    const unread = useSelector(getUnreadMessages)


    const getUnreadCount = (chats) => {
        return unread?.filter(item => chats.find(chat => chat.id === item.chatId))?.length || 0
    }

    const chatsMap = {
        "all": chats,
        "camps": chats?.filter(item => item?.eventType === "plancamp"),
        "seminars": chats?.filter(item => item?.eventType === "seminar"),
        "private": chats?.filter(item => item?.eventType === "private-lessons"),
        "requests": chats?.filter(item => item?.status !== "active"),
    }
    console.log(currentChat)
    return (
        <div className={classNames("chat-list", {
            'has-chat': currentChat
        })}>
            <div className="chat-list__header">
                <h6 className="heading">{pagination?.total} chats</h6>
                <div className="form-group input--md input--icon-left">
                    <div className="input-wrapper">
                        <span className="ico color-text">
                            <SearchIcon />
                        </span>
                        <input onChange={(e) => setSearch(e.target.value)} className="input input--solid" type="text"
                            placeholder="Search chat..." />
                    </div>
                </div>
            </div>
            <div className="chat-tabs row-group gap-6">
                <Swiper
                    spaceBetween={5}
                    slidesPerView={'auto'}
                    cssMode={true}
                >
                    {
                        tabs.map(item => {
                            const unreadCount = getUnreadCount(chatsMap[item.id])
                            return (
                                <SwiperSlide key={item.id}>
                                    <button count={unreadCount} className={classNames("pill ttu", {
                                        "notification": unreadCount,
                                        "pill--default": activeTab === item.id,
                                        "pill--light": activeTab !== item.id,
                                    })} onClick={() => setActiveTab(item.id)}>
                                        {item.name}
                                    </button>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

            </div>
            <div className="chat-list__body">
                {getFilteredChats(chatsMap[activeTab])?.length ?
                    getFilteredChats(chatsMap[activeTab])?.map((chat, index) => (
                        <ChatItem chat={chat} currentChat={currentChat} key={index} />
                    ))
                    :
                    <p className='color-text m-auto p-4'>There is no chats yet</p>
                }
            </div>
        </div>
    )
}