import { createSlice, current } from '@reduxjs/toolkit';
import { getHistoryFetch } from '../thunks/historyThunks';

const initialState = {
    history: [],
    isLoading: false
};

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHistoryFetch.fulfilled, (state, { payload }) => {
                state.history = payload?.data
                state.isLoading = false
            })
            .addCase(getHistoryFetch.pending, (state, { payload }) => {
                state.history = []
                state.isLoading = true
            })
            .addCase(getHistoryFetch.rejected, (state, { payload }) => {
                state.history = []
                state.isLoading = false
            })
    }
});

export const {
} = historySlice.actions;


export default historySlice.reducer;
