import React from 'react'
import Congratulations from '../../../components/parts/Congratulations'
import { Link, useLocation } from 'react-router-dom'
import { getUser } from '../../../redux/slices/applicationSlice'
import { useSelector } from 'react-redux'

const PlanningSuccess = () => {
    const { pathname } = useLocation()
    const user = useSelector(getUser)

    return (
        <>
            {
                pathname.includes("plan-seminar") &&
                    user?.role === "academy" ?
                    <Congratulations
                        text={
                            <>
                                Your pending seminar has been created, and published <br /> in the <Link className='color-secondary underline' to="/planed-by-academy">apply for seminar/camps</Link> tab.
                            </>
                        }
                        additionalLink={{
                            to: "/instructors",
                            title: "Invite Instructor(s)"
                        }}
                    />
                    :
                    null
            }
            {
                pathname.includes("plan-seminar") &&
                    user?.role === "instructor" ?
                    <Congratulations
                        text={
                            <>
                                Your pending seminar has been created, and published <br /> in the <Link className='color-secondary underline' to="/planed-by-insctructor">apply for seminar/camps</Link> tab.
                            </>
                        }
                        additionalLink={{
                            to: "/instructors",
                            title: "Invite Instructor(s)"
                        }}
                    />
                    :
                    null
            }
            {
                pathname.includes("plan-camp") &&
                    user?.role === "academy" ?
                    <Congratulations
                        text='Your pending camp has been created. Do you want invite insturctor(s) to your seminar?.'
                        additionalLink={{
                            to: "/instructors",
                            title: "Invite Instructor(s)"
                        }}
                    />
                    :
                    null
            }
            {
                pathname.includes("plan-camp") &&
                    user?.role === "instructor" ?
                    <Congratulations
                        text='Your pending camp has been created.'
                    />
                    :
                    null
            }
        </>
    )
}

export default PlanningSuccess