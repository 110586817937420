import React from 'react'
import { getImageSrc } from '../../../helpers/utils'
import Timer from '../../../components/parts/Timer'

const SeminarOverviewDetail = ({ pageData }) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60000); 
    return (
        <div className="form-item">
            <div className="other-data-columns">
                {pageData?.status !== 'pending' && 
                <div className="other-data-max other-data-col">
                    <div className="ticker-deadline">
                        <div className="deadline-title">
                            <p className="color-text text-sm">Min. attendance:</p>
                            <div className="amount-stud-block">
                                <span className="ico color-secondary">
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 2C4.8645 2 1.5 5.3645 1.5 9.5C1.5 13.6355 4.8645 17 9 17C13.1355 17 16.5 13.6355 16.5 9.5C16.5 5.3645 13.1355 2 9 2ZM9 15.5C5.69175 15.5 3 12.8082 3 9.5C3 6.19175 5.69175 3.5 9 3.5C12.3082 3.5 15 6.19175 15 9.5C15 12.8082 12.3082 15.5 9 15.5Z" fill="currentColor"></path>
                                        <path d="M7.50005 10.6902L5.7758 8.96898L4.7168 10.031L7.50155 12.8097L12.531 7.78023L11.4705 6.71973L7.50005 10.6902Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <p className="text-sm"><span>10</span> students</p>
                            </div>
                        </div>
                        <Timer expiryTimestamp={time}/>
                        <div className="deadline-footer">
                            <p className="text-sm">Min. attendance deadline</p>
                        </div>
                    </div>
                </div>}
                <div className="other-data-full other-data-col">
                    <div className="other-data--columns">
                        {pageData?.status != 'pending' || pageData?.user?.role === 'academy' &&
                            <div className="other-data--col">
                                <h6 className="heading">Academy:</h6>
                                <div className="seminar-row">
                                    <div className="ico ico--xs">                                       
                                        <img 
                                            src={pageData?.userAcademy?.academyProfile?.photo ? 
                                                getImageSrc(pageData?.userAcademy?.academyProfile?.photo) : 
                                                require("../../../assets/img/avatar-placeholder.svg").default} 
                                            alt="" 
                                        />
                                    </div>
                                    <div className="seminar-row--content">
                                        <p className="seminar-title text-sm">{pageData?.userAcademy?.academyProfile?.name}</p>
                                        <p className="seminar-desk text-sm color-text">
                                            {pageData?.userAcademy?.generalAcademyInfo?.addressLine}
                                        </p>
                                        {pageData?.generalAcademyInfo?.maxSeminarCapacity &&
                                            <div className="deadline-title">
                                                <p className="color-text text-sm">Max. capacity:</p>
                                                <div className="amount-stud-block">
                                                    <p className="text-sm">
                                                        <span>{pageData?.generalAcademyInfo?.maxSeminarCapacity}</span>
                                                        students
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {pageData?.status != 'pending' || pageData?.user?.role === 'instructor' && <div className="other-data--col">
                            <h6 className="heading">Instructors:</h6>
                            <ul className="row-list">
                                <li>
                                    <div className="card-user-block
                                                                    card-user-block--sm">
                                        <div className="card-user-block__icon">
                                            <img src={require("../../../assets/img/user-instructor.png")} alt="" />
                                        </div>
                                        <div className="card-user-block__name">Gordon Ryan</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card-user-block
                                                                    card-user-block--sm">
                                        <div className="card-user-block__icon">
                                            <img src={require("../../../assets/img/user-instructor.png")} alt="" />
                                        </div>
                                        <div className="card-user-block__name">Nicholas Meregali</div>
                                    </div>
                                </li>
                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeminarOverviewDetail
