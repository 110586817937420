import { createSlice } from '@reduxjs/toolkit';
import { getAllCampsFetch, getCampsMyAvailableToInstructorFetch, getSingleCampFetch } from '../thunks/planCampThuncks';

const initialState = {
    camps: [],
    campDetails: null,
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};

const planCampsSlice = createSlice({
    name: 'planCamps',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCampsFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.camps = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllCampsFetch.pending, (state, action) => {
                state.isLoading = true;
                state.camps = [];
                state.pagination = {};
            })
            .addCase(getAllCampsFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.camps = [];
                state.pagination = {};
            })
            .addCase(getCampsMyAvailableToInstructorFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.camps = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getCampsMyAvailableToInstructorFetch.pending, (state, action) => {
                state.isLoading = true;
                state.camps = [];
                state.pagination = {};
            })
            .addCase(getCampsMyAvailableToInstructorFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.camps = [];
                state.pagination = {};
            })
            .addCase(getSingleCampFetch.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.campDetails = payload;
            })
            .addCase(getSingleCampFetch.pending, (state, action) => {
                state.isLoading = true;
                state.campDetails = {};
            })
            .addCase(getSingleCampFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.campDetails = {};
            })
            
    }
});

export const {
    
} = planCampsSlice.actions;

export const getCampDetails = (state) => state.planCamps.campDetails

export default planCampsSlice.reducer;
