import SelectOne from "../ui/formElements/SelectOne";

import { ReactComponent as FiltersIcon } from "../../assets/img/icon-filters.svg"
import DropDown from "../ui/DropDown";
import CheckboxSwitch from "../ui/formElements/CheckboxSwitch";

const SelectFilters = ({ filters, filtersData, handleChange }) => {
    return (
        <>
            <DropDown
                dropDownClasses="lg-hidden"
                buttonClasses={"btn btn--outline btn--lg w-full justify-start"}
                dropDownListClasses={"px-36 py-32"}
                labelButton={(
                    <>
                        <FiltersIcon />
                        <span className="text-normal fw-500 text-14 style-normal">Filters</span>
                    </>
                )}
            >
                <div className="flex flex-col gap-12">
                    {filtersData.map(filter => (
                        <SelectOne
                            value={filters.query}
                            key={filter.query}
                            wrapperClasses={'form-group select--outline w-150 input--md select-border-option'}
                            options={filter.options}
                            placeholder={filter.label}
                            onChange={(option) => handleChange(filter.query, option.value)}
                            showAllLabel={filter.all}
                        />
                    ))}
                    <>
                        <CheckboxSwitch
                            text="Women only"
                            checked={filters.gender === "female"}
                            onChange={() => handleChange("gender", filters.gender === "female" ? "" : "female")}
                        />
                        <CheckboxSwitch
                            text="Men only"
                            checked={filters.gender === "male"}
                            onChange={() => handleChange("gender", filters.gender === "male" ? "" : "male")}
                        />
                    </>
                </div>
            </DropDown>
            <div className="hidden lg-flex items-center gap-12 mb-24">
                {filtersData.map(filter => (
                    <SelectOne
                        value={filters.query}
                        key={filter.query}
                        wrapperClasses={'form-group select--outline w-150 input--md select-border-option'}
                        options={filter.options}
                        placeholder={filter.label}
                        onChange={(option) => handleChange(filter.query, option.value)}
                        showAllLabel={filter.all}
                    />
                ))}
            </div>
        </>
    );
};

export default SelectFilters
