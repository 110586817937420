import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as CompassIcon } from "../../assets/img/ico-compass.svg"
import { ReactComponent as CheckIcon } from "../../assets/img/ico-SealCheck.svg"
import { getAge, getBelt, getFullLocation, getImageSrc } from '../../helpers/utils'
import { getUser } from '../../redux/slices/applicationSlice'
import { getSeminarsMyAvailableToInstructorFetch, sendSeminarRequestToInstructorFetch } from '../../redux/thunks/seminarsThunks'

import { toast } from 'react-toastify'
import PageLoader from '../../components/ui/PageLoader'
import { getInstructorInfoFetch } from '../../redux/thunks/instructorsThunks'
import InviteSeminar from './parts/InviteSeminar'
import { useTabs } from '../../hooks/useTabs'
import classNames from 'classnames'
import InviteCamp from './parts/InviteCamp'

const tabs = [
    { name: 'Seminars', id: 'seminars' },
    { name: 'Camps', id: 'camps' }
]

const InviteToSeminar = () => {
    const { id } = useParams()
    const dispatch = useDispatch()

    const { activeTab, setActiveTab } = useTabs(tabs)

    const { instructorInfo, isLoading: isInstructorLoading } = useSelector(state => state.instructors)

    useEffect(() => {
        if (!id) return;
        dispatch(getInstructorInfoFetch(id))
    }, [])


    const belt = getBelt(instructorInfo?.generalInstructorInfo?.belt)
    return (
        <section className="section-settings">
            {
                !isInstructorLoading ?
                    <div className="container container--sm">
                        <div className="settings-row">
                            <div className="settings-col">
                                <div className="settings-body settings-gap">
                                    <div className="sidebar-header heading-row">
                                        <h3 className="heading">Plan Seminar and Invite</h3>
                                    </div>
                                    <form className="form form-settings" method="post">
                                        <div className="form-header">
                                            <div className="row gutters-form">
                                                <div className="col-12">
                                                    <div className="intro__row">
                                                        <div className="intro__row__item">
                                                            <div className="photo-load">
                                                                <img src={getImageSrc(instructorInfo?.instructorProfile?.photo)} />
                                                            </div>
                                                            <div className="intro__details__container">
                                                                <div className="intro__details">
                                                                    <div className="row-group gap--xs">
                                                                        <h6 className="heading">{instructorInfo?.instructorProfile?.fullName}</h6>
                                                                        <span className="ico color-secondary">
                                                                            <CheckIcon />
                                                                        </span>
                                                                    </div>
                                                                    <ul className="dot-list">
                                                                        <li>
                                                                            <div className="row-group gap--xs">
                                                                                <span className="ico">
                                                                                    <CompassIcon />
                                                                                </span>
                                                                                <span>{getFullLocation([
                                                                                    instructorInfo?.generalInstructorInfo?.country,
                                                                                    instructorInfo?.generalInstructorInfo?.city,
                                                                                    instructorInfo?.generalInstructorInfo?.addressLine
                                                                                ])}</span>
                                                                            </div>
                                                                        </li>
                                                                        <li>Age {getAge(instructorInfo?.instructorProfile?.birthday)}</li>
                                                                        <li>{belt?.title || "No belt"}</li>
                                                                    </ul>
                                                                </div>
                                                                <Link to={`/instructors/${instructorInfo?.id}`} className="btn color-secondary btn-link-2 text-left justify-start">
                                                                    <span className="text--sm">View Profile</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap mt-28">
                                            <div className="select-checkers">
                                                {tabs.map((tab, index) => (
                                                    <button type='button' onClick={() => setActiveTab(tab.id)} key={index}
                                                        end
                                                        className={classNames("btn btn--md", { "btn--default": activeTab === tab.id })}>
                                                        <span>{tab.name}</span>
                                                    </button>
                                                ))}

                                            </div>
                                        </div>
                                        {activeTab === "seminars" && <InviteSeminar />}
                                        {activeTab === "camps" && <InviteCamp />}
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <PageLoader />
            }
        </section>
    )
}

export default InviteToSeminar