import { Country, State } from 'country-state-city'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from '../../../api'
import VideoPlayer from '../../../components/parts/cards/VideoPlayer'
import MapBox from "../../../components/parts/mapBox/MapBox"
import { getCoordinates } from "../../../components/parts/mapBox/mapBoxFunctions"
import Button from '../../../components/ui/buttons/Button'
import Input from '../../../components/ui/formElements/Input'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import { getImageSrc } from '../../../helpers/utils'

import { ReactComponent as TrashIcon } from "../../../assets/img/ico-trash.svg"

const GeneralInfoAcademy = () => {
    const [baseData, setBaseData] = useState(null)
    const [gallery, setGallery] = useState(null)
    const [updateInfoValue, setUpdateInfoValue] = useState({})

    const [firstInit, setFirstInit] = useState(true)

    const allCountries = Country.getAllCountries();
    const allStates = State.getStatesOfCountry(baseData?.country || "US");

    const countries = allCountries?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const states = allStates?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const [coordinates, setCoordinates] = useState({ lat: null, lon: null })


    const handleChange = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const res = await api.academyGeneralInfo.uploadAcademyGalleryList(formData);
        if (res.success) {
            toast.success(res?.message || "Added");
            setGallery(prev => (
                [
                    ...prev,
                    res.data
                ]
            ))
        } else {
            toast.success(res?.message || "Error");
        }
    };

    const updateInfoHandler = ({ target }) => {
        setBaseData(prev => ({
            ...prev,
            [target.name]: target.value
        }))
        setUpdateInfoValue(prev => ({
            ...prev,
            [target.name]: target.value
        }))
    }

    const save = async () => {
        const resInfo = await api.academyGeneralInfo.updateAcademyGeneralInfo(updateInfoValue);
        if (resInfo.success) {
            toast.success(resInfo.message || "Updated");
        } else {
            toast.error(resInfo?.message || "Error");
        }
    }
    const getAcademyGeneralInfo = async () => {
        let res = await api.academyGeneralInfo.getAcademyGeneralInfo();
        if (res?.data) {
            setCoordinates({
                lat: res.data.latitude,
                lon: res.data.longitude,
            })
            setBaseData(res.data);
            setGallery(res.data.gallery)
            setTimeout(() => {
                setFirstInit(false)
            }, 100)

        }
    }
    const removeGaleryHandle = async (id) => {
        const res = await api.academyGeneralInfo.deleteAcademyGalleryItem(id)
        if (res.success) {
            setGallery(prev => prev.filter(item => item.id !== id))
            toast.success(res?.message || "Delete");
        } else {
            toast.error(res?.message || "Error");
        }
    }
    useEffect(() => {
        getAcademyGeneralInfo();
    }, [])

    const changeCoordinatesHandler = (obj) => {
        setCoordinates({
            lat: obj.lat,
            lon: obj.lon,
        })

        updateInfoHandler({ target: { name: 'latitude', value: `${obj.lat}` } })
        updateInfoHandler({ target: { name: 'longitude', value: `${obj.lon}` } })
    }

    useEffect(() => {

        if (!baseData || firstInit) return


        const req = async (address) => {
            const res = await getCoordinates(address)

            if (coordinates.lat !== res.coordinates.lat || coordinates.lon !== res.coordinates.lon)
                changeCoordinatesHandler({
                    lat: res.coordinates.lat,
                    lon: res.coordinates.lon,
                })
        }

        if (baseData?.addressLine && baseData?.city && baseData?.zip) {
            const a = `${baseData?.addressLine} ${baseData?.city} ${updateInfoValue?.state} ${baseData?.zip}`
            req(a)
        }
        // 6391 Elgin St. Celina, Delaware 10299

    }, [baseData?.addressLine, baseData?.city, updateInfoValue?.state, baseData?.zip]);

    if (!baseData) return null
    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">General Information</h3>
            </div>
            <form className="form form-settings" method="post">
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <SelectOne
                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                        options={countries}
                                        placeholder={'Country...'}
                                        defaultValue={countries?.find(item => item?.value === baseData?.country || null)}
                                        label={'Country / Region'}
                                        onChange={(option) => {
                                            setUpdateInfoValue(prev => ({
                                                ...prev,
                                                'country': option.value
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'6391 Elgin St. Celina, Delaware 10299'}
                                        name={'addressLine'}
                                        label={"Address Line"}
                                        defaultValue={baseData?.addressLine}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="row gutters-form-2">
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'Milford'}
                                                label={"Town / City"}
                                                name={'city'}
                                                defaultValue={baseData?.city}
                                                onChange={updateInfoHandler}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <SelectOne
                                                options={states}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'State'}
                                                placeholder={'State...'}
                                                defaultValue={states?.find(item => item?.value === baseData?.state) || null}
                                                onChange={(option) => {
                                                    setUpdateInfoValue(prev => ({
                                                        ...prev,
                                                        'state': option.value
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'10299'}
                                                label={"ZIP"}
                                                defaultValue={baseData?.zip}
                                                name={'zip'}
                                                onChange={updateInfoHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="academy-profile-settings map"
                                        style={
                                            {
                                                height: '200px'
                                            }
                                        }
                                    >
                                        <MapBox mainPoints={coordinates.lat ? [
                                            {
                                                coordinates: [coordinates.lon, coordinates.lat]
                                            }
                                        ] : null}
                                            newCenter={coordinates.lat ? [coordinates.lon, coordinates.lat] : null}
                                            getCoordinatesOnclick={changeCoordinatesHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Add your link here...'}
                                        label={"Facebook"}
                                        defaultValue={baseData?.facebookURL}
                                        name={'facebookURL'}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Add your link here...'}
                                        label={"Instagram"}
                                        name={'instagramURL'}
                                        defaultValue={baseData?.instagramURL}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Add your link here...'}
                                        label={"YouTube"}
                                        name={'youtubeURL'}
                                        defaultValue={baseData?.youtubeURL}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Add your link here...'}
                                        label={"BJJ fanatics"}
                                        name={'fanaticsURL'}
                                        defaultValue={baseData?.fanaticsURL}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'2007'}
                                        label={"Year of Foundation"}
                                        name={'yearOfFoundation'}
                                        defaultValue={baseData?.yearOfFoundation}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'250'}
                                        label={"Number of Students"}
                                        name={'numberOfStudents'}
                                        defaultValue={baseData?.numberOfStudents}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Rubens Charles Maciel'}
                                        label={"Person in Charge"}
                                        name={'personInCharge'}
                                        defaultValue={baseData?.personInCharge}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Rubens Charles Cobrinha Maciel'}
                                        label={"Academy Owner"}
                                        name={'academyOwner'}
                                        defaultValue={baseData?.academyOwner}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'100'}
                                        name={'maxSeminarCapacity'}
                                        label={"Max. Seminar Capacity"}
                                        defaultValue={baseData?.maxSeminarCapacity}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        inputClasses={'input--solid'}
                                        placeholder={'cobrinhabjj.com'}
                                        label={"Website"}
                                        name={'website'}
                                        defaultValue={baseData?.website}
                                        onChange={updateInfoHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="row gutters-form-3">
                                <div className="col-12">
                                    <div className="heading-row">
                                        <div className="flex-ico-ss-text">
                                            <h4 className="heading">Gallery</h4>
                                        </div>
                                        <div className="flex-group">
                                            <label className='ml-auto'>
                                                <input type="file" hidden={true} onChange={(e) => handleChange(e.target.files[0])} />
                                                <span className="btn btn--md">
                                                    <span>Add Photo / Video</span>
                                                    <span className="ico">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.125 10H16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M10 3.125V16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {gallery.length && <div className="col-12">
                                    <ul className="gallery-row">
                                        {gallery.map((galleryItem) => (
                                            <li className="gallery-grid" key={galleryItem.id}>
                                                {galleryItem.mimetype?.includes("image") &&
                                                    <div className="gallery-thumb">
                                                        <img src={getImageSrc(galleryItem?.url)} alt={galleryItem?.url} />
                                                        <div className="thumb-actions">
                                                            <Button onClick={(e) => {
                                                                e.preventDefault()
                                                                removeGaleryHandle(galleryItem.id)
                                                            }} className="btn--square thumb-trash-btn">
                                                                <span className="ico">
                                                                    <TrashIcon />
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                                {galleryItem.mimetype?.includes("video") &&
                                                    <div className="gallery-thumb">
                                                        <VideoPlayer url={galleryItem.url} />
                                                        <div className="thumb-actions">
                                                            <Button className="btn--square thumb-trash-btn"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    removeGaleryHandle(galleryItem.id)
                                                                }}
                                                            >
                                                                <span className="ico">
                                                                    <TrashIcon />
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-footer --bordered">
                    <div className="flex-group justify-end">
                        <button type="submit" onClick={(e) => {
                            e.preventDefault()
                            save()
                        }} className="btn btn--primary btn--sm">
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default GeneralInfoAcademy
