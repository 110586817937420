import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { ReactComponent as LocationIcon } from "../../../assets/img/icon-decor-city.svg";
import { trainingStyle as trainingStyles } from '../../../constants';
import { getDateRange, getImageSrc } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';

const SeminarCardPreview = ({
    availableDates = [],
    location,
    seminarName,
    instructors = [],
    photo,
    fullName,
    minimalPriceTicket,
    cover,
    traningStyle,
}) => {
    const user = useSelector(getUser)

    const dateRange = useMemo(() => getDateRange(availableDates), [availableDates])

    const trainingStyleOption = trainingStyles.find(option => option.key === traningStyle)
    

    return (
        <div className="section-row-block__aside">
            {
                user ?
                    <div className={`card-seminar card-shadow`}>
                        {/* card-seminar--empty */}
                        <div className="card-seminar__img-block">
                            <div className="card-seminar__img-info">
                                <span className="card-seminar__info-date">
                                    {!dateRange ? 'Your Date' : dateRange}
                                </span>
                                {trainingStyleOption?.title && (
                                    <span className="card-seminar__gi">
                                        {
                                            trainingStyleOption.icon ? trainingStyleOption.icon : null
                                        }
                                        {trainingStyleOption?.title}
                                    </span>
                                )}
                            </div>
                            <div className="card-seminar__img">
                                {cover ?
                                    <img src={getImageSrc(cover?.url || cover)} alt={seminarName} />
                                    :
                                    <img src={require("../../../assets/img/photo-placeholder.svg").default} alt="" />
                                }
                            </div>
                        </div>
                        <div className="card-seminar__body">
                            <div className="card-seminar__group-city">
                                <div className="ico">
                                    <LocationIcon />
                                </div>
                                <span>{location || <span className='ttu italic'>Your location</span>}</span>
                            </div>

                            <h4 className="heading ">{seminarName ? seminarName : "New Seminar Name"}</h4>
                            {instructors?.length ?
                                <div className="card-seminar__group-tags">
                                    <span>{fullName}</span>
                                    {
                                        instructors?.map(instructor => (
                                            <span key={instructor.id}>{instructor?.fullName}</span>
                                        ))
                                    }
                                </div>
                                :
                                <div className="card-user-block">
                                    {
                                        photo ?
                                            <div className="card-user-block__icon">
                                                <img src={getImageSrc(photo)} alt={fullName} />
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="card-user-block__name">{fullName}</div>
                                </div>
                            }
                        </div>
                        <div className="card-seminar__footer">
                            {/* <div className="price-group">
                                <span className="price-group__price">${minimalPriceTicket || "100"}</span>
                                <span className="color-grey">/ ticket</span>
                            </div> */}
                            <div className="row-group gap--xs">
                                <a href="#" className="btn btn--primary btn--md radius disabled">Preview</a>
                                {/* <a href="#" className="btn btn--sm btn--square">
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9968 16.0439C10.7446 16.0439 10.5383 16.2503 10.541 16.5025C10.541 16.7547 10.7474 16.961 10.9996 16.961C11.2518 16.961 11.4581 16.7547 11.4581 16.5025C11.4581 16.2503 11.2518 16.0439 10.9968 16.0439" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10.9968 10.541C10.7446 10.541 10.5383 10.7474 10.541 10.9995C10.541 11.2517 10.7474 11.4581 10.9996 11.4581C11.2518 11.4581 11.4581 11.2517 11.4581 10.9995C11.4581 10.7474 11.2518 10.541 10.9968 10.541" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10.9968 5.03906C10.7446 5.03906 10.5383 5.2454 10.541 5.49759C10.541 5.74978 10.7474 5.95611 10.9996 5.95611C11.2518 5.95611 11.4581 5.74978 11.4581 5.49759C11.4581 5.2454 11.2518 5.03906 10.9968 5.03906" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </a> */}
                            </div>

                        </div>
                    </div>
                    :
                    null
            }
        </div >
    );
};

export default SeminarCardPreview;
