import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch';
import PageLoader from '../../../components/ui/PageLoader';
import { getImageSrc } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import { getChatByIdFetch } from '../../../redux/thunks/chatsThunks';
import { getInstructorInfoFetch } from '../../../redux/thunks/instructorsThunks';
import { Step } from '../parts/Step';


export const CardDetails = ({ isEdited, label, children }) => {
    return (
        <div className="form-group input--lg">
            <label className="label flex items-center gap--xs">
                {label}
                {
                    isEdited ?
                        <span className="flex">
                            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="6" height="6" rx="3" fill="#9747FF" />
                            </svg>
                        </span>
                        : null
                }

            </label>
            <div className='col-group gap--xs text--sm'>
                {(Array.isArray(children) && children.length) || (!Array.isArray(children) && children) ? children : "Not specified"}
            </div>
        </div>
    )
}

const Summary = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [agree, setAgree] = useState(false)
    const { chatId, instructorId } = useParams()
    const user = useSelector(getUser)
    const {
        currentChatData,
    } = useSelector(state => state.chats)

    const privateLesson = currentChatData?.privateLesson

    const { instructorInfo, isLoading } = useSelector(state => state.instructors)



    const handleSubmit = async () => {
        if (!agree) {
            toast.warn("You must agree to the terms")
            return
        }
        // const res = await dispatch(acceptSeminarRequestFetch({ seminarId: seminarDetails.id, requestId })).unwrap()
        // if (res) {
        //     navigate(`/chat/${chatId}`)
        // }
    }

    useEffect(() => {
        if (!chatId) return;
        dispatch(getChatByIdFetch({ chatId: chatId }))
        dispatch(getInstructorInfoFetch(instructorId))
    }, [chatId])

    if (!instructorInfo) {
        return null
    }
    const { instructorProfile, generalInstructorInfo, privateSession } = instructorInfo

    return (
        <>
            <section className="section-42">
                <div className="container">
                    {
                        !isLoading ?
                            <div className="double-section flex gap-80">
                                <div className="double-section__content">
                                    <h3 className="heading">Lesson Summary</h3>
                                    <div className="col-group">
                                        <Step title={"Location & Date"} href={"./../"}>
                                            <div className='row gx-16 py-24 mb-10'>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Country"}>
                                                        <p>{generalInstructorInfo.country}</p>
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Address"}>
                                                        <p>{generalInstructorInfo.address}</p>
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Dates & time"}>
                                                        {
                                                            generalInstructorInfo?.seminarDates?.map(date => (
                                                                <p>
                                                                    {moment(date.date).format("DD MMM, YYYY")}, {" "}
                                                                    {date.startTime} - {date.endTime}
                                                                </p>
                                                            ))
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Academy"}>
                                                        HART JIU-JITSU CONSHOHOCKEN
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </Step>
                                        <Step title={"Lesson Information"} href={"./../"}>
                                            <div className="row gx-16 py-24 mb-10">
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Gi or NoGi "}>

                                                    </CardDetails>
                                                </div>
                                                <div className="col-md-6">
                                                    <CardDetails label={"Attending"}>

                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </Step>
                                    </div>
                                    <button onClick={handleSubmit} className="btn btn--primary btn--lg">
                                        <span className="info">Confirm</span>
                                    </button>
                                    <div className="option-group mb-36">
                                        <CheckboxSwitch
                                            text={
                                                <>
                                                    I have read and agreed to the
                                                    {" "}
                                                    <Link className='color-secondary' to={"terms"}>
                                                        Terms and Conditions
                                                    </Link>
                                                    {" "}
                                                    and
                                                    {" "}
                                                    <Link className='color-secondary' to={"privacy"}>Privacy Policy</Link>
                                                </>
                                            }

                                            checked={agree}
                                            onChange={() => setAgree(!agree)}
                                        />
                                    </div>
                                </div>
                                <div className='double-section__aside'>
                                    <div className="card-shadow px-36 py-32 mb-16">
                                        <div className="instructor-profile__group">
                                            <div className="instructor-profile__image-logo">
                                                <img className="ico ico--md rounded-full"
                                                    src={getImageSrc(instructorProfile?.photo)}
                                                    alt={instructorProfile?.fullName} />
                                            </div>
                                            <div className="instructor-profile__group-info gap--xs">
                                                <p className="instructor-profile__name ttu">{instructorProfile?.fullName}</p>
                                                <p className="instructor-profile__location">{generalInstructorInfo?.city}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-shadow px-36 py-32">
                                        <h6 className='heading mb-28'>Price Information</h6>
                                        <div className="ticket-info-body ticket-info-body--no-border">
                                            <div className="ticket-info-body__item justify-between ">
                                                <span className="color-text">Session:</span>
                                                <span className="fw-500">$1,200</span>
                                            </div>
                                            <div className="ticket-info-body__item justify-between border-bottom pb-28 mb-28">
                                                <span className="color-text">Ticket Fee:</span>
                                                <span className="fw-500">$10</span>
                                            </div>
                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">Total:</span>
                                                <span className="fw-500">$1,210</span>
                                            </div>
                                            <div className="ticket-info-body__item justify-between text--xs color-text text-center">
                                                You have 48 hours to pay for the private lesson. The request will be canceled if the payment is not made within this timeframe. The total sum will be reserved.                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <PageLoader />
                    }
                </div>
            </section>
        </>
    )
}

export default Summary