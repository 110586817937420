import React, {useEffect, useState} from 'react'
import WhyChoseUs from '../components/parts/WhyChoseUs'
import FQA from '../components/parts/FQA'
import api from "../api";
import PageLoader from "../components/ui/PageLoader"
import PlatformBenefits from "../components/parts/PlatformBenefits";

const AboutUS = () => {
    const [pageData, setPageData] = useState(null);

    const getPageData = async () => {
        let res = await api.pages.getPagesByPath("/aboutUs");
        setPageData(res.data);
    }

    useEffect(() => {
        getPageData();
    }, []);

    return (
        pageData ? <>
            <section className="first-screen">
                <div className="firts-screen-bg">
                    <img src={pageData?.blocks?.[0]?.blockPresets?.[1]?.value ?
                        (process.env.REACT_APP_API_URL + pageData?.blocks?.[0]?.blockPresets?.[1]?.value) :
                        require("../assets/img/hero_about_us.png")
                    } alt=""/>
                    <div className="firts-screen-bg-effect"></div>
                </div>
                <div className="container">
                    <div className="first-screen-body first-screen-body--center">
                        <h1 className="heading"
                            dangerouslySetInnerHTML={{__html: pageData?.blocks?.[0]?.blockPresets?.[0]?.value || `About Us`}}></h1>
                    </div>
                </div>
            </section>
            <section className='au-second-screen'>
                <div className='container mobile-adapt'>

                    <div className='au-desc-block'>
                        <div className='row gx-60 au-gap-80'>
                            <div className='col-lg-6 au-desc-block-item'>
                                <span
                                    dangerouslySetInnerHTML={{__html: pageData?.blocks?.[0]?.blockPresets?.[2]?.value || `Welcome to Plan Seminars, the go-to platform for easily booking and planning Brazilian Jiu-Jitsu seminars. We're here to take the hassle out of organizing and attending BJJ events, making the entire process straightforward and efficient. Our goal is to make life easier for instructors, academies, and students, ensuring a smooth experience for everyone.`}}></span>
                            </div>
                            <div className='col-lg-6 au-desc-block-item'>
                                <span dangerouslySetInnerHTML={{
                                    __html: pageData?.blocks?.[0]?.blockPresets?.[3]?.value || `With Plan Seminars, instructors and academies can easily schedule, promote, and manage
                                their
                                seminars, while students can quickly find and register for the events they want to
                                attend.
                                We
                                strive to bring the BJJ community closer together by making it easier than ever to
                                connect,
                                learn, and grow through high-quality seminars.`
                                }}></span>

                            </div>
                        </div>

                    </div>
                </div>

                <div className='au-second-screen-bg-effect'></div>
                <div className='au-second-screen-bg-effect-mobile'></div>
            </section>
            <section className="article section">
                <div className="container">
                    <div className="row gx-60 gy-5 au-image-desc-section">
                        <div className="col-lg-6 au-block-one">
                            <div className="au-image-big img-wrapper">
                                <img src={
                                    pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[0]?.image_preview ?
                                        (process.env.REACT_APP_API_URL + pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[0]?.image_preview) :
                                        require("../assets/img/about-us-first.png")
                                } alt=""/>
                            </div>
                        </div>

                        <div className="col-lg-6 au-block-two">
                            <h2
                                className="heading heading-section"
                                dangerouslySetInnerHTML={{__html: pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[0]?.title || `WHO ARE WE ?`}}
                            ></h2>
                            <br/><br/>
                            <p className="description-section"
                               dangerouslySetInnerHTML={{__html: pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[0]?.content || `We are a team of dedicated jiu-jitsu athletes who saw a problem with the current process of arranging seminars, including issues with payment, scheduling, communication with athletes, and more. That's why we created Plan Seminars. Our platform simplifies the process for instructors, students, and academics worldwide, making it easier to teach, host, and attend seminars.`}}>
                            </p>
                        </div>
                    </div>

                </div>
            </section>
            <section className="article section">
                <div className="container">
                    <div className="row gx-60 gy-5 au-image-desc-section">
                        <div className="col-lg-6">
                            <h2
                                className="heading heading-section"
                                dangerouslySetInnerHTML={{__html: pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[1]?.title || `Our Mission`}}
                            ></h2>
                            <br/><br/>
                            <p className="description-section"
                               dangerouslySetInnerHTML={{__html: pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[1]?.content || `At Plan Seminars, our mission is to connect BJJ students with top-notch seminars and private lessons. We make it easier for students to learn from the best instructors and for academies and instructors to organize and promote their events.`}}>
                            </p>
                        </div>

                        <div className="col-lg-6 ">
                            <div className="au-image-big img-wrapper">
                                <img src={
                                    pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[0]?.image_preview ?
                                        (process.env.REACT_APP_API_URL + pageData?.blocks?.[1]?.blockPresets?.[0]?.value?.data?.[1]?.image_preview) :
                                        require("../assets/img/about_us_img.png")
                                } alt=""/>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <section className='section'>
                <PlatformBenefits blockData={{
                    blockPresets: [
                        {value: pageData?.blocks?.[1]?.blockPresets?.[3]?.value},
                        pageData?.blocks?.[1]?.blockPresets?.[2]
                    ]
                }}/>

            </section>

            <FQA blockData={pageData?.blocks?.[2]}/>
        </> : <PageLoader/>
    )
}

export default AboutUS
