import { format } from 'date-fns'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../components/ui/buttons/Button'
import { getBelt, getDateRange } from '../../../helpers/utils'
import { getUser } from '../../../redux/slices/applicationSlice'
import { sendSeminarRequestByInstructorFetch, sendSeminarRequestFetch } from '../../../redux/thunks/seminarsThunks'

import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg"
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg"
import { Country } from 'country-state-city'
import { sendCampRequestByInstructorFetch, sendRequestCampHostingFetch } from '../../../redux/thunks/planCampThuncks'
import CheckboxEl from '../../../components/ui/formElements/CheckboxEl'
import { toast } from 'react-toastify'

const SeminarSinglePendingAsside = ({ pageData, type = "seminar" }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(getUser)
    const [academyProfile] = useState(pageData?.userAcademy?.academyProfile)


    const handleDiscussDetails = async () => {
        if(!agree) {
            toast.error('You should accept our Terms and Conditions of Service')
            return
        }
        if (!pageData?.id) return
        if(type === "camp") {
            const res = await dispatch(sendRequestCampHostingFetch({ plancampId: pageData.id })).unwrap()
            if (res?.success) {
                navigate(`/chat/${res.chat?.id}`)
            }
        } else {
            const res = await dispatch(sendSeminarRequestFetch({ seminarId: pageData.id })).unwrap()
            if (res?.success) {
                navigate(`/chat/${res.chat?.id}`)
            }
        }
    }
    const handleRequestJoin = async () => {
        if(!agree) {
            toast.error('You should accept our Terms and Conditions of Service')
            return
        }
        if (!pageData?.id) return
        if(type === "camp") {
            const res = await dispatch(sendCampRequestByInstructorFetch({ plancampId: pageData.id })).unwrap()
            if (res?.success) {
                // navigate(`/chat/${res?.chatId}`)
            }
        } else {
            const res = await dispatch(sendSeminarRequestByInstructorFetch({ seminarId: pageData.id })).unwrap()
            if (res?.success) {
                // navigate(`/chat/${res?.chatId}`)
            }
        }
    }
    const [agree, setAgree] = useState(false)

    return (
        <>
            {pageData?.user?.role === 'academy' || pageData?.createdBy === "academy" ?
                <div className="card-shadow px-36 py-32">
                    {pageData?.user?.role !== 'academy' && <div className="flex items-center justify-between gap-12 border-bottom pb-28 mb-28">
                        <div className="price-group gap-4">
                            <span className="color-grey">from / </span>
                            <span className="price-group__price">{pageData?.minimalPriceTicket || pageData?.seminarMinPrice || 0}$</span>
                        </div>
                    </div>}
                    <div className="flex items-center justify-between gap-12 mb-28">
                        <div className="price-group">
                            <span className="price-group__price">{getDateRange(pageData?.seminarDates)}</span>
                        </div>
                    </div>

                    {user && user?.role === 'instructor' &&
                        <>
                            <div className="flex items-center mb-20">
                                <CheckboxEl checked={agree} onChange={(e) => setAgree(!agree)} wrapperClasses='mr-20'/> 
                                <span>I agree with</span>&nbsp;<Link to='/terms-of-service' className='btn btn-link'>Terms and Conditions of Service</Link>
                            </div>
                            <Button
                                onClick={handleRequestJoin}
                                className="btn btn--primary border-bottom mb-24 btn--lg w-full"
                            >
                                <span className="info text-20">Send Proposal</span>
                                <span className="ico">
                                    <ChevronRignt />
                                </span>
                            </Button>
                        </>
                    }

                    <a href="#" className="btn color-secondary gap-10 w-full mb-28 border-bottom pb-28">
                        <ShareIcon />
                        <span className="underline not-italic fw-500 tt-none">
                            Share with Friends
                        </span>
                    </a>

                    <div className="ticket-info-body ticket-info-body--no-border">
                        <div className="ticket-info-body__item">
                            <span className="color-text">Host: </span>
                            <span className="fw-500">{academyProfile?.name}</span>
                        </div>
                        <div className="ticket-info-body__item">
                            <span className="color-text">Address:</span>
                            <span className="fw-500">{
                                pageData?.address}
                            </span>
                        </div>
                        <div className="ticket-info-body__item">
                            <span className="color-text">Belt level:</span>
                            <span className="fw-500">{getBelt(pageData?.specificBelt)?.title}</span>
                        </div>
                        <div className="ticket-info-body__item">
                            <span className="color-text">Gym capacity:</span>
                            <span className="fw-500">{pageData?.maxGymCapacity} People</span>
                        </div>
                    </div>
                </div>
                :
                <div className="card-shadow px-36 py-32">
                    <div className="flex items-center justify-between gap-12 mb-28">
                        <div className="price-group gap-4">
                            <span className="price-group__price">{pageData?.minimalPriceTicket || pageData.seminarMinPrice || 0}$</span>
                            <span className="color-grey">/ seminar</span>
                        </div>
                    </div>
                    {(user && user?.role === 'academy') &&
                        <>
                        <div className="flex items-center mb-20">
                                <CheckboxEl checked={agree} onChange={(e) => setAgree(!agree)} wrapperClasses='mr-20'/> 
                                <span>I agree with</span>&nbsp;<Link to='/terms-of-service' className='btn btn-link'>Terms and Conditions of Service</Link>
                            </div>
                        <Button
                            onClick={handleDiscussDetails}
                            className="btn btn--primary border-bottom mb-24 btn--lg w-full"
                        >
                            <span className="info text-20">Discuss Details</span>
                            <span className="ico">
                                <ChevronRignt />
                            </span>
                        </Button>
                        </>
                    }

                    <a href="#" className="btn color-secondary gap-10 w-full mb-28 border-bottom pb-28">
                        <ShareIcon />
                        <span className="underline not-italic fw-500 tt-none">
                            Share with Friends
                        </span>
                    </a>

                    <div className="ticket-info-body">
                        {pageData?.seminarDates?.map((item, index) => (
                            <div key={index} className="ticket-info-body__location">
                                <span className="ico"><img src={require("../../../assets/img/Compass.svg").default} alt="" /></span>
                                <span className="flex-auto">
                                    {
                                        [Country.getCountryByCode(pageData?.location)?.name, pageData?.address].filter(Boolean).join(", ") || `${pageData?.continent}, ${pageData?.country}, ${pageData.city}` ||"Location not specified"
                                    }
                                </span>
                                <span className="color-grey">{item?.date && format(item?.date, 'dd MMM, yyyy')}</span>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}

export default SeminarSinglePendingAsside
