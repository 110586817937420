import React from 'react'
import ReactPlayer from 'react-player'
import { getImageSrc } from '../../../helpers/utils'
import Button from '../../ui/buttons/Button'

const VideoPlayer = ({ url, ...rest }) => {
    return (
        <div className="video-wrapper">
            <ReactPlayer
                controls={true}
                className='react-player'
                width="100%"
                height="100%"
                playing={true}
                playsinline={true}
                url={getImageSrc(url)}
                playIcon={
                    <Button
                        className="btn--md btn-play">
                        <img src={require("../../../assets/img/video-play.svg").default} alt={"play"} />
                    </Button>
                }
                light={
                    <video controls={false} src={getImageSrc(url)}></video>
                }
                {...rest}
            />
        </div>
    )
}

export default VideoPlayer