import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as AddIcon } from "../../assets/img/ico-circle-add.svg"
import CardTour from '../../components/parts/cards/CardTour'
import Button from '../../components/ui/buttons/Button'
import PageLoader from '../../components/ui/PageLoader'
import { createSeminar } from '../../redux/thunks/seminarsThunks'
import { convertObjectToFormData } from "../../helpers/utils"
import { Country } from 'country-state-city'
import { resetSeminarFormData } from '../../redux/slices/planningSlice'

const PreviewTour = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [seminars, setSeminars] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    function openDatabase() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('toursDB', 1);

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains('tours')) {
                    db.createObjectStore('tours', { keyPath: 'id', autoIncrement: true });
                }
            };

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }

    async function getAllTours() {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(['tours'], 'readonly');
            const store = transaction.objectStore('tours');

            return new Promise((resolve, reject) => {
                const request = store.getAll();
                request.onsuccess = () => {
                    resolve(request.result);
                    setSeminars(request.result)
                };
                request.onerror = (event) => {
                    reject(event.target.error);
                };
            });
        } catch (error) {
            console.error('Failed to retrieve tours:', error);
        }
    }

    async function handleDelete(id) {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(['tours'], 'readwrite');
            const store = transaction.objectStore('tours');

            return new Promise((resolve, reject) => {
                const request = store.delete(id);
                request.onsuccess = () => {
                    console.log(`Tour with ID ${id} deleted successfully`);
                    setSeminars(prev => prev?.filter(item => item.id !== id) || [])
                    resolve();
                };
                request.onerror = (event) => {
                    console.error('Failed to delete tour:', event.target.error);
                    reject(event.target.error);
                };
            });
        } catch (error) {
            console.error('Failed to delete tour:', error);
        }
    }


    const handleCreateNewTour = () => {
        navigate("/plan-seminar?tour=true")
        dispatch(resetSeminarFormData())

    }
    const handleSubmit = async () => {
        if (!seminars?.length) return;
        setIsLoading(true);

        try {
            const promises = await Promise.all(seminars.map(async (formData) => {
                const body = convertObjectToFormData(formData);
                body.set("country", Country.getCountryByCode(formData.country)?.name || "");
                return await dispatch(createSeminar(body)).unwrap();
            }));

            if (promises.length === seminars.length) {
                dispatch(resetSeminarFormData());
                navigate("/plan-seminar/success");
            }
        } catch (error) {
            console.error('Failed to submit seminars:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllTours()
    }, [])


    return (
        <main className="content flex flex-col my-seminars-page">
            <section>
                <div className="container">
                    <div className="my-seminars-page__header mb-42">
                        <div className="my-seminars-page__header--row">
                            <div className="option">
                                <div className="options options--px-0">
                                    <div className="option">
                                        <Link to={".."} href="#" className="btn btn--md btn--default">Cancel</Link>
                                    </div>
                                </div>
                                <h4 className="heading">Preview Tour</h4>
                            </div>
                            <div className="option">
                                <div className="options options--px-0">
                                    <div className="option">
                                        <Button onClick={handleSubmit} isLoading={isLoading} disabled={!seminars?.length} className="btn btn--md btn--primary">
                                            <span className="info">Publish Tour</span>
                                            <span className="ico ico-18">
                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <path d="M7.25 3.375L12.875 9L7.25 14.625" stroke="currentColor"
                                                            strokeWidth="2" strokeLinecap="square"></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath>
                                                            <rect width="18" height="18" fill="white" transform="translate(0.5)"></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="my-seminars-page__body">
                        {
                            !isLoading ? (
                                <div className="row gx-24 gy-70">
                                    {
                                        seminars.map((seminar, index) => (
                                            <div key={index} className="col-xl-4 col-md-6">
                                                <CardTour data={seminar} onDelete={() => handleDelete(seminar.id)} />
                                            </div>
                                        ))
                                    }
                                    <div className="col-xl-4 col-md-6">
                                        <button onClick={handleCreateNewTour} type="button" className="btn add-photo-btn add-photo-btn--col">
                                            <span className="ico">
                                                <AddIcon />
                                            </span>
                                            <h4 className="heading">Add New Seminar</h4>
                                        </button>
                                    </div>
                                </div>
                            ) : <PageLoader />
                        }

                    </div>
                </div>
            </section>

        </main>
    )
}

export default PreviewTour