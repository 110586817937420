import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../components/ui/buttons/Button'
import { acceptSeminarRequestFetch } from '../../redux/thunks/seminarsThunks'
import Contract from './parts/Contract'

const ContractPage = () => {
    const { seminarId, chatId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [signature, setSignature] = useState(null)
    const [agree, setAgree] = useState(false)

    const handleSubmit = async () => {
        if(!agree) {
            toast.error("You need to confirm the Terms and Conditions and Privacy Policy")
            return
        }
        if (!signature || !(signature instanceof File)) {
            toast.error("You need to sign the contract")
            return
        }
        const body = new FormData()
        body.append("signature", signature, signature.name)
        const res = await dispatch(acceptSeminarRequestFetch({ seminarId: seminarId, body }))
        if (res) {
            navigate(`/chat/${chatId}`)
        }
    }
    return (
        <main className="content">
            <section className="section-42 document-page">
                <div className="container">
                    <Contract onSign={(signature) => setSignature(signature)} onAgree={(agree) => setAgree(agree)} />
                    <div className="page-action-group">
                        <Link to={`/chat/${chatId}`} className="btn btn--default btn--sm">Cancel</Link>
                        <Button onClick={handleSubmit} className="btn btn--primary btn--sm">
                            <span className="info">Confirm</span>
                            <span className="ico">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square"></path>
                                </svg>
                            </span>
                        </Button>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ContractPage