
import Fancybox from '../../../components/ui/Fancybox';
import { getImageSrc } from '../../../helpers/utils';


const GalleryImages = function ({ gallery = [], coverId }) {

    const getSortedGallery = () => {
        if (coverId && gallery.length) {
            const copy = [...gallery]
            const coverImage = copy.find(image => image.id === coverId)
            const index = copy.findIndex(image => image.id === coverId)
            copy.splice(index, 1)
            copy.unshift(coverImage)

            return copy
        }
        return gallery
    }
    const sorted = getSortedGallery()
    return (
        <>
            <div className="relative">
                <Fancybox wrapperClasses='grid-imgs'>
                    {sorted.map((item, index) => (
                        <a data-fancybox="gallery" className={`${index > 4 ? 'hidden' : ''}`} href={getImageSrc(item?.url || item?.file)} key={item.id}>
                            <img src={getImageSrc(item.url || item?.file)} alt={item?.url || item?.file?.name} />
                        </a>))}
                    {sorted.length > 5 && <div className="grid-imgs__show-all">
                        <button type='button' data-fancybox-trigger="gallery" data-fancybox-index='0' className="btn btn--default btn--sm radius" >show All</button>
                    </div>}
                </Fancybox>
            </div>
        </>
    )
}

export default GalleryImages
