import React from 'react'
import {Link, NavLink} from 'react-router-dom'

function HeaderSingUp({title}) {
    return (
        <>
            <header className="header header--white header-sign-up">
                <div className="container">
                    <div className="header__body">
                        <Link to={'/'} className="header__logo">
                            <img className="logo-white" src={require("../../assets/img/logo.svg").default} alt=""/>
                            <img className="logo-black" src={require("../../assets/img/logo-black.svg").default} alt=""/>
                        </Link>
                        <p className="header__title">{title}</p>
                        <div className="header__actions header__actions--desktop">
                            <p className="header__info">Already have an account?</p>
                            <NavLink to="/?is-login=true" className="btn btn--primary btn--md">Login</NavLink>
                        </div>
                    </div>
                    <div className='mobile-sign-up-banner header__actions'>
                        <p className="header__info">Already have an account?</p>
                        <NavLink to="/?is-login=true" className="btn btn--primary btn--md">Login</NavLink>
                    </div>
                </div>
                
            </header>
        </>
    )
}

export default HeaderSingUp
