import React, { useEffect, useRef, useState } from 'react'
import Input from '../../components/ui/formElements/Input';
import Button from '../../components/ui/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import InputPass from '../../components/ui/formElements/InputPass';
import Textarea from '../../components/ui/formElements/Textarea';
import api from "../../api";
import { useDispatch } from "react-redux";
import { setToken, } from "../../redux/slices/applicationSlice.js";
import { getUserFetch } from "../../redux/thunks/applicationThunks.js";
import { toast } from 'react-toastify';
import SelectOne from "../../components/ui/formElements/SelectOne";
import { State } from "country-state-city";
import MapBox from "../../components/parts/mapBox/MapBox";
import { getCoordinates } from "../../components/parts/mapBox/mapBoxFunctions";

import { ReactComponent as ChevronPrevIcon } from "../../assets/img/ico-chevron--prev.svg"


const AcademySingUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const test = false;

    const [data, setData] = useState(test ? {
        name: "Dosekor Boxing",
        email: "dosekor672@losvtn.com",
        phone: "+123456789",
        personInCharge: "Guy Hawkins",
        password: "dosekor672@losvtn.com",
        photo: null,
        addressLine: '',
        city: null,
        state: null,
        zip: null,
        about: `Welcome to Cobrinha Brazilian Jiu-Jitsu, the premier training academy founded by the legendary Rubens Charles "Cobrinha" Maciel. Our academy is dedicated to promoting excellence in Brazilian Jiu-Jitsu, offering classes for all ages and skill levels, from beginners to professional competitors. Under the guidance of Cobrinha, a multiple-time world champion and a pioneering figure in BJJ, we provide a supportive and challenging environment where students can learn...`
    } : {
        name: "",
        email: "",
        phone: "",
        personInCharge: "",
        password: "",
        photo: null,
        addressLine: '',
        city: null,
        state: null,
        zip: null,
        about: ``
    });

    const [photo64, setPhoto64] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");

    const [currentStep, setCurrentStep] = useState(1);

    const steps = [
        {
            title: 'WELCOME TO Plan Seminars',
            description: 'Let’s start, tell us a bit about yourself',
            bullet: false
        },
        {
            title: 'NICE TO MEET YOU!',
            description: 'Let’s start, tell us a bit about yourself',
            bullet: true
        },
        {
            title: 'ACADEMY INFO',
            description: 'Tell us a bit about your academy',
            bullet: true
        },
        {
            title: 'ACADEMY LOCATION',
            description: 'Tell us a bit about your academy',
            bullet: true
        },
        {
            title: 'Set your password',
            description: 'Create password for your account',
            bullet: true
        },
    ];

    const stepsHandler = () => {
        if (currentStep === 1 && !data.name.length) {
            toast.error("ACADEMY NAME is required")
            return;
        }

        if (currentStep === 2 && !data.email.length) {
            toast.error("EMAIL is required")
            return;
        }

        if (currentStep === 2 && !data.phone.length) {
            toast.error("PHONE is required")
            return;
        }

        if (currentStep === 4 && !data.addressLine) {
            toast.error("Address is required")
            return;
        }

        if (currentStep === 4 && !data.city) {
            toast.error("City is required")
            return;
        }

        if (currentStep === 4 && !data.state) {
            toast.error("State is required")
            return;
        }

        if (currentStep === 4 && !data.zip) {
            toast.error("Zip is required")
            return;
        }

        if (currentStep < steps.length) {
            setCurrentStep(prev => prev + 1)
        }
    }
    const allStates = State.getStatesOfCountry("US");
    const states = allStates?.map(item => ({ value: item.isoCode, label: item.name })) || [];
    const [coordinates, setCoordinates] = useState({ lat: null, lon: null })
    const [updateInfoValue, setUpdateInfoValue] = useState({})
    const [firstInit, setFirstInit] = useState(true)

    const updateInfoHandler = ({ target }) => {
        console.log(target.name, target.value);

        setData(prev => ({
            ...prev,
            [target.name]: target.value
        }))
        setUpdateInfoValue(prev => ({
            ...prev,
            [target.name]: target.value
        }))
    }

    const changeCoordinatesHandler = (obj) => {
        setCoordinates({
            lat: obj.lat,
            lon: obj.lon,
        })

        updateInfoHandler({ target: { name: 'latitude', value: `${obj.lat}` } })
        updateInfoHandler({ target: { name: 'longitude', value: `${obj.lon}` } })
    }

    useEffect(() => {
        if (!data) return

        const req = async (address) => {
            const res = await getCoordinates(address)

            if (coordinates.lat !== res.coordinates.lat || coordinates.lon !== res.coordinates.lon)
                changeCoordinatesHandler({
                    lat: res.coordinates.lat,
                    lon: res.coordinates.lon,
                })
        }

        if (data?.addressLine && data?.city && data?.zip) {
            const a = `${data?.addressLine} ${data?.city} ${updateInfoValue?.state} ${data?.zip}`
            req(a)
        }
        // 103 Bristol Dr, Richmond, KY 40475, США

    }, [data?.addressLine, data?.city, updateInfoValue?.state, data?.zip]);


    const toBase64 = async (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async (file) => {
        setData({
            ...data,
            photo: file
        });

        let res = await toBase64(file);
        setPhoto64(res);
    }

    const signUp = async () => {
        if (data.password !== confirmPassword) {
            toast.error(`"Confirm Password" is not equal to "Password"`);
            return;
        }

        const formData = new FormData();

        const fields = ['addressLine', 'city', 'state', 'zip', 'latitude', 'longitude']
        const generalAcademyInfo = {};

        Object.entries(data).forEach(item => {
            if (fields.includes(item[0])) {
                formData.append(`generalAcademyInfo[${item[0]}]`, item[1])
                // generalAcademyInfo[item[0]] = item[1]
            } else {
                formData.append(item[0], item[1])
            }
        });

        // formData.append('generalAcademyInfo', JSON.stringify(generalAcademyInfo))

        let response = await api.auth.signupAcademy(formData);

        if (response?.success) {
            if (response?.token) {
                localStorage.setItem('authToken', response.token);

                dispatch(setToken(response.token));
                dispatch(getUserFetch(response.token));

                navigate("/settings/my-profile");

                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
        } else {
            toast.error(response.message || "Something went wrong");
        }
    }


    const handleBack = () => {
        if (currentStep >= 2) {
            setCurrentStep(prev => prev - 1)
        }
    }


    return (
        <div className="container">
            {steps[currentStep - 1].bullet &&
                <div className="steps mb-36">
                    {steps.map((item, index) => (
                        item.bullet && (
                            <button onClick={() => setCurrentStep(index + 1)} key={index} className={`steps__item ${(index + 1) === currentStep ? 'active' : ''}`}></button>
                        )
                    ))}
                </div>
            }
            {steps.map((item, index) => (
                <React.Fragment key={index}>
                    {(index + 1) === currentStep &&
                        <div className="heading-block text-center mb-36">
                            <h3 className="heading">{item.title}</h3>
                            <p className="desk-heading">{item.description}</p>
                        </div>
                    }
                </React.Fragment>
            ))}
            <form className="form form--md">
                {currentStep === 1 &&
                    <>
                        <Input
                            wrapperClasses={'form-group input--lg'}
                            inputClasses={'input--solid'}
                            label={'Academy Name'}
                            placeholder={'e.g. Jiu-Jitsu Academy...'}
                            value={data.name}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    name: e.target.value
                                })
                            }}
                        />
                    </>
                }
                {currentStep === 2 &&
                    <>
                        <label className="input--file">
                            <input type="file" id="fileInput" onChange={(e) => {
                                if (e.target.files[0]) {
                                    changePhoto(e.target.files[0]);
                                }
                            }} />
                            <div className="preview overflow-hidden">
                                {
                                    data.photo && photo64 ? <img src={photo64} alt="" /> :
                                        <span className="ico ico--sm">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_100_11347)">
                                                    <path d="M16 13V21" stroke="#67676C" strokeWidth="1.4"
                                                        strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M12 17H20" stroke="#67676C" strokeWidth="1.4"
                                                        strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path
                                                        d="M10 8L11.7037 5.445C11.795 5.30819 11.9186 5.196 12.0636 5.11838C12.2086 5.04076 12.3705 5.0001 12.535 5H19.465C19.6295 5.0001 19.7914 5.04076 19.9364 5.11838C20.0814 5.196 20.205 5.30819 20.2962 5.445L22 8H26C26.5304 8 27.0391 8.21071 27.4142 8.58579C27.7893 8.96086 28 9.46957 28 10V24C28 24.5304 27.7893 25.0391 27.4142 25.4142C27.0391 25.7893 26.5304 26 26 26H6C5.46957 26 4.96086 25.7893 4.58579 25.4142C4.21071 25.0391 4 24.5304 4 24V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H10Z"
                                                        stroke="#67676C" strokeWidth="1.4" strokeLinecap="round"
                                                        strokeLinejoin="round"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_100_11347">
                                                        <rect width="32" height="32" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                }
                            </div>
                            <p className="label">Add a photo</p>
                        </label>
                        <Input
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--solid'}
                            label={'Full Name'}
                            placeholder={'e.g. Jane Smith, Wade Warren...'}
                            value={data.name}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    name: e.target.value
                                })
                            }}
                        />
                        <Input
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--solid'}
                            label={'Email'}
                            placeholder={'Enter your email...'}
                            value={data.email}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    email: e.target.value
                                })
                            }}
                        />
                        <Input
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--solid'}
                            label={'Phone'}
                            placeholder={'Enter your phone...'}
                            value={data.phone}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    phone: e.target.value
                                })
                            }}
                        />
                    </>
                }
                {currentStep === 3 &&
                    <>
                        <Input
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--solid'}
                            label={'PERSON IN CHARGE'}
                            placeholder={'e.g. Jane Smith, Wade Warren...'}
                            value={data.personInCharge}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    personInCharge: e.target.value
                                })
                            }}
                        />
                        <Textarea
                            wrapperClasses={'input--lg'}
                            textareaClasses={'input--solid'}
                            label={'Academy Description'}
                            placeholder={'Describe your academy...'}
                            rows={"4"}
                            value={data.about}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    about: e.target.value
                                })
                            }}
                        />
                    </>
                }
                {currentStep === 4 && <>
                    <div className="row gutters-form-2">
                        <Input
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--solid'}
                            placeholder={'6391 Elgin St. '}
                            name={'addressLine'}
                            label={"Address Line"}
                            value={data?.addressLine}
                            onChange={updateInfoHandler}
                        />
                        <div className="col-md-4 col-sm-4 col-12">
                            <Input
                                wrapperClasses={'input--lg'}
                                inputClasses={'input--solid'}
                                placeholder={'Milford'}
                                label={"Town / City"}
                                name={'city'}
                                value={data?.city}
                                onChange={updateInfoHandler}
                            />
                        </div>
                        <div className="col-md-4 col-sm-4 col-12">
                            <SelectOne
                                options={states}
                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                label={'State'}
                                placeholder={'State...'}
                                value={states?.find(item => item?.value === data?.state) || null}
                                onChange={(option) => {
                                    setUpdateInfoValue(prev => ({
                                        ...prev,
                                        'state': option.value
                                    }))
                                    setData({ ...data, state: option.value })

                                }}
                            />
                        </div>
                        <div className="col-md-4 col-sm-4 col-12">
                            <Input
                                wrapperClasses={'input--lg'}
                                inputClasses={'input--solid'}
                                placeholder={'10299'}
                                label={"ZIP"}
                                value={data?.zip}
                                name={'zip'}
                                onChange={updateInfoHandler}
                            />
                        </div>
                        <div className="academy-profile-settings map"
                            style={
                                {
                                    height: '200px'
                                }
                            }
                        >
                            <MapBox mainPoints={coordinates.lat ? [
                                {
                                    coordinates: [coordinates.lon, coordinates.lat]
                                }
                            ] : null}
                                newCenter={coordinates.lat ? [coordinates.lon, coordinates.lat] : null}
                                getCoordinatesOnclick={changeCoordinatesHandler}
                            />
                        </div>
                    </div>
                </>
                }
                {currentStep === 5 &&
                    <>
                        <InputPass
                            formGroupClass={'input--lg input--icon-right'}
                            placeholder={'Password'}
                            inputClass={'input--solid w-full'}
                            forgotPass={false}
                            label={'Password'}
                            value={data.password}
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    password: e.target.value
                                })
                            }}
                        />
                        <InputPass
                            formGroupClass={'input--lg input--icon-right'}
                            placeholder={'Confirm Password'}
                            inputClass={'input--solid w-full'}
                            forgotPass={false}
                            label={'Confirm Password'}
                            value={confirmPassword}
                            onInput={(e) => {
                                setConfirmPassword(e.target.value)
                            }}
                        />
                    </>
                }
                <div className='row-group gap--xs'>
                    {currentStep >= 2 && (
                        <Button onClick={handleBack} className="btn btn--default btn--lg w-full fs-20 mt-12">
                            <span className="ico ico-18">
                                <ChevronPrevIcon />
                            </span>
                            <span>Back</span>
                        </Button>
                    )}
                    {currentStep === steps.length ?
                        <Button onClick={(e) => {
                            e.preventDefault();
                            signUp();
                        }} className="btn btn--primary btn--lg w-full fs-20 mt-12">
                            <span className="info">Create Account</span>
                        </Button>
                        :
                        <Button className="btn btn--primary btn--lg w-full fs-20 mt-12" onClick={stepsHandler}>
                            <span className="info">Continue</span>
                            <span className="ico">
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.25 3.375L12.875 9L7.25 14.625" stroke="currentColor" strokeWidth="2"
                                        strokeLinecap="square"></path>
                                </svg>
                            </span>
                        </Button>
                    }
                </div>
            </form>
        </div>
    )
}

export default AcademySingUp
