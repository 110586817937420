import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CheckboxSwitch from '../components/ui/formElements/CheckboxSwitch'
import { toast } from 'react-toastify'

const CookiesSettings = () => {
    const initialData = [
        {
            label: 'Necessary Cookies',
            text: 'Necessary cookies are cookies required for our website to function and cannot be disabled. Your browser can be set to alert or even block these cookies. However, this may affect the effectiveness and function of our website for you.',
            hasCheckbox: false,
            enabled: true
        },
        {
            label: 'Functional Cookies',
            text: 'Functionality cookies are used by us to remember your preferences so that our platform can be customised for your use, without them certain functions of our platform may not be available.',
            hasCheckbox: true,
            enabled: true
        },
        {
            label: 'Performance Cookies',
            text: 'Performance cookies enhance our website performance and functionality. These cookies also allow us to improve site behaviours, personalise your browsing experience and inform us on how you use our website so that we can improve the functionality of our website for your use.',
            hasCheckbox: true,
            enabled: true
        },
        {
            label: 'Analytical Cookies',
            text: 'We use these cookies to collect information to help us understand how our website is being used. An example is counting the number of visitors and seeing how many visitors move around our Website when they are using it. This helps us to improve the way our Website works, for example, ensuring that users, such as yourself, find what you are looking for easily.',
            hasCheckbox: true,
            enabled: true
        }
    ]
    const [cookiesData, setCookiesData] = useState(JSON.parse(localStorage.getItem('cookies_settings')) || initialData)
    const handleSave = () => {
        localStorage.setItem('cookies_settings', JSON.stringify(cookiesData))
        toast.success('Saved!')
    }
    return (
        <section className="section">
            <div className="container">
                {/* <h2 className="heading text-center mb-44">Privacy Policy</h2> */}
                <div className="card-shadow document-card mb-42 terms-doc">
                    <div className="document-card__header">
                        <h3 className="heading text-center mb-44">Cookies Settings</h3>
                    </div>
                    <p><b>Your Privacy is important to Us.</b></p>
                    <p>
                    Any of your visited websites is likely to store and retrieve information off of your browser, this process, in most cases, is done in the form of cookies. The gathered data provides information about the user, user’s preferences, and the device in use. Such information often helps the site to perform according to a visitor’s expectations. We respect your every right to privacy, so you are free to disable cookies used by our Website at any time. It’s your right to opt out of the sharing act at any time, and you may do so by disabling particular types of cookies at any time. For more information on our use of cookies, please see our cookies policy.
                    </p>
                    <p><b>Managing your Cookies.</b></p>
                    <ol type='a'>
                        {cookiesData.map((item, index) => <li key={index}>
                            <div className='cookies-checkbox'>
                                <p>
                                    <strong>{item.label}</strong>
                                </p>
                                {item.hasCheckbox &&
                                    <CheckboxSwitch
                                    checked={item.enabled}
                                    onChange={(e) => setCookiesData(prev => prev.map((prevItem, prevIndex) => index === prevIndex ? ({...prevItem, enabled: !prevItem.enabled}) : prevItem))}
                                />}
                            </div>
                            <p>{item.text}</p>
                        </li>)}
                    </ol>
                    <button onClick={handleSave} className="btn btn--primary btn--lg gap-10">
                        <span className="info fs-20">Confirm My Choices</span>
                    </button>
                   
                </div>

            </div>
        </section>
    )
}

export default CookiesSettings