import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Button from '../../../components/ui/buttons/Button'
import { getImageSrc } from '../../../helpers/utils'
import { getUser } from '../../../redux/slices/applicationSlice'
import { StepDesign } from './StepDesign'
import { StepDetails } from './StepDetails'

import classNames from 'classnames'
import { useState } from 'react'
import { ReactComponent as AjustIcon } from "../../../assets/img/icon-ajust.svg"
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots.svg"
import { getChatByIdFetch } from '../../../redux/thunks/chatsThunks'
import { acceptSeminarRequestFetch } from '../../../redux/thunks/seminarsThunks'
import StepBooking from './StepBooking'
import { StepLocationDate } from './StepLocationDate'


const SidebarHeader = ({ status, seminarDetails, statusMap, users = [], accepts = [] }) => {
    return (
        <>
            <div className="row-group gap--xxl pb-24 border-bottom mb-24">
                <div className="image-wrapper --small-thumb">
                    <img src={getImageSrc(seminarDetails?.photos?.find(photo => photo.id === seminarDetails.coverId)?.url)} alt={seminarDetails?.seminarName} />
                </div>
                <div className="col-group gap--xxl justify-between">
                    <div className="seminar-label-info">
                        <h6 className="heading">{seminarDetails?.seminarName}</h6>
                    </div>
                    <Link
                        to={
                            status === "done" ?
                                `/seminars/${seminarDetails?.id}`
                                :
                                `/planed-by-academy/${seminarDetails?.id}`
                        }
                        className='color-secondary ttu italic fw-600 text--xs underline'>
                        {status === "done" ? "Show Seminar" : "Preview"}
                    </Link>
                </div>
            </div>
            <div className='row-group gap--sm pb-24 border-bottom mb-24'>
                <span className='seminar__status'>Status:</span>
                <span className={classNames("seminar__dot", {
                    "--done": status === "done",
                    "--rejected": status === "rejected"
                })} />
                {statusMap[status] && (
                    <span className='chat-item__name'>{statusMap[status].label}</span>
                )}
            </div>
            <div className='row-group gap--sm pb-24'>
                <span className='seminar__status'>Participants:</span>
                {
                    !!users?.length && (
                        <div className='row-group gap--xs'>
                            {users?.map(user => (
                                <div key={user.id} className='participant'>
                                    {accepts.includes(user.id) && <span className='sign'></span>}
                                    <img src={getImageSrc(user.photo)} alt="user" />
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
        </>
    )
}

const ChatSidebarSeminar = ({ chatData, academyAccept }) => {
    const dispatch = useDispatch()
    const { chatId } = useParams()
    const user = useSelector(getUser)
    const seminarDetails = chatData.seminar
    const ownerId = seminarDetails?.userId
    const [isTermsAccepted, setIsTermsAccepted] = useState(false)
    const isActiveSeminar = academyAccept?.status === 'active'
    const isAccepted = academyAccept?.accepts?.length && academyAccept?.accepts.find(({ userId }) => userId === user?.id)

    const status = academyAccept ? (isActiveSeminar ? "done" : chatData?.academyAccept?.step || "done")
        :
        (seminarDetails.status === "active" ?
            "done" :
            [
                seminarDetails?.instructors?.[0]?.invite_status_instructor,
                seminarDetails?.instructors?.[0]?.invite_status_academy
            ].includes("rejected") ? "rejected" : "pending"
        )

    const handleAccept = async () => {
        const res = await dispatch(acceptSeminarRequestFetch({ seminarId: seminarDetails.id, requestId: academyAccept?.id })).unwrap()
        if (res) {
            dispatch(getChatByIdFetch({ chatId: chatData?.id }))
        }
    }
    if (seminarDetails.status === 'inactive') return null


    const statusMap = {
        rejected: {
            label: "Request Rejected",
        },
        pending: {
            label: "Pending Request",
        },
        'location_and_date': {
            label: "Discussing Location & Date",
            stepName: "Location & Date",
            actions: (
                <Link className='btn btn--primary btn--sm text-14 w-full' to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}`}>
                    <span className="ico">
                        <AjustIcon />
                    </span>
                    <span className="info">Adjust Location & Date</span>
                </Link>
            ),
        },
        details: {
            label: "Discussing Details",
            stepName: "Seminar Details",
            actions: (
                <Link className='btn btn--primary btn--sm text-14 w-full' to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/seminar-info`}>
                    <span className="ico">
                        <AjustIcon />
                    </span>
                    <span className="info">Adjust Seminar Details</span>
                </Link>
            ),
        },
        design: {
            label: "Discussing Design",
            stepName: "Design & Text",
            actions: (
                <Link className='btn btn--primary btn--sm text-14 w-full' to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/design-text`}>
                    <span className="ico">
                        <AjustIcon />
                    </span>
                    <span className="info">Adjust Design & Text</span>
                </Link>
            )
        },
        terms: {
            label: "Booking Specific Terms",
            actions: (
                <> {
                    isTermsAccepted ?
                        (
                            <Link className='btn btn--primary btn--sm text-14 w-full' to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/summary`}>
                                <span className="ico">
                                    <AjustIcon />
                                </span>
                                <span className="info">Proceed to Summary</span>
                            </Link>
                        )
                        :
                        (
                            <div className='row-group gap--xs'>
                                <Button className='btn btn--default btn--sm text-14 w-full' onClick={() => setIsTermsAccepted(true)}>
                                    <span className="info">Skip</span>
                                </Button>
                                <Button className='btn btn--primary btn--sm text-14 w-full' onClick={() => setIsTermsAccepted(true)}>
                                    <span className="info">Accept</span>
                                </Button>
                            </div>
                        )
                }
                </>
            ),
        },
        done: {
            label: "Active Seminar",
            button: null
        }
    };


    return (
        <aside className='chat-sidebar' >
            <div className="chat-sidebar__header">
                <h6 className="heading">Seminar Information</h6>
                <div className="row-group gap--xxl">
                    {/* <button className="btn btn--sm btn--square">
                        <span className="ico">
                            <DotsIcon />
                        </span>
                    </button> */}
                </div>
            </div>
            {
                <div className="chat-sidebar__body" >
                    <SidebarHeader
                        status={status}
                        seminarDetails={seminarDetails}
                        statusMap={statusMap}
                        accepts={academyAccept?.accepts?.map(item => item.userId)}
                        users={chatData?.users}
                    />

                    <StepLocationDate
                        canEdit={!isActiveSeminar}
                        seminarDetails={seminarDetails}
                        currentStatus={status}
                        status={["location", "pending", "rejected"]}
                    />
                    <StepDetails
                        seminarDetails={seminarDetails}
                        currentStatus={status}
                        status={["details", "pending", "rejected"]}
                    />
                    <StepDesign
                        seminarDetails={seminarDetails}
                        currentStatus={status}
                        status={["design", "pending", "rejected"]}
                    />
                    <StepBooking
                        data={academyAccept?.terms}
                        canEdit={!isActiveSeminar}
                        currentStatus={status}
                        status="terms"
                        link={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/booking`}
                    />

                    {
                        !isAccepted && (
                            <div className="chat-sidebar__footer gap--xs">

                                {
                                    // not active && is owner
                                    !isActiveSeminar && user?.id === ownerId && (
                                        statusMap?.[status]?.actions
                                    )
                                }
                                {
                                    // not confirmed && not active && current status is terms && terms is accepted && not owner
                                    // !isAccepted && !isActiveSeminar && !(status === "terms" && !isTermsAccepted) && user?.id !== ownerId &&
                                    !isActiveSeminar && status !== "terms" && user?.id !== ownerId &&
                                    <Button
                                        onClick={handleAccept}
                                        className="btn btn--primary btn--sm text-14 w-full"
                                    >
                                        <span className="info">
                                            Accept {statusMap?.[status]?.stepName || ""}
                                        </span>
                                    </Button>
                                }
                                {
                                    // not confirmed && not active && current status is terms && terms is accepted && not owner
                                    !isActiveSeminar && status === "terms" && isTermsAccepted && user?.id !== ownerId &&
                                    <div className='row-group gap--xs'>
                                        <Button className='btn btn--default btn--sm text-14 w-full' onClick={() => setIsTermsAccepted(true)}>
                                            <span className="ico">
                                                <AjustIcon />
                                            </span>
                                            <span className="info">Adjust</span>
                                        </Button>
                                        <Button className='btn btn--primary btn--sm text-14 w-full' onClick={handleAccept}>
                                            <span className="info">Confirm Offer</span>
                                        </Button>
                                    </div>

                                }
                                {
                                    // not confirmed && not active && current status is terms && not accept terms && not owner
                                    status === "terms" && !isActiveSeminar && !isTermsAccepted && user?.id !== ownerId &&
                                    statusMap?.["terms"]?.actions
                                }
                            </div>
                        )
                    }
                </div >
            }
        </aside >
    )
}

export default ChatSidebarSeminar