const base = 'api/contracts';

export const contracts = (instance) => ({
    // для академии и инструктора
    async getMyContracts() {
        const { data } = await instance.get(`${base}`);
        return data;
    },
    async signToContract(singId) {
        const { data } = await instance.post(`${base}/${singId}/sign`);
        return data;
    },
})