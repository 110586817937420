import React from 'react'
import { useTimer } from 'react-timer-hook';

const Timer = ({ expiryTimestamp }) => {
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
      } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    return (
        <div className="deadline-countdown">
            <div className="countdown-number">
                <span className="--days countdown-time">{days}</span>
                <span className="countdown-text">Day</span>
            </div>
            <div className="countdown-number">
                <span className="--days countdown-time">{hours}</span>
                <span className="countdown-text">HRS</span>
            </div>
            <div className="countdown-number">
                <span className="--days countdown-time">{minutes}</span>
                <span className="countdown-text">MIN</span>
            </div>
            <div className="countdown-number">
                <span className="--days countdown-time">{seconds}</span>
                <span className="countdown-text">SEc</span>
            </div>
        </div>
    )
}

export default Timer
