import classNames from 'classnames'
import { format, isToday } from 'date-fns';
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import profilePlaceholder from '../../../assets/img/user-profile.svg';
import { getImageSrc } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import {getChatOnlineUsers} from "../../../redux/slices/socketChatSlice";
import {getUnreadMessages} from "../../../redux/slices/chatsSlice";

const ChatItem = ({ chat, currentChat }) => {
    const navigate = useNavigate()
    const user = useSelector(getUser)
    
    const unread = useSelector(getUnreadMessages)

    const isUnread = unread.filter(el => el.chatId === chat.id).length
    
    const getRecipient = (chat) => {
        return chat?.users?.find(recipient => recipient?.id !== user?.id)
    }

    const onlineArr = useSelector(getChatOnlineUsers)
    const isOnline = onlineArr.includes(chat?.users?.[1].id)
    
    return (
        <div className={classNames("chat-item-wrapper flex flex-col", {
            active: chat?.id === currentChat?.id
        })}>
            <div className="chat-item" onClick={() => navigate(`/chat/${chat.id}`)}>
                <div className='relative'>
                    <div className="chat-item__image">
                        {
                            chat?.users?.length > 2 ?
                                chat?.users?.slice(0, 3).map(user => (
                                    <img
                                        className="ico ico--md chat-item__image---group"
                                        src={getImageSrc(user?.photo, profilePlaceholder)}
                                        alt={user?.fullName}
                                        key={user.id}
                                    />
                                ))
                                :
                                <img
                                    className="ico ico--md"
                                    src={getImageSrc(getRecipient(chat)?.photo, profilePlaceholder)}
                                    alt={getRecipient(chat)?.fullName}
                                />
                        }

                    </div>
                    {isOnline && <span className="seminar__dot --done user-online"></span>}
                </div>


                <div className="chat-item__body">
                    <div className="chat-item__heading">
                        <p className="chat-item__name">
                            {
                                chat?.users?.length > 2 ?
                                    [chat.users.map(user => user?.fullName).slice(0, 2), "+1"].join(", ")
                                    :
                                    getRecipient(chat).fullName
                            }
                        </p>

                        <span className="chat-item__date">
                            {format(chat?.updatedAt || chat.createdAt, isToday(chat?.updatedAt || chat.createdAt) ? "HH:mm" : 'E')}
                        </span>
                    </div>
                    <p className="color-text fw-500">
                    {chat?.seminar?.seminarName}
                    </p>
                    <div className={`chat-item__message ${isUnread ? 'unread-message' : ''}`}>
                        <p>{chat?.message?.message || "There is no messages yet"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatItem