import React, { useState } from 'react'
import Input from '../../components/ui/formElements/Input'
import api from "../../api";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/slices/applicationSlice.js";

const Security = () => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        password: "",
        newPassword: ""
    });

    const [confirmPassword, setConfirmPassword] = useState("");

    const save = async() => {
        if(!data?.password?.length || !data?.newPassword?.length || !confirmPassword?.length){
            toast.error(`All fields are required`);
            return;
        }

        if(data.newPassword !== confirmPassword){
            toast.error(`"CONFIRM PASSWORD" is not equal to "NEW PASSWORD"`);
            return;
        }

		let updateUserRes = await api.users.updateUser(data);

        if(updateUserRes.success){
            toast.success(updateUserRes.message || "Updated");
            if(updateUserRes?.token){
                localStorage.setItem('authToken', updateUserRes.token);
			    dispatch(setToken(updateUserRes.token));
            }
        } else {
            toast.error(updateUserRes?.message || "Error");
        }
    }

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">Security</h3>
            </div>
            <form className="form form-settings" method="post" onSubmit={(e)=>{
                e.preventDefault();
            }}>
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        type="password"
                                        label={'Current Password'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Current Password'}
                                        value={data?.password}
                                        onInput={(e)=>{
                                            setData((prev)=>{
                                                return {
                                                    ...prev,
                                                    password: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        type="password"
                                        label={'New Password'}
                                        inputClasses={'input--solid'}
                                        placeholder={'New Password'}
                                        value={data?.newPassword}
                                        onInput={(e)=>{
                                            setData((prev)=>{
                                                return {
                                                    ...prev,
                                                    newPassword: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        wrapperClasses={'input--lg'}
                                        type="password"
                                        label={'Confirm Password'}
                                        inputClasses={'input--solid'}
                                        placeholder={'Confirm Password'}
                                        value={confirmPassword}
                                        onInput={(e)=>{
                                            setConfirmPassword(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-footer">
                    <div className="flex-group justify-end">
                        <button type="submit" className="btn btn--primary btn--sm" onClick={(e)=>{
                            e.preventDefault();
                            save();
                        }}>
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Security
