import React, { useState } from 'react'
import { SeminarTemplate } from '../parts/SeminarTemplate';
import { useSelector } from 'react-redux';
import { getAllCampsFetch } from '../../../redux/thunks/planCampThuncks';
import { Country } from 'country-state-city';
import { belts, languages, trainingStyle } from '../../../constants';

export const CampsPendingByAcademy = () => {
  const { camps, isLoading, pagination } = useSelector(state => state.planCamps)
  const [filters] = useState({
    sort_by: "",
    sort_as: "desc",
    gender: "",
    location: "",
    price: "",
    trainingStyle: "",
    belt: "",
    language: "",
    search: "",
    page: "",
    limit: ""
  })

  const allCountries = Country.getAllCountries();
  const locationOptions = allCountries?.map(item => ({ value: item.name.toLowerCase(), label: item.name })) || [];
  const priceOptions = ["$$$$$", "$$$$", "$$$", "$$", "$"].map(item => ({ label: item, value: item }));
  const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({ label: title, value: title }))
  const beltOptions = belts.map(({ title, key }) => ({ label: title, value: title }))
  const languageOptions = languages.map(({ title, key }) => ({ label: title, value: title }))
  const sortOptions = ['id', 'locations'].map(item => ({ label: item, value: item }));

  const filtersData = [
    // { label: "Location", query: "location", options: locationOptions },
    // { label: "Seminar Price", query: "price", options: priceOptions },
    // { label: "Gi or No-Gi", query: "trainingStyle", options: trainingStyleOptions },
    // { label: "Belt Rank", query: "belt", options: beltOptions },
    // { label: "Language", query: "language", options: languageOptions },
    { label: "Gi or No-Gi", query: "trainingStyle", options: trainingStyleOptions, all: true },
    { label: "Belt Rank", query: "belt", options: beltOptions },
  ];
  return (
    <>
      <SeminarTemplate
        filterLinks={[
          {label: 'Seminars', link:'/planed-by-academy'},
          {label: 'Camps', link:'/planed-by-academy/camps'}
      ]}
        firsScreenOptions={{
          title: 'SEMINARS / Camps',
          description: 'Discover the seminars currently active on our platform',
          placeholder: 'What seminar do you want to find?',
          imageBg: require('../../../assets/img/current-seminars.png')
        }}
        filters={filters}
        filtersData={filtersData}
        sotrBy={sortOptions}

        baseQuery='status=pending&role=academy'
        fethFuntion={getAllCampsFetch}

        seminars={camps}
        pagination={pagination}
        isLoading={isLoading}
      />
    </>
  )
}
