import { Link } from 'react-router-dom'
import Button from '../ui/buttons/Button'
import ModalTemplate from './ModalTemplate'

import LogoDark from '../../assets/img/logo-black.svg'

const DiscussionHelperModal = ({ open, handleClose, }) => {

    return (
        <ModalTemplate open={open} modalSize={"modal__dialog--xs"} onClick={handleClose}>
            <div className="modal__body">
                <form className="form form--lg">
                    <div className="form-header">
                        <div className="heading-block">
                            <Link to={'/'} className="mb-32">
                                <img className="logo-black" src={LogoDark} alt="" />
                            </Link>
                            <h5 className="heading fw-800">Type a message in the chat and start discussing the seminar details</h5>
                        </div>
                        <ul className="color-text marked-list --no-border gap--sm col-group">
                            <li>
                                You can see all the details to be discussed on the right side of the chat.
                            </li>
                            <li>
                                The other party may adjust the terms based on what you agree on here.
                            </li>
                            <li>
                                Once everything is settled, please accept the terms.
                            </li>
                        </ul>
                    </div>
                    <Button onClick={handleClose} className={"btn btn--lg btn--primary"}>Okay</Button>
                </form>
            </div>
        </ModalTemplate>
    )
}

export default DiscussionHelperModal