import React from 'react'

const PlatformBenefits = ({ blockData }) => {
    const listData = blockData?.blockPresets?.[0]?.value?.data || [
        {
            title: 'Easier to Arrange and book Seminars/Camps',
            description: 'Easily arrange seminars by customizing and posting for academies or instructors to apply.'
        },
        {
            title: 'Promotion and free marketing',
            description: 'Get your seminar or camp listed on our site for thousands to see and easily share to boost ticket sales.'
        },
        {
            title: 'No risks involved',
            description: 'We handle all payments to ensure both instructors and academies are guaranteed to be paid promptly after the seminar. '
        },
        {
            title: 'Security around deposits and payments',
            description: 'Our platform ensures that every transaction and payment happens smoothly and with safe precautions.'
        },
        {
            title: 'Unknown instructors get a chance to be seen',
            description: 'Passionate BJJ instructors, even those not actively competing, can showcase their skills and gain visibility.'
        },
        {
            title: 'Easier to communicate',
            description: 'Instructors and academies can easily communicate back and forward on the platform through the chat.'
        },
    ]

    const images = [
        {
            image: require('../../assets/img/benefit-0.png'),
        },
        {
            image: require('../../assets/img/benefit-1.png'),
        },
        {
            image: require('../../assets/img/benefit-2.png'),
        },
        {
            image: require('../../assets/img/benefit-3.png'),
        },
        {
            image: require('../../assets/img/benefit-4.png'),
        },
        {
            image: require('../../assets/img/benefit-5.png'),
        },
    ]
    return (
        <section className="section relative">
            <div className='firts-screen-bg'>
                <img src={require('../../assets/img/benefits.png')} alt=""/>
            </div>
            <div className="container">
                <h2 className="heading text-center mb-44 benefits-heading" dangerouslySetInnerHTML={{__html: blockData?.blockPresets?.[1]?.value || `Platform Benefits` }}></h2>
                <div className="row gx-60 benefit-row">
                    {listData.map((item, index) => (
                        <div className="col-12 col-md-6 col-xl-4 flex flex-col benefit-gut-rule" key={index}>
                            <div className='benefit-item'>
                                <div className='benefit-item-heading'>
                                    <div className='benefit-item-rectangle'>
                                        <div className='benefit-item-rectangle-sub'></div>
                                    </div>
                                    <div className='benefit-item-image'>
                                        <img src={item.icon ? (process.env.REACT_APP_API_URL + item.icon) : images[index].image} alt=""/>
                                    </div>
                                </div>
                                <div className='benefit-item-title'>
                                    {item.title}
                                </div>
                                <div className='benefit-item-desc'>
                                    {item.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    )
}

export default PlatformBenefits
