import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Input from '../../../components/ui/formElements/Input';
import SelectMulty from '../../../components/ui/formElements/SelectMulty';
import Textarea from '../../../components/ui/formElements/Textarea';
import UploadVideos from '../../../components/ui/formElements/UploadVideos';
import PageLoader from '../../../components/ui/PageLoader';
import { teachingStyles } from '../../../constants';
import { convertObjectToFormData } from '../../../helpers/utils';
import SeminarPhotosUpload from '../parts/SeminarPhotosUpload';

import { resetSeminarFormData } from '../../../redux/slices/planningSlice';
import { createCampFetch, updateCampFetch } from '../../../redux/thunks/planCampThuncks';
import HotelInfoSection from '../parts/HotelInfoSection';
import IncludedSection from '../parts/IncludedSection';
import PopularQuestions from '../parts/PopularQuestions';
import ScheduleDates from '../parts/ScheduleDates';
import { pick } from 'lodash';
import { updateSeminarFetch } from '../../../redux/thunks/seminarsThunks';

const DesignText = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { campId, chatId } = useParams()
    const [editedFields, setEditedFields] = useState([])

    const [isLoading, setIsLoading] = useState()
    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();

    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [coverImage, setCoverImage] = useState(null)

    const teachingStyleOptions = teachingStyles.map(({ key, title }) => ({ value: key, label: title }))


    const handleSubmit = async () => {
        setIsErrorsShown(true)
        setIsLoading(true)
        if (campId) {
            if (!errors.hasRequiredError) {
                const body = new FormData()
                const dataToUpdate = pick(formData, editedFields)
                convertObjectToFormData(dataToUpdate, body)

                const res = await dispatch(updateCampFetch({ campId, body })).unwrap()
                if (res) {
                    navigate(`/chat/${chatId}`)
                }
            }
        } else {
            if (!errors.hasRequiredError) {
                const body = new FormData()
                convertObjectToFormData(formData, body)

                const res = await dispatch(createCampFetch(body)).unwrap()
                if (res) {
                    dispatch(resetSeminarFormData())
                    navigate("../success")
                }
            }
        }
        setIsLoading(false)
    }
    const handleStepChange = (name, value) => {
        handleChange(name, value)
        setEditedFields(prev => [...prev, name])
    }
    const handleChangePhotos = (photos) => {
        handleStepChange("photos", photos)
    }
    const handleChangeVideos = (videos) => {
        handleStepChange("videos", videos)
    }

    // useEffect(() => {
    //     handlePreviewChange("cover", coverImage)
    //     handleStepChange("coverImageName", coverImage?.name)
    // }, [coverImage])

    useEffect(() => {
        handlePreviewChange("cover", coverImage)
        const getCoverName = (cover) => cover?.name || cover?.originalname
        if (coverImage?.id) {
            handleStepChange("coverImageId", coverImage?.id)
            return
        }
        if (getCoverName(coverImage)) {
            handleStepChange("coverImageName", getCoverName(coverImage))
        } else if (formData.photos?.length) {
            handleStepChange("coverImageName", getCoverName(formData.photos[0]))
        } else {
            handleStepChange("coverImageName", "")
        }
    }, [coverImage])


    useEffect(() => {
        setErrorConfig({
            requiredFields: [
                "traningStyle",
                "photos",
                "name",
                "teachingStyles",
                "summary",
                // "videos",
                // "hotelLinks",
                // "what_included",
                "startAt",
                "endAt",
                // "schedule",
                // "faq",
            ]
        })
    }, [formData])

    return (
        <>
            {
                !isLoading ?
                    <>
                        <SeminarPhotosUpload
                            images={formData?.photos}
                            setImages={handleChangePhotos}
                            setCoverImage={setCoverImage}
                            coverImage={coverImage}
                            title="Camp Photo(S)"
                        // error={isErrorsShown && errors.photos}
                        />
                        <hr className="hr mb-42" />
                        <h6 className="heading mb-32">Camp Info</h6>
                        <div className="row gx-16 py-24 mb-42">
                            <div className="col-12">
                                <Input
                                    label="Camp Name"
                                    wrapperClasses={"input--lg pb-32 border-bottom"}
                                    inputClasses={"input--solid"}
                                    value={formData?.name}
                                    error={isErrorsShown && errors?.name}
                                    onChange={(e) => handleStepChange("name", e.target.value)}
                                    placeholder={"Add a name here..."}
                                />
                            </div>
                            <div className="col-12">
                                <SelectMulty
                                    label={"Teaching Style"}
                                    wrapperClasses={"select--outline select--outline-bg input--lg w-full pb-32 border-bottom"}
                                    options={teachingStyleOptions}
                                    value={formData.teachingStyles}
                                    error={isErrorsShown && errors?.teachingStyles}
                                    onChange={(options) => handleStepChange("teachingStyles", options.map(({ value }) => value))}
                                    placeholder={"Add teaching style..."}
                                />
                            </div>

                            <div className="col-12">
                                <Textarea
                                    label="Summary"
                                    wrapperClasses={"input--lg"}
                                    textareaClasses={"input--solid"}
                                    style={{ minHeight: 140 }}
                                    value={formData?.summary}
                                    error={isErrorsShown && errors?.summary}
                                    onChange={(e) => handleStepChange("summary", e.target.value)}
                                    placeholder={"Please write summary here..."}
                                />
                            </div>
                            {
                                campId && (
                                    <>
                                        <div className="col-12">
                                            <UploadVideos
                                                videos={formData?.videos}
                                                setVideos={handleChangeVideos}
                                                error={isErrorsShown && errors?.videos}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <HotelInfoSection
                                                data={formData?.hotelLink}
                                                handleChange={(data) => handleStepChange("hotelLink", data)}
                                                errors={errors?.hotelLink}
                                                isErrorsShown={isErrorsShown}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <IncludedSection
                                                data={formData?.what_included}
                                                handleChange={(data) => handleStepChange("what_included", data)}
                                                error={isErrorsShown && errors?.what_included}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="border-bottom mb-42 pb-42">
                                                <label className="label mb-12">Camp Schedule</label>
                                                <ScheduleDates
                                                    startAt={formData?.startAt}
                                                    endAt={formData?.endAt}
                                                    data={formData?.schedule}
                                                    handleChange={(data) => handleStepChange("schedule", data)}
                                                    handleInit={(data) => handleChange("schedule", data)}
                                                    errors={errors?.schedule}
                                                    isErrorsShown={isErrorsShown}
                                                />
                                            </div>

                                            <PopularQuestions
                                                data={formData.faq}
                                                handleChange={(data) => handleStepChange("faq", data)}
                                                errors={errors?.faq}
                                                isErrorsShown={isErrorsShown}
                                            />

                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="page-action-group">
                            <Link to={"../seminar-info"} className="btn btn--default btn--sm">Cancel</Link>
                            <button onClick={handleSubmit} className={`btn btn--primary btn--sm ${isLoading ? "disabled" : ""}`}>
                                <span className="info">Continue</span>
                                <span className="ico">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </>
                    :
                    <PageLoader />
            }
        </>
    )
}

export default DesignText