import React from 'react'
import { NavLink } from 'react-router-dom'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import SelectFilters from '../../../components/parts/SelectFilters'
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch'

const SeminarFilters = ({ onChange, viewStatus = 'defautl', links = [], filters, filtersData, totalCards, sortBy, name = "seminars" }) => {

    return (
        <>
            <div className="flex items-center justify-between flex-wrap gap-24 seminar-filters">
                {
                    !!links.length && (
                        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
                            <div className="select-checkers">
                                {links.map((link, index) => (
                                    <NavLink to={link.link} key={index}
                                        end
                                        className={({ isActive }) => isActive ? "btn btn--md radius btn--default" : "btn btn--md"}>
                                        <span>{link.label}</span>
                                    </NavLink>
                                ))}

                            </div>
                        </div>
                    )
                }
                <div className='seminar-filters-grid'>
                    <SelectFilters showAll={true} filters={filters} handleChange={onChange} filtersData={filtersData} />
                    <SelectOne
                        wrapperClasses={"lg-hidden form-group select--outline w-150 input--md select-border-option"}
                        value={filters.sort_by}
                        options={sortBy}
                        onChange={(option) => onChange('sort_by', option.value)}
                    />
                </div>
            </div>

            <div className="flex items-center justify-between flex-wrap gap-24">
                <span className="instructors__counter mb-12 lg-flex hidden">{totalCards} {name} are available</span>
                <div className="flex items-center flex-wrap mb-12 gap-24">
                    {true ?
                        <div className='hidden lg-flex items-center flex-wrap gap-24'>
                            <CheckboxSwitch
                                text="Women only"
                                checked={filters.gender === "female"}
                                onChange={() => onChange("gender", filters.gender === "female" ? "" : "female")}
                            />
                            <CheckboxSwitch
                                text="Men only"
                                checked={filters.gender === "male"}
                                onChange={() => onChange("gender", filters.gender === "male" ? "" : "male")}
                            />
                        </div>
                        :
                        <label className="switch">
                            <div className="switch-group">
                                <input type="checkbox" className="switch__field" />
                                <span className="switch__slider"></span>
                            </div>
                            <span className="switch__text">Min. Attendance</span>
                        </label>
                    }
                    <div className="items-center lg-flex hidden">
                        <span className="label-sort">Sort by:</span>
                        <div className="form-group select--sort input--md">
                            <SelectOne
                                value={filters.sort_by}
                                options={sortBy}
                                onChange={(option) => onChange('sort_by', option.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <span className="instructors__counter mb-12 flex lg-hidden">{totalCards} {name} are available</span>
        </>
    )
}

export default SeminarFilters
