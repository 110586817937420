import { useState } from "react"
import { Link } from "react-router-dom"

export const Step = ({ title, children, href }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <div className='chat-sidebar__accordion gap--lg' onClick={() => setIsOpen(!isOpen)}>
                <span className="ico">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_310_26441)">
                            <path d="M3.4375 11H18.5625" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
                            {
                                !isOpen && (
                                    <path d="M11 3.4375V18.5625" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
                                )
                            }
                        </g>
                        <defs>
                            <clipPath id="clip0_310_26441">
                                <rect width="22" height="22" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                <h4 className="flex-auto">{title}</h4>
                <Link to={href} className="color-secondary">Edit</Link>
            </div>
            {
                (isOpen) && (
                    <div className={`col-group gap-24 pt-24 ${isOpen ? "pb-24 border-bottom" : ""}`}>
                        {children}
                    </div>
                )
            }
        </>
    )
}