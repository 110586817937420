import { useEffect } from 'react';
import { Link, RouterProvider, useNavigate } from "react-router-dom";
import { router } from "./routers/routers";


import 'air-datepicker/air-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

import './assets/css/about-us-new.css';
import './assets/css/custom.css';
import './assets/css/customMobile.css';
import './assets/css/media-bravo.css';
import './assets/css/react-styles.css';
import './assets/fonts/OSans-Condensed/stylesheet.css';

import { toast, ToastContainer } from 'react-toastify';
// import { getUser } from "./redux/slices/applicationSlice.js";
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";
import CookiesBanner from './components/parts/CookiesBanner';
import { getUser } from "./redux/slices/applicationSlice";
import { addUnreadMessage, changeStatus, setNewMessage, setUnreadMessages, upChatToTop, updateChat } from "./redux/slices/chatsSlice";
import { setUnreadNotificationsCount } from './redux/slices/notificationsSlice';
import {
	getChatSocket,
	setOnlineOneUser,
	setOnlineUsers,
	setSocket,
	setUserOffline
} from "./redux/slices/socketChatSlice";
import { getUserFetch } from "./redux/thunks/applicationThunks";
import { getAllChatsFetch } from "./redux/thunks/chatsThunks";
import { getImageSrc } from './helpers/utils';
import Button from './components/ui/buttons/Button';



function App() {
	const dispatch = useDispatch();
	// const user = useSelector(getUser);
	// const chatSocket = useSelector(getChatSocket)

	// useEffect(() => {
	// 	const token = localStorage.getItem('authToken')

	// 	const req = async () => {
	// 		const socket = io('https://bjj.webstaginghub.com', {
	// 			path: '/socket',
	// 			transports: ['websocket'],
	// 			auth: {
	// 				token
	// 			}
	// 		});

	// 		dispatch(getAllChatsFetch())

	// 		dispatch(setSocket(socket))

	// 		socket.on('chat:newMessage', data => {
	// 			dispatch(setNewMessage(data))
	// 		});

	// 		socket.on('chat:newMessage', data => {
	// 			dispatch(upChatToTop(data))
	// 		});

	// 		socket.on('chat:onlineChatUsers', data => {
	// 			dispatch(setOnlineUsers(data))
	// 		});

	// 		socket.on('chat:userOffline', data => {
	// 			dispatch(setUserOffline(data))
	// 		});

	// 		socket.on('chat:userOnline', data => {
	// 			dispatch(setOnlineOneUser(data))
	// 		});

	// 		socket.on('chat:unreadMessagesCount', data => {
	// 			dispatch(setUnreadMessages(data))
	// 		});

	// 		socket.on('chat:readedMessages', data => {
	// 			dispatch(addUnreadMessage(data))
	// 		});

	// 		socket.on('chat:eventUpdate', data => {
	// 			dispatch(changeStatus(data))
	// 		});
	// 		socket.on('chat:updated', data => {
	// 			dispatch(updateChat(data))
	// 		});

	// 		socket.on('notifications:unreaded', data => {
	// 			dispatch(setUnreadNotificationsCount(data))
	// 		});

	// 		socket.on('notifications:new', data => {
	// 			const navigate = (id) => window.location.pathname = `/instructors/${id}`;
	// 			if (data.length) {
	// 				data.forEach(item => {
	// 					const content = (
	// 						<div className='col-group gap--sm'>
	// 							<div className='row-group gap--sm'>
	// 								<div className='image-wrapper avatar'>
	// 									<img src={getImageSrc(item?.payload?.photo)} alt="instructor" />
	// 								</div>
	// 								<h6>{item?.payload?.fullName}</h6>
	// 							</div>
	// 							<p className='color-text'>
	// 								{item.message}
	// 							</p>
	// 							<Button className={"btn--primary btn--sm"} onClick={() => navigate(item.id)}>To instructor</Button>
	// 						</div>
	// 					)
	// 					toast(content)
	// 				})
	// 			}
	// 		});
	// 	}

	// 	if (token && !chatSocket) {
	// 		req()
	// 	}

	// }, [user?.id]);




	useEffect(() => {
		const token = localStorage.getItem('authToken')

		const req = async () => {
			await dispatch(getUserFetch())
		}

		if (token) {
			req()
		}
		

	}, []);

	return (
		<div className="App">
			<RouterProvider router={router}></RouterProvider>
			<ToastContainer />
			<CookiesBanner />
		</div>
	);
}

export default App;
