import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/ui/Loader'
import { getUser } from '../../../redux/slices/applicationSlice'

import classNames from 'classnames'
import { ReactComponent as ChevronRightIcon } from "../../../assets/img/ico-chevron--next.svg"
import { ReactComponent as SearchIcon } from "../../../assets/img/ico-search-loupe.svg"
import { getDateRange, getImageSrc } from '../../../helpers/utils'
import { getSeminarsMyAvailableToInstructorFetch } from '../../../redux/thunks/seminarsThunks'


const InviteSeminar = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(getUser);


    const [seminarsShownCount, setSeminarsShownCount] = useState(5)

    const [selectedSeminar, setSelectedSeminar] = useState(null)


    const { seminars, isLoading: isSeminarsLoading } = useSelector(state => state.seminars)

    const [shownSeminars, setShownSeminars] = useState(seminars)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        setShownSeminars(
            seminars?.filter(seminar =>
                seminar?.seminarName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [seminars, searchTerm]);


    const handleSubmit = async () => {
        if (!selectedSeminar) {
            toast.error("Select at least one seminar");
        }
        const res = await dispatch(sendSeminarRequestToInstructorFetch({ seminarId: selectedSeminar, instructorId: id })).unwrap()
        if (res?.chatId) {
            navigate(`/chat/${res?.chatId}`);
        }

    };

    const handleSeminarSelect = (seminarId) => {
        setSelectedSeminar(seminarId)
    }

    useEffect(() => {
        if (!id) return;
        dispatch(getSeminarsMyAvailableToInstructorFetch(id))
    }, [])
    return (
        <>
            <div className="form-body">
                <div className="form-items-list">
                    <div className="form-item">
                        <div className="row gutters-form-4">
                            <div className="col-12">
                                <div className="heading-row">
                                    <div className="flex-ico-ss-text">
                                        <h6 className="heading">
                                            Select seminar(s) or create new to invite instructor:
                                        </h6>
                                    </div>
                                    <div className="flex-group">
                                        {
                                            user?.role === "academy" &&
                                            <Link to={"/plan-seminar"} className="btn btn--primary btn--sm">
                                                <span className="info">Create New Planned Seminar</span>
                                            </Link>
                                        }
                                        {
                                            user?.role === "instructor" &&
                                            <Link to={"/plan-seminar/instructor"} className="btn btn--primary btn--sm">
                                                <span className="info">Create New Planned Seminar</span>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group input--md input--icon-left">
                                    <div className="input-wrapper">
                                        <span className="ico color-text">
                                            <SearchIcon />
                                        </span>
                                        <input
                                            className="input input--solid"
                                            type="text" placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                {
                                    !isSeminarsLoading && shownSeminars?.length ?
                                        <ul className="row gutters-form">
                                            {
                                                [...shownSeminars].splice(0, seminarsShownCount).map(seminar => (
                                                    <li className="col-12" key={seminar.id}>
                                                        <label className="select-check">
                                                            <input
                                                                type="checkbox"
                                                                // checked={selectedSeminarsIds.includes(seminar.id)}
                                                                checked={selectedSeminar === seminar.id}
                                                                onChange={() => handleSeminarSelect(seminar.id)}
                                                                hidden
                                                                className='hidden'
                                                            />
                                                            <div className="select-seminar-label">
                                                                <div className="seminar-label-item">
                                                                    <span className="check"></span>
                                                                </div>
                                                                <div className="seminar-label-item">
                                                                    <div className="image-wrapper --small-thumb">
                                                                        <img src={getImageSrc(seminar.cover)} />
                                                                    </div>
                                                                </div>
                                                                <div className="seminar-label-item">
                                                                    <div className="seminar-label-info">
                                                                        <p className="text-sm color-text">{seminar.customLocation}</p>
                                                                        <h6 className="heading">{seminar.seminarName}</h6>
                                                                    </div>
                                                                    <p className="text-sm">{getDateRange(seminar?.seminarDates)}</p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        : isSeminarsLoading ?
                                            <Loader />
                                            :
                                            <p>Not found</p>
                                }
                            </div>
                            {
                                shownSeminars?.length > seminarsShownCount ?
                                    <div className="col-12">
                                        <button onClick={() => setSeminarsShownCount(prev => prev + 5)} type="button"
                                            className="btn btn--sm radius btn-light w-full">
                                            <span>Show More</span>
                                        </button>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-footer">
                <div className="flex-group justify-between">
                    <Link to={-1} className="btn btn--default btn--sm">Cancel</Link>
                    <button onClick={handleSubmit} type="button" disabled={!selectedSeminar} className={classNames("btn btn--primary btn--sm", { "disabled": !selectedSeminar })}>
                        <span className="info">Send Invite</span>
                        <span className="ico">
                            <ChevronRightIcon />
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default InviteSeminar