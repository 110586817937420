import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch';
import PageLoader from '../../../components/ui/PageLoader';
import { getDateRange, getImageSrc } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import { acceptCampRequestFetch, getSingleCampFetch } from '../../../redux/thunks/planCampThuncks';
import { acceptSeminarRequestFetch, getSeminarsById } from '../../../redux/thunks/seminarsThunks';
import { Step } from '../parts/Step';


export const CardDetails = ({ isEdited, label, children }) => {
    return (
        <div className="form-group input--lg">
            <label className="label flex items-center gap--xs">
                {label}
                {
                    isEdited ?
                        <span className="flex">
                            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="6" height="6" rx="3" fill="#9747FF" />
                            </svg>
                        </span>
                        : null
                }

            </label>
            <div className='col-group gap--xs text--sm'>
                {(Array.isArray(children) && children.length) || (!Array.isArray(children) && children) ? children : "Not specified"}
            </div>
        </div>
    )
}

const CampSummary = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [agree, setAgree] = useState({
        booking: false,
        terms: false
    })
    const { campId, chatId, requestId } = useParams()
    const user = useSelector(getUser)

    const { camps, campDetails, isLoading } = useSelector(state => state.planCamps)

    useEffect(() => {
        if (!campId) return;
        dispatch(getSingleCampFetch(campId))
    }, [campId])


    const changeAgree = (type) => {
        setAgree(prev => ({
            ...prev,
            [type]: !prev[type]
        }))
    }


    const handleSubmit = async () => {
        if (Object.values(agree).some(value => !value)) {
            toast.warn("You must agree to the terms")
            return
        }
        const res = await dispatch(acceptCampRequestFetch({ plancampId: campDetails.id, requestId })).unwrap()
        if (res) {
            navigate(`/chat/${chatId}`)
        }
    }
    return (
        <>
            <section className="section-42">
                <div className="container">
                    {
                        !isLoading && campDetails ?
                            <div className="double-section flex gap-80">
                                <div className="double-section__content">
                                    <h3 className="heading">Seminar Summary</h3>
                                    <div className="row-group gap--xxl">
                                        <div className="image-wrapper --small-thumb">
                                            <img src={getImageSrc(campDetails?.photos?.find(photo => photo.id === campDetails.coverId)?.url)} alt={campDetails?.seminarName} />
                                        </div>
                                        <div className="col-group flex-auto gap--lg justify-between">
                                            <p className='text--xs color-text'>{moment(campDetails?.startAt).format("DD MMM, YYYY")}
                                                {" "}
                                                -
                                                {" "}
                                                {moment(campDetails?.startAt).format("DD MMM, YYYY")}</p>
                                            <div className="seminar-label-info">
                                                <h6 className="heading">{campDetails?.name}</h6>
                                            </div>
                                        </div>
                                        <Link
                                            to={""}
                                            className='btn btn--default btn--sm'>
                                            Preview
                                        </Link>
                                    </div>
                                    <div className="col-group">
                                        <Step title={"Location & Date"} href={"./../"}>
                                            <div className='row gx-16 py-24 mb-10'>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Country"}>
                                                        <p>{campDetails.location}</p>
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Address"}>
                                                        <p>{campDetails.address}</p>
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-6">
                                                    <CardDetails label={"Dates & time"}>
                                                        <div className="row-group justify-between">
                                                            <p className='fw-500'>Start</p>
                                                            <p className='color-text'>{moment(campDetails.startAt).format("DD MMM, YYYY")}</p>
                                                        </div>
                                                        <div className="row-group justify-between">
                                                            <p className='fw-500'>End</p>
                                                            <p className='color-text'>{moment(campDetails.endAt).format("DD MMM, YYYY")}</p>
                                                        </div>
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </Step>
                                        <Step title={"Seminar Details"} href={"./../seminar-info"}>
                                            <div className="row gx-16 py-24 mb-10">
                                                <div className="col-12">
                                                    <CardDetails label={"Teaching Styles"}>
                                                        {
                                                            campDetails.teachingStyles.map(item => (
                                                                item
                                                            ))
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Language"}>
                                                        {
                                                            campDetails.languages?.map(item => (
                                                                item
                                                            ))
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Gi or NoGi"}>
                                                        {
                                                            campDetails.traningStyle
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Max Gym Capacity"}>
                                                        {
                                                            campDetails.maxGymCapacity
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Target Audience"}>
                                                        {
                                                            campDetails.targetAudience
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Under the Age of 18"}>
                                                        {
                                                            campDetails.allowUnder18 ? "Allowed" : "Not Allowed"
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Min Attendance"}>
                                                        {
                                                            campDetails.minAttendance
                                                        }
                                                    </CardDetails>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label={"Min Attendance Deadline"}>
                                                        {
                                                            campDetails.minAttendanceDeadline
                                                        }
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </Step>
                                        <Step title={"Design & Text"} href={"./../design-text"}>
                                            {
                                                campDetails?.photos?.length ?
                                                    <div className="row gutters-form-7">
                                                        {
                                                            campDetails?.photos.map(photo => (
                                                                <div className="col-2" key={photo?.id}>
                                                                    <img className='w-full' src={getImageSrc(photo?.url)} style={{ height: 96 }} alt={photo?.originalname} />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    : null
                                            }
                                            <CardDetails
                                                label={"Seminar Name"}

                                            >
                                                <p>{campDetails?.seminarName}</p>
                                            </CardDetails>
                                            <CardDetails
                                                label={"Summary"}
                                            >
                                                <p>{campDetails?.summary}</p>
                                            </CardDetails>
                                        </Step>
                                        <Step title={"Booking Specific Terms"} href={"./../booking"}>
                                            <div className='terms-doc text--sm'>
                                                <ol className='col-group gap--lg' style={{ listStylePosition: "inside" }}>
                                                    <li>
                                                        Reservation Confirmation
                                                        <br />
                                                        <br />
                                                        <p>
                                                            All reservations are subject to availability and are confirmed upon receipt of a confirmation email from our booking team. Please ensure that all details provided at the time of booking are accurate. Any discrepancies should be reported within 24 hours of receiving the confirmation.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Payment and Cancellation
                                                        <br />
                                                        <br />
                                                        <p>
                                                            Payment must be made in full at the time of booking. We accept all major credit cards and other payment methods as specified at checkout. Cancellations made more than 48 hours before the scheduled arrival time will receive a full refund. Cancellations made within 48 hours of arrival are non-refundable.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Check-in and Check-out
                                                        <br />
                                                        <br />
                                                        <p>
                                                            Standard check-in time is 3:00 PM, and check-out time is 11:00 AM. Early check-in or late check-out requests are subject to availability and may incur additional charges. Guests are responsible for informing the property of any delays in arrival.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Changes to Reservations
                                                        <br />
                                                        <br />
                                                        <p>
                                                            Any changes to the reservation, including but not limited to changes in dates, number of guests, or room type, must be requested at least 72 hours before the scheduled arrival date. All changes are subject to availability and may result in additional charges.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Liability and Responsibility
                                                        <br />
                                                        <br />
                                                        <p>
                                                            The property is not responsible for any personal injuries, damages, or loss of belongings during your stay. Guests are advised to take necessary precautions and secure their valuables. In case of any property damage caused by the guest, the cost of repair or replacement will be charged to the guest's account.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        House Rules and Regulations
                                                        <br />
                                                        <br />
                                                        <p>
                                                            Guests are expected to adhere to the property’s house rules, which include maintaining cleanliness, respecting noise levels, and adhering to any specific regulations communicated by the property management. Violation of these rules may result in additional charges or immediate termination of the booking.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Force Majeure
                                                        <br />
                                                        <br />
                                                        <p>
                                                            In the event of circumstances beyond our control, including but not limited to natural disasters, strikes, or governmental actions, the property reserves the right to cancel or modify the booking. In such cases, guests will be offered alternative arrangements or a full refund.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        Privacy and Data Protection
                                                        <br />
                                                        <br />
                                                        <p>
                                                            All personal information provided during the booking process will be handled in accordance with our privacy policy. We are committed to protecting your data and will not share your information with third parties without your consent, except as required by law.
                                                        </p>
                                                    </li>

                                                </ol>
                                            </div>
                                        </Step>
                                    </div>
                                    <button onClick={handleSubmit} className="btn btn--primary btn--lg">
                                        <span className="info">Confirm</span>
                                    </button>
                                    <div className="option-group mb-36">
                                        <CheckboxSwitch
                                            text={
                                                <>
                                                    I have read and agreed to the
                                                    {" "}
                                                    <Link className='color-secondary' to={"terms"}>
                                                        Terms and Conditions
                                                    </Link>
                                                    {" "}
                                                    and
                                                    {" "}
                                                    <Link className='color-secondary' to={"privacy"}>Privacy Policy</Link>
                                                </>
                                            }

                                            checked={agree.terms}
                                            onChange={() => changeAgree("terms")}
                                        />
                                        <CheckboxSwitch
                                            text={"I accept the Booking Specific Terms (if included)"}
                                            checked={agree.booking}
                                            onChange={() => changeAgree("booking")}
                                        />
                                    </div>
                                </div>
                                <div className='double-section__aside'>
                                    <div className="card-shadow px-36 py-32 mb-16">
                                        <div className="instructor-profile__group">
                                            <div className="instructor-profile__image-logo">
                                                <img className="ico ico--md rounded-full"
                                                    src={getImageSrc(user?.profile?.photo)}
                                                    alt={user?.profile?.name} />
                                            </div>
                                            <div className="instructor-profile__group-info">
                                                <p className="instructor-profile__name">{user?.profile?.name}</p>
                                                <p className="instructor-profile__location">{user?.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-shadow px-36 py-32">
                                        <h6 className='heading mb-28'>Price Information</h6>
                                        <div className="ticket-info-body ticket-info-body--no-border">
                                            <div className="ticket-info-body__item justify-between border-bottom pb-28 mb-28">
                                                <span className="color-text">Shared Profit:</span>
                                                <span className="fw-500">
                                                    {campDetails?.sharedProfit} / {100 - campDetails?.sharedProfit}%</span>
                                            </div>
                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">Instructor Cut:</span>
                                                <span className="fw-500">$ 1000</span>
                                            </div>
                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">Academy Potential Revenue:</span>
                                                <span className="fw-500">$ 1000</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : isLoading ?
                                <PageLoader />
                                : <h2 className='heading'>Seminar is not found</h2>
                    }
                </div>
            </section>
        </>
    )
}

export default CampSummary