import React from 'react'
import { ReactComponent as ChevronPrevIcon } from "../../../assets/img/ico-chevron--prev.svg"
import { useDispatch } from 'react-redux'
import { reset, setCurrentTemplateImage } from '../../../redux/slices/editorSlice'
import { Link } from 'react-router-dom'
import Button from '../../../components/ui/buttons/Button'

const HeaderTemplateEditor = ({ onSubmit, isApplying, handleBack }) => {
    const dispatch = useDispatch()
    const handleReset = () => {
        dispatch(reset())
    }

    // const handleChangeImage = (e) => {
    //     const image = e.target.files[0]
    //     if(image) {
    //         dispatch(setCurrentTemplateImage(URL.createObjectURL(image)))
    //     }
    // }
    return (
        <header className="header header--white">
            <div className="container container-full">
                <div className="header__body">
                    <Button onClick={handleBack} className="btn btn--default btn--md">
                        <span className="ico ico-18">
                            <ChevronPrevIcon />
                        </span>
                        <span>Back</span>
                    </Button>

                    <nav className="header__menu">
                        <ul className="header__list">
                            {/* <li>
                                <div className="dropdown header__dropdown" data-dropdown="dropdown">
                                    <label>
                                        <input onChange={handleChangeImage} accept="image/*" type="file" className='hidden' hidden />
                                        <span
                                            className="header__link header__dropdown-button"
                                        >
                                            <span>Open</span>
                                            <span className="ico ico-20">
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.9033 7.64344C1.93167 7.71196 1.97972 7.77054 2.04138 
                                                7.81176C2.10304 7.85298 2.17554 7.87498 2.2497 7.875L9.7497 7.875C9.82392 7.87506 
                                                9.89648 7.8531 9.9582 7.81189C10.0199 7.77069 10.068 7.71211 10.0964 7.64355C10.1248 
                                                7.57499 10.1323 7.49954 10.1178 7.42676C10.1033 7.35398 10.0675 7.28713 10.015 
                                                7.23469L6.26502 3.48469C6.23019 3.44982 6.18883 3.42216 6.14331 3.40329C6.09778 3.38442 
                                                6.04899 3.3747 5.9997 3.3747C5.95042 3.3747 5.90163 3.38442 5.8561 3.40329C5.81058 3.42216 
                                                5.76922 3.44982 5.73439 3.48469L1.98439 7.23469C1.93196 7.28716 1.89628 7.354 1.88184 
                                                7.42676C1.86741 7.49952 1.87487 7.57492 1.9033 7.64344Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li> */}
                            <li>
                                <button onClick={handleReset} className="header__link">Reset</button>
                            </li>
                        </ul>
                    </nav>
                    <div className="header__actions">
                        <Button isLoading={isApplying} type='button' onClick={onSubmit} className="btn btn--primary btn--md">Apply Cover</Button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderTemplateEditor