import ModalTemplate from "./ModalTemplate";
import React, {useEffect, useState} from "react";
import Input from "../ui/formElements/Input";
import SelectOne from "../ui/formElements/SelectOne";
import api from "../../api";

export const EditCooperateTeam = ({open, handleClose, data, onSuccess}) => {

    const [name, setName] = useState('')
    const [logo, setLogo] = useState(null)
    const [formConfig, setImageConfig] = useState({
        imageChangeOnNew: false,
        nameChangeOnNew: false
    })
    const [imageTeam64, setImageTeam64] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})



    useEffect(() => {
        if (data.name) {
            setName(data.name)
        }
    }, []);

    const save = async (e) => {
        e.preventDefault()

        if (!name) {
            setErrors(prev => ({...prev, name: true}))
            return
        }
        
        const form = new FormData()
        
        if (formConfig.imageChangeOnNew) {
            form.append('logo', logo)
        }

        form.append('name', name)


        setIsLoading(true)
        
        const res = await api.cooperateTeam.updateCooperateTeam(form, data.id)

        if (res.success) {
            onSuccess && onSuccess({
                action: 'update',
                id: data.id,
                team: res.data
            })

            handleClose && handleClose()

        }



        setIsLoading(false)
    }

    const deleteTeam = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await api.cooperateTeam.deleteCooperateTeam(data.id)

        if (res.success) {
            onSuccess && onSuccess({
                action: 'delete',
                id: data.id
            })

            handleClose && handleClose()
        }

        setIsLoading(false)
    }

    const toBase64 = async(file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async(file) => {
        if (!file) return
        let res = await toBase64(file);
        setImageConfig(prev => ({...prev, imageChangeOnNew: true}))
        setLogo(file)
        setImageTeam64(res)
    }


    return (
        <>
            <ModalTemplate open={open} onClick={handleClose}>
                <div className="modal__body">
                    <form className="form form--lg">
                        <div className="form-header">
                            <div className="heading-block">
                                <h3 className="heading">Edit Team</h3>
                                {/*<p className="text--md desk-heading">*/}
                                {/*    Select one of options below to create account*/}
                                {/*</p>*/}
                            </div>
                        </div>

                        <div className="form-body">
                            <div className="row gutters-form">

                                <div className="col-12 flex justify-center">
                                    <label htmlFor={'team-img-edit'} className="tw-item tw-logo cursor-pointer">
                                        <div className="ico ico--lg rounded-full border-ico">
                                            {
                                                imageTeam64 ?
                                                    <img
                                                        src={imageTeam64 ? imageTeam64 : (process.env.REACT_APP_API_URL + imageTeam64)}
                                                        alt=""/>
                                                    : data?.logo ? <img src={(process.env.REACT_APP_API_URL + data.logo)} /> : null
                                            }
                                        </div>
                                        <input name='team-img-edit' id='team-img-edit' type="file" hidden disabled={isLoading}
                                               onChange={e => changePhoto(e.target.files[0])}
                                        />
                                    </label>
                                </div>

                                <div className="col-12">
                                    <div className="add-widget-control">
                                        <Input
                                            disabled={isLoading}
                                            wrapperClasses={'input--lg'}
                                            inputClasses={'input--solid'}
                                            placeholder={'Name'}
                                            label={"Team Name"}
                                            value={name}
                                            onInput={(e) => {
                                                setName(e.currentTarget.value);
                                                setErrors(prevState => ({...prevState, name: false}))
                                            }}
                                        />
                                    </div>
                                    {errors.name && <p className='error-input'>Enter a team name</p>}
                                </div>

                            </div>
                        </div>

                        <div className="form-footer">

                            <div className={`flex-group justify-between`}>
                                <button onClick={deleteTeam}
                                        className={`btn btn--danger btn--sm ${isLoading ? 'disabled' : ''}`}>
                                    <span>Delete</span>
                                </button>
                                <button onClick={save} className={`btn btn--primary btn--sm ${isLoading ? 'disabled' : ''}`}><span>Save</span>
                                </button>
                            </div>
                        </div>

                    </form>

                </div>


            </ModalTemplate>
        </>
    )
}