import React, { useState } from 'react'
import { SeminarTemplate } from '../parts/SeminarTemplate';
import { belts, languages, trainingStyle } from '../../../constants';
import { Country } from 'country-state-city';
import { getAllSeminarsWithFiltersFetch } from '../../../redux/thunks/seminarsThunks';
import { useSelector } from 'react-redux';

const SeminarsPendingByAcademy = () => {
    const { seminars, pagination, isLoading } = useSelector(state => state.seminars)
    const [filters] = useState({
        sort_by: "",
        sort_as: "desc",
        gender: "",
        location: "",
        price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
        limit: ""
    })

    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map(item => ({ value: item.name.toLowerCase(), label: item.name })) || [];
    const priceOptions = ["$$$$$", "$$$$", "$$$", "$$", "$"].map(item => ({ label: item, value: item }));
    const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({ label: title, value: title }))
    const beltOptions = belts.map(({ title, key }) => ({ label: title, value: title }))
    const languageOptions = languages.map(({ title, key }) => ({ label: title, value: title }))
    const sortOptions = ['id', 'locations'].map(item => ({ label: item, value: item }));

    const filtersData = [
        { label: "Location", query: "location", options: locationOptions, all: true },
        { label: "Seminar Price", query: "price", options: priceOptions, all: true },
        { label: "Gi or No-Gi", query: "trainingStyle", options: trainingStyleOptions, all: true },
        { label: "Belt Rank", query: "belt", options: beltOptions },
        { label: "Language", query: "language", options: languageOptions, all: true },
    ];
    
    return (
        <>  
            <SeminarTemplate 
                pageType='pending'
                filterLinks={[
                    {label: 'Seminars', link:'/planed-by-academy'},
                    {label: 'Camps', link:'/planed-by-academy/camps'}
                ]}
                firsScreenOptions={{
                    title: 'Pending SEMINARS / Camps',
                    description: 'Discover the seminars and camps from academies that needs instructors',
                    placeholder: 'What seminar do you want to find?',
                    imageBg: require('../../../assets/img/pending-seminars-bg.png')
                }} 
                filters={filters}
                filtersData = {filtersData}
                sotrBy={sortOptions}
                
                baseQuery='status=pending&role=academy'
                fethFuntion={getAllSeminarsWithFiltersFetch}
               
                
                seminars={seminars}
                pagination={pagination}
                isLoading={isLoading}
            />
        </>
    )
}

export default SeminarsPendingByAcademy
