import DesignText from "./DesignText";
import PlanCampWrapper from "./PlanCampWrapper";
import SeminarInformation from "./SeminarInformation";
import TimeAndDate from "./TimeAndDate";
import BookingTerms from "./BookingTerms";

export const PlanCamp = {
    PlanCampWrapper,
    TimeAndDate,
    SeminarInformation,
    DesignText,
    BookingTerms
}