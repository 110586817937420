import React from "react";

import { ReactComponent as CheckIco } from "../../../../assets/img/ico-check.svg"


export const PLConfirmUsers = () => {


    return (
        <>
            <div className="flex gap-8 flex-col">
                <div className='border-bottom w-full'></div>

                <div className='pt-24 flex flex-col gap-20 fs-14'>
                    <div className='flex justify-between pb-24'>
                        <div className='flex gap-10 items-center'>
                            <div className="private-lesson-user">
                                <img className="ico ico--sm"
                                     src="https://bjj.webstaginghub.com//uploads/30ba9583-0634-48e1-a22b-a2a70847e020_1721813076375_.jpeg"
                                     alt="Annette Black"/></div>
                            <p className="fw-500">Leslie Alexander</p>
                        </div>

                        <div className="fw-500 flex gap-10 items-center private-lesson-confirmed">
                            <div className='ico'>
                                <CheckIco/>
                            </div>
                            Confirmed
                        </div>

                    </div>

                    <div className='flex justify-between pb-24'>
                        <div className='flex gap-10 items-center'>
                            <div className="private-lesson-user">
                                <img className="ico ico--sm"
                                     src="https://bjj.webstaginghub.com//uploads/6710dab6-e0c8-4cf7-b878-0f48cad74b39_1721813594969_.png"
                                     alt="Annette Black"/></div>
                            <p className="fw-500">Mikey Musumeci</p>
                        </div>

                        <div className="fw-500 flex gap-10 items-center ">
                            Not Confirmed
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}