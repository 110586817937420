import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import EventDatesSelect from '../../pages/planning/parts/EventDatesSelect'
import { updateRequesToPrivateLessonFetch } from '../../redux/thunks/privateLessonsThunks'
import Button from '../ui/buttons/Button'
import ModalTemplate from './ModalTemplate'

const ReschedulePrivateLessonModal = ({ open, handleClose, onSubmit, data = [], id }) => {
    const dispatch = useDispatch()
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [dates, setDates] = useState(data)

    const handleChange = (data) => {
        setDates(data)
    }
    useEffect(() => {
        setDates(data)
    }, [data])

    const handleSubmit = async () => {
        setIsErrorsShown(true)
        const res = await dispatch(updateRequesToPrivateLessonFetch({ id, body: { dates } })).unwrap()
        if (res?.success) {
            handleClose && handleClose()
        }
        if (onSubmit) {
            onSubmit()
        }
    }

    return (
        <ModalTemplate open={open} modalSize={"modal__dialog--sm"} onClick={handleClose}>
            <div className="modal__body">
                <form className="form form--lg">
                    <div className="form-header">
                        <div className="heading-block">
                            <h3 className="heading">Reschedule Private Lesson</h3>
                            <p className="text--md desk-heading">
                                Change date and time  in fields below.
                            </p>
                        </div>
                    </div>
                    <EventDatesSelect dates={dates} isErrorsShown={isErrorsShown} handleChange={handleChange} />
                    <Button onClick={handleSubmit} type='button' className={"btn btn--lg btn--primary"}>Save Changes</Button>
                </form>
            </div>
        </ModalTemplate>
    )
}

export default ReschedulePrivateLessonModal