import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'


export const useTabs = (tabs) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const defaultTab = tabs[0].id
    const [activeTab, setActiveTab] = useState(
        (searchParams.get('view')) || defaultTab,
    )

    useEffect(() => {
        setSearchParams((prev) => {
            prev.set('view', activeTab)
            return prev
        })
    }, [activeTab, setSearchParams])

    return { activeTab, setActiveTab }
}
