import { createSlice } from '@reduxjs/toolkit'
import { getInstructorGeneralInfoFetch, getUserFetch } from '../thunks/applicationThunks';

const initialState = {
    authorized: null,
    user: null,
    token: null,
    generalInfo: {},
    isGeneralInfoLoading: false,
    authModalOpen: null
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },

        setToken: (state, action) => {
            state.token = action.payload;
        },

        setLogOut: (state, action) => {
            localStorage.removeItem('authToken')

            state.authorized = null
            state.user = null
            state.token = null
        },
        setAuthModalOpen: (state, { payload }) => {
            state.authModalOpen = payload
        }
    },

    extraReducers(builder) {
        builder.addCase(getUserFetch.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
            state.modal = {
                show: false,
                name: '',
                payload: {}
            }
        });

        builder.addCase(getUserFetch.pending, (state, action) => {
            state.authorized = null;
        });

        builder.addCase(getUserFetch.rejected, (state, action) => {
            localStorage.removeItem('authToken');
            state.authorized = false;
            state.token = null;
        });

        builder.addCase(getInstructorGeneralInfoFetch.fulfilled, (state, action) => {
            state.generalInfo = action.payload;
            state.isGeneralInfoLoading = false
        });

        builder.addCase(getInstructorGeneralInfoFetch.pending, (state, action) => {
            state.isGeneralInfoLoading = true
        });

        builder.addCase(getInstructorGeneralInfoFetch.rejected, (state, action) => {
            state.isGeneralInfoLoading = false
        });
    }
})

export const {
    setUser, setAuthorized, setToken, setLogOut, setAuthModalOpen
} = applicationSlice.actions;

export const getUser = (state) => state.application.user;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;

export default applicationSlice.reducer