const base = 'api/instructors';

export const instructors = (instance) => ({
    async getInstructorsAll(query) {
        const { data } = await instance.get(`${base}/pub?${query}`);
        return data;
    },
    async getInstructorInfo(instructorId) {
        const { data } = await instance.get(`${base}/${instructorId}`);
        return data;
    },
    async findInstructor(q) {
        const { data } = await instance.get(`${base}/invite-search?search=${q}`);
        return data;
    },
})