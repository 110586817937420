const urlBase = 'api/instructor-private-session-info';

export const instructorPrivateSessionInfo = (instance) => ({
    async getInstructorPrivateSessionInfo() {
        const { data } = await instance.get(`${urlBase}`);
        return data;
    },
    async updateInstructorPrivateSessionInfo(payload) {
        const { data } = await instance.put(`${urlBase}`, payload);
        return data;
    },
    async createNewAvailabilities(payload){
        const { data } = await instance.post(`${urlBase}/availability`, payload);
        return data;
    },
    async availabilityDeleteByDays(payload){
        const { data } = await instance.post(`${urlBase}/availability/delete-by-days`, payload);
        return data;
    },
    async addNewRescheduleDate(payload){
        const { data } = await instance.post(`${urlBase}/rescheduled-date`, payload);
        return data;
    },
    async addOrUpdateRescheduledDates(payload){
        const { data } = await instance.post(`${urlBase}/rescheduled-dates`, payload);
        return data;
    },
    async deleteRescheduleDate(id){
        const { data } = await instance.post(`${urlBase}/rescheduled-dates/delete`, { ids: [id] });
        return data;
    },
})