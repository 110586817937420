import React, { useEffect, useState } from 'react'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import Input from '../../../components/ui/formElements/Input'
import SelectMulty from '../../../components/ui/formElements/SelectMulty'
import Textarea from '../../../components/ui/formElements/Textarea'
import { Country, State } from 'country-state-city';
import api from "../../../api";
import { toast } from 'react-toastify';
import { AddPastInstructors } from "../../../components/modals/AddPastInstructors";
import { EditCooperateTeam } from "../../../components/modals/EditCooperateTeam";
import { belts, trainingStyle, weightDevision } from '../../../constants'

const GeneralInfoInstructor = () => {
    const [data, setData] = useState(null);

    const [updateData, setUpdateData] = useState({});

    const [openAddInstructorModal, setOpenAddInstructorModal] = useState({
        open: false, mode: null, instructor: null
    })

    const [openAddTeamModal, setOpenAddTeamModal] = useState({
        open: false, team: null
    })

    const [videos64, setVideos64] = useState({
        highlightVideo: null,
        introductionVideo: null,
    });

    const [academiesOptions, setAcademiesOptions] = useState([]);

    const allCountries = Country.getAllCountries();

    const allStates = State.getStatesOfCountry(data?.country || "US");

    const countries = allCountries?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const states = allStates?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const beltOptions = belts.map(({ key, title }) => ({ value: key, label: title }));

    const trainingStyleOptions = trainingStyle.map(({ key, title }) => ({ value: key, label: title }));

    const weightOptions = weightDevision.map(({ key, title }) => ({ value: key, label: title }))

    const stylePositionOptions = [
        "Berimbolo",
        "Guard",
        "Half Guard",
        "Mount",
        "Back Control",
        "Side Control",
        "Turtle",
        "De La Riva Guard",
        "X-Guard",
        "Spider Guard",
        "Closed Guard",
        "Open Guard"
    ].map(item => ({ value: item, label: item }));

    const languages = [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'French', label: 'French' },
        { value: 'Portuguese', label: 'Portuguese' },
    ]

    const readURL = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = e => res(e.target.result);
            reader.onerror = e => rej(e);
            reader.readAsDataURL(file);
        });
    };

    const onChangeData = async (field, value) => {
        if (field === "introductionVideo" || field === "highlightVideo") {
            if (!value) {
                return;
            }

            let srcString = await readURL(value);

            setVideos64((prev) => {
                return {
                    ...prev,
                    [field]: srcString
                }
            });
        }

        setData((prev) => {
            return {
                ...prev,
                [field]: value
            }
        });

        setUpdateData((prev) => {
            return {
                ...prev,
                [field]: value
            }
        });
    }

    const getInstructorGeneralInfo = async () => {
        let res = await api.profiles.getInstructorGeneralInfo();

        if (res?.data) {
            setData(res.data);
        }
    }

    const getAllAcademies = async () => {
        let res = await api.academyGeneralInfo.getAllAcademies();
        setAcademiesOptions(res.data.map(item => ({ value: item.id, label: item.name })));
    }

    const [allInstructors, setAllInstructors] = useState(false)

    const getAllInstructors = async () => {
        let res = await api.pastInstructors.getAllPastInstructors()
        if (res.data) {
            setAllInstructors(res.data)
        }
    }

    // cooperate team

    const [allCooperateTeams, setCooperateTeams] = useState(false)
    const [cooperateTeamAddLoading, setCooperateTeamAddLoading] = useState(false)
    const [newTeamName, setNewTeamName] = useState('')
    const [newTeamImg, setNewTeamImg] = useState(null)
    const [imageTeam64, setImageTeam64] = useState(null)
    const [teamNameError, setTeamNameError] = useState(false)

    const getAllCooperateTeam = async () => {
        let res = await api.cooperateTeam.getAllCooperateTeams()
        if (res.data) {
            setCooperateTeams(res.data)
        }
    }

    const toBase64 = async (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async (file) => {
        if (!file) return
        let res = await toBase64(file);
        setNewTeamImg(file)
        setImageTeam64(res)
    }


    const save = async () => {
        if (Object.entries(updateData)?.length) {
            var formData = new FormData();

            Object.entries(updateData).forEach(item => {
                if (["languages", "favoriteStyles"].includes(item[0])) {
                    formData.append(item[0], JSON.stringify(item[1]));
                } else {
                    formData.append(item[0], item[1]);
                }
            });

            let updateUserRes = await api.profiles.updateInstructorGeneralInfo(formData);

            if (updateUserRes.success) {
                toast.success(updateUserRes.message || "Updated");

                setUpdateData({});
                getInstructorGeneralInfo();
            } else {
                toast.error(updateUserRes?.message || "Error");
            }
        }
    }

    const onSuccessCooperateTeam = (data) => {

        if (data.action === 'update') {
            setCooperateTeams(prev => prev.map((el) => {
                if (el.id === data.id) {
                    return data.team
                } else {
                    return el
                }
            }))
        }

        if (data.action === 'delete') {
            setCooperateTeams(prev => prev.filter(el => el.id !== data.id))
        }


    }


    const onSuccessAddModal = (data) => {
        if (data.action === 'delete') {
            setAllInstructors(prevState => prevState.filter(item => item.id !== data.instructor.id));
        }
        if (data.action === 'create') {
            setAllInstructors(prevState => ([...prevState, data.instructor]))
        }
        if (data.action === 'edit') {
            setAllInstructors(prevState =>
                prevState.map(item =>
                    item.id === data.instructor.id ? data.instructor : item
                )
            )
        }
    }

    const saveTeam = async (e) => {
        e.preventDefault()

        if (!newTeamName) {
            setTeamNameError(true)
            return
        }

        const data = new FormData();

        data.append('name', newTeamName)
        data.append('logo', newTeamImg)

        setCooperateTeamAddLoading(true)
        const res = await api.cooperateTeam.addCooperateTeam(data)

        if (res.data) {
            setCooperateTeams(prev => [...(prev || []), res.data]);
            setNewTeamImg(null)
            setImageTeam64(null)
            setNewTeamName('')
        }

        setCooperateTeamAddLoading(false)

    }


    useEffect(() => {
        getInstructorGeneralInfo();
        getAllAcademies();
        getAllInstructors();
        getAllCooperateTeam();
    }, [])

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">General Information</h3>
            </div>
            {
                data ?
                    <form className="form form-settings" method="post" onSubmit={(e) => { e.preventDefault(); }}>
                        <div className="form-body">
                            <div className="form-items-list">
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-12">
                                            <SelectOne
                                                wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                options={countries}
                                                label={'Country / Region'}
                                                value={countries?.find(item => item?.value === data.country) || { value: 'US', label: 'United States' }}
                                                onChange={(e) => {
                                                    onChangeData("country", e.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'6391 Elgin St. Celina, Delaware 10299'}
                                                label={"Address Line"}
                                                value={data.addressLine}
                                                onChange={(e) => {
                                                    onChangeData("addressLine", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="row gutters-form-2">
                                                <div className="col-md-4 col-sm-4 col-12">
                                                    <Input
                                                        wrapperClasses={'input--lg'}
                                                        inputClasses={'input--solid'}
                                                        placeholder={'Milford'}
                                                        label={"Town / City"}
                                                        value={data.city}
                                                        onChange={(e) => {
                                                            onChangeData("city", e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-12">
                                                    <SelectOne
                                                        options={states}
                                                        wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                        label={'State'}
                                                        value={states?.find(item => item?.value === data.state) || {}}
                                                        onChange={(e) => {
                                                            onChangeData("state", e.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-12">
                                                    <Input
                                                        wrapperClasses={'input--lg'}
                                                        inputClasses={'input--solid'}
                                                        placeholder={'10299'}
                                                        label={"ZIP"}
                                                        value={data.zip}
                                                        onChange={(e) => {
                                                            onChangeData("zip", e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'Add your link here...'}
                                                label={"Facebook"}
                                                value={data.facebookURL}
                                                onChange={(e) => {
                                                    onChangeData("facebookURL", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'Add your link here...'}
                                                label={"Instagram"}
                                                value={data.instagramURL}
                                                onChange={(e) => {
                                                    onChangeData("instagramURL", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'Add your link here...'}
                                                label={"YouTube"}
                                                value={data.youtubeURL}
                                                onChange={(e) => {
                                                    onChangeData("youtubeURL", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'Add your link here...'}
                                                label={"BJJ fanatics"}
                                                value={data.fanaticsURL}
                                                onChange={(e) => {
                                                    onChangeData("fanaticsURL", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                inputClasses={'input--solid'}
                                                placeholder={'Marlboro, New Jersey, USA'}
                                                label={"Origin"}
                                                value={data.origin}
                                                onChange={(e) => {
                                                    onChangeData("origin", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                label={'Years of Training'}
                                                inputClasses={'input--solid'}
                                                value={data.yearsTraning}
                                                type="number"
                                                min={1}
                                                max={20}
                                                onChange={(e) => {
                                                    onChangeData("yearsTraning", e.target.value)
                                                }}
                                                placeholder="0"
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <Input
                                                wrapperClasses={'input--lg'}
                                                label={'Approx Seminar price'}
                                                inputClasses={'input--solid'}
                                                value={data.approxPrice}
                                                onChange={(e) => {
                                                    onChangeData("approxPrice", e.target.value)
                                                }}
                                                placeholder="0$"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectMulty
                                                options={languages}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'Language(s)'}
                                                placeholder={'Type language to add...'}
                                                value={data.languages?.map(item => ({ value: item, label: item })) || []}
                                                onChange={(value) => {
                                                    onChangeData("languages", value.map(item => item.value))
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <SelectOne
                                                options={beltOptions}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'Belt'}
                                                value={beltOptions?.find(item => item?.value === data.belt) || {}}
                                                onChange={(e) => {
                                                    onChangeData("belt", e.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <SelectOne
                                                options={trainingStyleOptions}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'Training Style'}
                                                value={trainingStyleOptions?.find(item => item?.value === data.traningStyle) || {}}
                                                onChange={(e) => {
                                                    onChangeData("traningStyle", e.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectMulty
                                                options={stylePositionOptions}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'Favorite Style/Position'}
                                                placeholder={'Type style to add...'}
                                                value={data.favoriteStyles?.map(item => ({ value: item, label: item })) || []}
                                                onChange={(value) => {
                                                    onChangeData("favoriteStyles", value.map(item => item.value))
                                                }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectOne
                                                options={weightOptions}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'Weight Division'}
                                                value={weightOptions?.find(item => item?.value === data.weightDivision) || {}}
                                                onChange={(e) => {
                                                    onChangeData("weightDivision", e.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-12">
                                            <SelectOne
                                                options={academiesOptions?.length ? academiesOptions : [{ label: 'Not Listed', value: 'Not Listed', placeholder: true }]}
                                                wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                                label={'Academy'}
                                                labelRowSecondItem={<a href="#" className="btn color-secondary btn-link">
                                                    <span className="text--xs">Academy not listed?</span>
                                                </a>}
                                                value={academiesOptions?.find(item => item?.value === data.academyId) || {}}
                                                onChange={(e) => {
                                                    onChangeData("academyId", e.value)
                                                }}
                                            />
                                        </div>
                                        {/* <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            inputClasses={'input--solid'}
                                            placeholder={'Type academy name...'}
                                            label={"Academy Name"}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            inputClasses={'input--solid'}
                                            placeholder={'Type academy address...'}
                                            label={"Academy Address"}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            inputClasses={'input--solid'}
                                            placeholder={'Type website...'}
                                            label={"Website"}
                                        />
                                    </div> */}
                                        <div className="col-12">
                                            <Textarea
                                                label={'Achievements'}
                                                wrapperClasses={'input--lg'}
                                                textareaClasses={'input--solid'}
                                                // value={`• 1st Place IBJJF World Championship 
                                                // (2017 / 2018 / 2019 / 2021)
                                                // • 1st Place IBJJF Pan Championship (2016)
                                                // • 1st Place IBJJF European Open (2017 / 2020)
                                                // • 1st Place IBJJF American Nationals 
                                                // (2015 / 2016)`}
                                                style={{
                                                    height: '140px'
                                                }}
                                                value={data.achievements}
                                                onChange={(e) => {
                                                    onChangeData("achievements", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="form-group select--outline  select--outline-bg input--lg">
                                                <label className="label">Highlight video</label>
                                                <label className="fake-video--wrapper">
                                                    <input
                                                        type="file" hidden={true}
                                                        onChange={(e) => {
                                                            onChangeData("highlightVideo", e.target.files[0])
                                                        }} accept="video/mp4,video/x-m4v,video/*"
                                                    />
                                                    {
                                                        updateData?.highlightVideo || data?.highlightVideo ? <div className="fake-video">
                                                            <div className="fake-video--poster flex items-center justify-center">
                                                                {videos64?.highlightVideo ?
                                                                    <video
                                                                        src={videos64?.highlightVideo}
                                                                        className='w-full max-w-full h-[190px] min-h-[190px]'
                                                                    ></video>
                                                                    : typeof data?.highlightVideo === "string" ?
                                                                        <video
                                                                            src={process.env.REACT_APP_API_URL + data?.highlightVideo}
                                                                            className='w-full max-w-full h-[190px] min-h-[190px]'
                                                                        ></video>
                                                                        : null}
                                                            </div>
                                                            <div className="btn btn--primary btn--sm radius">
                                                                <span>Change Video</span>
                                                            </div>
                                                        </div> : <div className="fake-video">
                                                            <div className="btn btn--sm radius">
                                                                <span className="ico">
                                                                    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.4998 9.33301V18.6663" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M19.1668 14.0003H9.8335" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.5 24.5V24.5C8.7005 24.5 4 19.7995 4 14V14C4 8.2005 8.7005 3.5 14.5 3.5V3.5C20.2995 3.5 25 8.2005 25 14V14C25 19.7995 20.2995 24.5 14.5 24.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </span>
                                                                <span>Add Video</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="form-group select--outline select--outline-bg input--lg">
                                                <label className="label">Introduction video</label>
                                                <label className="fake-video--wrapper">
                                                    <input
                                                        type="file" hidden={true}
                                                        onChange={(e) => {
                                                            onChangeData("introductionVideo", e.target.files[0])
                                                        }} accept="video/mp4,video/x-m4v,video/*"
                                                    />
                                                    {
                                                        updateData?.introductionVideo || data?.introductionVideo ? <div className="fake-video">
                                                            <div className="fake-video--poster flex items-center justify-center">
                                                                {videos64?.introductionVideo ?
                                                                    <video
                                                                        src={videos64?.introductionVideo}
                                                                        className='w-full max-w-full h-[190px] min-h-[190px]'
                                                                    ></video>
                                                                    : typeof data?.introductionVideo === "string" ?
                                                                        <video
                                                                            src={process.env.REACT_APP_API_URL + data?.introductionVideo}
                                                                            className='w-full max-w-full h-[190px] min-h-[190px]'
                                                                        ></video>
                                                                        : null}
                                                            </div>
                                                            <div className="btn btn--primary btn--sm radius">
                                                                <span>Change Video</span>
                                                            </div>
                                                        </div> : <div className="fake-video">
                                                            <div className="btn btn--sm radius">
                                                                <span className="ico">
                                                                    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.4998 9.33301V18.6663" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M19.1668 14.0003H9.8335" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.5 24.5V24.5C8.7005 24.5 4 19.7995 4 14V14C4 8.2005 8.7005 3.5 14.5 3.5V3.5C20.2995 3.5 25 8.2005 25 14V14C25 19.7995 20.2995 24.5 14.5 24.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </span>
                                                                <span>Add Video</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item" >
                                    <div className="row gutters-form" >
                                        <div className="col-12">
                                            <div className="heading-row">
                                                <div className="flex-ico-ss-text">
                                                    <h4 className="heading">Past Instructors</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="add-widget" >
                                                <ul className="row gutters-form">
                                                    {allInstructors && allInstructors.length > 0 && allInstructors.map((el, idx) => <li key={el.id} className="col-12">
                                                        <div className="add-widget--item row gutters-form">
                                                            <div className="add-widget-control">
                                                                <Input
                                                                    wrapperClasses={'input--lg'}
                                                                    inputClasses={'input--solid'}
                                                                    placeholder={'Rafael Mendes'}
                                                                    value={el.fullName}
                                                                    label={"Full Name"}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="add-widget-control">
                                                                {/*<SelectOne*/}
                                                                {/*    options={beltOptions}*/}
                                                                {/*    wrapperClasses={'select--outline  select--outline-bg input--lg'}*/}
                                                                {/*    label={'Academy'}*/}
                                                                {/*    value={beltOptions[0]}*/}
                                                                {/*/>*/}
                                                                <Input
                                                                    wrapperClasses={'input--lg'}
                                                                    inputClasses={'input--solid'}
                                                                    placeholder={'Rafael Mendes'}
                                                                    value={el.belt}
                                                                    label={"Academy"}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="add-widget-control tw-actions">
                                                                <div className="btn btn--lg p-0">
                                                                    <button onClick={() => setOpenAddInstructorModal({ open: true, mode: 'edit', instructor: el })}
                                                                        className="btn btn--square btn--md rounded-full btn-border">
                                                                        {/*<span className="ico">*/}
                                                                        {/*    <svg width="20" height="20" viewBox="0 0 20 20"*/}
                                                                        {/*         fill="none"*/}
                                                                        {/*         xmlns="http://www.w3.org/2000/svg">*/}
                                                                        {/*        <path fillRule="evenodd" clipRule="evenodd"*/}
                                                                        {/*              d="M13.4482 17.4997H6.54818C5.67568 17.4997 4.95068 16.8263 4.88568 15.9555L4.13818 5.83301H15.8332L15.1107 15.9513C15.0482 16.8238 14.3224 17.4997 13.4482 17.4997V17.4997Z"*/}
                                                                        {/*              stroke="currentColor"*/}
                                                                        {/*              strokeWidth="1.2"*/}
                                                                        {/*              strokeLinecap="round"*/}
                                                                        {/*              strokeLinejoin="round"></path>*/}
                                                                        {/*        <path d="M10.0002 9.16699V14.167"*/}
                                                                        {/*              stroke="currentColor"*/}
                                                                        {/*              strokeWidth="1.2"*/}
                                                                        {/*              strokeLinecap="round"*/}
                                                                        {/*              strokeLinejoin="round"></path>*/}
                                                                        {/*        <path d="M3.3335 5.83366H16.6668"*/}
                                                                        {/*              stroke="currentColor"*/}
                                                                        {/*              strokeWidth="1.2"*/}
                                                                        {/*              strokeLinecap="round"*/}
                                                                        {/*              strokeLinejoin="round"></path>*/}
                                                                        {/*        <path*/}
                                                                        {/*            d="M14.1668 5.83333L13.3227 3.58167C13.0785 2.93083 12.4568 2.5 11.7618 2.5H8.2385C7.5435 2.5 6.92183 2.93083 6.67766 3.58167L5.8335 5.83333"*/}
                                                                        {/*            stroke="currentColor" strokeWidth="1.2"*/}
                                                                        {/*            strokeLinecap="round"*/}
                                                                        {/*            strokeLinejoin="round"></path>*/}
                                                                        {/*        <path d="M12.8585 9.16699L12.5002 14.167"*/}
                                                                        {/*              stroke="currentColor"*/}
                                                                        {/*              strokeWidth="1.2"*/}
                                                                        {/*              strokeLinecap="round"*/}
                                                                        {/*              strokeLinejoin="round"></path>*/}
                                                                        {/*        <path d="M7.1418 9.16699L7.50013 14.167"*/}
                                                                        {/*              stroke="currentColor"*/}
                                                                        {/*              strokeWidth="1.2"*/}
                                                                        {/*              strokeLinecap="round"*/}
                                                                        {/*              strokeLinejoin="round"></path>*/}
                                                                        {/*    </svg>*/}
                                                                        {/*</span>*/}
                                                                        <span className="ico">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                                                                                viewBox="0 0 24 24" fill="none">
                                                                                <g id="Edit / Edit_Pencil_01">
                                                                                    <path id="Vector"
                                                                                        d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001"
                                                                                        stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                                                                        strokeLinejoin="round" />
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>)}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="flex-group">
                                                <button onClick={() => setOpenAddInstructorModal({ open: true, mode: 'create', instructor: null })} className="btn btn--primary btn--sm">
                                                    <span className="ico">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g>
                                                                <path d="M3.125 10H16.875" stroke="currentColor"
                                                                    strokeWidth="1.2" strokeLinecap="round"
                                                                    strokeLinejoin="round"></path>
                                                                <path d="M10 3.125V16.875" stroke="currentColor"
                                                                    strokeWidth="1.2" strokeLinecap="round"
                                                                    strokeLinejoin="round"></path>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_550_16199">
                                                                    <rect width="20" height="20" fill="white"></rect>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                    <span>add Instructor</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form-3">
                                        <div className="col-12">
                                            <div className="heading-row">
                                                <div className="flex-ico-ss-text">
                                                    <h4 className="heading">affiliation/team</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <ul className="team-list">
                                                {allCooperateTeams && allCooperateTeams.length > 0 && allCooperateTeams.map((el, idx) => <li className="team-item">
                                                    <div className="team-widget">
                                                        <div className="tw-item tw-logo">
                                                            <div className="ico ico--lg rounded-full border-ico">
                                                                {el.logo && <img src={process.env.REACT_APP_API_URL + el.logo} />}
                                                            </div>

                                                        </div>
                                                        <div className="tw-item tw-control">
                                                            <input readOnly className="input input--solid input--lg"
                                                                type="text" value={el.name} />
                                                        </div>
                                                        <div className="tw-item tw-actions">
                                                            <button onClick={() => setOpenAddTeamModal({
                                                                open: true,
                                                                team: el
                                                            })}
                                                                className="btn btn--square  btn--md rounded-full btn-border">
                                                                <span className="ico">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="20px" height="20px"
                                                                        viewBox="0 0 24 24" fill="none">
                                                                        <g id="Edit / Edit_Pencil_01">
                                                                            <path id="Vector"
                                                                                d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001"
                                                                                stroke="#000000" strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>)}

                                                <li className="team-item">
                                                    <div className="team-widget">
                                                        <label htmlFor={'team-img'} className="tw-item tw-logo">
                                                            <div className="ico ico--lg rounded-full border-ico">
                                                                {
                                                                    imageTeam64 ?
                                                                        <img
                                                                            src={imageTeam64 ? imageTeam64 : (process.env.REACT_APP_API_URL + imageTeam64)}
                                                                            alt="" />
                                                                        : null
                                                                }
                                                            </div>
                                                            <input name='team-img' id='team-img' type="file" hidden
                                                                disabled={cooperateTeamAddLoading}
                                                                onChange={e => changePhoto(e.target.files[0])}
                                                            />
                                                        </label>
                                                        <div className="tw-item tw-control">
                                                            <input className="input input--solid input--lg"
                                                                disabled={cooperateTeamAddLoading}
                                                                type="text" placeholder="Add new team..."
                                                                value={newTeamName}
                                                                onChange={e => {
                                                                    setTeamNameError(false)
                                                                    setNewTeamName(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="tw-item tw-actions">
                                                            <button onClick={saveTeam} href="#"
                                                                className={`btn btn--square  btn--md rounded-full btn-border ${cooperateTeamAddLoading ? 'disabled' : ''}`}>
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {teamNameError && <p className='error-input' style={{ padding: '5px 75px' }}>Enter a team Name</p>}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-footer --bordered">
                            <div className="flex-group justify-end">
                                <button type="submit" className={`btn btn--primary btn--sm ${!Object.entries(updateData).length ? 'disabled' : ''}`} onClick={(e) => {
                                    e.preventDefault();
                                    save();
                                }}>
                                    <span>Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    : null}

            {openAddInstructorModal.open && <AddPastInstructors
                data={{ id: data?.academyId, belts: beltOptions }}
                handleClose={() => setOpenAddInstructorModal({ open: false, mode: null, instructor: null })}
                open={openAddInstructorModal.open}
                instructorData={openAddInstructorModal.instructor}
                mode={openAddInstructorModal.mode}
                onSuccess={data => onSuccessAddModal(data)}
            />}

            {openAddTeamModal.open && <EditCooperateTeam
                data={openAddTeamModal.team}
                handleClose={() => setOpenAddTeamModal({ open: false, team: null })}
                open={openAddTeamModal.open}
                onSuccess={data => onSuccessCooperateTeam(data)}
            />}

        </div>
    )
}

export default GeneralInfoInstructor



const TeamPart = ({ }) => {

    const [isEdit, setIsEdit] = useState(false)


    return (
        <li className="team-item">
            <div className="team-widget">
                <div className="tw-item tw-logo">
                    <div className="ico ico--lg rounded-full border-ico">
                        <img src={require("../../../assets/img/team-logo-2.png")} />
                    </div>
                </div>
                <div className="tw-item tw-control">
                    <input className="input input--solid input--lg" type="text" value="Alliance Jiu Jitsu" />
                </div>
                <div className="tw-item tw-actions">


                    <a href="#" className="btn btn--square btn--md rounded-full btn-border">

                        {/* мусорник */}
                        {<span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M13.4482 17.4997H6.54818C5.67568 17.4997 4.95068 16.8263 4.88568 15.9555L4.13818 5.83301H15.8332L15.1107 15.9513C15.0482 16.8238 14.3224 17.4997 13.4482 17.4997V17.4997Z"
                                    stroke="currentColor" strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path d="M10.0002 9.16699V14.167"
                                    stroke="currentColor" strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path d="M3.3335 5.83366H16.6668"
                                    stroke="currentColor" strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path
                                    d="M14.1668 5.83333L13.3227 3.58167C13.0785 2.93083 12.4568 2.5 11.7618 2.5H8.2385C7.5435 2.5 6.92183 2.93083 6.67766 3.58167L5.8335 5.83333"
                                    stroke="currentColor" strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path d="M12.8585 9.16699L12.5002 14.167"
                                    stroke="currentColor" strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path d="M7.1418 9.16699L7.50013 14.167"
                                    stroke="currentColor" strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                            </svg>
                        </span>}

                        {/* карандаш */}
                        <span className="ico">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                width="20px" height="20px"
                                viewBox="0 0 24 24" fill="none">
                                <g id="Edit / Edit_Pencil_01">
                                    <path id="Vector"
                                        d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001"
                                        stroke="#000000" strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round" />
                                </g>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </li>
    )
}
