import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import HeaderSingUp from '../components/headers/HeaderSingUp'

const DefaultLayout = ({headerTitle}) => {
	return (
		<>
			<HeaderSingUp title={headerTitle} />
			<div className="wrapper header-fixed mobile-header-fixed">
    			<main className="content sign-up">
					<Outlet/>
				</main>
			</div>
		</>
	)
}

export default DefaultLayout