import React from 'react'
import ReactPaginate from 'react-paginate'

export const PaginationTemplate = ({
    limit = 1,
    maxPages = 1,
    offset = 0,
    page = 1,
    total = 1,
    onPageChange = () => { }
}) => {

    return (
        <div className="paginations__wrap">
            <span className="page__counter">Page {page} of {maxPages}</span>

            <ReactPaginate
                breakLabel="..."
                nextLabel={null}
                previousLabel={null}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={maxPages}
                total={total}
                onPageChange={(selectedItem) => onPageChange(selectedItem.selected + 1)}
                forcePage={page - 1}
                className="paginations"
                pageClassName="pagination__item"
                pageLinkClassName="pagination__btn"
                activeLinkClassName='active'
                previousClassName="pagination__previous"
                nextClassName="pagination__next"
            />

            <div className="flex items-center gap-12">
                <button
                    className="btn btn--outline btn--square btn--sm rounded-full"
                    onClick={() => onPageChange(Math.max(0, page - 1))}
                    disabled={page === 1}
                >
                    <span className="ico">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.16663 9.99967H15.8333" stroke="#67676C" strokeWidth="1.2"
                                strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M8.33329 5.83301L4.16663 9.99967" stroke="#67676C" strokeWidth="1.2"
                                strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M8.33329 14.1667L4.16663 10" stroke="#67676C" strokeWidth="1.2"
                                strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </button>
                <button
                    className="btn btn--outline btn--square btn--sm rounded-full"
                    onClick={() => onPageChange(Math.min(maxPages, page + 1))}
                    disabled={page === maxPages}
                >
                    <span className="ico">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8333 9.99967H4.16663" stroke="#67676C" strokeWidth="1.2"
                                strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M11.6666 14.1667L15.8333 10" stroke="#67676C" strokeWidth="1.2"
                                strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M11.6666 5.83301L15.8333 9.99967" stroke="#67676C" strokeWidth="1.2"
                                strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </button>
            </div>
        </div>
    )
}
