export const CardDetails = ({ isEdited, label, afterLabel, children }) => {
    return (
        <div className="form-group input--lg">
            <label className="label row-group justify-between gap--xs">
                <div className="row-group gap--xs">
                    {label}
                    {
                        isEdited ?
                            <span className="flex">
                                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="6" height="6" rx="3" fill="#9747FF" />
                                </svg>
                            </span>
                            : null
                    }
                </div>
                {afterLabel}
            </label>
            <div className={`card-details col-group ${isEdited ? "--edited" : ""}`}>
                {children}
            </div>
        </div>
    )
}