const base = 'api/private-lessons';

export const privateLessons = (instance) => ({
    async createRequesToPrivateLesson({ instructorId, body }) {
        const { data } = await instance.post(`${base}/${instructorId}`, body);
        return data;
    },
    async updateRequesToPrivateLesson({ id, body }) {
        const { data } = await instance.put(`${base}/${id}`, body);
        return data;
    },
    async acceptRequestsPrivateLessons(id) {
        const { data } = await instance.get(`${base}/accept-student-request/${id}`);
        return data;
    },
    async acceptRequestsPrivateLessonsStep(id) {
        const { data } = await instance.get(`${base}/accept-step/${id}`);
        return data;
    },
    async rejectRequestsPrivateLesson(id) {
        const { data } = await instance.get(`${base}/reject/${id}`);
        return data;
    },
})