import { useWindowSize } from '@uidotdev/usehooks'
import React, { useEffect, useRef } from 'react'
import ReactDatePicker from 'react-datepicker'

const DatePicker = ({ inputClassName = "", wrapperClassName = "", error, label, ...props }) => {
    const {width} = useWindowSize();
    const isMobile = width <= 767.98;
    const pickerRef = useRef(null)
    useEffect(() => {
        if (isMobile && pickerRef.current !== null) {
          pickerRef.current.input.readOnly = true;
        }
    }, [isMobile, pickerRef]);
    return (
        <div className={`form-group input--lg ${!label ? "gap-0" : "gap-0"} ${wrapperClassName}`}>
            {label && <label className="label mb-10">{label}</label>}
            <ReactDatePicker
                dateFormat="dd MMM, yyyy"
                className={`input input--solid ${inputClassName} ${error ? "input--error" : ""}`}
                ref={pickerRef}
                withPortal={isMobile}
                {...props}
                
            />
        </div>
    )
}

export default DatePicker