const chatsBase = 'api/chats';

export const chats = (instance) => ({
    async getAllChats() {
        const { data } = await instance.get(`${chatsBase}/my?no_limit=true`);
        return data;
    },
    async getChatMessages({ chatId }) {
        const { data } = await instance.get(`${chatsBase}/${chatId}/messages?limit=100`);
        return data;
    },
    async getChatById({ chatId }) {
        try {
            const { data } = await instance.get(`${chatsBase}/${chatId}`);
            return data;
        } catch (e) {
            return e.response.data
        }
    },
    async sendChatMessage({ chatId, body }) {
        const { data } = await instance.post(`${chatsBase}/${chatId}`, body);
        return data;
    },
})