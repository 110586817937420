import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Input from '../../../components/ui/formElements/Input'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import Textarea from '../../../components/ui/formElements/Textarea'
import { trainingStyle } from '../../../constants'
import { convertObjectToFormData } from '../../../helpers/utils'
import { getUser } from '../../../redux/slices/applicationSlice'
import { createRequesToPrivateLessonFetch } from '../../../redux/thunks/privateLessonsThunks'
import EventDatesSelect from '../parts/EventDatesSelect'


const TimeAndDate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { instructorId } = useParams()
    const user = useSelector(getUser)
    const [formData, handleChange, errors, setErrorConfig, instructorInfo] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const handleSubmit = async () => {
        setIsErrorsShown(true)
        setIsLoading(true)
        console.log(errors);
        const newData = {...formData}
        if (!errors.hasRequiredError) {
            const res = await dispatch(createRequesToPrivateLessonFetch({
                instructorId,
                body: newData
            })).unwrap()

            if (res?.chat?.id) {
                navigate(`/chat/${res.chat.id}`)
            }
        }
        setIsLoading(false)
    }
    const instructorTraningStyle = instructorInfo?.privateSession?.traningStyle

    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({ value: key, label: title })).filter(item => {
        if (instructorTraningStyle === "gi_and_no-gi") {
            return true
        } else {
            return item.value === instructorTraningStyle
        }
    })

    useEffect(() => {
        setErrorConfig({
            exceptions: [
            ],
            requiredFields: [
                "dates",
            ],
            validators: {
                availableDates: (value) => {
                    if (!value.length) return false
                    let isValid = true
                    value.forEach(item => {
                        if (!item.date) {
                            isValid = false
                        }
                    })
                    return isValid
                }
            }
        })
    }, [formData])


    return (
        <>
            <div className="mb-42">
                <div className="row gx-16 py-24 mb-10 ">
                    <div className='col-12'>
                        <h3 className='row-group'>
                            Ticket and receipt are sent to the info below
                        </h3>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group input--lg">
                            <label className='label'>Account ID</label>
                            <p className='fw-500 text-14'>{user?.id}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group input--lg">
                            <label className='label'>Name</label>
                            <p className='fw-500 text-14'>{user?.profile?.fullName}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group input--lg">
                            <label className='label'>Email</label>
                            <p className='fw-500 text-14'>{user?.email}</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr className='hr' />
                    </div>
                    <div className='col-12'>
                        <h4 className='row-group'>
                            Set Time & Date
                        </h4>
                    </div>
                    <div className="col-12">
                        <EventDatesSelect
                            dates={formData.dates}
                            handleChange={(dates) => handleChange("dates", dates)}
                            isErrorsShown={isErrorsShown}
                        />
                    </div>
                    <div className="col-12">
                        <hr className='hr' />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                        <Input
                            label={"People Attending"}
                            wrapperClasses={"input--lg"}
                            inputClasses="input--solid"
                            type="text"
                            placeholder="0"
                            value={formData.peopleAttending}
                            error={isErrorsShown && errors?.peopleAttending}
                            onChange={(e) => handleChange("peopleAttending", e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                        <SelectOne
                            label={"Gi or Nogi"}
                            wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                            options={traningStyleOptions}
                            value={formData.style}
                            error={isErrorsShown && errors?.style}
                            onChange={(option) => handleChange("style", option.value)}
                            placeholder={"Gi or Nogi"}
                        />

                    </div>
                    <div className="col-12">
                        <Textarea
                            label="Optional Message"
                            wrapperClasses={"input--lg"}
                            textareaClasses={"input--solid"}
                            style={{ minHeight: 140 }}
                            value={formData.message}
                            error={isErrorsShown && errors?.message}
                            onChange={(e) => handleChange("message", e.target.value)}
                            placeholder={"Please write message here..."}
                        />
                    </div>
                </div>
            </div>

            <hr className='hr mb-42 mt-42' />

            <div className="page-action-group">
                <Link to={-1} className="btn btn--default btn--sm">Cancel</Link>
                <button isLoading={isLoading} onClick={handleSubmit} className="btn btn--primary btn--sm">
                    <span className="info">Continue</span>
                    <span className="ico">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                        </svg>
                    </span>
                </button>
            </div>
        </>
    )
}

export default TimeAndDate