import moment from "moment"
import { useState } from "react"
import { CardDetails } from "../CardDetails"
import { Step } from "../Step"


export const StepLocationDate = ({ seminarDetails, currentStatus, status, link, canEdit = true }) => {
    const [open, setOpen] = useState(false)

    return (
        <Step currentStatus={currentStatus} status={status} label="Location & Date">
            <CardDetails
                label={"Location "}
                isEdited={seminarDetails?.lastEdited?.includes("address") || seminarDetails?.lastEdited?.includes("location")}
            >
                <p className='color-text'>
                    {[seminarDetails?.instructorLocation, seminarDetails?.address, seminarDetails?.continent, seminarDetails?.country, seminarDetails?.city].filter(Boolean).join(", ") || "Empty"}
                </p>
            </CardDetails>
            <CardDetails
                label={"Start Date"}
                isEdited={seminarDetails?.lastEdited?.includes("startAt")}
            >
                <div className="row-group justify-between">
                    <p className='fw-500'>{moment(seminarDetails.startAt).format("DD MMM, YYYY")}</p>
                    <button className="color-secondary fw-500">Change Date</button>
                </div>
            </CardDetails>
            <CardDetails
                label={"End Date"}
                isEdited={seminarDetails?.lastEdited?.includes("endAt")}
            >
                <div className="row-group justify-between">
                    <p className='fw-500'>{moment(seminarDetails.endAt).format("DD MMM, YYYY")}</p>
                    <button className="color-secondary fw-500">Change Date</button>
                </div>
            </CardDetails>
        </Step>
    )
}