import React, { useEffect, useState } from 'react'
import Input from '../../../components/ui/formElements/Input'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import Textarea from '../../../components/ui/formElements/Textarea'
import DatePicker from "react-datepicker";
import api from "../../../api";
import { toast } from 'react-toastify';

const MyProfileInstructor = ({ userData }) => {
	const [data, setData] = useState({
		email: userData.email,
		phone: userData.phone,
		...userData.profile
	});

	const [updateData, setUpdateData] = useState({});

	const [images64, setImages64] = useState({
		photo: null,
		cover: null,
	});


	// const countrys = [
	// 	{ value: 'brazil', label: 'Brazil' },
	// 	{ value: 'ukraine', label: 'Ukraine' },
	// ]
	// const days = [
	// 	{ value: '11', label: '15' },
	// 	{ value: '12', label: '16' },
	// 	{ value: '13', label: '17' },
	// 	{ value: '14', label: '18' },
	// ]
	// const months = [
	// 	{ value: 'April', label: 'April' }
	// ]
	// const yesrs = [
	// 	{ value: '1997', label: '1997' }
	// ]
	// const bely = [
	// 	{ value: 'Purple Belt', label: 'Purple Belt' }
	// ]

	const onChangeData = (field, value) => {
		setData((prev) => {
			return {
				...prev,
				[field]: value
			}
		});

		setUpdateData((prev) => {
			return {
				...prev,
				[field]: value
			}
		});
	}

	const toBase64 = async(file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async(file, field) => {
        onChangeData(field, file);

        let res = await toBase64(file);
		
		setImages64((prev)=>{
			return {
				...prev,
				[field]: res
			}
		})
    }

	const save = async() => {
		if(updateData?.email || updateData?.phone){
			let updatePayload = {};

			["email", "phone"].forEach(item => {
				if(updateData[item]){
					updatePayload[item] = updateData[item];
				}
			})

			let updateUserRes = await api.users.updateUser(updatePayload);

			if(updateUserRes.success){
				toast.success(updateUserRes.message || "Updated");
			} else {
				toast.error(updateUserRes?.message || "Error");
			}
		}

		if(updateData?.cover || updateData?.photo || updateData?.birthday || updateData?.fullName || updateData?.nickname || updateData?.bio){
			var formData = new FormData();

			if(updateData?.birthday){
				formData.append("birthday", new Date(updateData?.birthday).toISOString());
			}

			["cover", "photo", "fullName", "nickname", "bio"].forEach(item => {
				if(updateData[item]){
					formData.append(item, updateData[item]);
				}
			})

			let updateInstructorRes = await api.profiles.updateInstructor(formData);

			if(updateInstructorRes.success){
				toast.success(updateInstructorRes.message || "Updated");

				setUpdateData({})
			} else {
				toast.error(updateInstructorRes?.message || "Error");
			}
		}
	}

	return (
		<div className="settings-body settings-gap">
			<div className="sidebar-header heading-row">
				<h3 className="heading">My Profile</h3>
			</div>
			<form
				className="form form-settings" method="post"
				onSubmit={(e)=>{e.preventDefault();}}
			>
				<div className="form-header">
					<div className="row gutters-form">
						<div className="col-12">

							<div className="cover-block">
								<div className="cover-load">
									{/* <img src={require("../../../assets/img/cover-photo.jpg")} /> */}
									{
										data?.cover ?
											<img src={images64?.cover ? images64?.cover : (process.env.REACT_APP_API_URL + data?.cover)} alt="" />
										: null
									}
									<label className="photo-load-control">
										<input type="file" hidden={true} onChange={(e)=>{ changePhoto(e.target.files[0], "cover"); }} />
										<div className="photo-load-btn btn">
											<span className="ico">
												<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.75 13.75L11 11" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M11 11L8.25 13.75" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M10.9993 18.3333V11" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M7.33398 17.4166H5.50065C3.47561 17.4166 1.83398 15.775 1.83398 13.75C1.83398 11.7249 3.47561 10.0833 5.50065 10.0833L5.49148 10.0188C5.26333 8.42173 5.73945 6.80425 6.79647 5.58549C7.85349 4.36673 9.38737 3.66664 11.0007 3.66663C12.6139 3.66664 14.1478 4.36673 15.2048 5.58549C16.2619 6.80425 16.738 8.42173 16.5098 10.0188L16.5007 10.0833C18.5257 10.0833 20.1673 11.7249 20.1673 13.75C20.1673 15.775 18.5257 17.4166 16.5007 17.4166H14.6673" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
												</svg>
											</span>
										</div>
									</label>
								</div>
								<div className="photo-load">
									{
										data?.photo ?
											<img src={images64?.photo ? images64?.photo : (process.env.REACT_APP_API_URL + data?.photo)} alt="" />
										: null
									}

									<label className="photo-load-control">
										<input type="file" hidden={true} onChange={(e)=>{ changePhoto(e.target.files[0], "photo"); }} />
										<div className="photo-load-btn btn">
											<span className="ico">
												<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.75 13.75L11 11" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M11 11L8.25 13.75" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M10.9993 18.3333V11" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M7.33398 17.4166H5.50065C3.47561 17.4166 1.83398 15.775 1.83398 13.75C1.83398 11.7249 3.47561 10.0833 5.50065 10.0833L5.49148 10.0188C5.26333 8.42173 5.73945 6.80425 6.79647 5.58549C7.85349 4.36673 9.38737 3.66664 11.0007 3.66663C12.6139 3.66664 14.1478 4.36673 15.2048 5.58549C16.2619 6.80425 16.738 8.42173 16.5098 10.0188L16.5007 10.0833C18.5257 10.0833 20.1673 11.7249 20.1673 13.75C20.1673 15.775 18.5257 17.4166 16.5007 17.4166H14.6673" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
												</svg>
											</span>
										</div>
									</label>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div className="form-body">
					<div className="form-items-list">
						<div className="form-item">
							<div className="row gutters-form">
								<div className="col-12">
									<Input
										wrapperClasses={'input--lg'}
										label={'Full Name'}
										inputClasses={'input--solid'}
										value={data?.fullName}
										onInput={(e)=>{
											onChangeData('fullName', e.target.value);
										}}
									/>
								</div>
								<div className="col-12">
									<Input
										wrapperClasses={'input--lg'}
										label={'Nickname'}
										inputClasses={'input--solid'}
										value={data?.nickname}
										onInput={(e)=>{
											onChangeData('nickname', e.target.value);
										}}
									/>
								</div>
								<div className="col-12">
									<div className="form-group input--lg">
										<label className="label">Date of Birth</label>
										<div className="row gutters-form-2">
											<div className="col-md-4 col-sm-4 col-12">
												{/* <SelectOne
													options={days}
													wrapperClasses={'select--outline select--outline-bg input--lg'}
													value={days[0]}
												/> */}
												<div className="day-datepicker">
													<DatePicker 
														dateFormat="dd"
														className={`input input--solid`} 
														selected={data.birthday} 
														onChange={(date) => {
															onChangeData('birthday', date);
														}}
													/>
												</div>
											</div>
											<div className="col-md-4 col-sm-4 col-12">
												{/* <SelectOne
													options={months}
													wrapperClasses={'select--outline select--outline-bg input--lg'}
													value={months[0]}
												/> */}
												<div className="month-datepicker">
													<DatePicker 
														showMonthYearPicker
														dateFormat="MM"
														className={`input input--solid`} 
														selected={data.birthday} 
														onChange={(date) => {
															onChangeData('birthday', date);
														}}
													/>
												</div>
											</div>
											<div className="col-md-4 col-sm-4 col-12">
												{/* <SelectOne
													options={yesrs}
													wrapperClasses={'select--outline select--outline-bg input--lg'}
													value={yesrs[0]}
												/> */}
												<div className="month-datepicker">
													<DatePicker 
														showYearPicker
														dateFormat="yyyy"
														className={`input input--solid`} 
														selected={data.birthday} 
														onChange={(date) => {
															onChangeData('birthday', date);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-12">
									<Input
										wrapperClasses={'input--lg'}
										label={'Email'}
										inputClasses={'input--solid'}
										value={data.email}
										onInput={(e)=>{
											onChangeData('email', e.target.value);
										}}
									/>
								</div>
								<div className="col-md-6 col-sm-6 col-12">
									<Input
										wrapperClasses={'input--lg'}
										label={'Phone'}
										inputClasses={'input--solid'}
										value={data.phone}
										onInput={(e)=>{
											onChangeData('phone', e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="form-item">
							<div className="row gutters-form">
								<div className="col-12">
									<Textarea
										wrapperClasses={'input--lg'}
										label={'Bio'}
										textareaClasses={'input--solid'}
										rows={'6'}
										// value={'Mikey Musumeci is arguably the best American-born Brazilian Jiu-Jitsu practitioner in history. Born into an Italian-American family in 1996, Musumeci started practicing BJJ at age 4 and never looked back. Throughout his childhood, the young prodigy would spend hours before and after school training and drilling jiu-jitsu techniques, typically alongside his sister Tammi, who would become a multiple-time BJJ World Champion herself.'}
										value={data.bio}
										onChange={(e) => {
											onChangeData('bio', e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="form-item">
							<div className="row gutters-form">
								<div className="col-12">
									<div className="heading-row">
										<div className="flex-ico-ss-text">
											<span className="ico ico--sm rounded-full border-ico">
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" clipRule="evenodd" d="M7.5 6.91594V6.06595C7.49995 5.85512 7.63216 5.66692 7.8305 5.59545L8.593 5.32095C8.85574 5.22635 9.14325 5.22635 9.406 5.32095L10.1695 5.59594C10.3677 5.66735 10.4998 5.8553 10.5 6.06595V6.91594C10.5 7.55244 9.6095 8.02344 9.2 8.20694C9.07285 8.26436 8.92715 8.26436 8.8 8.20694C8.39051 8.02344 7.5 7.55144 7.5 6.91594Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
													<rect x="3" y="2.25" width="12" height="13.5" rx="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></rect>
													<path d="M6.75 10.5H11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
													<path d="M7.875 12.75H10.125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
												</svg>
											</span>
											<h4 className="heading">Verificative your Fighter ID</h4>
										</div>
										<div className="flex-group">
											<a href="#" className="btn btn--primary btn--sm">
												<span className="info">Verificate ID</span>
												<span className="ico ico-14">
													<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clipPath="url(#clip0_146_4014)">
															<path d="M7.25 3.375L12.875 9L7.25 14.625" stroke="currentColor" strokeWidth="2" strokeLinecap="square"></path>
														</g>
														<defs>
															<clipPath id="clip0_146_4014">
																<rect width="18" height="18" fill="white" transform="translate(0.5)"></rect>
															</clipPath>
														</defs>
													</svg>
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-footer --bordered">
					<div className="flex-group justify-end">
						<button type="submit" className={`btn btn--primary btn--sm ${!Object.entries(updateData).length ? 'disabled' : ''}`} onClick={(e)=>{
							e.preventDefault();
							save();
						}}>
							<span>Save Changes</span>
						</button>
					</div>
				</div>
			</form>

		</div>
	)
}

export default MyProfileInstructor
