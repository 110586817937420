const usersBase = 'api/academy-general-info/';

export const academyGeneralInfo = (instance) => ({
    async getAcademyGeneralInfo(payload) {
        const { data } = await instance.get(`${usersBase}/my`);
        return data;
    },
    async updateAcademyGeneralInfo(payload) {
        const { data } = await instance.put(`${usersBase}/my`, payload);
        return data;
    },
    async getAcademyGalleryList(payload) {
        const { data } = await instance.get(`${usersBase}/gallery`);
        return data;
    },
    async uploadAcademyGalleryList(payload) {
        const { data } = await instance.post(`${usersBase}/gallery`, payload);
        return data;
    },
    async deleteAcademyGalleryItem(id) {
        const { data } = await instance.delete(`${usersBase}/gallery/${id}`);
        return data;
    },
    async getAllAcademies(queries = "limit=1000") {
        const { data } = await instance.get(`/api/academies/pub?${queries}`);
        return data;
    },
    async getAcademyById(academyId) {
        const { data } = await instance.get(`/api/academies/${academyId}`);
        return data;
    },
})