import React, { useState, useEffect, Fragment } from 'react'
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch'
import Input from '../../../components/ui/formElements/Input'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import DropDown from '../../../components/ui/DropDown'
import CheckboxEl from '../../../components/ui/formElements/CheckboxEl'
import api from "../../../api";
import { toast } from 'react-toastify';
import { trainingStyle } from "../../../constants";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import { AddAnOverrideModal } from "../../../components/modals/AddAnOverrideModal";
import classNames from 'classnames'

const PrivateSessionInstructor = () => {
    const people = new Array(30).fill('').map((item, index) => ({ value: index + 1, label: index + 1 }));
    // { start: '09:00', end: '13:00' }
    const weeklySchedule = [
        {
            dayName: 'monday',
            isActive: true,
            timeSetList: []
        },
        {
            dayName: 'tuesday',
            isActive: false,
            timeSetList: []
        },
        {
            dayName: 'wednesday',
            isActive: false,
            timeSetList: []
        },
        {
            dayName: 'thursday',
            isActive: false,
            timeSetList: []
        },
        {
            dayName: 'friday',
            isActive: false,
            timeSetList: []
        },
        {
            dayName: 'saturday',
            isActive: false,
            timeSetList: []
        },
        {
            dayName: 'sunday',
            isActive: false,
            timeSetList: []
        },
    ];

    const [schedule, setShedule] = useState(weeklySchedule);
    console.log('schedule:', schedule);
    const [scheduleFromAccount, setScheduleFromAccount] = useState([]);

    const activeSchedules = schedule.filter(item => item.isActive);

    const [rescheduledDatesActive, setRescheduledDatesActive] = useState(false);

    const [rescheduleEdit, setRescheduleEdit] = useState(null);

    const [data, setData] = useState({
        instructorPrivateSessionInfo: null
    });

    const [updateData, setUpdateData] = useState({});

    const [copyTimeInData, setCopyTimeInData] = useState([
        { day: "monday", active: false },
        { day: "tuesday", active: false },
        { day: "wednesday", active: false },
        { day: "thursday", active: false },
        { day: "friday", active: false },
        { day: "saturday", active: false },
        { day: "sunday", active: false },
    ]);

    const getInstructorPrivateSessionInfo = async () => {
        setShedule(weeklySchedule);

        let res = await api.instructorPrivateSessionInfo.getInstructorPrivateSessionInfo();

        setData((prev) => ({
            ...prev,
            instructorPrivateSessionInfo: res?.data
        }));

        if (res?.data?.availability?.length) {
            setScheduleFromAccount(res?.data?.availability);

            for (let index = 0; index < res?.data?.availability.length; index++) {
                const availabilityItem = res?.data?.availability[index];

                setShedule((prev) => {
                    return prev.map((itemA) => itemA.dayName === availabilityItem.day ? { ...itemA, isActive: true, timeSetList: [...itemA.timeSetList, { start: availabilityItem.startTime.slice(0, 5), end: availabilityItem.endTime.slice(0, 5) }] } : itemA)
                })
            }
        }
    }

    const onChangeData = (baseField, field, value) => {
        setData((prev) => {
            return {
                ...prev,
                [baseField]: {
                    ...(prev[baseField]),
                    [field]: value
                }
            }
        });

        setUpdateData((prev) => {
            return {
                ...prev,
                [baseField]: {
                    ...(prev[baseField]),
                    [field]: value
                }
            }
        });
    }

    const copyTimeInSubmit = (indexAttr) => {
        for (let index = 0; index < copyTimeInData.length; index++) {
            if (copyTimeInData[index]?.active) {
                setShedule((prev) => {
                    return [...prev]?.map((item) => item?.dayName === copyTimeInData[index].day ? { ...item, isActive: true, timeSetList: schedule[indexAttr].timeSetList } : item)
                })
            }
        }
    };

    const changeStartOrEndTime = (availabilityIndex, itemIndex, field, value) => {
        setShedule((prev) => {
            return prev.map((itemA, itemAIndex) => itemAIndex === availabilityIndex ? { ...itemA, timeSetList: [...itemA.timeSetList]?.map((timeItem, timeItemIndex) => timeItemIndex === itemIndex ? { ...timeItem, [field]: value } : timeItem) } : itemA)
        })
    }

    const tConvert = (time) => {
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

        if (time.length > 1) {
            time = time.slice(1);
            time[5] = +time[0] < 12 ? 'am' : 'pm';
            time[0] = +time[0] % 12 || 12;
        }
        return time.join('');
    }

    const updateInstructorPrivateSessionInfo = async () => {
        let res = await api.instructorPrivateSessionInfo.updateInstructorPrivateSessionInfo(updateData?.instructorPrivateSessionInfo);

        if (res.success) {
            toast.success(res?.message || "PRIVATE SESSION INFORMATION Updated");
        } else {
            toast.error(res?.message || "PRIVATE SESSION INFORMATION Updated Error");
        }

        console.log('updateInstructorPrivateSessionInfo res:', res);
    }

    const createNewAvailabilities = async () => {
        let payload = {};

        schedule.filter(item => item.isActive)?.map(item => ({ [item.dayName.toLowerCase()]: item.timeSetList })).forEach((item) => {
            payload[Object.keys(item)[0]] = Object.values(item)[0];
        });

        let res = await api.instructorPrivateSessionInfo.createNewAvailabilities({ days: payload });

        if (res.success) {
            toast.success(res?.message || "AVAILABILITY Updated");
        } else {
            toast.error(res?.message || "AVAILABILITY Error");
        }
    }

    const checkAvailabilitiesToRemove = async () => {
        let toRemove = [];

        for (let index = 0; index < scheduleFromAccount.length; index++) {
            const scheduleItem = scheduleFromAccount[index];

            if (!activeSchedules.map(item => item?.dayName)?.includes(scheduleItem.day)) {
                toRemove.push(scheduleItem.day);
            }
        }

        if (toRemove?.length) {
            await api.instructorPrivateSessionInfo.availabilityDeleteByDays({ days: toRemove });
        }
    }

    // const addAnOverride = async() => {
    //     let res = await api.instructorPrivateSessionInfo.addNewRescheduleDate({
    //         date: new Date(rescheduledForm.date).toISOString().slice(0, 10),
    //         startTime: rescheduledForm.startTime,
    //         endTime: rescheduledForm.endTime
    //     });

    //     if(res.success){
    //         toast.success(res?.message || "Rescheduled Date Created Successfully");
    //         await getInstructorPrivateSessionInfo();
    //     } else {
    //         toast.error(res?.message || "Rescheduled Date Error");
    //     }
    // }

    const deleteRescheduleDate = async (id) => {
        let res = await api.instructorPrivateSessionInfo.deleteRescheduleDate(id);

        if (res.success) {
            toast.success(res?.message || "Rescheduled Date Deleted Successfully");
            await getInstructorPrivateSessionInfo();
        } else {
            toast.error(res?.message || "Rescheduled Date Delete Error");
        }
    }

    const save = () => {
        if (updateData?.instructorPrivateSessionInfo && ["gym", "address", "pricePerSession", "traningStyle", "maxPeople"].some(r => Object.keys(updateData?.instructorPrivateSessionInfo).includes(r))) {
            updateInstructorPrivateSessionInfo();
        }

        if (activeSchedules?.length) {
            createNewAvailabilities();
        }

        checkAvailabilitiesToRemove();
    }

    useEffect(() => {
        getInstructorPrivateSessionInfo();
    }, [])

    return (
        <>
            {data.instructorPrivateSessionInfo ?
                <div className="settings-body settings-gap">
                    <form className="form form-settings" method="post">
                        <div className="form-body">
                            <div className="form-items-list">
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-12">
                                            <div className="sidebar-header heading-row">
                                                <h3 className="heading">Availability</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="table-times">
                                                {schedule.map((item, index) => (
                                                    <div className="table-times--row">
                                                        <div className='row-group justify-between gap--sm'>
                                                            <div className="table-times-col">
                                                                <CheckboxSwitch
                                                                    text={item.dayName}
                                                                    checked={item.isActive}
                                                                    name={item.dayName}
                                                                    textClassName="capitalize"
                                                                    onChange={(e) => {
                                                                        setShedule((prev) => {
                                                                            return prev.map((itemA, itemAIndex) => itemAIndex === index ?
                                                                                {
                                                                                    ...itemA, isActive: e.target.checked,
                                                                                    ...(itemA?.timeSetList?.length ? { timeSetList: itemA.timeSetList } : { timeSetList: [{ start: '09:00', end: '13:00' }] })
                                                                                } : itemA
                                                                            )
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <TimeActions
                                                                wrapperClasses={"lg-hidden"}
                                                                {...{
                                                                    copyTimeInData,
                                                                    setCopyTimeInData,
                                                                    copyTimeInSubmit,
                                                                    index
                                                                }}
                                                            />
                                                        </div>
                                                        {item.isActive ?
                                                            <div className="table-times-col flex-auto justify-between" >
                                                                <ul className="times-list">
                                                                    {item?.timeSetList.map((set, idx) => (
                                                                        <li className="times-list--item">
                                                                            <div className="times--item-col flex-auto">
                                                                                <div className="times-control">
                                                                                    <InputMask
                                                                                        mask="99:99" wrapperClasses={'w-full input--solid'}
                                                                                        value={set.start} onInput={(e) => {
                                                                                            changeStartOrEndTime(index, idx, 'start', e.target.value.slice(0, 5))
                                                                                        }}
                                                                                    >
                                                                                        {(inputProps) => <Input {...inputProps} />}
                                                                                    </InputMask>
                                                                                    <div className="times-control-split">
                                                                                        <img src={require("../../../assets/img/time-split-ico.svg").default} />
                                                                                    </div>
                                                                                    <InputMask
                                                                                        mask="99:99" wrapperClasses={'w-full input--solid'}
                                                                                        value={set.end} onInput={(e) => {
                                                                                            changeStartOrEndTime(index, idx, 'end', e.target.value.slice(0, 5))
                                                                                        }}
                                                                                    >
                                                                                        {(inputProps) => <Input {...inputProps} />}
                                                                                    </InputMask>
                                                                                </div>
                                                                            </div>
                                                                            <div className="times--item-col">
                                                                                <div className="times-actions">
                                                                                    {idx === 0 ?
                                                                                        <button
                                                                                            type="button" className="btn btn--square btn--md rounded-full btn-border"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                setShedule((prev) => {
                                                                                                    return prev.map((itemA, itemAIndex) => itemAIndex === index ? { ...itemA, timeSetList: [...itemA.timeSetList, { start: '09:00', end: '13:00' }] } : itemA)
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <span className="ico">
                                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M3.125 10H16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                    <path d="M10 3.125V16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                </svg>
                                                                                            </span>
                                                                                        </button> :
                                                                                        <button
                                                                                            type="button" className="btn btn--square btn--md rounded-full btn-border"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                setShedule((prev) => {
                                                                                                    return prev.map((itemA, itemAIndex) => itemAIndex === index ? { ...itemA, timeSetList: itemA.timeSetList.filter((itemF, itemFIndex) => itemFIndex !== idx) } : itemA)
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <span className="ico">
                                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.4482 17.4997H6.54818C5.67568 17.4997 4.95068 16.8263 4.88568 15.9555L4.13818 5.83301H15.8332L15.1107 15.9513C15.0482 16.8238 14.3224 17.4997 13.4482 17.4997V17.4997Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                    <path d="M10.0002 9.16699V14.167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                    <path d="M3.3335 5.83366H16.6668" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                    <path d="M14.1668 5.83333L13.3227 3.58167C13.0785 2.93083 12.4568 2.5 11.7618 2.5H8.2385C7.5435 2.5 6.92183 2.93083 6.67766 3.58167L5.8335 5.83333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                    <path d="M12.8585 9.16699L12.5002 14.167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                    <path d="M7.1418 9.16699L7.50013 14.167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                                </svg>
                                                                                            </span>
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                <TimeActions
                                                                    wrapperClasses={"hidden lg-flex"}
                                                                    {...{
                                                                        copyTimeInData,
                                                                        setCopyTimeInData,
                                                                        copyTimeInSubmit,
                                                                        index
                                                                    }}
                                                                />
                                                            </div>
                                                            : null}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form">
                                        <div className="col-12">
                                            <div className="gutters-form--gap">
                                                <div className="sidebar-header heading-row">
                                                    <div className="heading-block">
                                                        <h3 className="heading">Rescheduled dates</h3>
                                                        <p className="desk-heading --sm-heading">
                                                            Add dates when your availability
                                                            is not the same as on other days.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="ovverade-list">
                                                    <ul className="settings-list">
                                                        {data?.instructorPrivateSessionInfo?.rescheduledDate?.map((item, index) => {
                                                            return <li className="settings-list--item" key={index}>
                                                                <div className="card-bordered card-row">
                                                                    <div className="card-row--item justify-between flex-auto overflow--x-hidden">
                                                                        <div className="card-row-group overflow--x-hidden justify-between">
                                                                            <div className="heading-block --date-heading">
                                                                                {/* <p className="date-text">Thursday, 18 April</p> */}
                                                                                <p className="date-text">{new Date(item.date).toDateString().slice(0, -5)}</p>
                                                                                <p className="desk-heading --sm-heading time-text">
                                                                                    {
                                                                                        item?.times?.map((timeItem, timeItemIndex) => {
                                                                                            return <Fragment key={timeItemIndex}>
                                                                                                <span>{tConvert(timeItem.startTime)}</span> - <span>{tConvert(timeItem.endTime)}</span>
                                                                                                {timeItemIndex !== item?.times?.length - 1 ? <span>, </span> : null}
                                                                                            </Fragment>
                                                                                        })
                                                                                    }
                                                                                    {/* <span>9:00am</span> - <span>5:00pm</span> */}
                                                                                    {item?.isUnavailable ?
                                                                                        <span>Unavailable all day</span>
                                                                                        : null}
                                                                                    {!item?.times?.length && !item?.isUnavailable ? <span>Empty time</span> : null}
                                                                                </p>
                                                                            </div>
                                                                            <div className="flex-group justify-end flex-group--xs flex-nowrap">
                                                                                <a
                                                                                    href="#" className="btn btn--square btn--sm rounded-full btn-opacity"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        deleteRescheduleDate(item.id)
                                                                                    }}
                                                                                >
                                                                                    <span className="ico">
                                                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fill-rule="evenodd" clipRule="evenodd" d="M14.7928 19.2493H7.20276C6.24301 19.2493 5.44551 18.5087 5.37401 17.5508L4.55176 6.41602H17.4163L16.6215 17.5462C16.5528 18.5059 15.7543 19.2493 14.7928 19.2493V19.2493Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M11.0003 10.084V15.584" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M3.66699 6.41732H18.3337" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M15.5837 6.41667L14.6551 3.93983C14.3865 3.22392 13.7027 2.75 12.9382 2.75H9.06249C8.29799 2.75 7.61416 3.22392 7.34558 3.93983L6.41699 6.41667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M14.1437 10.084L13.7495 15.584" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M7.85598 10.084L8.25014 15.584" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </a>
                                                                                <a href="#" className="btn btn--square btn--sm rounded-full btn-opacity" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setRescheduleEdit(item);
                                                                                    setRescheduledDatesActive(true);
                                                                                }}>
                                                                                    <span className="ico">
                                                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fill-rule="evenodd" clipRule="evenodd" d="M4.85159 14.3176L14.3171 4.85211C14.6746 4.49461 15.2548 4.49461 15.6123 4.85211L17.1487 6.38844C17.5062 6.74594 17.5062 7.32619 17.1487 7.68369L7.68226 17.1483C7.51084 17.3206 7.27801 17.4169 7.03509 17.4169H4.58301V14.9648C4.58301 14.7219 4.67926 14.489 4.85159 14.3176Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M12.6045 6.5625L15.437 9.395" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })}
                                                    </ul>

                                                    <div className="flex-group">
                                                        <a href="#" className="btn btn--primary btn--sm" onClick={(e) => {
                                                            e.preventDefault();
                                                            setRescheduledDatesActive(true);
                                                        }}>
                                                            <span className="ico">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.125 10H16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M10 3.125V16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                            <span>add an override</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="row gutters-form-3">
                                        <div className="col-12">
                                            <div className="row gutters-form">
                                                <div className="col-12">
                                                    <div className="sidebar-header heading-row">
                                                        <h3 className="heading">
                                                            Private Session Information
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row gutters-form">
                                                <div className="col-12">
                                                    <div className="row gutters-form">
                                                        <div className="col-md-6 col-sm-6 col-12">
                                                            <Input
                                                                wrapperClasses={"input--lg"}
                                                                inputClasses={'input--solid'}
                                                                label={'Gym'}
                                                                value={data.instructorPrivateSessionInfo.gym}
                                                                onInput={(e) => {
                                                                    onChangeData('instructorPrivateSessionInfo', 'gym', e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-sm-6 col-12">
                                                            <Input
                                                                wrapperClasses={"input--lg"}
                                                                inputClasses={'input--solid'}
                                                                label={'Address'}
                                                                value={data.instructorPrivateSessionInfo.address}
                                                                onInput={(e) => {
                                                                    onChangeData('instructorPrivateSessionInfo', 'address', e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row gutters-form-2">
                                                        <div className="col-md-4 col-sm-4 col-12">
                                                            <Input
                                                                wrapperClasses={"input--lg"}
                                                                inputClasses={'input--solid'}
                                                                label={'Price for a session'}
                                                                // value={'$ 900.00 / hr'}
                                                                value={data.instructorPrivateSessionInfo.pricePerSession}
                                                                onInput={(e) => {
                                                                    onChangeData('instructorPrivateSessionInfo', 'pricePerSession', e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-sm-4 col-12">
                                                            <SelectOne
                                                                wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                                label={'What Do You Teach?'}
                                                                options={trainingStyle.map(item => ({ value: item.key, label: item.title }))}
                                                                value={data.instructorPrivateSessionInfo.traningStyle}
                                                                onChange={(e) => {
                                                                    onChangeData('instructorPrivateSessionInfo', 'traningStyle', e.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-sm-4 col-12">
                                                            <SelectOne
                                                                wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                                label={'Max People'}
                                                                options={people}
                                                                value={data.instructorPrivateSessionInfo.maxPeople}
                                                                onChange={(e) => {
                                                                    onChangeData('instructorPrivateSessionInfo', 'maxPeople', e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-footer">
                            <div className="flex-group justify-end">
                                <button
                                    type="submit" className="btn btn--primary btn--sm"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        save();
                                    }}
                                >
                                    <span>Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div> : null}

            {
                rescheduledDatesActive ?
                    <AddAnOverrideModal
                        open={rescheduledDatesActive}
                        handleClose={() => {
                            setRescheduledDatesActive(false);
                            setRescheduleEdit(null);
                        }}
                        overrideAdded={() => {
                            getInstructorPrivateSessionInfo();
                            setRescheduledDatesActive(false);
                        }}
                        rescheduleEdit={rescheduleEdit}
                    />
                    : null
            }
        </>
    )
}


function TimeActions({
    wrapperClasses,
    copyTimeInData,
    setCopyTimeInData,
    copyTimeInSubmit,
    index
}) {
    return (
        <div className={classNames("time-actions", wrapperClasses)}>
            <DropDown
                labelButton={
                    <span className="ico">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="17.5" y="14.167" width="11.6667" height="11.6667" rx="3" transform="rotate(180 17.5 14.167)" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></rect>
                            <path d="M14.1667 16.6663C13.6336 17.2008 12.9096 17.5007 12.1548 17.4997H5C3.61929 17.4997 2.5 16.3804 2.5 14.9997V7.84493C2.499 7.09012 2.79891 6.36604 3.33334 5.83301" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                }
                dropDownClasses={'dropdown--sm --bottom-start'}
                buttonClasses={'btn btn--square btn--md rounded-full btn-border'}
                position='right'
            >
                <div
                    className="dropdown-popup copy-time-in-dropdown"
                    style={{ minWidth: '204px' }}
                >
                    <div className="dropdown-popup--header">
                        <h6 className="heading">Copy time in</h6>
                    </div>
                    <div className="dropdown-popup--body">
                        <ul className="select-week-list">
                            <li className="week-item">
                                <CheckboxEl
                                    wrapperClasses={'check-simulate check-group-btn'}
                                    label={'Select All'}
                                    checked={copyTimeInData.filter((ctidItem) => (ctidItem.active))?.length === 7}
                                    onChange={(e) => {
                                        setCopyTimeInData((prev) => {
                                            return prev.map((itemA, itemAIndex) => ({ ...itemA, active: e.target.checked }))
                                        })
                                    }}
                                />
                            </li>
                            {
                                copyTimeInData?.map((ctidItem, ctidIndex) => {
                                    return <li className="week-item" key={ctidIndex}>
                                        <CheckboxEl
                                            wrapperClasses={'check-simulate check-group-btn capitalize'}
                                            label={ctidItem.day}
                                            checked={ctidItem.active}
                                            onChange={(e) => {
                                                setCopyTimeInData((prev) => {
                                                    return prev.map((itemA, itemAIndex) => ctidIndex === itemAIndex ? { ...itemA, active: e.target.checked } : itemA)
                                                })
                                            }}
                                        />
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="dropdown-popup--footer">
                        <a href="#" className="btn btn--sm">
                            <span>Cancel</span>
                        </a>
                        <button
                            type="button" className="btn btn--sm btn--primary"
                            onClick={(e) => {
                                e.preventDefault();
                                copyTimeInSubmit(index);
                            }}
                        >
                            <span>Submit</span>
                        </button>
                    </div>
                </div>
            </DropDown>
        </div>
    )
}

export default PrivateSessionInstructor
