import DesignText from "./DesignText";
import PlanSeminarWrapper from "./PlanSeminarWrapper";
import SeminarInformation from "./SeminarInformation";
import TimeAndDate from "./TimeAndDate";
import BookingTerms from "./BookingTerms";

export const PlanSeminar = {
    PlanSeminarWrapper,
    TimeAndDate,
    SeminarInformation,
    DesignText,
    BookingTerms,
}