const base = 'api/plan-camps';

export const planCamp = (instance) => ({
    async getAllCamps(query = "") {
        const { data } = await instance.get(`${base}?${query}`);
        return data;
    },
    async getSingleCamp(planId) {
        const { data } = await instance.get(`${base}/${planId}`);
        return data;
    },
    async getOnlyMyCamps() {
        const { data } = await instance.get(`${base}/my`);
        return data;
    },
    // academy + instructor ( разница в количестве полей formData )
    async createCamp(payload) {
        const { data } = await instance.post(`${base}`, payload);
        return data;
    },
    async instructorJoinToPlancamp(campId) {
        const { data } = await instance.post(`${base}/${campId}/instructor-join`);
        return data;
    },

    async addInstructorToCamp(campId) {
        const { data } = await instance.post(`${base}/${campId}/instructor-add`);
        return data;
    },

    // academy + instructor ( разница в количестве полей formData )
    async updateCamp({ body, campId }) {
        const { data } = await instance.put(`${base}/${campId}`, body);
        return data;
    },

    async getPlanCampCreatedByAcademy() {
        const { data } = await instance.get(`${base}/planned-by-academy`);
        return data;
    },

    async getPlanCampInstrcutorsActive(clapmId) {
        const { data } = await instance.get(`${base}/${clapmId}/instructors`);
        return data;
    },
    // Только для владельца
    async getPlancampInstructorsAll(clapmId) {
        const { data } = await instance.get(`${base}/${clapmId}/instructors/all`);
        return data;
    },
    // Только для владельца
    async getPlancampInstructorsWaitConfirmation(clapmId) {
        const { data } = await instance.get(`${base}/${clapmId}/instructors/waiting-confirmation`);
        return data;
    },
    async getPlancampInstructorsWaitingContract(clapmId) {
        const { data } = await instance.get(`${base}/${clapmId}/instructors/waiting-contract`);
        return data;
    },
    // Получение реквестов Академии или инструктора:
    // 1. без qwery - получает все реквесты 
    // 2. qwery = "status=waiting-instructor-contract" - получет реквесты ожидающие подтверждения
    async getPlancampRequests(qwery) {
        const { data } = await instance.get(`${base}/requests?${qwery}`);
        return data;
    },

    async sendRequestCampHosting({ plancampId }) {
        const { data } = await instance.get(`${base}/request-hosting/${plancampId}`);
        return data;
    },
    async sendCampRequestByInstructor({ plancampId }) {
        const { data } = await instance.get(`${base}/instructor-join/${plancampId}`);
        return data;
    },
    async acceptInstructorCampRequest({ instructorId }) {
        const { data } = await instance.get(`${base}/instructor-accept/${instructorId}`);
        return data;
    },
    async inviteInstructorCampRequest({ plancampId, instructorId }) {
        const { data } = await instance.get(`${base}/instructor-invite/${plancampId}/${instructorId}`);
        return data;
    },
    async acceptCampRequest({ plancampId, requestId, body }) {
        const { data } = await instance.post(`${base}/request-accept/${plancampId}/${requestId}`, body);
        return data;
    },
    async rejectCampRequest({ requestId }) {
        const { data } = await instance.get(`${base}/instructor-reject/${requestId}`);
        return data;
    },
    async getCampsMyAvailableToInstructor(instructorId) {
        const { data } = await instance.get(`${base}/available-invite/${instructorId}`);
        return data;
    },
})