import { getImageSrc } from "../../../helpers/utils"
import { Step } from "./Step"

const { CardDetails } = require("./CardDetails")

export const StepDesign = ({ seminarDetails, currentStatus, status }) => {
    return (
        <Step currentStatus={currentStatus} status={status} label="Design & Text">
            {
                seminarDetails?.photos?.length ?
                    <div className="row gutters-form-7">
                        {
                            seminarDetails?.photos.map(photo => (
                                <div className="col-6" key={photo?.id}>
                                    <img className='w-full' src={getImageSrc(photo?.url)} style={{ height: 96 }} alt={photo?.originalname} />
                                </div>
                            ))
                        }
                    </div>
                    : null
            }
            <CardDetails
                label={"Seminar Name"}
                isEdited={seminarDetails?.lastEdited?.includes("seminarName")}
            >
                <p className='color-text'>{seminarDetails?.seminarName || "Empty"}</p>
            </CardDetails>
            <CardDetails
                label={"Summary"}
                isEdited={seminarDetails?.lastEdited?.includes("summary")}
            >
                <p className='color-text'>{seminarDetails?.summary || "Empty"}</p>
            </CardDetails>
        </Step >
    )
}