import React, {useEffect, useState} from 'react'

const AccordeonTypeArrow = ({ children, title, isActive = false, className='', objData=null, onClick }) => {
    const [accordeonActive, setAccordeonActive] = useState(isActive)
    const clickHandler = () => {
        onClick && onClick(objData, !accordeonActive)
        setAccordeonActive(prev => !prev);
    }

    useEffect(() => {
        if (!isActive) {
            setAccordeonActive(false)
        }
    }, [isActive])

    return (
        <div className={`accordion ${accordeonActive ? 'open' : ''} ${className}`}>
            <div className="btn btn--lg accordion__button" onClick={clickHandler}>
                <span>
                    {title}
                </span>
                <span className="ico accordion__arrow">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_278_6526)">
                            <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_278_6526">
                                <rect width="14" height="14" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </span>
            </div>
            {accordeonActive &&
                <div className="accordion__body" >
                    {children}
                </div>
            }

        </div>
    )
}

export default AccordeonTypeArrow
