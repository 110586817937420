import React, { useState } from 'react'
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from "../../../api"
import { apiConfig } from '../../../api/config.js'
import { setToken, } from "../../../redux/slices/applicationSlice.js"
import { getUserFetch } from "../../../redux/thunks/applicationThunks.js"
import Button from '../../ui/buttons/Button'
import Input from '../../ui/formElements/Input'
import InputPass from '../../ui/formElements/InputPass'
import ModalTemplate from '../ModalTemplate'

const LoginModal = ({ open, handleClose, singUpHandler, forgotPassHandler }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: "",
        password: ""
    });

    const login = async () => {
        let response = await api.auth.login(data);

        if (response?.success && response.data.token) {
            localStorage.setItem('authToken', response.data.token);
            dispatch(setToken(response.data.token));
            setTimeout(() => {
                dispatch(getUserFetch(response.data.token));
                handleClose();
                navigate('/');
            }, 200);
        } else {
            toast.error(response.message || "Email or password are wrong");
        }
    }

    return (
        <ModalTemplate open={open} onClick={handleClose}>
            <div className="modal__header">
                <div className="logo">
                    <img src={require('../../../assets/img/logo-black.svg').default} />
                </div>
            </div>
            <div className="modal__body">
                <form className="form form--lg" action='#' autoComplete="off" onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <div className="form-header">
                        <div className="heading-block">
                            <h3 className="heading">Login to account</h3>
                            <p className="text--md desk-heading">
                                Fill in the form below to access your account.
                            </p>
                        </div>
                    </div>
                    <div className="form-body">
                        <div className="row gutters-form">
                            <div className="col-12">
                                <Input
                                    wrapperClasses={'input--xl'}
                                    label={'Email or phone'}
                                    inputClasses={'input--solid'}
                                    placeholder={'Type your email or phone...'}
                                    value={data.email}
                                    onInput={(e) => {
                                        setData({
                                            ...data,
                                            email: e.target.value.trim()
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <InputPass
                                    label={'Password'}
                                    inputClass={'input--solid w-full'}
                                    placeholder={"Password"}
                                    formGroupClass={'input--xl input--icon-right'}
                                    forgotPassClick={forgotPassHandler}
                                    value={data.password}
                                    onInput={(e) => {
                                        setData({
                                            ...data,
                                            password: e.target.value
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <Button
                                    type="submit"
                                    className="w-full btn--primary btn--lg radius fs-20"
                                    onClick={(e) => {
                                        login();
                                    }}
                                >
                                    <span>Login</span>
                                </Button>
                            </div>
                            <div className="col-12 text-center">
                                <p className="desk-heading">or login with</p>
                            </div>
                            <div className="col-12">
                                <ul className="flex-group social-list justify-center">
                                    <li>
                                        <a
                                            href={`${apiConfig.baseURL}oauth/google`}
                                            className="btn btn--square btn--xl rounded-full btn-border">
                                            <span className="ico">
                                                <img src={require("../../../assets/img/ico-google.svg").default} />
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <Button className="btn btn--square btn--xl rounded-full btn-border">
                                            <span className="ico">
                                                <img src={require("../../../assets/img/ico-apple.svg").default} />
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href={`${apiConfig.baseURL}oauth/facebook`}
                                            className="btn btn btn--square btn--xl rounded-full btn-border">
                                            <span className="ico"><img src={require("../../../assets/img/ico-facebook.svg").default} /></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gutters-form">
                            <div className="col-12 text-center">
                                <p className="text--md">
                                    Don’t have an account yet?
                                    &nbsp;
                                    <Button onClick={singUpHandler} className="color-secondary btn-link">
                                        <span className="--fontFamily text-normal
                                        text--md">Sign Up</span>
                                    </Button>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    )
}

export default LoginModal
