import { Country } from 'country-state-city';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Input from '../../../components/ui/formElements/Input';
import Textarea from '../../../components/ui/formElements/Textarea';
import PageLoader from '../../../components/ui/PageLoader';
import { convertObjectToFormData } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import { resetSeminarFormData } from '../../../redux/slices/planningSlice';
import { createSeminar, updateSeminarFetch } from '../../../redux/thunks/seminarsThunks';
import SeminarPhotosUpload from '../parts/SeminarPhotosUpload';
import PopularQuestions from '../parts/PopularQuestions';
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch';

const DesignText = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { seminarId, chatId } = useParams()
    const user = useSelector(getUser)
    const { seminarDetails, isLoading } = useSelector(state => state.seminars)
    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [coverImage, setCoverImage] = useState(null)
    const [editedFields, setEditedFields] = useState([])


    const handleStepChange = (name, value) => {
        handleChange(name, value)
        setEditedFields(prev => [...prev, name])
    }
    const handleChangePhotos = (photos) => {
        handleStepChange("photos", photos)
    }
    const handleDeletePhoto = (id) => {
        handleStepChange("delete_photos", [...(formData?.delete_photos || []), id])
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    function openDatabase() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('toursDB', 1);

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains('tours')) {
                    db.createObjectStore('tours', { keyPath: 'id', autoIncrement: true });
                }
            };

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }

    async function saveTour(formData) {
        try {

            const base64photos = await Promise.all(

                formData.photos?.map((photo) => {
                    if (photo === formData.coverFileName) {

                    }
                    return toBase64(photo)
                })
            );
            // const cover = await toBase64(formData.photos.find(photo => photo.filename === formData.coverFileName))
            const cover = formData.photos.find(photo => photo.filename === formData.coverFileName)
            const tour = {
                ...formData,
                // photos: base64photos,
                // cover
            };

            const db = await openDatabase();

            const transaction = db.transaction(['tours'], 'readwrite');
            const store = transaction.objectStore('tours');

            const request = store.add(tour);
            request.onsuccess = () => {
                console.log('Tour saved successfully!');
            };

            request.onerror = (event) => {
                console.error('Failed to save the tour:', event.target.error);
            };
        } catch (error) {
            console.error('Failed to save the tour:', error);
        }
    }


    const handleSubmit = async () => {
        setIsErrorsShown(true)
        console.log(errors.hasErrors, errors, formData);
        if (seminarId) {
            if (!errors.hasRequiredError) {
                const body = new FormData()
                const dataToUpdate = pick(formData, editedFields)
                convertObjectToFormData(dataToUpdate, body)

                const res = await dispatch(updateSeminarFetch({ seminarId, body }))
                if (res) {
                    navigate(`/chat/${chatId}`)
                }
            }
        } else {
            if (!errors.hasRequiredError) {
                const body = new FormData()
                convertObjectToFormData(formData, body)
                body.set("country", Country.getCountryByCode(formData.country)?.name)
                const isTour = localStorage.getItem("isTour")

                if (isTour === "true") {
                    await saveTour(formData);
                    navigate("../preview-tour")

                } else {
                    const res = await dispatch(createSeminar(body)).unwrap()
                    if (res) {
                        dispatch(resetSeminarFormData())
                        navigate("../success")
                    }
                }
            }
        }
    }


    useEffect(() => {
        setEditedFields(prev => {
            if (prev.includes("photos")) {
                return prev
            } else {
                return [...prev, "photos"]

            }
        })
    }, [formData?.photos])

    useEffect(() => {
        handlePreviewChange("cover", coverImage)
        const getCoverName = (cover) => cover?.name || cover?.originalname
        if (coverImage?.id) {
            handleStepChange("coverId", coverImage?.id)
            return
        }
        if (getCoverName(coverImage)) {
            handleStepChange("coverFilename", getCoverName(coverImage))
        } else if (formData.photos?.length) {
            handleStepChange("coverFilename", getCoverName(formData.photos[0]))
        } else {
            handleStepChange("coverFilename", "")
        }
    }, [coverImage])

    useEffect(() => {
        setErrorConfig({
            exceptions: [],
            requiredFields: ["photos", "seminarName", "summary"]
        })

    }, [formData])



    return (
        <>
            {
                !isLoading ?
                    <>
                        <SeminarPhotosUpload
                            images={formData.photos}
                            setImages={handleChangePhotos}
                            onDelete={handleDeletePhoto}
                            setCoverImage={setCoverImage}
                            coverImage={coverImage}
                            error={isErrorsShown && errors.photos}
                        />
                        <hr className="hr mb-42" />
                        <h6 className="heading mb-32">Seminar Info</h6>
                        <div className="row gx-16 py-24 mb-42">
                            <div className="col-12">
                                <Input
                                    label="Seminar Name"
                                    wrapperClasses={"input--lg"}
                                    inputClasses={"input--solid"}
                                    value={formData.seminarName}
                                    error={isErrorsShown && errors?.seminarName}
                                    onChange={(e) => handleStepChange("seminarName", e.target.value)}
                                    placeholder={"Add a name here..."}
                                />
                            </div>

                            <div className="col-12">
                                <Textarea
                                    label="Summary"
                                    wrapperClasses={"input--lg"}
                                    textareaClasses={"input--solid"}
                                    style={{ minHeight: 140 }}
                                    value={formData.summary}
                                    error={isErrorsShown && errors?.summary}
                                    onChange={(e) => handleStepChange("summary", e.target.value)}
                                    placeholder={"Please write summary here..."}
                                />
                            </div>
                            {
                                seminarId && (
                                    <>
                                        <hr className="hr" />
                                        <PopularQuestions
                                            data={formData.faq}
                                            handleChange={(data) => handleStepChange("faq", data)}
                                            errors={errors?.faq}
                                            isErrorsShown={isErrorsShown}
                                            isSeminar
                                        />
                                    </>
                                )
                            }
                            {/* <div className="col-12">
                                <div className="form-group input--lg">
                                    <div className="label-row">
                                        <label className="label">About Academy(s)</label>
                                        <a
                                            href='#'
                                            data-tooltip-id="about-academy"
                                            className="btn color-secondary btn-link">
                                            <span className="text--xs">Learn More</span>
                                        </a>
                                        <Tooltip
                                            id="about-academy"
                                            content={`
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                                       ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                     sunt in culpa qui officia deserunt mollit anim id est laborum
                                    `}
                                            style={{ maxWidth: 400 }}
                                        />
                                    </div>
                                    <Textarea
                                        wrapperClasses={"input--lg"}
                                        textareaClasses={"input--solid"}
                                        style={{ minHeight: 140 }}
                                        value={formData.bio}
                                        disabled={formData.bio}
                                        error={isErrorsShown && errors?.bio}
                                        onChange={(e) => handleChange("bio", e.target.value)}
                                        placeholder={"Please write an optional information about instructors..."}
                                    />
                                </div>
                            </div> */}
                            <CheckboxSwitch
                                text="Set this seminar as private."
                                checked={formData.isPrivate}
                                onChange={() => handleStepChange("isPrivate", !formData.isPrivate)}
                            />
                        </div>

                        <div className="page-action-group">
                            <Link to={seminarId ? `/chat/${chatId}` : "../seminar-info"} className="btn btn--default btn--sm">Cancel</Link>
                            <button onClick={handleSubmit} className="btn btn--primary btn--sm">
                                {
                                    seminarId ?
                                        <span className="info">Apply Changes</span>
                                        :
                                        <>
                                            <span className="info">Continue</span>
                                            <span className="ico">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                                </svg>
                                            </span>
                                        </>

                                }
                            </button>
                        </div>
                    </>
                    :
                    <PageLoader />
            }
        </>
    )
}

export default DesignText