import React, { useState } from 'react'
import Button from '../buttons/Button'

const InputPass = (
    { 
        label, 
        placeholder,
        forgotPass=true, 
        forgotPassClick,
        inputClass, 
        formGroupClass,
        value, 
        ...props 
    }
    ) => {
        
    const [inputType, setInputType] = useState('password');
    const showPassHandler = () => {
        setInputType(prev => {
            if(prev === 'password') return 'text'
            if(prev === 'text') return 'password'
        })
    }
    return (
        <div className={`form-group ${formGroupClass}`}>
            {(forgotPass || label) && <div className="label-row">
               {label && <label className="label">{label}</label>} 
               {forgotPass && 
                    <Button onClick={forgotPassClick}
                        className="color-secondary btn-link">
                        <span className="text--xs">Forgot Password?</span>
                    </Button>
                }
            </div>}
            <div className="input-wrapper">
                <Button type="button" className="ico" onClick={showPassHandler}>
                    {inputType==="text" && <span className="btn-ico"><img src={require("../../../assets/img/eye-false.svg").default}/></span>}
                    {inputType==="password" && <span className="btn-ico"><img src={require("../../../assets/img/eye-true.svg").default}/></span>}
                </Button>
                <input className={`input ${inputClass}`} type={inputType} placeholder={placeholder} value={value} {...props}/>
            </div>
        </div>
    )
}

export default InputPass
