import DefaultLayout from "../layouts/DefaultLayout";
import SecondaryLayout from "../layouts/SecondaryLayout";
import SingUpLayout from "../layouts/SingUpLayout";
import AboutUS from "../pages/AboutUS";
import HomePage from "../pages/HomePage";

import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import AcademySingUp from "../pages/singUpPages/AcademySingUp";
import InstructorSingUp from "../pages/singUpPages/InstructorSingUp";
import StudentSingUp from "../pages/singUpPages/StudentSingUp";

import BillingInfo from "../pages/profiles/studentProfilePages/BillingInfo";

import { MySeminarsLayout } from "../layouts/MySeminarsLayout";
import ProfileLayout from "../layouts/ProfileLayout";
import AcademiesPage from "../pages/academies/AcademiesPage";
import AcademySingle from "../pages/academies/AcademySingle";
import { Chat } from "../pages/chat/Chat";
import ContactUs from "../pages/ContactUs";
import TemplateEditor from "../pages/editor/TemplateEditor";
import { Instructors } from "../pages/instructors/Instructors";
import { InstructorSingle } from "../pages/instructors/InstructorSingle";
import InviteToSeminar from "../pages/instructors/InviteToSeminar";
import SuccessInvite from "../pages/instructors/SuccessInvite";
import ContractPage from "../pages/planning/ContractPage";
import PlanningSuccess from "../pages/planning/parts/PlanningSuccess";
import { PlanCamp } from "../pages/planning/planCamp";
import { PlanSeminar } from "../pages/planning/planSeminar";
import GeneralInfo from "../pages/profiles/GeneralInfo";
import LocationInstructr from "../pages/profiles/instructorProfilePages/LocationInstructr";
import PrivateSessionInstructor from "../pages/profiles/instructorProfilePages/PrivateSessionInstructor";
import { RequestedPtivateLessons } from "../pages/profiles/instructorProfilePages/RequestedPtivateLessons";
import MyProfile from "../pages/profiles/MyProfile";
import { MySeminars } from "../pages/profiles/MySeminars";
import PayuotMethods from "../pages/profiles/PayuotMethods";
import { ProfileRequests } from "../pages/profiles/ProfileRequests";
import Security from "../pages/profiles/Security";
import { CampsMain } from "../pages/seminarsCamps/capms/CampsMain";
import { CampsPendingByAcademy } from "../pages/seminarsCamps/capms/CampsPendingByAcademy";
import { CampsPendingByInstructor } from "../pages/seminarsCamps/capms/CampsPendingByInstructor";
import { CampsSingle } from "../pages/seminarsCamps/capms/CampsSingle";
import SeminarOverview from "../pages/seminarsCamps/SeminarOverview";
import SeminarSingle from "../pages/seminarsCamps/seminars/SeminarSingle";
import { SeminarMain } from "../pages/seminarsCamps/seminars/SeminarsMain";
import SeminarsPendingByAcademy from "../pages/seminarsCamps/seminars/SeminarsPendingByAcademy";
import SeminarsPendingByInstructor from "../pages/seminarsCamps/seminars/SeminarsPendingByInstructor";
import AboutUSNew from "../pages/AboutUSNew";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import NotFoundPage from "../pages/NotFoundPage";
import TermsOfUse from "../pages/TermsOfUse";
import RefundPolicy from "../pages/RefundPolicy";
import CookiesSettings from "../pages/CookiesSettings";
import SeminarSummary from "../pages/planning/planSeminar/SeminarSummary";
import { RequestPrivateSession } from "../pages/planning/requestPrivateSession";
import PreviewTour from "../pages/planning/PreviewTour";
import NotificationsPage from "../pages/NotificationsPage";
import SocketProvider from "../layouts/SocketProvider";
import CampSummary from "../pages/planning/planCamp/CampSummary";


export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<SocketProvider />}>

                <Route element={<DefaultLayout />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/recovery-password/:restoreToken" element={<HomePage />} />
                    {/*<Route path="/about-us" element={<AboutUS />} />*/}
                    <Route path="/about-us" element={<AboutUSNew />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/instructors" element={<Instructors />} />
                </Route>

                <Route
                    element={<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col"
                        footerHide={true}
                        wrapperClass=" header-fixed"
                    />}
                >
                    <Route path="/academies" element={<AcademiesPage />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/chat/:chatId" element={<Chat />} />
                    <Route path="/chat/:chatId/:seminarId" element={<Chat />} />
                    <Route path="/notifications" element={<NotificationsPage />} />

                </Route>
                <Route
                    path="/instructors/:id"
                    element={<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col user-page header-fixed"
                    />}
                >
                    <Route index element={<InstructorSingle />}></Route>
                    <Route path="invite" element={<InviteToSeminar />} />
                    
                    <Route path="invite/success" element={<SuccessInvite />} />
                </Route>

                <Route
                    path="/planed-by-academy"
                    element={<DefaultLayout
                        mainClass="current-seminars"
                    />}
                >
                    <Route index element={<SeminarsPendingByAcademy />}></Route>
                </Route>
                <Route
                    path="/planed-by-insctructor"
                    element={<DefaultLayout
                        mainClass="current-seminars"
                    />} >
                    <Route index element={<SeminarsPendingByInstructor />} />
                </Route>

                <Route
                    element={<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col user-page"
                        wrapperClass=" header-fixed"
                    />}
                >
                    <Route path="/instructors/:id" element={<InstructorSingle />} />
                    <Route path="/academies/:id" element={<AcademySingle />} />
                </Route>
                <Route
                    element={<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col"
                        wrapperClass=" header-fixed"
                    />}
                >
                    <Route path="/my-seminars/:id" element={<SeminarOverview />} />
                </Route>

                <Route
                    element={<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col my-seminars-page"
                        wrapperClass=" header-fixed"
                    />}
                >
                    <Route element={<MySeminarsLayout />}>
                        <Route path="/my-seminars" element={<MySeminars />}></Route>
                        <Route path="/requests" element={<ProfileRequests />}></Route>
                        <Route path="/requested-lessons" element={<RequestedPtivateLessons />}></Route>
                    </Route>
                </Route>

                <Route
                    element={<DefaultLayout
                        mainClass="current-seminars"
                    />}
                >
                    <Route path="/seminars" element={<SeminarMain />} />
                    <Route path="/camps" element={<CampsMain />} />
                    <Route path="/planed-by-academy/camps" element={<CampsPendingByAcademy />} />
                    <Route path="/planed-by-academy" element={<SeminarsPendingByAcademy />} />
                    <Route path="/planed-by-insctructor" element={<SeminarsPendingByInstructor />} />
                    <Route path="/planed-by-insctructor/camps" element={<CampsPendingByInstructor />} />
                </Route>


                <Route
                    element={<DefaultLayout
                        headerClass="header--white"
                        mainClass="current-seminars"
                        wrapperClass=" header-fixed"
                    />}
                >
                    <Route path="/seminars/:id" element={<SeminarSingle />}></Route>
                    <Route path="/camps/:id" element={<CampsSingle />}></Route>
                    <Route
                        path="/planed-by-insctructor/:id"
                        element={<SeminarSingle
                            pageTyleDefault="pending"
                        />}
                    ></Route>
                    <Route
                        path="/planed-by-insctructor/camps/:id"
                        element={<CampsSingle
                            pageTyleDefault="pending"
                        />}
                    ></Route>
                    <Route path="/planed-by-academy/:id" element={<SeminarSingle pageTyleDefault="pending" />}></Route>
                    <Route path="/planed-by-academy/camps/:id" element={<CampsSingle pageTyleDefault="pending" />}></Route>
                </Route>

                <Route element={<SingUpLayout headerTitle={'STUDENT ACCOUNT'} />}>
                    <Route path="/student-sing-up" element={<StudentSingUp />} />
                </Route>
                <Route element={<SingUpLayout headerTitle={'ACADEMY ACCOUNT'} />}>
                    <Route path="/academy-sing-up" element={<AcademySingUp />} />
                </Route>
                <Route element={<SingUpLayout headerTitle={'INSTRUCTOR ACCOUNT'} />}>
                    <Route path="/instructor-sing-up" element={<InstructorSingUp />} />
                </Route>
                <Route element={<ProfileLayout />}>
                    <Route path="/settings/my-profile" element={<MyProfile />} />
                    <Route path="/settings/security" element={<Security />} />
                    <Route path="/settings/general-information" element={<GeneralInfo />} />
                    <Route path="/settings/payout-methods" element={<PayuotMethods />} />
                    <Route path="/settings/location-availability" element={<LocationInstructr />} />
                    <Route path="/settings/private-session" element={<PrivateSessionInstructor />} />
                    <Route path="/settings/billing" element={<BillingInfo />} />
                </Route>
                {/* planning */}
                <Route path={"/template-editor"} element={<TemplateEditor />} />
                <Route element={<SecondaryLayout />}>
                    <Route path="/edit-seminar/:chatId/:seminarId/:requestId" element={<PlanSeminar.PlanSeminarWrapper />}>
                        <Route index element={<PlanSeminar.TimeAndDate />} />
                        <Route path="seminar-info" element={<PlanSeminar.SeminarInformation />} />
                        <Route path="design-text" element={<PlanSeminar.DesignText />} />
                        <Route path="booking" element={<PlanSeminar.BookingTerms />} />
                        <Route path="success" element={<PlanningSuccess />} />
                    </Route>
                    <Route path="/edit-seminar/:chatId/:seminarId/contract" element={<ContractPage />} />
                    <Route path="/edit-seminar/:chatId/:seminarId/:requestId/summary" element={<SeminarSummary />} />
                    <Route path="/plan-seminar" element={<PlanSeminar.PlanSeminarWrapper />}>
                        <Route index element={<PlanSeminar.TimeAndDate />} />
                        <Route path="seminar-info" element={<PlanSeminar.SeminarInformation />} />
                        <Route path="design-text" element={<PlanSeminar.DesignText />} />
                        <Route path="success" element={<PlanningSuccess />} />
                    </Route>
                    <Route path="/plan-seminar/preview-tour" element={<PreviewTour />} />
                    <Route path="/plan-camp" element={<PlanCamp.PlanCampWrapper />}>
                        <Route index element={<PlanCamp.TimeAndDate />} />
                        <Route path="seminar-info" element={<PlanCamp.SeminarInformation />} />
                        <Route path="design-text" element={<PlanCamp.DesignText />} />
                        <Route path="success" element={<PlanningSuccess />} />
                    </Route>
                    <Route path="/edit-camp/:chatId/:campId/:requestId" element={<PlanCamp.PlanCampWrapper />}>
                        <Route index element={<PlanCamp.TimeAndDate />} />
                        <Route path="seminar-info" element={<PlanCamp.SeminarInformation />} />
                        <Route path="design-text" element={<PlanCamp.DesignText />} />
                        <Route path="booking" element={<PlanCamp.BookingTerms />} />
                        <Route path="success" element={<PlanningSuccess />} />
                    </Route>
                    {/* <Route path="/edit-camp/:chatId/:campId/contract" element={<ContractPage />} /> */}
                    <Route path="/edit-camp/:chatId/:campId/:requestId/summary" element={<CampSummary />} />

                    <Route path="/request-private-session/:instructorId" element={<RequestPrivateSession.RequestPrivateSessionWrapper />}>
                        <Route index element={<RequestPrivateSession.TimeAndDate />} />
                    </Route>
                    <Route path="/request-private-session/:instructorId/:chatId/summary" element={<RequestPrivateSession.Summary />} />

                    <Route path="terms-of-service" element={<TermsAndConditions />} />
                    <Route path="terms-of-use" element={<TermsOfUse />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="refund-policy" element={<RefundPolicy />} />
                    <Route path="cookies-settings" element={<CookiesSettings />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Route>
        </>
    ), { basename: "/" }
)