const authBase = 'api/auth';

export const auth = (instance) => ({
    async login(payload) {
        const { data } = await instance.post(`${authBase}/login`, payload);

        if(data?.data && data.data?.token) {
            localStorage.setItem('authToken', data.data?.token);

            instance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${data.data.token}`
                return config;
            })
        }


        return data;
    },

    async signupStudent(payload) {
        const { data } = await instance.post(`${authBase}/signup-student`, payload);
        return data;
    },

    async signupAcademy(payload) {
        const { data } = await instance.post(`${authBase}/signup-academy`, payload);
        return data;
    },

    async signupInstructor(payload) {
        const { data } = await instance.post(`${authBase}/signup-instructor`, payload);
        return data;
    },

    async logout() {
        const { data } = await instance.get(`${authBase}/logout`);
        return data;
    },
    
    async forgotPassword(payload) {
        const { data } = await instance.post(`${authBase}/forgot-password`, payload);
        return data;
    },
    
    async restorePassword(payload) {
        const { data } = await instance.post(`${authBase}/restore-password`, payload);
        return data;
    },
});