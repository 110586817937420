import React, { useRef, useState, useEffect } from 'react';

const Accordeon = ({ children, title, isActive = false, className, onClick }) => {
    const [height, setHeight] = useState(isActive ? 'auto' : '0px');
    const contentRef = useRef(null);

    useEffect(() => {
        if (isActive) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setHeight('0px');
        }
    }, [isActive]);

    return (
        <div className={`accordion-item ${isActive ? 'active' : ''} ${className}`}>
            <div className="accordeon-head" onClick={onClick}>
                <span className="accordion-icon"></span>
                <h4 className="accordeon-head-title">
                    {title}
                </h4>
            </div>
            <div
                className="accordion-item-body"
                ref={contentRef}
                style={{
                    maxHeight: height,
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                }}
            >
                <div className="accordion-item-content">
                    {typeof children === 'string' ? (
                        <div dangerouslySetInnerHTML={{ __html: children }}></div>
                    ) : (
                        children
                    )}
                </div>
            </div>
        </div>
    );
};

export default Accordeon;
