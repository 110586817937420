import classNames from "classnames"
import { useState } from "react"

export const Step = ({ currentStatus, label, children, status }) => {
    const statuses = ['location_and_date', "details", "design", "terms", "done"]
    const isDone = statuses.indexOf(currentStatus) > statuses.indexOf(Array.isArray(status) && status?.length ? status[0] : status)
    const isCurrent = Array.isArray(status) ? status.includes(currentStatus) : status === currentStatus
    const [isOpen, setIsOpen] = useState(isCurrent ? true : false)
    return (
        <>
            {
                <>
                    {

                        <div className={classNames('chat-sidebar__accordion', {
                            'block-disabled-20': !isDone && !isCurrent
                        })} onClick={(isDone || isCurrent) ? () => setIsOpen(!isOpen) : () => { }}>
                            <h4>{label}</h4>
                            <span className="ico">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_15_17038)">
                                        <path d="M3.4375 11H18.5625" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        {
                                            !isOpen ?
                                                <path d="M11 3.4375V18.5625" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                : null
                                        }
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15_17038">
                                            <rect width="22" height="22" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>

                    }
                    {
                        (isOpen) ?
                            <div className={`col-group gap-24 ${!isCurrent && isOpen ? "pb-24 border-bottom" : ""}`}>
                                {children}
                            </div>
                            : null
                    }
                </>

            }
        </>
    )
}