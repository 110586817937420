import moment from "moment";
import { belts, teachingStyles, trainingStyle } from "../constants";

export const toggle = (state, value) => {
    return !state ? value : null
}


export const getImageSrc = (() => {
    const cache = new Map();
    return (image, placeholder = require("../assets/img/photo-placeholder.svg").default) => {
        if (!image) return placeholder;

        if (cache.has(image)) {
            return cache.get(image);
        }

        let src;
        if (typeof image === "string" && image.startsWith("data:image")) {
            src = image;
        } else {
            src = image instanceof File ? URL.createObjectURL(image) : process.env.REACT_APP_API_URL + image;
        }

        cache.set(image, src);

        return src;
    };
})();

export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, typeof object[key] === "string" ? object[key] : JSON.stringify(object[key])));
    return formData;
}

// export const checkErrors = (data, { requiredFields = [], exceptions = [], validators = {}, messages = {} } = {}) => {
//     const buildErrorStructure = (nestedData, prefix = '') => {
//         const structure = {};

//         Object.keys(nestedData).forEach(name => {
//             const fullName = prefix ? `${prefix}.${name}` : name;
//             if (exceptions.includes(fullName)) {
//                 structure[name] = false;
//             } else if (validators[fullName]) {
//                 structure[name] = validators[fullName](nestedData[name]) ? false : messages[fullName] || true;
//             } else if (Array.isArray(nestedData[name])) {
//                 const arrayErrors = nestedData[name].map(item => {
//                     if (typeof item === 'object' && item !== null) {
//                         if (item instanceof File) {
//                             return !item;
//                         } else {
//                             return buildErrorStructure(item, fullName);
//                         }
//                     } else {
//                         return !item;
//                     }
//                 });

//                 const allFalse = arrayErrors.every(item => item === false);
//                 structure[name] = allFalse && arrayErrors.length > 0 ? false : arrayErrors;
//             } else if (typeof nestedData[name] === 'object' && nestedData[name] !== null) {
//                 if (nestedData[name] instanceof File) {
//                     structure[name] = !nestedData[name];
//                 } else if (nestedData[name] instanceof Date) {
//                     structure[name] = false
//                 } else {
//                     structure[name] = buildErrorStructure(nestedData[name], fullName);
//                 }
//             } else {
//                 const isRequired = requiredFields.includes(fullName) || !requiredFields?.length;
//                 if (isRequired) {
//                     structure[name] = !nestedData[name];
//                 } else {
//                     structure[name] = false;
//                 }
//             }
//         });
//         return structure;
//     };

//     const errors = buildErrorStructure(data);
//     const errorFields = []
//     const errorRequiredFields = []

//     const checkIfHasErrors = (errorObject, requiredFields = []) => {
//         return (Object.entries(errorObject)).some(([key, value]) => {
//             if (Array.isArray(value)) {
//                 const isError = value.some(item => item === true || (typeof item === 'string' && item !== '') || checkIfHasErrors(item))
//                 if (isError) {
//                     if (requiredFields.length && requiredFields.includes(key)) {
//                         errorRequiredFields.push(key)
//                     } else {
//                         errorFields.push(key)
//                     }
//                 }
//                 return isError
//             } else if (typeof value === 'object' && value !== null) {
//                 return checkIfHasErrors(value);
//             }
//             if (value === true) {
//                 if (requiredFields.length && requiredFields.includes(key)) {
//                     errorRequiredFields.push(key)
//                 } else {
//                     errorFields.push(key)
//                 }
//             }
//             return value === true || (typeof value === 'string' && value !== '');
//         });
//     };

//     const hasErrors = checkIfHasErrors(errors);

//     const hasRequiredError = checkIfHasErrors(errors, requiredFields)

//     return {
//         ...errors,
//         hasErrors,
//         hasRequiredError,
//         errorFields,
//         errorRequiredFields
//     };
// };

export const checkErrors = (data, { requiredFields = [], exceptions = [], validators = {}, messages = {} } = {}) => {
    const buildErrorStructure = (nestedData, prefix = '') => {
        const structure = {};

        Object.keys(nestedData).forEach(name => {
            const fullName = prefix ? `${prefix}.${name}` : name;
            if (exceptions.includes(fullName)) {
                structure[name] = false;
                return;
            }
            if (validators[fullName]) {
                structure[name] = validators[fullName](nestedData[name]) ? false : messages[fullName] || true;
                return;
            }
            if (Array.isArray(nestedData[name])) {
                if (nestedData[name].length === 0) {
                    structure[name] = true;
                    return;
                }
                const arrayErrors = nestedData[name].map((item, index) => {
                    const itemFullName = `${fullName}[${index}]`;
                    if (typeof item === 'object' && item !== null && !(item instanceof File)) {
                        return buildErrorStructure(item, itemFullName);
                    }
                    return !item;
                });
                const allFalse = arrayErrors.every(item => item === false);
                structure[name] = allFalse ? false : arrayErrors;
                return;
            }
            if (typeof nestedData[name] === 'object' && nestedData[name] !== null) {
                if (nestedData[name] instanceof File || nestedData[name] instanceof Date) {
                    structure[name] = false;
                } else {
                    structure[name] = buildErrorStructure(nestedData[name], fullName);
                }
                return;
            }
            const parentIsRequired = requiredFields.some(field => fullName.startsWith(field));
            const isRequired = parentIsRequired || requiredFields.includes(fullName) || !requiredFields.length;
            structure[name] = isRequired ? !nestedData[name] : false;
        });
        return structure;
    };

    const errors = buildErrorStructure(data);
    const errorFields = [];
    const errorRequiredFields = [];

    const collectErrorFields = (errorObject, prefix = '') => {
        Object.entries(errorObject).forEach(([key, value]) => {
            const fullName = prefix ? `${prefix}.${key}` : key;
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    const itemFullName = `${fullName}[${index}]`;
                    if (typeof item === 'object' && item !== null) {
                        collectErrorFields(item, itemFullName);
                    } else if (item === true || (typeof item === 'string' && item !== '')) {
                        if (requiredFields.some(field => itemFullName.startsWith(field))) {
                            errorRequiredFields.push(itemFullName);
                        } else {
                            errorFields.push(itemFullName);
                        }
                    }
                });
            } else if (typeof value === 'object' && value !== null) {
                collectErrorFields(value, fullName);
            } else if (value === true || (typeof value === 'string' && value !== '')) {
                if (requiredFields.some(field => fullName.startsWith(field))) {
                    errorRequiredFields.push(fullName);
                } else {
                    errorFields.push(fullName);
                }
            }
        });
    };

    collectErrorFields(errors);

    const hasErrors = errorFields.length > 0 || errorRequiredFields.length > 0;
    const hasRequiredError = errorRequiredFields.length > 0;

    return {
        ...errors,
        hasErrors,
        hasRequiredError,
        errorFields,
        errorRequiredFields
    };
};

export const convertObjectToFormData = (data, formData = new FormData(), modifiers = {}, acceptNull = true) => {
    const appendFormData = (formData, key, value) => {
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if (typeof item === 'object' && !(item instanceof File)) {
                    appendFormData(formData, `${key}[${index}]`, item);
                } else if (item instanceof File) {
                    formData.append(key, item, item.name);
                } else {
                    formData.append(`${key}[]`, item);
                }
            });
        } else if (typeof value === 'object' && !(value instanceof File) && value !== null) {
            for (let nestedKey in value) {
                appendFormData(formData, `${key}[${nestedKey}]`, value[nestedKey]);
            }
        } else if (value instanceof File) {
            formData.append(key, value, value.name);
        } else {
            formData.append(key, value);
        }
        if (value === "") {
            formData.delete(key);
        }
        if (acceptNull && value === null) {
            formData.delete(key);
        }
    };

    for (let key in data) {
        if (modifiers[key]) {
            appendFormData(formData, key, modifiers[key](data[key]));
        } else {
            appendFormData(formData, key, data[key]);
        }
    }

    return formData;
};

export const getDateRange = (availableDates, isCamp) => {
    if (!availableDates?.length) return;

    const sorted = [...availableDates].sort((a, b) => +new Date(a?.date) - +new Date(b?.date));

    const start = isCamp ? availableDates[0] : sorted[0]?.date;
    const end = isCamp ? availableDates[availableDates.length - 1] : sorted[sorted.length - 1]?.date;

    if (!start || !end) return;

    const startDate = moment(start).format("DD MMM");
    const endDate = moment(end).format("DD MMM");
    const year = moment(start).format("YYYY");

    return `${startDate} - ${endDate}, ${year}`;
};

export const getAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export const getTeachStyle = (teachStyles) => {
    if (!teachStyles?.length) return;

    return teachStyles
        .map(item => teachingStyles.find(({ key }) => (key === item))?.title || item)
}

export const getTrainingStyle = (key) => {
    return trainingStyle.filter(item => item.key === key)
}

export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))


export const convertTimeToDate = (date, time) => {
    if (typeof time !== "string" || !time.length) return null;
    const [hours, minutes] = time.split(":");
    if (!hours || !minutes) return null;
    return moment(date instanceof Date ? date : new Date()).set({ "hours": +hours, "minutes": +minutes }).toDate()
};
export const convertDatesToTimeSlot = (eventsArray = []) => (Array.isArray(eventsArray) ? eventsArray : []).reduce((acc, event) => {
    const existingEvent = acc.find(e => e.date === event.date);
    const timeSlot = {
        startTime: convertTimeToDate(event.date, event.startTime),
        endTime: convertTimeToDate(event.date, event.endTime)
    };
    if (existingEvent) {
        existingEvent.timeSlots.push(timeSlot);
    } else {
        acc.push({ date: event.date, id: uuidv4(), timeSlots: [timeSlot] });
    }
    return acc;
}, []);

export const getFullLocation = (args) => {
    return [...args].filter(Boolean).join(", ") || "Location not specified"
}

const getDanAndColorFromTitle = (title) => {
    const danColorRegex = /(?:(\d+)(?:st|nd|rd|th)\s+Dan,\s+)?(White|Blue|Purple|Brown|Black|Red)\s+Belt/i;
    const match = title.match(danColorRegex);

    if (match) {
        const dan = match[1] ? `${match[1]}th Dan` : null;
        const color = match[2].toLowerCase();
        return { dan, color };
    }
    return { dan: null, color: null };
};
const generateBeltIcon = ({ color, dan }, secondary) => {
    if (secondary) {
        return (
            <svg width="218" height="18" viewBox="0 0 218 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="122" height="18" rx="2" fill={color} />
                {dan > 0 && <line y1="3.94922" x2="122" y2="3.94922" stroke="#424242" strokeWidth="0.5" strokeDasharray="2 2" />}
                {dan > 1 && <line y1="8.94922" x2="122" y2="8.94922" stroke="#424242" strokeWidth="0.5" strokeDasharray="2 2" />}
                {dan > 2 && <line y1="13.9492" x2="122" y2="13.9492" stroke="#424242" strokeWidth="0.5" strokeDasharray="2 2" />}
                <rect width="36" height="18" transform="translate(82)" fill={color === "black" ? "#ED0006" : "#02020A"} />
            </svg>
        )
    }
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5112_75224)">
                <path d="M0.75 3.99741L5.23067 3.99609L5.53527 4.89335L4.80432 5.48235L3.64509 6.41477C3.58116 6.46626 3.20608 6.79495 3.15666 6.80455C3.11009 6.8136 3.04318 6.80382 2.9938 6.80323L2.62441 6.8023L1.53 6.80073L0.75 6.80233V3.99741Z" fill={color} />
                <path d="M16.7496 6.80291L15.0427 6.80365L14.5545 6.80737C14.4909 6.80798 14.4059 6.81867 14.3442 6.80823C14.323 6.80464 14.2937 6.77505 14.2771 6.7617L14.073 6.59391L13.5348 6.1574L11.9629 4.89324L12.2666 3.99609L16.7496 3.99877V6.80291Z" fill={color} />
                <path d="M6.22656 4L11.2695 4.00361L10.3212 6.85091C10.2937 6.92048 10.2733 6.99506 10.2496 7.06617L10.0838 7.55958C10.0636 7.61792 10.0464 7.69968 10.0138 7.75066L7.47389 7.74716L6.22656 4Z" fill={color} />
                <path d="M5.82817 5.8692L5.85078 5.86719C5.88363 5.91384 5.89863 5.99138 5.9174 6.04648L6.0837 6.54624L6.51365 7.83404L6.80431 8.69349L2.63577 12.0162L2.27845 11.585L0.876953 9.83412C0.939979 9.77177 1.01673 9.7188 1.0864 9.66373L1.4047 9.40716L2.70596 8.36552L4.60265 6.84809L5.49554 6.13287L5.82817 5.8692Z" fill={color} />
                <path d="M11.6451 5.85547C11.6988 5.8888 11.7476 5.93623 11.7971 5.97572L12.0935 6.21045L13.0073 6.94063L15.6453 9.05021L16.6262 9.83263L15.2249 11.5436L14.8293 12.0244L10.6973 8.69013L11.6451 5.85547Z" fill={color === "black" ? "#ED0006" : "#02020A"} />
                {dan >= 4 && <line x1="12.9459" y1="6.75687" x2="11.0822" y2="9.10777" stroke="white" strokeWidth="0.5" />}
                {dan >= 3 && <line x1="13.7296" y1="7.37796" x2="11.8659" y2="9.72887" stroke="white" strokeWidth="0.5" />}
                {dan >= 2 && <line x1="14.5133" y1="7.99906" x2="12.6496" y2="10.35" stroke="white" strokeWidth="0.5" />}
                {dan >= 1 && <line x1="15.2967" y1="8.62015" x2="13.4331" y2="10.9711" stroke="white" strokeWidth="0.5" />}
                {dan >= 0 && <line x1="16.0804" y1="9.24124" x2="14.2168" y2="11.5921" stroke="white" strokeWidth="0.5" />}
            </g>
            <defs>
                <clipPath id="clip0_5112_75224">
                    <rect width="16" height="16" fill="white" transform="translate(0.75)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export const getBelt = (value) => {

    if (!value) return
    const belt = belts.find(belt => belt.key === value)
    if (!belt) {
        const { dan, color } = getDanAndColorFromTitle(value);
        return {
            key: value,
            title: value,
            icon: generateBeltIcon({ color, dan }),
            iconSecondary: generateBeltIcon({ color, dan }, true)
        }
    }
    const { dan, color } = getDanAndColorFromTitle(belt.title);
    return {
        ...belt,
        icon: generateBeltIcon({ color, dan }),
        iconSecondary: generateBeltIcon({ color, dan }, true)
    }
}