import React, { Fragment } from 'react'
import { getDateRange, getTeachStyle, getTrainingStyle } from '../../../helpers/utils'

const SeminarOverviewHeader = ({ pageData }) => {
    const trainingStyle = getTrainingStyle(pageData?.traningStyle)
    return (
        <div className="sidebar-headers">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    {pageData?.seminarName}
                </h3>
                <div className="row-group gap--md">
                    <a href="#" className="btn btn--primary btn--md radius">
                        <span>Edit Seminar</span>
                    </a>
                    <button type="button" className="btn btn--square btn--md rounded-full btn-border">
                        <span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4189 9.99497C15.4189 10.2252 15.2323 10.4118 15.002 10.4118C14.7718 10.4118 14.5852 10.2252 14.5852 9.99497C14.5852 9.76475 14.7718 9.57812 15.002 9.57812C15.2323 9.57812 15.4189 9.76475 15.4189 9.99497" stroke="#323232" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.4169 9.99497C10.4169 10.2252 10.2303 10.4118 10.0001 10.4118C9.76988 10.4118 9.58325 10.2252 9.58325 9.99497C9.58325 9.76475 9.76988 9.57812 10.0001 9.57812C10.2303 9.57812 10.4169 9.76475 10.4169 9.99497" stroke="#02020A" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.41473 9.99497C5.41473 10.2252 5.22811 10.4118 4.99789 10.4118C4.76768 10.4118 4.58105 10.2252 4.58105 9.99497C4.58105 9.76475 4.76768 9.57812 4.99789 9.57812C5.22811 9.57812 5.41473 9.76475 5.41473 9.99497" stroke="#02020A" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div className="sidebar-header heading-row">
                <div className="options">
                    {pageData?.status === 'pending' ?
                        <>
                        {(pageData?.priceInstructorMin || pageData?.seminarMinPrice) && 
                            <div className="option">
                                <div className="price-group">
                                    <span className="color-grey mr-8 text-sm">from </span>
                                    <span className="price-group__price">
                                        {pageData?.user?.role === "academy" ? 
                                            pageData?.priceInstructorMin : 
                                            pageData?.seminarMinPrice
                                        }
                                    </span>
                                </div>
                            </div>
                        }
                        <div className="option">
                            <p className="text-sm font-500">Planned Seminar</p>
                        </div>
                        </>
                        :
                        <div className="option">
                            <div className="price-group">
                                <span className="price-group__price">$1,500 </span>
                                <span className="color-grey mr-8 text-sm">/ ticket</span>
                                <span className="price-group__amount">10 left</span>
                            </div>
                        </div>
                    }

                    <div className="option">
                        <span className="card-seminar__gi">
                            <span className="ico">
                                {trainingStyle[0]?.icon}
                            </span>
                            <span>{trainingStyle[0]?.title}</span>
                        </span>
                        <ul className="list-point-row">
                            {pageData?.seminarDates && 
                                <li><p className="text-sm">{getDateRange(pageData?.seminarDates)}</p></li>
                            }
                            {pageData?.teachingStyles && 
                            <li>
                                <p className="text-sm">{[getTeachStyle(pageData?.teachingStyles)].join(', ')}</p>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeminarOverviewHeader
