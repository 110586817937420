import { addBusinessDays } from 'date-fns'
import { defaultsDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import api from '../../api'
import Button from '../../components/ui/buttons/Button'
import Loader from '../../components/ui/Loader'
import { getImageSrc } from '../../helpers/utils'
import { getUser } from '../../redux/slices/applicationSlice'
import { acceptInstructorCampRequestFetch, rejectCampRequestFetch } from '../../redux/thunks/planCampThuncks'
import { acceptInstructorRequestFetch, cancelSeminarRequestFetch } from '../../redux/thunks/seminarsThunks'


const RequestItem = ({ data, owner, getRequests }) => {
    const dispatch = useDispatch();
    const me = useSelector(getUser)
    const isSeminar = data.object_type === "seminar"
    const isCamp = data.object_type === "plancamp"

    const handleApprove = async () => {
        const requestInstructorId = data.id
        if (!requestInstructorId) return

        if (isSeminar) {
            await dispatch(acceptInstructorRequestFetch({ requestInstructorId })).unwrap()
            getRequests()
        }
        if (isCamp) {
            await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestInstructorId })).unwrap()
            getRequests()
        }
    }

    const handleCancelRequest = async () => {
        const requestId = data.id
        if (!requestId) return

        if (isSeminar) {
            await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap()
            getRequests()
        }
        if (isCamp) {
            await dispatch(rejectCampRequestFetch({ requestId })).unwrap()
            getRequests()
        }
    }

    const user = data.owner

    const isMy = data?.owner.id === me?.id

    const event = data.seminar || data.plancamp

    const hrefPrefix = event.userAcademy ? "planed-by-academy" : "planed-by-insctructor"



    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img src={getImageSrc(user?.photo)} alt={user?.fullName || user?.name} />
                </div>
                <div className="col-group gap-4" style={{ width: 300 }}>
                    <h6 className="heading truncate flex-auto">{event?.name || event?.seminarName}</h6>
                    <p className="color-text truncate flex-auto">{event?.summary || event?.summary}</p>
                </div>
            </div>
            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">Seminar Price</span>
                    <p className="fw-500 text--sm">${event?.seminarMinPrice}</p>
                </div>
                <div className="col-group gap--xs">
                    <span className="label">Ticket Price</span>
                    <p className="fw-500 text--sm">{event?.ticketPrice}</p>
                </div>
                <div className="col-group gap--xs">
                    <span className="label">teaching</span>
                    <p className="fw-500 text--sm">{event?.traningStyle}</p>
                </div>
                <div className="col-group gap--xs">
                    <span className="label">Date</span>
                    <p className="fw-500 text--sm">{new Date(event.startAt || event.seminarDates?.[0]?.date).toLocaleDateString()}</p>
                </div>
            </div>
            <div className="request-item__action">
                {/* <Button className="btn btn--sm btn--square notification" count={data.count}> */}
                {/* <Link to={`/chat/${data.chat?.id}`} className="btn btn--sm btn--square">
                    <span className="ico">
                        <ChatIcon />
                    </span>
                </Link> */}
                <Link to={`/${hrefPrefix}/${event?.id}`} className="btn btn--sm">Show Details</Link>
                {!isMy && (
                    <Button onClick={handleApprove} className="btn btn--sm btn--primary">Approve</Button>
                )}
                <Button onClick={handleCancelRequest} className="btn btn--sm btn--default">Decline</Button>
            </div>
        </div>
    )
}

export const InstructorsAndAcademiesRequests = ({ userRole }) => {

    const [searchParams, setSearchParams] = useSearchParams()

    const [activeTab, setActiveTab] = useState('seminars');
    const [requests, setRequests] = useState({
        seminars: [],
        camps: [],
        my: []
    })
    const [isLoading, setIsLoading] = useState(false)

    const getRequests = async () => {
        setIsLoading(true)
        const camps = await api.requests.getRequests("event=plancamp&no_limit=true");
        const seminars = await api.requests.getRequests("event=seminar&no_limit=true");
        const my = await api.requests.getRequests("is_my_own=true&no_limit=true")

        setRequests({
            seminars: seminars?.data || [],
            camps: camps?.data || [],
            my: my?.data || [],
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getRequests()
    }, [])

    const handleChangeTab = (tab) => {
        setActiveTab(tab)
    }

    return (
        <div className="tab-seminars tabs">
            <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    <button
                        className={`tab-link ${activeTab === 'seminars' ? 'active-tab' : ''}`}
                        onClick={() => { handleChangeTab('seminars') }}>
                        <span className="tab-link__text">
                            Seminar Requests
                        </span>
                        <span className="tab-link__number">{requests?.seminars?.length}</span>
                    </button>
                    <button
                        className={`tab-link ${activeTab === 'camps' ? 'active-tab' : ''}`}
                        onClick={() => { handleChangeTab('camps') }}>
                        <span className="tab-link__text">Camp Requests</span>
                        <span className="tab-link__number">{requests?.camps?.length}</span>
                    </button>
                    <button
                        className={`tab-link ${activeTab === 'my' ? 'active-tab' : ''}`}
                        onClick={() => { handleChangeTab('my') }}>
                        <span className="tab-link__text">My Own Requests</span>
                        <span className="tab-link__number">{requests?.my?.length}</span>
                    </button>
                </div>
            </div>
            <div className="">
                {activeTab === 'seminars' && <div className="tab active-tab">
                    {
                        requests.seminars?.length && !isLoading ? (
                            <div className="request-items">
                                {requests.seminars.map(item => (
                                    <RequestItem key={item.id} data={item} getRequests={getRequests} />
                                ))}
                            </div>
                        )
                            : isLoading ?
                                <Loader />
                                : <h2>There is no requiests yet</h2>
                    }
                </div>}
                {activeTab === 'camps' && <div className="tab active-tab">
                    {
                        requests.camps?.length && !isLoading ? (
                            <div className="request-items">
                                {requests.camps.map(item => (
                                    <RequestItem key={item.id} data={item} getRequests={getRequests} />
                                ))}
                            </div>
                        )
                            : isLoading ?
                                <Loader />
                                : <h2>There is no requiests yet</h2>
                    }
                </div>}
                {activeTab === 'my' && <div className="tab active-tab">
                    {
                        requests.my?.length && !isLoading ? (
                            <div className="request-items">
                                {requests.my.map(item => (
                                    <RequestItem owner={true} key={item.id} data={item} getRequests={getRequests} />
                                ))}
                            </div>
                        )
                            : isLoading ?
                                <Loader />
                                : <h2>There is no requiests yet</h2>
                    }
                </div>}
            </div>
        </div>
    )
}
