import { useEffect, useState } from "react";
import DatePicker from "../../../components/ui/formElements/DatePicker"
import moment from "moment";
import { eachDayOfInterval, format } from "date-fns";
import classNames from "classnames";

const DateRangeSelect = ({handleChange, disabled, isErrorsShown, errors}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null); 
    useEffect(() => {
        if(!(startDate && endDate)) return
        handleChange(eachDayOfInterval({
            start: startDate,
            end: endDate
        }).map(date => ({
            date:format(date, 'yyyy-MM-dd'),
            startTime: null,
            endTime: null
        })))
    }, [startDate, endDate])
    return <div className={classNames("schedule-group", {
        'block-disabled': disabled
    })} >
        <div className="input-date-time-group">
            <div className="flex-1">
                <DatePicker
                    label={'Start Date'}
                    placeholderText="Select Start Date"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    // excludeDates={excludeDates}
                    error={!disabled && isErrorsShown && errors?.availableDates && !startDate}
                    minDate={moment().toDate()}
                />
            </div>
            <span className="input-delimiter" style={{marginTop: 26}}></span>
            <div className="flex-1">
                <DatePicker
                    label={'End Date'}
                    placeholderText="Select End Date"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    // excludeDates={excludeDates}
                    error={!disabled && isErrorsShown && errors?.availableDates && !endDate}
                    minDate={startDate}
                />
            </div>
        </div>
    </div>
}

export default DateRangeSelect