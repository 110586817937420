import React from 'react'

const InputRadio = ({checked,children, value, id, name, onChange}) => {
  return (
    <label className="check-btn">
        <input type="radio" name={name} hidden={true} value={value} checked={checked} id={id} onChange={onChange}/>
        {children}
    </label>
  )
}

export default InputRadio
