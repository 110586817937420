import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import api from '../../../api';
import { ReactComponent as InfoIcon } from "../../../assets/img/question-circle.svg";
import Textarea from '../../../components/ui/formElements/Textarea';
import PageLoader from '../../../components/ui/PageLoader';
import { getUser } from '../../../redux/slices/applicationSlice';

const BookingTerms = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { seminarId, chatId, requestId } = useParams()
    const user = useSelector(getUser)
    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const { camps, campDetails, isLoading } = useSelector(state => state.planCamps)


    const handleStepChange = (name, value) => {
        handleChange(name, value)
    }

    const handleSubmit = async () => {
        if (!errors.hasRequiredError) {
            const res = await api.requests.createUpdateTerms({ requestId, body: { terms: formData.bookingTerms } })
            if (res?.success) {
                navigate(`/chat/${chatId}`)
            }
        }
    }


    useEffect(() => {
        setErrorConfig({
            exceptions: [],
            requiredFields: ["bookingTerms"]
        })

    }, [formData])



    return (
        <>
            {
                !isLoading ?
                    <>
                        <h4 className='row-group mb-36'>
                            Set your location Availability
                            <span className="popover ico ms-2" data-tippy-content="Content">
                                <InfoIcon />
                            </span>
                        </h4>
                        <div className="row mb-36">
                            <div className="col-12">
                                <Textarea
                                    label={"Booking Specific Terms"}
                                    wrapperClasses={"input--lg"}
                                    textareaClasses={"input--solid"}
                                    value={formData.bookingTerms}
                                    error={isErrorsShown && errors?.bookingTerms}
                                    onChange={(e) => handleStepChange("bookingTerms", e.target.value)}
                                    placeholder={"Type or paste booking specific terms here..."}
                                    style={{
                                        resize: "vertical",
                                        minHeight: 250
                                    }}
                                    maxChars={1000}
                                />
                            </div>
                        </div>

                        <div className="page-action-group">
                            <Link to={seminarId ? `/chat/${chatId}` : "../seminar-info"} className="btn btn--default btn--sm">Cancel</Link>
                            <button onClick={handleSubmit} className="btn btn--primary btn--sm">
                                {
                                    seminarId ?
                                        <span className="info">Apply Changes</span>
                                        :
                                        <>
                                            <span className="info">Continue</span>
                                            <span className="ico">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                                </svg>
                                            </span>
                                        </>

                                }
                            </button>
                        </div>
                    </>
                    :
                    <PageLoader />
            }
        </>
    )
}

export default BookingTerms