import React from 'react'
import { getTrainingStyle } from '../../../helpers/utils'

const SinglePageTitleBlock = ({ title, traningStyle, allowUnder18, location, status }) => {
    const trainingStyle = getTrainingStyle(traningStyle)
    console.log(status)
    return (
        <>
            {
                status !== 'pending' &&
                <div className="card-seminar__group-city">
                    <div className="flex">
                        <img src={require("../../../assets/img/icon-decor-city.svg").default} />
                    </div>
                    <span>{location || "Location not specified"}</span>
                </div>
            }

            <div className="flex items-center justify-between flex-wrap gap-12">
                <h3 className="heading mb-36">{title}</h3>

                <div className="flex items-center gap-14 mb-36">
                    <span className="color-grey fs-16">Style:</span>
                    <span className="card-seminar__gi h-36 radius-10">
                        {trainingStyle[0]?.icon}
                        {trainingStyle[0]?.title}
                    </span>
                    {!allowUnder18 && <div className='btn bg-dark color-white fw-600 btn--sm radius-10'>+18</div>}
                </div>
            </div>
        </>
    )
}

export default SinglePageTitleBlock
