import React, { useEffect, useState } from 'react';
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch';
import { Link, useParams } from 'react-router-dom';
import CreateSignatureModal from '../../../components/modals/CreateSignatureModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/applicationSlice';
import { getSeminarContractFetch } from '../../../redux/thunks/seminarsThunks';
import { getImageSrc } from '../../../helpers/utils';

const staticData = {
    instructorName: "[Instructor's Name]",
    instructorAddress: "[Instructor's Address]",
    instructorCityStateZip: "[City, State, Zip Code]",
    companyName: "[Your Company Name or Individual Name]",
    companyAddress: "[Address]",
    companyCityStateZip: "[City, State, Zip Code]",
    emailAddress: "[Email Address]",
    phoneNumber: "[Phone Number]",
    date: "[Date]",
    seminarName: "[Name of the BJJ Seminar]",
    seminarDateTime: "[Date and Time]",
    seminarLocation: "[Venue Name and Address]",
    agreedAmount: "[Agreed Amount]",
    paymentTerms: "[Payment Terms, e.g., half upfront and half upon completion]",
    travelDetails: "[Specify Details, e.g., airfare, accommodation, meals]",
    numberOfDays: "[Number of Days]",
    state: "[State]",
};

const Contract = ({ data = staticData, onSign, onAgree }) => {
    const { seminarId } = useParams()
    const [agree, setAgree] = useState(false)
    const user = useSelector(getUser)
    const role = user?.role
    const contract = useSelector(state => state.seminars.contract)

    const [signature, setSignature] = useState({
        academy: "",
        instructor: ""
    });

    const [createSignatureModalOpen, setCreateSignatureModalOpen] = useState(false);


    const handleSignatureChange = (role, signature) => {
        setSignature(prev => ({
            ...prev,
            [role]: signature
        }));
        onSign && onSign(signature)
    };

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSeminarContractFetch({ seminarId }))
    }, [])

    useEffect(() => {
        if (!contract) return
        setSignature({
            academy: contract?.sign_academy,
            instructor: contract?.sign_instructor
        })
    }, [contract])
    return (
        <>
            <div className="card-shadow document-card mb-42">
                <div className="document-card__header">
                    <h3 className="heading text-center mb-32">BJJ Seminar Contract</h3>
                    <div className="document-global-info">
                        <div className="document-global-info__item">
                            <p>{data.instructorName}</p>
                            <p>{data.instructorAddress}</p>
                            <p>{data.instructorCityStateZip}</p>
                        </div>
                        <div className="document-global-info__item">
                            <p>{data.companyName}</p>
                            <p>{data.companyAddress}</p>
                            <p>{data.companyCityStateZip}</p>
                            <p>{data.emailAddress}</p>
                            <p>{data.phoneNumber}</p>
                            <p>{data.date}</p>
                        </div>
                    </div>
                </div>
                <div className="document-card__body">
                    <p>Dear {data.instructorName},</p><br />
                    <p>This Agreement ("Agreement") is entered into on {data.date}, between {data.companyName}, hereinafter referred to as the "Host," and {data.instructorName}, hereinafter referred to as the "Instructor."</p><br />
                    <p>Seminar Details: a. Title: {data.seminarName} b. Date and Time: {data.seminarDateTime} c. Location: {data.seminarLocation}</p><br />
                    <p>Compensation:</p><br />
                    <ul>
                        <li>
                            The Instructor agrees to provide a Brazilian Jiu-Jitsu (BJJ) seminar at the agreed-upon date, time, and location.
                        </li>
                        <li>
                            The Host agrees to compensate the Instructor the sum of {data.agreedAmount} for the seminar.
                        </li>
                        <li>
                            Payment will be made on {data.paymentTerms}.
                        </li>
                    </ul>
                    <br />
                    <p>Travel and Accommodation:</p>
                    <br />
                    <ul>
                        <li>
                            The Host agrees to cover reasonable travel expenses for the Instructor, including {data.travelDetails}.
                        </li>
                        <li>
                            Any additional expenses must be pre-approved by the Host.
                        </li>
                    </ul>
                    <br />
                    <p>Marketing and Promotion:</p>
                    <br />
                    <ul>
                        <li>
                            The Host will be responsible for marketing and promoting the seminar.
                        </li>
                        <li>
                            The Instructor agrees to provide promotional materials such as photos, bios, and any other necessary content.
                        </li>
                    </ul>
                    <br />
                    <p>Cancellation:</p>
                    <br />
                    <ul>
                        <li>
                            In the event of a cancellation by either party, a notice must be provided at least {data.numberOfDays} days before the scheduled seminar date.
                        </li>
                        <li>
                            If the Instructor cancels, any advanced payment made by the Host will be refunded promptly.
                        </li>
                        <li>
                            If the Host cancels, the Instructor will receive compensation for any non-refundable expenses incurred.
                        </li>
                    </ul>
                    <br />
                    <p>Liability:</p>
                    <br />
                    <ul>
                        <li>
                            The Instructor is responsible for their actions during the seminar.
                        </li>
                        <li>
                            The Host is not liable for any injuries or damages incurred by participants during the seminar.
                        </li>
                    </ul>
                    <br />
                    <p>Intellectual Property:</p>
                    <br />
                    <ul>
                        <li>
                            Any materials, techniques, or intellectual property shared by the Instructor during the seminar remain the property of the Instructor.
                        </li>
                    </ul>
                    <br />
                    <p>Confidentiality:</p>
                    <br />
                    <ul>
                        <li>Both parties agree to keep any confidential information shared during the seminar confidential.</li>
                    </ul>
                    <br />
                    <p>Governing Law:</p>
                    <br />
                    <ul>
                        <li>This Agreement shall be governed by and construed in accordance with the laws of {data.state}.</li>
                    </ul>
                    <br />
                    <p>IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date first above written.</p>
                    <br />
                    <p>{data.companyName} {data.instructorName}</p>
                </div>
                <div className="document-card__footer">
                    <div className="form-group input--sm">
                        <label className="label">Instructor Signature</label>
                        <label
                            className={`upload-sing ${role === "instructor" ? "--active" : ""}`}
                            onClick={
                                role === "instructor" ?
                                    () => setCreateSignatureModalOpen(true)
                                    : null
                            }
                        >
                            <span className="upload-sing__preview">
                                {
                                    signature.instructor
                                    &&
                                    // signature.instructor
                                    // instanceof File &&
                                    // <img src={URL.createObjectURL(signature.instructor)}
                                    //     alt="Instructor Signature" />
                                    <img src={getImageSrc(signature?.instructor, null)}
                                        alt="Instructor Signature" />
                                }
                            </span>
                        </label>
                    </div>
                    <div className="form-group input--sm">
                        <label className="label">Academy Signature</label>
                        <label
                            className={`upload-sing ${role === "academy" ? "--active" : ""}`}
                            onClick={
                                role === "academy" ?
                                    () => setCreateSignatureModalOpen(true)
                                    : null
                            }
                        >
                            <span className="upload-sing__preview">
                                {
                                    signature.academy
                                    &&
                                    <img src={getImageSrc(signature?.academy, null)}
                                        alt="Academy Signature" />
                                }
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='col-group gap--md mb-42'>
                <CheckboxSwitch
                    onChange={() => {
                        onAgree(!agree)
                        setAgree(!agree)
                    }}
                    checked={agree}
                    text={
                        <>
                            I have read and agreed to the
                            {" "}
                            <Link className="color-secondary" to={"/terms"}>Terms and Conditions</Link>
                            {" "}
                            and
                            {" "}
                            <Link className="color-secondary" to={"/privacy"}>Privacy Policy</Link>
                        </>
                    }
                />
            </div>
            {
                createSignatureModalOpen ?
                    <CreateSignatureModal
                        open={createSignatureModalOpen}
                        handleClose={() => { setCreateSignatureModalOpen(false); }}
                        setSignature={(base64Image) => {
                            handleSignatureChange(role, base64Image)
                        }}
                    />
                    : null
            }
        </>
    );
}

export default Contract;
