import { createSlice } from '@reduxjs/toolkit';
import {
    getAcademySeminarsAllFetch,
    getAcademySeminarsByIdFetch,
    createAcademySeminarFetch,
    updateAcademySeminarFetch,
    deleteAcademySeminarFetch,
    uploadAcademySeminarPhotosFetch,
    deleteAcademySeminarPhotoByIdFetch,
    addAcademySeminarDateFetch,
    updateAcademySeminarDateFetch,
    deleteAcademySeminarDateFetch,
} from '../thunks/academySeminarsThunk';

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

const academySeminarsSlice = createSlice({
    name: 'academySeminars',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAcademySeminarsAllFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAcademySeminarsAllFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getAcademySeminarsAllFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getAcademySeminarsByIdFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAcademySeminarsByIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getAcademySeminarsByIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createAcademySeminarFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createAcademySeminarFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(createAcademySeminarFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(updateAcademySeminarFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateAcademySeminarFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(updateAcademySeminarFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteAcademySeminarFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteAcademySeminarFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(deleteAcademySeminarFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(uploadAcademySeminarPhotosFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(uploadAcademySeminarPhotosFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(uploadAcademySeminarPhotosFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteAcademySeminarPhotoByIdFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteAcademySeminarPhotoByIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(deleteAcademySeminarPhotoByIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addAcademySeminarDateFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addAcademySeminarDateFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(addAcademySeminarDateFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(updateAcademySeminarDateFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateAcademySeminarDateFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(updateAcademySeminarDateFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteAcademySeminarDateFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteAcademySeminarDateFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(deleteAcademySeminarDateFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const {} = academySeminarsSlice.actions;
export default academySeminarsSlice.reducer;
