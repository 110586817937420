import { useEffect, useRef, useState } from 'react'
import SelectOne from '../../components/ui/formElements/SelectOne'
import HeaderTemplateEditor from './parts/HeaderTemplateEditor'

import { ReactComponent as CalcMinusIcon } from "../../assets/img/ico-calc-minus.svg"
import { ReactComponent as CalcPlusIcon } from "../../assets/img/ico-calc-plus.svg"
import { ReactComponent as CaretLeftIcon } from "../../assets/img/ico-carret-left.svg"
import { ReactComponent as CheckIcon } from "../../assets/img/ico-check-editor.svg"
import { ReactComponent as RedoIcon } from "../../assets/img/ico-editor-next.svg"
import { ReactComponent as UndoIcon } from "../../assets/img/ico-editor-prev.svg"
import { ReactComponent as QuestionIcon } from "../../assets/img/ico-question.svg"

import { getFontEmbedCSS, toPng } from 'html-to-image'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { ReactComponent as AlignCenterIcon } from "../../assets/img/ico-align-center.svg"
import { ReactComponent as AlignLeftIcon } from "../../assets/img/ico-align-left.svg"
import { ReactComponent as AlignRightIcon } from "../../assets/img/ico-align-right.svg"
import { ReactComponent as BoldIcon } from "../../assets/img/ico-text-bold.svg"
import { ReactComponent as ItalicIcon } from "../../assets/img/ico-text-italic.svg"
import { ReactComponent as UnderlineIcon } from "../../assets/img/ico-text-underline.svg"
import { toggle } from '../../helpers/utils'
import { getCurrentStyles, redo, setActiveIndex, setCurrentStyles, setCurrentTemplateImage, undo } from '../../redux/slices/editorSlice'
import { setSeminarFormData } from '../../redux/slices/planningSlice'
import BackgroundEditor from './parts/BackgroundEditor'
import BoxShadowEditor from './parts/BoxShadowEditor'
import OutlineEditor from './parts/OutlineEditor'
import RangeSlider from './parts/RangeSlider'
import Template from './templates/Template'
import { templates } from './templates/variants'

const generateFontSizes = (start, end, step) => {
    const sizes = [];
    for (let size = start; size <= end; size += step) {
        sizes.push({ label: size, value: size });
    }
    return sizes;
};

const TemplateEditor = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const { activeIndex, currentTemplate, history, historyIndex } = useSelector(state => state.editor)
    const [isApplying, setIsApplying] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const [selectedTemplate, setSelectedTemplate] = useState(currentTemplate)
    const [activeSidebar, setActiveSidebar] = useState("templates"); // edit-text
    const currentStyles = useSelector(getCurrentStyles)

    const [zoom, setZoom] = useState(100);
    const [fonts, setFonts] = useState([]);

    const [zoomCoef, setZoomCoef] = useState(0)

    const tools = [
        { value: "700", name: "fontWeight", icon: <BoldIcon /> },
        { value: "italic", name: "fontStyle", icon: <ItalicIcon /> },
        { value: "underline", name: "textDecoration", icon: <UnderlineIcon /> },
        { value: "left", name: "textAlign", icon: <AlignLeftIcon /> },
        { value: "center", name: "textAlign", icon: <AlignCenterIcon /> },
        { value: "right", name: "textAlign", icon: <AlignRightIcon /> },
    ];
    const ranges = [
        { label: "Letter Spacing", name: "letterSpacing", step: 0.1, min: -5, max: 5, postfix: "px", defaultValue: 0 },
        { label: "Line Height", name: "lineHeight", step: 1, min: 0, max: 200, postfix: "px", defaultValue: 34 },
        { label: "Opacity", name: "opacity", step: 0.1, min: 0, max: 1, defaultValue: 1 },
    ];
    const fontSizes = generateFontSizes(1, 400, 1);

    const handleChange = (name, value) => {
        dispatch(setCurrentStyles({ name, value }))
    };

    const handleUndo = () => {
        dispatch(undo())
    };

    const handleRedo = () => {
        dispatch(redo())
    };

    const handleBlur = (e) => {
        // e.stopPropagation();
        // dispatch(setActiveIndex(null))
        // setActiveSidebar("templates")
        // document.activeElement.blur()
    }

    const canUndo = history.length > 1 && historyIndex > 0
    const canRedo = history.length > 1 && historyIndex < history.length - 1

    const elementRef = useRef()
    const seminarFormData = useSelector(state => state.planning.seminarFormData)
    const navigate = useNavigate()

    const htmlToImageConvert = async () => {
        setIsApplying(true);
        dispatch(setActiveIndex(null));
        setActiveSidebar("templates");
        document.activeElement.blur();
        const fontEmbedCSS = await getFontEmbedCSS(elementRef.current);

        toPng(elementRef.current, { fontEmbedCSS, pixelRatio: 3, quality: 1 })
            .then(async (dataUrl) => {
                const response = await fetch(dataUrl);
                const blob = await response.blob();

                const file = new File([blob], 'image.png', { type: 'image/png' });

                dispatch(setSeminarFormData({
                    name: "photos",
                    value: [...(seminarFormData?.photos?.length ? seminarFormData.photos : []), file]
                }));
                navigate(location.state?.previousLocation || -1, {
                    state: {
                        previousLocation: location.pathname
                    },
                });
            })
            .catch((err) => {
                console.log("Error converting HTML to PNG:", err);
            })
            .finally(() => {
                setIsApplying(false);
            });
    };

    const handleBack = () => {
        navigate(location.state?.previousLocation || -1, {
            state: {
                previousLocation: location.pathname
            },
        });
    }


    useEffect(() => {
        const getFonts = async () => {
            const fontList = [];
            if (document.fonts && document.fonts.status === 'loaded') {
                const fontFaces = document.fonts;
                for (const fontFace of fontFaces) {
                    fontList.push(fontFace.family);
                }
            }
            const fontsOptions = [...new Set(fontList)].map(name => ({ label: name, value: `'${name}', sans-serif` }));
            setFonts(fontsOptions);
        };
        getFonts();
    }, [document.fonts.status]);


    useEffect(() => {
        if (isSidebarOpen) {
            document.querySelector("body").classList.add("sidebar--close")
        } else {
            document.querySelector("body").classList.remove("sidebar--close")
        }
        return () => {
            document.querySelector("body").classList.remove("sidebar--close")
        }
    }, [isSidebarOpen])

    const resize = () => {
        if (window.innerWidth < 700) {
            const k = (700 - window.innerWidth) / 1000
            setZoomCoef(k)
        }
    }
    useEffect(() => {
        window.addEventListener("resize", resize)
        return () => window.removeEventListener("resize", resize)
    }, [])


    const handleChangeImage = (e) => {
        const image = e.target.files[0]
        if (image) {
            dispatch(setCurrentTemplateImage(URL.createObjectURL(image)))
        }
    }

    return (
        <>
            {
                <>
                    <HeaderTemplateEditor handleBack={handleBack} onSubmit={htmlToImageConvert} isApplying={isApplying} />
                    <div className="wrapper header-fixed">
                        <main className="content editor-page">
                            <div className="overlay sidebar-btn-js"></div>
                            <aside className={`editor-sidebar ${activeSidebar === "templates" ? "--active" : ""}`}>
                                <div className="editor-sidebar--container">
                                    <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} type="button" className="btn btn--square btn-sidebar-toggler sidebar-btn-js">
                                        <span className="ico ico-sidebar-toggler">
                                            <CaretLeftIcon />
                                        </span>
                                    </button>
                                    <div className="editor-sidebar--header --has-border">
                                        <div className="es--h-item">
                                            <p className="editor-sidebar--title">
                                                Cover Templates
                                            </p>
                                        </div>
                                        <div className="es--h-item">
                                            <button
                                                type="button"
                                                className="btn"
                                                data-tooltip-id="cover-templates"
                                            >
                                                <span className="ico color-secondary" style={{ "--ico-size": "18px" }}>
                                                    <QuestionIcon />
                                                </span>
                                            </button>
                                            <Tooltip
                                                id="cover-templates"
                                                content={`
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                                            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                            sunt in culpa qui officia deserunt mollit anim id est laborum
                                    `}
                                                style={{ maxWidth: 400, zIndex: 999 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="editor-sidebar--body --has-border">
                                        <div className="es--body-item">
                                            <label className='mb-14'>
                                                <input onChange={handleChangeImage} accept="image/*" type="file" className='hidden' hidden />
                                                <span className='btn btn btn--primary btn--md w-full'>
                                                    <span className="ico">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.9643 9.25885L9.99972 12.2234L7.03516 9.25885" stroke="#02020A" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M9.99985 3.33057V12.2234" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16.6695 13.7048C16.6695 15.3421 15.3422 16.6693 13.7049 16.6693H6.29513C4.65785 16.6693 3.33057 15.3421 3.33057 13.7048" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                    <span className="info">
                                                        Replace Photo
                                                    </span>
                                                </span>
                                            </label>
                                            <div className="row" style={{ gap: "8px 0" }}>
                                                {
                                                    templates.map((template, index) => (
                                                        <div
                                                            key={index}
                                                            className="col-12 flex justify-center"
                                                            style={{
                                                                aspectRatio: "1.32244897959",
                                                                overflow: "hidden"
                                                            }}
                                                        >
                                                            <label
                                                                className="editor-checkbox"
                                                                style={{
                                                                    transform: `scale(${0.34})`
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    hidden name="template-select"
                                                                    value={template.templateId}
                                                                    checked={selectedTemplate.templateId === template.templateId}
                                                                    onChange={(e) => setSelectedTemplate(template)}
                                                                />
                                                                <span className="ico ico-checked">
                                                                    <CheckIcon />
                                                                </span>
                                                                <div className="editor-checkbox--overlay"></div>
                                                                <Template template={template} />
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>

                            <aside className={`editor-sidebar ${activeSidebar === "edit-text" ? "--active" : ""}`}>
                                <div className="editor-sidebar--container">
                                    <button type="button" onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="btn btn--square btn-sidebar-toggler sidebar-btn-js">
                                        <span className="ico ico-sidebar-toggler">
                                            <CaretLeftIcon />
                                        </span>
                                    </button>
                                    <div className="editor-sidebar--header --has-border">
                                        <div className="es--h-item">
                                            <button type="button" onClick={() => setActiveSidebar("templates")} className="btn" >
                                                <span className="ico" style={{ "--ico-size": "18px" }}>
                                                    <CaretLeftIcon />
                                                </span>
                                            </button>
                                            <p className="editor-sidebar--title">Edit Text</p>
                                        </div>
                                    </div>
                                    <div className="editor-sidebar--body --has-border">
                                        <div className="es--body-item">
                                            <label className='mb-14'>
                                                <input onChange={handleChangeImage} accept="image/*" type="file" className='hidden' hidden />
                                                <span className='btn btn btn--primary btn--md w-full'>
                                                    <span className="ico">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.9643 9.25885L9.99972 12.2234L7.03516 9.25885" stroke="#02020A" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M9.99985 3.33057V12.2234" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16.6695 13.7048C16.6695 15.3421 15.3422 16.6693 13.7049 16.6693H6.29513C4.65785 16.6693 3.33057 15.3421 3.33057 13.7048" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                    <span className="info">
                                                        Replace Photo
                                                    </span>
                                                </span>
                                            </label>
                                            <div className="row gutters-form-7">
                                                <div className="col-12">
                                                    <SelectOne
                                                        label={"Font"}
                                                        wrapperClasses={"select--outline select--outline-bg select--outline-2 input--sm flex-auto"}
                                                        options={fonts}
                                                        value={currentStyles?.fontFamily}
                                                        onChange={(option) => handleChange("fontFamily", option.value)}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <div className="flex gap-12">
                                                        <SelectOne
                                                            wrapperClasses={"select--outline select--outline-bg select--outline-2 input--sm flex-auto"}
                                                            value={parseInt(currentStyles?.fontSize)}
                                                            options={fontSizes}
                                                            onChange={(option) => handleChange("fontSize", option.value)}
                                                        />
                                                        <label className="picker-custom btn btn--square btn--sm">
                                                            <input type="color" value={currentStyles?.color || "#ffffff"} onChange={({ target }) => handleChange("color", target.value)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="es--body-item px-0 pt-0">
                                            <div className="group-btn btn btn--md bg-light">
                                                {
                                                    tools.map(({ icon, name, value }) => (
                                                        <button
                                                            key={value}
                                                            onClick={(e) => handleChange(name, toggle(currentStyles?.[name], value))}
                                                            type="button"
                                                            className={`btn btn--square btn-tool ${currentStyles?.[name] === value ? "active" : ""}`}
                                                        >
                                                            <span className="ico">
                                                                {icon}
                                                            </span>
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="es--body-item">
                                            <div className="row gutters-form-7">
                                                {
                                                    ranges.map((range) => (
                                                        <div className="col-12" key={range.name}>
                                                            <RangeSlider
                                                                value={currentStyles?.[range.name]}
                                                                onChange={(value) => handleChange(range.name, value)}
                                                                {...range}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        <div className="es--body-item">
                                            <div className="row gutters-form-7">
                                                <div className="col-12">
                                                    <BackgroundEditor currentStyles={currentStyles} handleChange={handleChange} />
                                                </div>
                                                <div className="col-12">
                                                    <OutlineEditor currentStyles={currentStyles} handleChange={handleChange} />
                                                </div>
                                                <div className="col-12">
                                                    <BoxShadowEditor currentStyles={currentStyles} handleChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>

                            <section className="workscape-editor" onClick={handleBlur}>
                                <div className="workspace-body">
                                    <div onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveSidebar("edit-text")
                                    }}>
                                        <div ref={elementRef} style={{
                                            transform: `scale(${zoom / 100 - zoomCoef})`
                                        }}>
                                            <Template isEditable={true} template={selectedTemplate} />
                                        </div>
                                    </div>
                                </div>

                                <div className="workspace-footer" style={{ zIndex: 100 }}>
                                    <div className="workspace-footer-control">
                                        <div className="calculator" data-calculator>
                                            <button
                                                type="button"
                                                className="btn btn-calculator btn--square btn-calc--minus"
                                                onMouseDown={({ target }) => setZoom(prev => +prev - 10)}
                                            >
                                                <span className="ico">
                                                    <CalcMinusIcon />
                                                </span>
                                            </button>
                                            <input
                                                type="text"
                                                name="zoom"
                                                className="input"
                                                value={zoom}
                                                onChange={({ target }) => setZoom(target.value)}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-calculator btn--square btn-calc--plus"
                                                onMouseDown={({ target }) => setZoom(prev => +prev + 10)}
                                            >
                                                <span className="ico">
                                                    <CalcPlusIcon />
                                                </span>
                                            </button>
                                        </div>
                                        {
                                            zoom !== 100 ?
                                                <button
                                                    type="button"
                                                    className="btn btn-calculator btn--square ms-4 px-5                                                                                                                     "
                                                    onMouseDown={({ target }) => setZoom(100)}
                                                >
                                                    Reset
                                                </button>
                                                : null
                                        }
                                    </div>
                                    <div className="workspace-footer-control">
                                        <button
                                            onClick={handleUndo}
                                            type="button"
                                            className={`btn btn--square btn-tool ${!canUndo ? "disabled" : ""}`}
                                            disabled={!canUndo}
                                        >
                                            <span className="ico">
                                                <UndoIcon />
                                            </span>
                                        </button>
                                        <button
                                            onClick={handleRedo}
                                            type="button"
                                            className={`btn btn--square btn-tool ${!canRedo ? "disabled" : ""}`}
                                            disabled={!canRedo}
                                        >
                                            <span className="ico">
                                                <RedoIcon />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </main>
                    </div>
                </>
            }
        </>
    )
}

export default TemplateEditor
