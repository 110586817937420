import React from 'react'
import Button from '../../components/ui/buttons/Button'

const PayoutMethodCard = ({ method }) => {
    const methods = {
        stripe: {
            logo: require("../../assets/img/ico-payout-stripe.svg").default,
            name: "Stripe"
        },
        paypal: {
            logo: require("../../assets/img/ico-payout-paypal.svg").default,
            name: "PayPAl"
        },
    }
    return (
        <div className="card-bordered card-row payout-method-card">
            <div className="card-row--item justify-between flex-auto overflow--x-hidden">
                <div className="card-row-group overflow--x-hidden">
                    <div className="flex flex-col justify-center">
                        <div className="payout-logo">
                            <img src={methods[method].logo} alt={methods[method.name]} />
                        </div>
                    </div>
                    <div className="heading-block heading-nowrap flex-auto">
                        <h6 className="heading">{methods[method].name}</h6>
                        <p className="desk-heading --sm-heading">
                            Please, connect your
                            {methods[method].name} account to accept payments.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex-group justify-end flex-group--xs flex-nowrap">
                <Button className="btn btn--primary btn--sm radius"><span>Setup</span></Button>
            </div>
        </div>
    )
}

const PayuotMethods = () => {
    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">Payout Methods</h3>
            </div>
            <form className="form form-settings" method="post">
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <ul className="settings-list">
                                        <li className="settings-list--item">
                                            <PayoutMethodCard method={"stripe"} />
                                        </li>
                                        <li className="settings-list--item">
                                            <PayoutMethodCard method={"paypal"} />
                                        </li>
                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default PayuotMethods
