import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import PageLoader from '../../../components/ui/PageLoader';
import { checkErrors, getTrainingStyle } from '../../../helpers/utils';
import { getSeminarFormData, resetSeminarFormData, setSeminarFormData } from '../../../redux/slices/planningSlice';
import { getInstructorInfoFetch } from '../../../redux/thunks/instructorsThunks';

const initialData = {
    "dates": [],
    "style": "",
    "peopleAttending": "",
    "message": ""
}

const RequestPrivateSessionWrapper = () => {
    const { instructorId } = useParams()
    const dispatch = useDispatch()
    const [errorConfig, setErrorConfig] = useState({})
    const { instructorInfo, isLoading } = useSelector(state => state.instructors)

    const formData = useSelector(getSeminarFormData)
    const [errors, setErrors] = useState({})

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({ name, value }))

    }

    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig))
    }, [formData, errorConfig])

    useEffect(() => {
        dispatch(resetSeminarFormData(initialData))
    }, [])

    useEffect(() => {
        if (!instructorId) return;
        dispatch(getInstructorInfoFetch(instructorId))
    }, [instructorId])


    if (!instructorInfo) {
        return null
    }
    const { instructorProfile, generalInstructorInfo, privateSession } = instructorInfo

    return (
        <>
            <section className="section-42">
                <div className="container">
                    {
                        formData && instructorInfo && !isLoading ?
                            <div className="section-row-block">
                                <div className="section-row-block__body">
                                    <Outlet
                                        context={[formData, handleChange, errors, setErrorConfig, instructorInfo]}
                                    />
                                </div>
                                <div className="section-row-block__aside">
                                    <div className="card-shadow px-36 py-32">
                                        <div className="ticket-info-body ticket-info-body--no-border">
                                            <div className="ticket-info-body__item">
                                                <span className="color-text">Academy:</span>
                                                <span className="fw-500">HART JIU-JITSU CONSHOHOCKEN </span>
                                            </div>
                                            <div className="ticket-info-body__item">
                                                <span className="color-text">Instructor:</span>
                                                <span className="fw-500">{instructorProfile.fullName}</span>
                                            </div>
                                            <div className="ticket-info-body__item">
                                                <span className="color-text">Address::</span>
                                                <span className="fw-500">{generalInstructorInfo.addressLine}, {generalInstructorInfo.city}</span>
                                            </div>
                                            <div className="ticket-info-body__item">
                                                <span className="color-text">Seminar price:</span>
                                                <span className="fw-500">$ 1200 / hr</span>
                                            </div>
                                            <div className="ticket-info-body__item">
                                                <span className="color-text">Instructor style:</span>
                                                <span className="fw-500">{getTrainingStyle(privateSession?.traningStyle)?.[0]?.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <PageLoader />
                    }
                </div>
            </section>
        </>
    )
}

export default RequestPrivateSessionWrapper