import React from 'react'

const BillingInfo = () => {
    const invoises = [
        {
            name: 'Invoice #1234',
            date: '02.03.2024',
            price: '$100.00',
            user_name: 'Kristin Watson',
        },
        {
            name: 'Invoice #1234',
            date: '02.03.2024',
            price: '$100.00',
            user_name: 'Kristin Watson',
        },
        {
            name: 'Invoice #1234',
            date: '02.03.2024',
            price: '$100.00',
            user_name: 'Kristin Watson',
        },
        {
            name: 'Invoice #1234',
            date: '02.03.2024',
            price: '$100.00',
            user_name: 'Kristin Watson',
        },
    ]
    return (
        <div className="settings-body settings-gap">
            <form className="form form-settings" method="post">
                <div className="form-body">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <div className="sidebar-header heading-row">
                                <h3 className="heading">Billing Information</h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <ul className="list-credit-card">
                                <li className="lcc-item">
                                    <div className="wrapp-card-credit">
                                        <div className="card-credit">
                                            <div className="card-credit-header">
                                                <div className="credit-ico-method">
                                                    <img src={require("../../../assets/img/ico-mastercard.svg").default}/>
                                                </div>
                                                <div className="credit-actions">
                                                    <a href="#" className="btn">
                                                        <span className="ico">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clipRule="evenodd" d="M3.96975 11.7139L11.7143 3.96937C12.0068 3.67687 12.4815 3.67687 12.774 3.96937L14.031 5.22637C14.3235 5.51887 14.3235 5.99362 14.031 6.28612L6.28575 14.0299C6.1455 14.1709 5.955 14.2496 5.75625 14.2496H3.75V12.2434C3.75 12.0446 3.82875 11.8541 3.96975 11.7139Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M10.3125 5.37012L12.63 7.68762" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="card-credit-body">
                                                <div className="credit-secret">
                                                    <p className="credit-secret-text">
                                                        •••• •••• •••• 3507
                                                    </p>
                                                    <button type="button" className="btn btn-secret">
                                                        <span className="ico">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g opacity="0.3">
                                                                    <path d="M3.37451 2.8125L14.6245 15.1875" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M10.8919 11.0814C10.3399 11.5833 9.61119 11.8453 8.86604 11.8098C8.12089 11.7743 7.42035 11.4443 6.91854 10.8923C6.41672 10.3403 6.15473 9.6116 6.1902 8.86645C6.22567 8.1213 6.55569 7.42076 7.10768 6.91895" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M9.5293 6.2373C10.1272 6.35181 10.6719 6.65724 11.0814 7.10772C11.4909 7.5582 11.7432 8.12939 11.8004 8.73551" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M14.6674 11.8899C16.2002 10.5174 16.8745 9.00001 16.8745 9.00001C16.8745 9.00001 14.6245 3.93751 8.99947 3.93751C8.51236 3.93684 8.02603 3.97635 7.54541 4.05563" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M5.20264 4.82324C2.336 6.27449 1.12451 8.99981 1.12451 8.99981C1.12451 8.99981 3.37451 14.0623 8.99951 14.0623C10.3175 14.0726 11.6191 13.769 12.7964 13.1764" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2003_9398">
                                                                        <rect width="18" height="18" fill="currentColor"></rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="card-credit-footer">
                                                <div className="credit-info">
                                                    <p>02/30</p>
                                                    <p>John Carter</p>
                                                </div>
                                                <div className="card-credit-chip">
                                                    <span className="ico">
                                                        <img src={require("../../../assets/img/credit-chip.svg").default}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="lcc-item">
                                    <button type="button" className="wrapp-card-credit">
                                        <div className="btn btn--sm radius">
                                            <span className="ico">
                                                <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.4998 9.33301V18.6663" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M19.1668 14.0003H9.8335" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path fill-rule="evenodd" clipRule="evenodd" d="M14.5 24.5V24.5C8.7005 24.5 4 19.7995 4 14V14C4 8.2005 8.7005 3.5 14.5 3.5V3.5C20.2995 3.5 25 8.2005 25 14V14C25 19.7995 20.2995 24.5 14.5 24.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span>Add Credit Card</span>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <div className="sidebar-header heading-row">
                                        <h3 className="heading">Invoices</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="table-wrapper">
                                        <table className="table">
                                            <tbody>
                                                {invoises.map((item, index) => (

                                               
                                                    <tr key={index}>
                                                        <td style={{
                                                            width: "0px"
                                                        }}>
                                                            <p className="font-500">{item.date}</p>
                                                        </td>
                                                        <td>
                                                            <p className="color-text">{item.name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="font-500">{item.price}</p>
                                                        </td>
                                                        <td>
                                                            <p className="color-text">{item.user_name}</p>
                                                        </td>
                                                        <td className="--sticky-end-table">
                                                            <div className="table-actions justify-end">
                                                                <a href="#" download="" className="btn--square btn--xs">
                                                                    <span className="ico">
                                                                        <img src={require("../../../assets/img/ico-download.svg").default}/>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                 )) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default BillingInfo
