import React from 'react'
import GeneralInfoAcademy from './academyProfilePages/GeneralInfoAcademy'
import GeneralInfoInstructor from './instructorProfilePages/GeneralInfoInstructor'
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/slices/applicationSlice';

const GeneralInfo = () => {
    const user = useSelector(getUser);
    return (
        <>
            {user && user.role === "academy" && <GeneralInfoAcademy />}
            
            {user && user.role === "instructor" && <GeneralInfoInstructor />}
        </>
    )
}

export default GeneralInfo
