import React from 'react'
import { Link } from 'react-router-dom'

const Congratulations = ({ title = "Congratulations!", text = "", doneLink = { to: "/", title: "Done" }, additionalLink, additionalText }) => {
    return (
        <section className="empty-section">
            <div className="container">
                <div className="empty-section-body">
                    <div className="empty-section-body__icon">
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 8.66666V34.6667C6.5 37.0565 8.4435 39 10.8333 39H18.4167L26 47.6667L33.5833 39H41.1667C43.5565 39 45.5 37.0565 45.5 34.6667V8.66666C45.5 6.27683 43.5565 4.33333 41.1667 4.33333H10.8333C8.4435 4.33333 6.5 6.27683 6.5 8.66666ZM18.8652 17.9682L23.8333 22.9363L34.2182 12.5515L37.2818 15.6152L23.8333 29.0637L15.8015 21.0318L18.8652 17.9682Z" fill="#9747FF" />
                        </svg>
                    </div>
                    <div className="empty-section-body__title">
                        <h4 className="heading mb-16">{title}</h4>
                        <p>{text}</p>
                        {
                            additionalText ?
                            <p className='color-text mt-42'>{additionalText}</p>
                            :
                                null
                        }
                    </div>
                    <div className="row-group gap--md justify-between">
                        {
                            additionalLink &&
                            <Link to={additionalLink?.to} className="btn btn--default btn--sm">
                                <span className="info">{additionalLink.title}</span>
                            </Link>
                        }
                        <Link to={doneLink.to} className="btn btn--primary btn--sm">
                            <span className="info">{doneLink.title}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Congratulations