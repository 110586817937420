const PageLoader = () => {
    return (
        <div className="page-loader">
            <div className="svg-wrpr">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 56 56" fill="none">
                    <path d="M47.7991 8.20093C42.5105 2.91234 35.4796 0 28 0C20.5211 0 13.4895 2.91234 8.20093 8.20093C2.91234 13.4895 0 20.5211 0 28C0 35.4789 2.91234 42.5105 8.20093 47.7991C13.4895 53.0877 20.5204 56 28 56C35.4789 56 42.5105 53.0877 47.7991 47.7991C53.0877 42.5105 56 35.4789 56 28C56 20.5211 53.0869 13.4895 47.7991 8.20093Z" fill="white"/>
                    <path d="M20.2158 26.3578C18.8266 24.3046 17.8087 22.228 17.2598 20.3208C17.7522 19.7577 18.2476 19.2238 18.734 18.7322C20.7632 16.7248 23.3314 14.7251 25.7769 13.2493C28.7525 11.4536 31.4081 10.5049 33.4568 10.5049C34.8038 10.5049 35.8986 10.916 36.7087 11.7254C37.2975 12.2773 38.3441 13.3095 39.9117 14.8847C39.5382 14.841 39.1527 14.8192 38.762 14.8192C34.0893 14.8192 28.248 17.9303 23.1371 23.1406C22.0943 24.1646 21.1118 25.2458 20.2158 26.3578Z" fill="#02020A"/>
                    <path d="M14.8863 39.9116C13.3126 38.3462 12.2796 37.2989 11.733 36.7154C9.11955 34.102 11.2519 29.2252 12.958 26.2715C14.1363 28.4685 15.4668 30.5263 16.5284 32.1165C15.1867 34.9355 14.622 37.6197 14.8863 39.9116Z" fill="#02020A"/>
                    <path d="M33.4575 45.4932C31.5782 45.4932 29.1771 44.6906 26.4922 43.1674C27.0102 42.8301 27.5644 42.4852 28.172 42.1201L28.367 42.0034L28.3022 41.7858C28.1027 41.1157 28.4325 40.3123 29.086 39.8763C29.6214 39.5195 30.1891 39.1415 30.7568 38.7695C33.6006 40.3462 36.3654 41.1789 38.7627 41.1789C39.1527 41.1789 39.5382 41.1571 39.9117 41.1134C38.3441 42.6885 37.2975 43.7216 36.7155 44.2659C35.8986 45.0829 34.8045 45.4932 33.4575 45.4932Z" fill="#02020A"/>
                    <path d="M20.3274 38.7451C19.762 38.2504 19.2259 37.7535 18.7327 37.2656C16.6192 35.1295 13.7363 31.5456 11.988 27.8984C10.6771 25.1637 9.56728 21.4503 11.7267 19.2909C12.2793 18.7013 13.3116 17.654 14.886 16.0879C14.3311 20.9059 17.467 27.296 23.1419 32.8624C24.1644 33.9052 25.2463 34.8878 26.3591 35.7838C24.3036 37.1767 22.2286 38.1954 20.3274 38.7451Z" fill="#02020A"/>
                    <path d="M42.4821 30.6573C42.3111 30.32 42.141 29.9827 41.9708 29.6454C41.5379 28.7886 41.0906 27.9024 40.6411 27.0357C40.4845 26.7338 40.3166 26.4274 40.154 26.1315C39.837 25.5532 39.5095 24.9561 39.2422 24.3508C40.7496 21.3564 41.3933 18.511 41.1132 16.0889C42.6914 17.6587 43.7236 18.7061 44.2657 19.2851C45.4343 20.4536 45.7693 22.1816 45.2679 24.4268C44.8643 26.2346 43.9036 28.3805 42.4821 30.6573Z" fill="#02020A"/>
                    <path d="M22.5428 45.4938C21.195 45.4938 20.101 45.0827 19.2909 44.2733C18.7021 43.7222 17.6555 42.6891 16.0879 41.114C16.4613 41.1569 16.8468 41.1795 17.2376 41.1795C21.9111 41.1795 27.7523 38.0684 32.8624 32.8581C33.9053 31.8349 34.8878 30.7537 35.7831 29.6416C37.173 31.6948 38.1902 33.7722 38.7391 35.6786C38.2467 36.2418 37.7512 36.7756 37.2648 37.2673C35.2357 39.2746 32.6682 41.2736 30.2219 42.7501C27.2471 44.5451 24.5915 45.4938 22.5428 45.4938Z" fill="#02020A"/>
                    <path d="M25.6893 17.4825C22.6843 15.7402 19.7652 14.8202 17.2376 14.8202C16.8468 14.8202 16.4621 14.842 16.0879 14.8857C17.6555 13.3105 18.7021 12.2775 19.2841 11.7331C20.101 10.9162 21.195 10.5059 22.542 10.5059C24.904 10.5059 28.1069 11.7889 31.5983 14.1275C31.2271 14.3338 30.8544 14.5386 30.4824 14.7441C29.8334 15.1018 29.1618 15.4722 28.5045 15.8426C27.6461 16.3268 26.7848 16.8357 25.952 17.3282L25.6893 17.4825Z" fill="#02020A"/>
                    <path d="M41.1118 39.9101C41.6667 35.0921 38.5315 28.7027 32.8559 23.1356C31.8335 22.0928 30.7515 21.1102 29.6387 20.2142C31.6942 18.8213 33.7693 17.8026 35.6704 17.2529C36.2366 17.7476 36.7727 18.2445 37.2651 18.7324C39.3786 20.8685 42.2616 24.4525 44.0099 28.0989C45.3207 30.8335 46.4305 34.547 44.2711 36.7064C43.7222 37.2937 42.6892 38.341 41.1118 39.9101Z" fill="#02020A"/>
                </svg>
            </div>
            <div className="firts-screen-bg">
                <div className="firts-screen-bg-effect"></div>
            </div>
        </div>
    )
}

export default PageLoader;
