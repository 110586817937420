import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../components/ui/buttons/Button'
import Loader from '../components/ui/Loader'
import { getImageSrc } from '../helpers/utils'
import { deleteNotificationFetch, getAllNotificationsFetch, readNotificationsFetch } from '../redux/thunks/notificationsThunks'
import { ReactComponent as ChevronNextIcon } from '../assets/img/ico-chevron--next.svg'



export const NotificationItem = ({ data, isAllReading }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isReading, setIsReading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const handleNavigate = (id, instructorId) => {
        navigate(`/instructors/${instructorId}`)
        dispatch(readNotificationsFetch({ ids: [id] }))
    }

    const handleRead = () => {
        setIsReading(true)
        dispatch(readNotificationsFetch({ ids: [data.id] })).finally(() => setIsReading(false))
    }

    const handleDelete = () => {
        setIsDeleting(true)
        dispatch(deleteNotificationFetch(data.id)).finally(() => setIsDeleting(false))
    }

    return (
        <div className={classNames('notification-item', {
            "--readed": data.isReaded
        })}>
            <div className="notification-item__body">
                <div className='col-group gap--sm'>
                    <div className='row-group gap--sm'>
                        <div className='image-wrapper avatar'>
                            <img src={getImageSrc(data?.payload?.photo)} alt="instructor" />
                        </div>
                        <h6>{data?.payload?.fullName}</h6>
                    </div>
                    <p className='color-text'>
                        {data.message}
                    </p>
                    <Button
                        className={"btn--primary btn--sm w-fit"}
                        onClick={() => handleNavigate(data.id, data?.payload?.instructorId)}>
                        <span className="info">See Profile</span>
                        <span className="ico ico--20">
                            <ChevronNextIcon />
                        </span>
                    </Button>
                </div>
            </div>
            <div className="notification-item__actions">
                <Button
                    isLoading={isDeleting}
                    onClick={handleDelete}
                    className={"btn--default btn--md"}>
                    Delete
                </Button>
                {
                    !data.isReaded && (
                        <Button
                            isLoading={isReading || isAllReading}
                            onClick={handleRead}
                            className={"btn--primary btn--md"}>
                            Mark as read
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

const NotificationsPage = () => {
    const dispatch = useDispatch()
    const [isAllReading, setIsAllReading] = useState(false)
    const { notifications, isLoading } = useSelector(state => state.notifications)

    const handleReadAll = () => {
        setIsAllReading(true)
        dispatch(readNotificationsFetch({
            ids: notifications.map(item => item.id)
        })).finally(() => setIsAllReading(false))
    }
    useEffect(() => {
        dispatch(getAllNotificationsFetch())
    }, [])
    return (
        <section className='col-group flex-auto py-5'>
            <div className="container col-group flex-auto">
                <div className='row-group justify-between gap--md mb-24'>
                    <h3 className='heading'>Notifications</h3>
                    {
                        !!notifications?.length && (
                            <Button
                                isLoading={isAllReading}
                                onClick={handleReadAll}
                                className={"btn--primary btn--md"}>
                                Read all
                            </Button>
                        )
                    }
                </div>
                {
                    notifications?.length && !isLoading ? (
                        <div className='col-group gap--md'>
                            {
                                notifications.map(item => (
                                    <NotificationItem key={item.id} data={item} isAllReading={isAllReading} />
                                ))
                            }
                        </div>
                    )
                        : isLoading
                            ? (
                                <div className="m-auto">
                                    <Loader />
                                </div>
                            )
                            : (
                                <div className="m-auto">
                                    <h5 className='heading'>There is no notifications</h5>
                                </div>
                            )
                }
            </div>
        </section>
    )
}

export default NotificationsPage