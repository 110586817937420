import React, { useEffect, useState } from 'react'
import { ReactComponent as ChatIcon } from '../../../assets/img/chatDark.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/img/calendar.svg'
import ReschedulePrivateLessonModal from "../../../components/modals/ReschedulePrivateLessonModal";
import api from '../../../api';
import PageLoader from '../../../components/ui/PageLoader';
import Button from '../../../components/ui/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import { getImageSrc } from '../../../helpers/utils';
import { useDispatch } from 'react-redux';
import { acceptRequestsPrivateLessonsFetch, rejectRequestsPrivateLessonFetch } from '../../../redux/thunks/privateLessonsThunks';




export const RequestedPtivateLessons = () => {
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false)

    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getRequests = async () => {
        setIsLoading(true)
        const res = await api.requests.getRequestsPrivateLessons();
        setRequests(res?.data || [])
        setIsLoading(false)
    }


    useEffect(() => {
        getRequests()
    }, [])


    if (isLoading) {
        return <PageLoader />
    }

    if (!requests?.length) {
        return <h2 className='heading text-center mt-28'>There is no items now</h2>
    }


    return (
        <>
            <div className="request-items pt-28">
                {
                    requests?.map(item => (
                        <RequestItem key={item.id} data={item} update={getRequests} />
                    ))
                }
            </div>
            <ReschedulePrivateLessonModal open={openRescheduleModal} handleClose={() => setOpenRescheduleModal(false)}
                data={[new Date()]}
            />
        </>
    )
}


function RequestItem({ data, update }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false)
    const [isApproving, setIsApproving] = useState(false)
    const [isRejecting, setIsRejecting] = useState(false)

    const handleApprove = async (id) => {
        setIsApproving(true)
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(data.id)).unwrap()
        if (res?.success) {
            navigate(`/chat/${res.data?.chat?.id}`)
            update && update()
        }
        setIsApproving(false)

    }
    const handleReject = async (id) => {
        setIsRejecting(true)
        const res = await dispatch(rejectRequestsPrivateLessonFetch(data.id)).unwrap()
        if (res?.success) {
            update && update()
        }
        setIsRejecting(false)

    }
    const studentProfile = data.student?.studentProfile
    return (
        <>
            <div className="request-item">
                <div className="request-item__user">
                    <div className="ico ico--md img-wrapper rounded-full">
                        <img src={getImageSrc(studentProfile?.photo)} alt="student" />
                    </div>
                    <div className="col-group gap-4">
                        <h6 className="heading">Wade Warren</h6>
                        <p className="color-text">25 Feb - 04 Mar, 2024</p>
                    </div>
                </div>
                <div className="request-item__details">
                    <div className="col-group gap--xs">
                        <span className="label">Total Price</span>
                        <p className="fw-500 text--sm">$1,300</p>
                    </div>
                    <div className="col-group gap--xs">
                        <span className="label">People</span>
                        <p className="fw-500 text--sm">1</p>
                    </div>
                    <div className="col-group gap--xs">
                        <span className="label">teaching</span>
                        <p className="fw-500 text--sm">NoGi</p>
                    </div>
                    <div className="col-group gap--xs">
                        <span className="label">Location</span>
                        <p className="fw-500 text--sm">BJJ New Gym</p>
                    </div>
                </div>
                <div className="request-item__action">
                    <button type='button' onClick={() => setOpenRescheduleModal(true)} className="btn btn--sm">
                        <span className="ico">
                            <CalendarIcon />
                        </span>
                        RESCHEDULE
                    </button>

                    {/*<a href="#" className="btn btn--sm">Show Details</a>*/}

                    <button isLoading={isRejecting} onClick={handleReject} className="btn btn--sm ">Decline</button>
                    <Button isLoading={isApproving} onClick={handleApprove} className="btn btn--sm btn--primary">Approve</Button>
                    <Link to={`/chat/${data?.chat?.id}`} className="btn btn--sm btn--square notification" count="1">
                        <span className="ico">
                            <ChatIcon />
                        </span>
                    </Link>
                </div>
            </div>
            <ReschedulePrivateLessonModal
                data={data.dates}
                id={data.id}
                open={openRescheduleModal}
                handleClose={() => setOpenRescheduleModal(false)}
            />
        </>
    )
}