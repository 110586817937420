import React from 'react'
import { Link } from 'react-router-dom'
import pdf from '../assets/Privacy and Cookies Policy - bjj NEW.pdf'

const RefundPolicy = () => {
    const handleClick = (e) => {
        e.preventDefault()
        const link = document.createElement('a');
        link.href = pdf;
        link.download = 'your-file-name.pdf'; // Set desired file name
        link.click();
    }
    return (
        <section className="section">
            <div className="container">
                {/* <h2 className="heading text-center mb-44">Privacy Policy</h2> */}
                <div className="card-shadow document-card mb-42 terms-doc">
                    <div className="document-card__header">
                        <h3 className="heading text-center mb-44">Cancellation and Refund Policy</h3>
                    </div>
                    <p>This Cancellation and Refund Policy (&ldquo; <strong>Policy</strong>&rdquo;) should be applied in conjunction with our <Link to='/terms-of-service'>Terms and Conditions of Service</Link>, <Link to='/terms-of-use'>Website Terms of Use</Link>, <Link to='/privacy-policy'>Privacy Policy</Link> and all other policies on our Website (&ldquo;Incorporated Terms&rdquo;). The Incorporated Terms are hereby incorporated by reference. Unless other stated in this Policy, defined words used in this Policy shall have the same meaning as used in the Incorporated Terms. </p>
                    <p>We offer a limited cancellation rights for Service Provider&rsquo;s Services. Please read this Policy and the Incorporated Terms carefully before you engage with any Service Provider on our Website.</p>
                    <ol>
                        <li>
                            <h1>Cancellation Rights?</h1>
                        </li>
                    </ol>
                    <p>Cancellation defers based on the type of Service Provider Services purchased by you. The various cancellation provisions are as follows:</p>
                    <ol type='a'>
                        <li>
                            <p>
                                <strong>Cancellation of Events by Academies. </strong>
                            </p>
                            <p>When purchasing a reservation for an event (including but not limited to seminars, camps, etc) hosted by an Academy, the Academy will include a minimum attendance number. This means the event will only occur if the minimum attendance number is met (the Minimum Attendance Requirement).</p>
                            <p>Academies will be required to include a cut-off date for the attendance number. Such date must be at the minimum 5 days before the event date.</p>
                            <p>Academies may cancel any event that does not meet the Minimum Attendance Requirement. It is the responsibility and discretion of of the Academies to cancel this event. Academies may decide to go on with the event event even if the Minimum Attendance Requirement was not attained.</p>
                            <p>Academies may cancel events (and subsequently all Bookings for the event) at any time but no later than seven days before the event date.</p>
                            <p>Cancellation may affect an Academy&rsquo;s rating and review on our Website.</p>
                            <p>Once cancelled, all Bookings for the event will be refunded in accordance with section 3 of this Policy.</p>
                    
                        </li>
                        <li>
                            <p>
                                <strong>Cancellation by Instructors.</strong>
                            </p>
                            <p>Instructors may cancel a Booking for the Instructor Services at any time before 30 days of the date of performance.</p>
                            <p>Outside the 30 days, the Instructor may cancel for health and safety reasons. The Instructor must communicate such special cancellations to us (through our <Link to='/contact-us'>Contact Us</Link> page). The Instructor may offer to reschedule cancelled sessions to service Users, however, that is subject to acceptance at the full discretion of the service User.</p>
                            <p>Cancellation may affect an Instructor&rsquo;s rating and review on our Website. Once cancelled, Booking will be refunded in accordance with section 3 of this Policy.</p>
                    
                        </li>
                        <li>
                            <p>
                                <strong>Cancellation of Bookings for Instructor Services by Academies.</strong>
                            </p>
                            <p>Outside the right of an Academy to cancel an event in the manner described in section 1(a) of this Policy, an Academy may cancel a Booking for an Instructor Service at anytime no later than 45 days before the date the event starts. Any cancellation, except the cancellation of the whole event as described in 1(a), the Academy will not receive any refund, and the Instructor will have the right to the full payment as deposited with Plan Seminars.</p>
                    
                        </li>
                        <li>
                            <p>
                                <strong>Cancellation of Bookings for Instructors&rsquo; Services by Students.</strong>
                            </p>
                            <p>With a limited right to refund, Students can cancel Bookings within 5 days of making the relevant Booking and get a full refund if there is a minimum of 7 days before the performance date.</p>
                        </li>
                        <li>
                            <p>
                                <strong>Plan Seminars&rsquo; Cancellation </strong>
                            </p>
                            <p>We reserve the right to cancel any Booking or event without prior notice if we determine, at our sole discretion, that a User has violated any of our Website policies or any applicable laws. If a Booking or event is cancelled due to such violations, the User may also be prohibited from making future bookings or participating in any future events, and further legal action may be taken as deemed necessary.</p>
                            <p>While we are not required to provide prior notice, we will attempt to notify the User of the cancellation and the reasons for such action using the contact information provided during the Booking or registration process. By using our services, you agree to comply with all our policies and applicable laws and acknowledge that we may exercise our right to cancel Bookings or events as described above.</p>
                        </li>
                    </ol>
                   
                   
                    
                    <ol start='2'>
                        <li>
                            <h1>How to Cancel.</h1>
                        </li>
                    </ol>
                    <p>You must let us know if you want to cancel a Booking or an event. You can do this by contacting us through our <Link to='/contact-us'>contact us page</Link>. To meet the cancellation deadline, it is sufficient for you to send your cancellation request before the cancellation period contained in section 1.</p>
                    <ol start='3'>
                        <li>
                            <h1>Refund.</h1>
                        </li>
                    </ol>
                    <p>We will refund all payments received from you within 14 days of your cancellation request if such request is made in accordance with this Policy.</p>
                    <p>We will issue the refund using the same payment method you used when you placed your order, unless you have expressly agreed otherwise.</p>
                    <p>We do not offer refunds or cancellations for no-show, late arrivals or non-attendance of events, seminars or sessions to which a Booking was made. The decision to refund or reschedule for non-attendance, late arrival or no-show rests solely with the Service Provider&rsquo;s discretion. Only the Service Provider can authorise or make this request to us on your behalf. Please contact the Service Provider for any refund or cancellation regarding non-attendance, late arrival or no-show.</p>
                </div>

            </div>
        </section>
    )
}

export default RefundPolicy