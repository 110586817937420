const base = 'api/academy-seminars';

export const academySeminars = (instance) => ({
    async getAcademySeminarsAll(payload) {
        const { data } = await instance.get(`${base}/all`);
        return data;
    },
    async getAcademySeminarsById(seminarId) {
        const { data } = await instance.get(`${base}/${seminarId}`);
        return data;
    },
    async createAcademySeminar(payload) {
        const { data } = await instance.post(`${base}`, payload);
        return data;
    },
    async updateAcademySeminar(payload) {
        const { data } = await instance.put(`${base}/${payload.seminarId}`, payload);
        return data;
    },
    async deleteAcademySeminar(seminarId) {
        const { data } = await instance.delete(`${base}/${seminarId}`);
        return data;
    },
    async uploadAcademySeminarPhotos({ body, seminarId }) {
        const { data } = await instance.post(`${base}/photos/${seminarId}`, body);
        return data;
    },
    async deleteAcademySeminarPhotoById(photoId) {
        const { data } = await instance.delete(`${base}/photos/${photoId}`);
        return data;
    },
    async addAcademySeminarDate(payload) {
        const { data } = await instance.post(`${base}/dates/${payload.seminarId}`, payload);
        return data;
    },
    async updateAcademySeminarDate(payload) {
        const { data } = await instance.put(`${base}/dates/${payload.dateId}`, payload);
        return data;
    },
    async deleteAcademySeminarDate(dateId) {
        const { data } = await instance.put(`${base}/dates/${dateId}`);
        return data;
    },
})