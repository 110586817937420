import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveIndex } from '../../../redux/slices/editorSlice';
// import { ReactComponent as CircleIcon } from "../../../assets/img/rt-circle.svg";
// import { ReactComponent as RectIcon } from "../../../assets/img/rt-rect.svg";

const ContentEditable = ({ block, isEditable, index, id, children }) => {
    const style = block.attributes.style
    const elementRef = useRef(null)
    const activeIndex = useSelector(state => state.editor.activeIndex)
    const isActive = activeIndex === index
    const dispatch = useDispatch()
    const [content, setContent] = useState("")
    // const [toolsStyle, setToolsStyle] = useState({})

    const handleInput = (event) => {
        setContent(event.target.innerHTML)
    };
    // function getPositionAndSizeRelativeToRelativeParent(element) {
    //     let parent = element.parentElement;
    //     let offsetTop = element.offsetTop;
    //     let offsetLeft = element.offsetLeft;
    //     let width = element.offsetWidth;
    //     let height = element.offsetHeight;

    //     while (parent && parent !== document.body) {
    //         const parentStyles = window.getComputedStyle(parent);
    //         if (parentStyles.position === 'relative') {
    //             const parentRect = parent.getBoundingClientRect();
    //             const elementRect = element.getBoundingClientRect();
    //             offsetTop = elementRect.top - parentRect.top;
    //             offsetLeft = elementRect.left - parentRect.left;
    //             width = element.offsetWidth;
    //             height = element.offsetHeight;
    //             break;
    //         }
    //         offsetTop += parent.offsetTop;
    //         offsetLeft += parent.offsetLeft;
    //         parent = parent.parentElement;
    //     }

    //     return { top: offsetTop, left: offsetLeft, width, height };
    // }
    // useEffect(() => {
    //     if (!elementRef.current || !isEditable) return
    //     setToolsStyle(getPositionAndSizeRelativeToRelativeParent(elementRef.current))
    // }, [block, content, activeIndex, isEditable, elementRef.current])

    return (
        <>
            <div
                id={id}
                ref={elementRef}
                onFocus={() => dispatch(setActiveIndex(index))}
                className={`editor-element ${isActive ? "--active" : ""}`}
                style={style}
                contentEditable={isEditable}
                suppressContentEditableWarning={true}
                onInput={handleInput}
            >
                {children}
            </div>
            {/* {
                isEditable && isActive && (
                    <div className={`editor-element-render--tools ${isActive ? "--active" : ""}`} contentEditable={false} style={{
                        userSelect: "none",
                        pointerEvents: "none",
                        ...toolsStyle
                    }}>
                        <div className="rt-control --rt-1 ml-auto" style={{ cursor: "default" }}>
                            <CircleIcon />
                        </div>
                        <div className="rt-control --rt-2 ml-auto" style={{ cursor: "default" }}>
                            <RectIcon />
                        </div>
                        <div className="rt-control --rt-3 mx-auto" style={{ cursor: "default" }}>
                            <CircleIcon />
                        </div>
                    </div>
                )
            } */}
        </>
    );
};

export default ContentEditable