import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import SeminarOverviewHeader from './parts/SeminarOverviewHeader'
import SeminarOverviewPrices from './parts/SeminarOverviewPrices'
import SeminarOverviewDetail from './parts/SeminarOverviewDetail'
import SeminarOverviewRequsts from './parts/SeminarOverviewRequsts'
import SeminarOverviewBought from './parts/SeminarOverviewBought'
import { useDispatch, useSelector } from 'react-redux'
import { getSeminarsById } from '../../redux/thunks/seminarsThunks'
import { getSeminarDetailes } from '../../redux/slices/seminarsSlice'
import PageLoader from '../../components/ui/PageLoader'

const SeminarOverview = () => {
    const {id} = useParams();
    const  dispatch = useDispatch();
    const pageData = useSelector(getSeminarDetailes);
    const {isLoading} = useSelector(state => state.seminars)
    useEffect(()=>{
        dispatch(getSeminarsById(id))
    }, [dispatch])
    
    if (isLoading) return <PageLoader/> 
    return (
        <section className="section-settings profile-page">
            <div className="container container--full">
                <div className="section-heading-row">
                    <Link to={'/my-seminars'} className="btn btn-back">
                        <span className="ico"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6667 6.66683L8.33335 10.0002L11.6667 13.3335" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        </span>
                        <p className="text-sm">Back</p>
                    </Link>
                </div>
                <div className="settings-row">
                    <div className="settings-col">
                        <div className="settings-body settings-gap">
                            <SeminarOverviewHeader pageData={pageData} />
                            <div className="form form-settings">
                               {pageData?.status != 'pending' && <SeminarOverviewPrices/>}
                                <div className="form-body">
                                    <div className="form-items-list">
                                        <SeminarOverviewDetail pageData={pageData} />
                                        {pageData?.status === 'pending' ?
                                            <SeminarOverviewRequsts/>
                                            :
                                            <SeminarOverviewBought/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SeminarOverview
