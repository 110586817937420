export const templates = [
    {
        id: "card-editor",
        templateId: "t1",
        blocks: [
            {
                id: "card-editor",
                tagName: "div",
                attributes: {
                    style: {
                        display: "flex",
                        backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }
                },
            },
            {
                id: "column1",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        flex: 1,
                    }
                },
            },
            {
                id: "p1",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        textAlign: "center",
                        textTransform: "uppercase",
                        backgroundColor: "#F20018",
                        color: "#ffffff",
                        fontFamily: "'Fjalla One', sans-serif",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "35px",

                    }
                },
                content: "&nbsp; Grapple Mastery - Advanced Techniques and Strategies in BJJ &nbsp;"
            },
            {
                id: "p2",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "90px",
                        letterSpacing: "0.2px",
                        lineHeight: "100px",
                        fontWeight: "400",
                        fontFamily: "'Fjalla One', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: "Dave Camarillo"
            },
            {
                id: "p3",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "20px",
                        letterSpacing: "0.2px",
                        lineHeight: "30px",
                        fontWeight: "400",
                        fontFamily: "'Fjalla One', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: `
                    22-25 October • Gi Style
                    <br> 
                    Copenhagen, Denmark
                `
            },
            {
                id: "bg-image",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/template-11--bg.jpg"),
                    alt: "Background Image",
                    style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        inset: 0,
                    }
                }
            }
        ]
    },
    {
        templateId: "t2",
        blocks: [
            {
                id: "card-editor",
                tagName: "div",
                attributes: {
                    style: {
                        display: "flex",
                        backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    },
                },
            },
            {
                id: "column1",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                        backgroundColor: "#0D080B",
                    }
                },
            },
            {
                id: "column2",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                    }
                },
            },
            {
                id: "p1",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "40px",
                        letterSpacing: "0.2px",
                        lineHeight: "30px",
                        fontWeight: "800",
                        fontFamily: "'Chathura', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff",
                    }
                },
                content: "Copenhagen, Denmark"
            },
            {
                id: "spacer1",
                tagName: "div",
                appendTo: "column1",
                attributes: {
                    style: {
                        width: "100px",
                        height: "1px",
                        backgroundColor: "#ffffff",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                id: "p2",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "70px",
                        letterSpacing: "0.2px",
                        lineHeight: "40px",
                        fontWeight: "800",
                        fontFamily: "'Chathura', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#FFD600",
                    }
                },
                content: "Dave <br/> Camarillo"
            },
            {
                id: "spacer2",
                tagName: "div",
                appendTo: "column1",
                attributes: {
                    style: {
                        width: "100px",
                        height: "1px",
                        backgroundColor: "#ffffff",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                id: "p3",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "35px",
                        letterSpacing: "0.2px",
                        lineHeight: "28px",
                        fontWeight: "800",
                        fontFamily: "'Chathura', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#FFD600",
                    }
                },
                content: `22-25 October`
            },
            {
                id: "spacer3",
                tagName: "div",
                appendTo: "column1",
                attributes: {
                    style: {
                        width: "100px",
                        height: "1px",
                        backgroundColor: "#ffffff",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                id: "p4",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "35px",
                        letterSpacing: "0.2px",
                        lineHeight: "28px",
                        fontWeight: "800",
                        fontFamily: "'Chathura', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#FFFFFF",
                    }
                },
                content: `Grapple Mastery - <br> Advanced Techniques and Strategies in BJJ`
            },
            {
                id: "p5",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "35px",
                        letterSpacing: "0.2px",
                        lineHeight: "28px",
                        fontWeight: "400",
                        fontFamily: "'Chathura', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#FFD600",
                    }
                },
                content: `GI Style`
            },
            {
                id: "bg-image",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/template-20--bg.jpg"),
                    alt: "Background Image",
                    style: {
                        width: "50%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        top: 0,
                        right: 0,
                    }
                }
            },
            {
                id: "decor1",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/ico-lines.svg").default,
                    alt: "Background Image",
                    style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "50%",
                        width: "20px",
                        height: "100%",
                        pointerEvents: "none",
                        filter: `brightness(0) saturate(100%) 
                                invert(84%) sepia(49%) 
                                saturate(2268%) hue-rotate(359deg) 
                                brightness(102%) contrast(103%)`,
                    }
                }
            }
        ]
    },
    {
        templateId: "t3",
        blocks: [
            {
                id: "card-editor",
                tagName: "div",
                attributes: {
                    style: {
                        display: "flex",
                        backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }
                },
            },
            {
                id: "column1",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "16px",
                        justifyContent: "flex-end"
                    }
                },
            },
            // {
            //     id: "p1",
            //     tagName: "p",
            //     appendTo: "column1",
            //     attributes: {
            //         style: {
            //             textAlign: "center",
            //             textTransform: "uppercase",
            //             backgroundColor: "#F20018",
            //             color: "#ffffff",
            //             fontFamily: "'Fjalla One', sans-serif",
            //             fontSize: "20px",
            //             fontWeight: "400",
            //             lineHeight: "35px",
            //             marginLeft: "auto",
            //             marginBottom: "auto",
            //             paddingLeft: "16px",
            //             paddingRight: "16px",
            //         }
            //     },
            //     content: "$120.00"
            // },
            {
                id: "p2",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "90px",
                        letterSpacing: "0.2px",
                        lineHeight: "80px",
                        fontWeight: "800",
                        fontFamily: "'Roboto Condensed', sans-serif",
                        fontStyle: "italic",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: "Dave Camarillo"
            },
            {
                id: "p3",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "20px",
                        letterSpacing: "0.2px",
                        lineHeight: "30px",
                        fontWeight: "400",
                        fontFamily: "'Fjalla One', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: `22-25 October • Gi Style`
            },
            {
                id: "p4",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "20px",
                        letterSpacing: "0.2px",
                        lineHeight: "24px",
                        fontWeight: "800",
                        fontFamily: "'Roboto Condensed', sans-serif",
                        fontStyle: "italic",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#F20018",
                    }
                },
                content: `&nbsp; Grapple Mastery - Advanced <br /> Techniques and Strategies in BJJ &nbsp;`
            },
            {
                id: "p5",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "20px",
                        letterSpacing: "0.2px",
                        lineHeight: "24px",
                        fontWeight: "500",
                        fontFamily: "'Roboto Condensed', sans-serif",
                        fontStyle: "italic",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#FFFFFF",
                    }
                },
                content: `Copenhagen, Denmark`
            },
            {
                id: "bg-image",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/template-19--bg.jpg"),
                    alt: "Background Image",
                    style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        inset: 0,
                    }
                }
            }
        ]
    },
    {
        templateId: "t4",
        blocks: [
            {
                id: "card-editor",
                tagName: "div",
                attributes: {
                    style: {
                        display: "flex",
                        backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    },
                },
            },
            {
                id: "column1",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end"
                    }
                },
            },
            {
                id: "p1",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff",
                        fontFamily: "'Exo 2', sans-serif",
                        fontSize: "17px",
                        fontWeight: "600",
                        lineHeight: "20px",
                    }
                },
                content: "&nbsp; Grapple Mastery - Advanced Techniques and Strategies in BJJ &nbsp;"
            },
            {
                id: "spacer1",
                tagName: "div",
                appendTo: "column1",
                attributes: {
                    style: {
                        width: "100%",
                        height: "3px",
                        backgroundColor: "#630610",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                id: "p2",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "68px",
                        letterSpacing: "0.2px",
                        lineHeight: "70px",
                        fontWeight: "700",
                        fontFamily: "'Exo 2', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: "Dave Camarillo"
            },
            {
                id: "p3",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: "18px",
                        letterSpacing: "0.2px",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontFamily: "'Exo 2', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: `
                    22-25 October &#8226; Gi Style <br> Copenhagen, Denmark
                `
            },
            {
                id: "bg-image",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/template-18--bg.jpg"),
                    alt: "Background Image",
                    style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        inset: 0,
                    }
                }
            },
            {
                id: "decor1",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/bg-shapes-1.svg").default,
                    alt: "Background Image",
                    style: {
                        zIndex: -1,
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        maxHeight: '100%',
                        height: '175px',
                        pointerEvents: 'none',
                        filter: `brightness(0) saturate(100%) invert(14%) sepia(23%) 
                        saturate(7316%) hue-rotate(327deg) brightness(76%) contrast(123%)`
                    }
                }
            }
        ]
    },
    {
        templateId: "t5",
        blocks: [
            {
                id: "card-editor",
                tagName: "div",
                attributes: {
                    style: {
                        backgroundImage: "linear-gradient(180deg, rgba(61, 0, 139, 0) 25.58%, rgba(61, 0, 139, 0.9) 84.03%)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }
                },
            },
            {
                id: "column1",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end"
                    }
                },
            },
            {
                id: "row1",
                tagName: "div",
                appendTo: "column1",
                attributes: {
                    style: {
                        display: 'flex',
                        gap: '8px',
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                id: "p1",
                tagName: "p",
                appendTo: "row1",
                attributes: {
                    style: {
                        fontSize: "40px",
                        letterSpacing: "0.2px",
                        lineHeight: "50px",
                        fontWeight: "400",
                        fontFamily: "'Bayon', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: `22-25 Oct`
            },
            {
                id: "spacer1",
                tagName: "div",
                appendTo: "row1",
                attributes: {
                    style: {
                        height: '100%',
                        width: '1px',
                        backgroundColor: '#FFFFFF',
                        margin: '0 3px'
                    }
                },
            },
            {
                id: "p2",
                tagName: "p",
                appendTo: "row1",
                attributes: {
                    style: {
                        fontSize: "68px",
                        letterSpacing: "0.2px",
                        lineHeight: "70px",
                        fontWeight: "400",
                        fontFamily: "'Bayon', sans-serif",
                        textAlign: "left",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: ` Dave <br> Camarillo`
            },
            {
                id: "p3",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        backgroundColor: 'transparent',
                        color: '#ffffff',
                        fontFamily: "'Bayon', sans-serif",
                        outline: '1px solid #ffffff',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '35px',
                        letterSpacing: "0.2px"
                    }
                },
                content: `Grapple Mastery - Advanced Techniques and Strategies in BJJ`
            },
            {
                id: "p4",
                tagName: "p",
                appendTo: "column1",
                attributes: {
                    style: {
                        fontSize: '18px',
                        lineHeight: '24px',
                        fontWeight: '400',
                        fontFamily: "'Bayon', sans-serif",
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        color: '#ffffff',
                        letterSpacing: "0.2px"

                    }
                },
                content: `GI Style • Copenhagen, Denmark`
            },
            {
                id: "bg-image",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/template-17--bg.jpg"),
                    alt: "Background Image",
                    style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        inset: 0,
                    }
                }
            },
            {
                id: "decor1",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/bg-shapes-2.svg").default,
                    alt: "Background Image",
                    style: {
                        zIndex: -1,
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        maxHeight: '100%',
                        height: '175px',
                        pointerEvents: 'none',
                        filter: `brightness(0) saturate(100%) invert(100%) sepia(0%) 
                        saturate(7465%) hue-rotate(57deg) brightness(103%) contrast(103%)`
                    }
                }
            }

        ]
    },
    {
        templateId: "t6",
        blocks: [
            {
                id: "card-editor",
                tagName: "div",
                attributes: {
                    style: {
                        display: "flex",
                        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 73.75%)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }
                },
            },
            {
                id: "column1",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "flex-end"
                    }
                },
            },
            {
                id: "column2",
                tagName: "div",
                appendTo: "card-editor",
                attributes: {
                    style: {
                        padding: "25px 30px",
                        gap: "16px",
                        height: "100%",
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }
                },
            },
            {
                id: "p1",
                tagName: "p",
                appendTo: "column2",
                attributes: {
                    style: {
                        fontSize: "80px",
                        lineHeight: "70px",
                        fontWeight: "400",
                        letterSpacing: "-3px",
                        fontFamily: "'League Gothic', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff"
                    }
                },
                content: `Dave <br/> Camarillo`
            },
            {
                id: "p2",
                tagName: "p",
                appendTo: "column2",
                attributes: {
                    style: {
                        fontSize: "48px",
                        lineHeight: "67px",
                        fontWeight: "400",
                        fontFamily: "'League Gothic', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        backgroundColor: "#F20018",
                        color: "#ffffff"
                    }
                },
                content: `22-25 Oct`
            },
            {
                id: "block1",
                tagName: "div",
                appendTo: "column2",
                attributes: {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        paddingTop: "18px",
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        paddingBottom: "18px",
                        fontSize: "23px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        fontFamily: "'League Gothic', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff",
                        outline: "3px solid #F20018"
                    }
                },
            },
            {
                id: "p3",
                tagName: "p",
                appendTo: "block1",
                attributes: {
                    style: {
                        fontSize: "23px",
                        lineHeight: "28px",
                        fontWeight: "400",
                        fontFamily: "'League Gothic', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff",
                    }
                },
                content: `Grapple Mastery – Advanced Techniques and Strategies in BJJ`
            },
            // {
            //     id: "p4",
            //     tagName: "p",
            //     appendTo: "block1",
            //     attributes: {
            //         style: {
            //             fontSize: "23px",
            //             lineHeight: "20px",
            //             fontWeight: "400",
            //             fontFamily: "'League Gothic', sans-serif",
            //             textAlign: "center",
            //             textTransform: "uppercase",
            //             color: "#ffffff",
            //         }
            //     },
            //     content: `$120.00 / ticket`
            // },
            {
                id: "p5",
                tagName: "p",
                appendTo: "block1",
                attributes: {
                    style: {
                        fontSize: "23px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        fontFamily: "'League Gothic', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff",
                    }
                },
                content: `Gi Style`
            },
            {
                id: "p6",
                tagName: "p",
                appendTo: "block1",
                attributes: {
                    style: {
                        fontSize: "23px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        fontFamily: "'League Gothic', sans-serif",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#ffffff",
                    }
                },
                content: `Copenhagen, Denmark`
            },
            {
                id: "bg-image",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/template-12--bg.jpg"),
                    alt: "Background Image",
                    style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        inset: 0,
                    }
                }
            },

        ]
    },
    {
        "templateId": "template7",
        "blocks": [
            {
                "id": "card-editor",
                "tagName": "div",
                "attributes": {
                    "style": {
                        "display": "flex",
                        "backgroundImage": "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #202938 63.75%)",
                        "backgroundPosition": "center center",
                        "backgroundSize": "cover",
                        "position": "relative"
                    }
                }
            },
            {
                "id": "column1",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "16px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "flex-end",
                    }
                }
            },
            {
                "id": "column2",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "30px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        alignItems: "flex-start",
                        justifyContent: 'center'
                    }
                }
            },
            {
                "id": "p1",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "80px",
                        "lineHeight": "70px",
                        "fontWeight": "400",
                        "fontFamily": "'Teko', sans-serif",
                        "textAlign": "start",
                        "textTransform": "uppercase",
                        "color": "#ffffff"
                    }
                },
                "content": "Dave <br/> Camarillo"
            },
            {
                "id": "p2",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "23px",
                        "lineHeight": "20px",
                        "fontWeight": "300",
                        "fontFamily": "'Teko', sans-serif",
                        "textAlign": "start",
                        "textTransform": "uppercase",
                        "color": "#ffffff"
                    }
                },
                "content": "Grapple Mastery – Advanced <br /> Techniques and Strategies in BJJ"
            },
            {
                "id": "block1",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "display": "flex",
                        "flexDirection": "column",
                        alignItems: "flex-start",
                        "gap": "19px",
                        "fontSize": "23px",
                        "lineHeight": "20px",
                        "fontWeight": "400",
                        "fontFamily": "'Teko', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                    }
                }
            },
            {
                "id": "p3",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "23px",
                        "lineHeight": "20px",
                        "fontWeight": "500",
                        "fontFamily": "'Teko', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#000000",
                        "backgroundColor": "#FDDD62",
                        paddingTop: "6px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        paddingBottom: "6px",
                        borderRadius: "6px",
                    }
                },
                "content": "22-25 October"
            },
            // {
            //     "id": "p4",
            //     "tagName": "p",
            //     "appendTo": "block1",
            //     "attributes": {
            //         "style": {
            //             "fontSize": "23px",
            //             "lineHeight": "20px",
            //             "fontWeight": "300",
            //             "fontFamily": "'Teko', sans-serif",
            //             "textAlign": "center",
            //             "textTransform": "uppercase",
            //             "color": "#ffffff"
            //         }
            //     },
            //     "content": "$120.00 / ticket"
            // },
            {
                "id": "p5",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "23px",
                        "lineHeight": "20px",
                        "fontWeight": "300",
                        "fontFamily": "'Teko', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#ffffff"
                    }
                },
                "content": "Gi Style"
            },
            {
                "id": "p6",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "23px",
                        "lineHeight": "20px",
                        "fontWeight": "500",
                        "fontFamily": "'Teko', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "backgroundColor": "#000000",
                        borderRadius: "12px",
                        "color": "#FDDD62",
                        paddingTop: "6px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        paddingBottom: "6px",
                        borderRadius: "6px",
                    }
                },
                "content": "Copenhagen, Denmark"
            },
            {
                "id": "bg-image",
                "tagName": "img",
                "appendTo": "card-editor",
                "attributes": {
                    "src": require('../../../assets/img/template-13--bg.png'),
                    "alt": "Background Image",
                    "style": {
                        "width": "100%",
                        "height": "100%",
                        "position": "absolute",
                        "zIndex": -1,
                        "inset": 0
                    }
                }
            },
            {
                id: "decor2",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/t7-decor-2.svg").default,
                    alt: "decor",
                    style: {
                        width: "200px",
                        height: "100px",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        pointerEvents: "none",
                    }
                }
            },
            {
                id: "decor1",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/t7-decor-1.svg").default,
                    alt: "decor",
                    style: {
                        width: "70px",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        right: "-20px",
                        height: "100%",
                        pointerEvents: "none",
                    }
                }
            },
        ]
    },
    {
        "templateId": "template8",
        "blocks": [
            {
                "id": "card-editor",
                "tagName": "div",
                "attributes": {
                    "style": {
                        "display": "flex",
                        "backgroundImage": "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #14214E 63.75%)",
                        "backgroundPosition": "center center",
                        "backgroundSize": "cover",
                        "position": "relative"
                    }
                }
            },
            {
                "id": "column1",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "16px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "flex-end"
                    }
                }
            },
            {
                "id": "column2",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "40px 30px",
                        "gap": "16px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "space-between"
                    }
                }
            },
            {
                "id": "block1",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "flex": 1,
                    }
                }
            },
            {
                "id": "p1",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "100px",
                        "lineHeight": "80px",
                        "fontWeight": "400",
                        "fontFamily": "'Passion One', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#71CFED"
                    }
                },
                "content": "Dave"
            },
            {
                "id": "p2",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "46px",
                        "lineHeight": "20px",
                        "fontWeight": "400",
                        "fontFamily": "'Passion One', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#71CFED"
                    }
                },
                "content": "Camarillo"
            },
            {
                "id": "spacer1",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "backgroundColor": "#5366A0",
                        width: "100%",
                        height: "6px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                "id": "p3",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "19px",
                        "lineHeight": "19px",
                        "fontWeight": "700",
                        "fontFamily": "'Open Sans Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#ffffff"
                    }
                },
                "content": "Grapple Mastery – <br /> Advanced Techniques and <br /> Strategies in BJJ"
            },
            {
                "id": "spacer2",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "backgroundColor": "#5366A0",
                        width: "100%",
                        height: "6px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                "id": "block2",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "flex": 1,
                    }
                }
            },
            {
                "id": "p4",
                "tagName": "p",
                "appendTo": "block2",
                "attributes": {
                    "style": {
                        "fontSize": "40px",
                        "lineHeight": "28px",
                        "fontWeight": "700",
                        "fontFamily": "'Open Sans Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#858DBC"
                    }
                },
                "content": "22-25 October"
            },
            // {
            //     "id": "p5",
            //     "tagName": "p",
            //     "appendTo": "block2",
            //     "attributes": {
            //         "style": {
            //             "fontSize": "24px",
            //             "lineHeight": "24px",
            //             "fontWeight": "700",
            //             "fontFamily": "'Open Sans Condensed', sans-serif",
            //             "textAlign": "center",
            //             "textTransform": "uppercase",
            //             "color": "#858DBC"
            //         }
            //     },
            //     "content": "12:00 - 3:00Pm"
            // },
            {
                "id": "spacer3",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "backgroundColor": "#5366A0",
                        width: "100%",
                        height: "6px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                "id": "p6",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "30px",
                        "lineHeight": "28px",
                        "fontWeight": "700",
                        "fontFamily": "'Open Sans Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#858DBC"
                    }
                },
                "content": "GI Style"
            },
            {
                "id": "spacer4",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "backgroundColor": "#5366A0",
                        width: "100%",
                        height: "6px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }
                },
            },
            {
                "id": "p7",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "20px",
                        "fontWeight": "700",
                        "fontFamily": "'Open Sans Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#ffffff"
                    }
                },
                "content": "&nbsp; Copenhagen, Denmark &nbsp;"
            },
            {
                "id": "bg-image",
                "tagName": "img",
                "appendTo": "card-editor",
                "attributes": {
                    "src": require('../../../assets/img/template-13--bg.png'),
                    "alt": "Background Image",
                    "style": {
                        "width": "100%",
                        "height": "100%",
                        "position": "absolute",
                        "zIndex": -1,
                        "inset": 0
                    }
                }
            },
            {
                id: "decor1",
                tagName: "img",
                appendTo: "card-editor",
                attributes: {
                    src: require("../../../assets/img/bubbles.svg").default,
                    alt: "Background Image",
                    style: {
                        width: '120px',
                        height: '100%',
                        position: 'absolute',
                        top: '0px',
                        left: '50%',
                        pointerEvents: 'none',
                        transform: 'translateX(-50%)'
                    }
                }
            }
        ]
    },
    {
        "templateId": "template9",
        "blocks": [
            {
                "id": "card-editor",
                "tagName": "div",
                "attributes": {
                    "style": {
                        "display": "flex",
                        "backgroundImage": "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 63.75%)",
                        "backgroundPosition": "center center",
                        "backgroundSize": "cover",
                        "position": "relative"
                    }
                }
            },
            {
                "id": "column1",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "16px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "flex-end"
                    }
                }
            },
            {
                "id": "column2",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "30px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "space-between"
                    }
                }
            },
            {
                "id": "p1",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "68px",
                        "lineHeight": "55px",
                        "fontWeight": "500",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "left",
                        "textTransform": "uppercase",
                        "color": "#970614"
                    }
                },
                "content": "Dave Camarillo"
            },
            {
                id: "decor1",
                tagName: "img",
                appendTo: "column2",
                attributes: {
                    src: require("../../../assets/img/decor.svg").default,
                    alt: "Background Image",
                    style: {
                        width: '100%',
                        height: '20px',
                    }
                }
            },
            {
                "id": "p2",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "26px",
                        "lineHeight": "26px",
                        "fontWeight": "400",
                        letterSpacing: "-1px",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "start",
                        "textTransform": "uppercase",
                        "color": "#696969"
                    }
                },
                "content": "Grapple Mastery – <br /> Advanced Techniques and <br /> Strategies in BJJ"
            },
            {
                "id": "block1",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                    }
                }
            },
            {
                "id": "p3",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "40px",
                        "lineHeight": "28px",
                        "fontWeight": "600",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#970614"
                    }
                },
                "content": "22-25 October",
            },
            {
                "id": "p3_1",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "26px",
                        "lineHeight": "30px",
                        "fontWeight": "300",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "060002"
                    }
                },
                "content": "12:00 - 3:00Pm",
            },
            {
                "id": "block2",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                    }
                }
            },
            {
                "id": "p4",
                "tagName": "p",
                "appendTo": "block2",
                "attributes": {
                    "style": {
                        "fontSize": "40px",
                        "lineHeight": "28px",
                        "fontWeight": "600",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#970614"
                    }
                },
                "content": "GI Style"
            },
            {
                "id": "p4_1",
                "tagName": "p",
                "appendTo": "block2",
                "attributes": {
                    "style": {
                        "fontSize": "26px",
                        "lineHeight": "30px",
                        "fontWeight": "300",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "center",
                        "textTransform": "uppercase",
                        "color": "#696969"
                    }
                },
                "content": "&nbsp; Copenhagen, Denmark &nbsp;"
            },
            {
                "id": "bg-image",
                "tagName": "img",
                "appendTo": "card-editor",
                "attributes": {
                    "src": require('../../../assets/img/template-15--bg.png'),
                    "alt": "Background Image",
                    "style": {
                        "width": "100%",
                        "height": "100%",
                        "position": "absolute",
                        "zIndex": -1,
                        "inset": 0
                    }
                }
            }
        ]
    },
    {
        "templateId": "template10",
        "blocks": [
            {
                "id": "card-editor",
                "tagName": "div",
                "attributes": {
                    "style": {
                        "display": "flex",
                        "backgroundImage": "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 63.75%)",
                        "backgroundPosition": "center center",
                        "backgroundSize": "cover",
                        "position": "relative"
                    }
                }
            },
            {
                "id": "column1",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "16px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "flex-end"
                    }
                }
            },
            {
                "id": "column2",
                "tagName": "div",
                "appendTo": "card-editor",
                "attributes": {
                    "style": {
                        "padding": "25px 30px",
                        "gap": "16px",
                        "height": "100%",
                        "display": "flex",
                        "flex": 1,
                        "flexDirection": "column",
                        "justifyContent": "space-between"
                    }
                }
            },
            {
                "id": "block1",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                    }
                }
            },
            {
                "id": "p1",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "68px",
                        "lineHeight": "55px",
                        "fontWeight": "500",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "left",
                        "textTransform": "uppercase",
                        "color": "#F20018",
                        "height": "85px"
                    }
                },
                "content": "Dave"
            },
            {
                "id": "p2",
                "tagName": "p",
                "appendTo": "block1",
                "attributes": {
                    "style": {
                        "fontSize": "50px",
                        "fontFamily": "'Oooh Baby', cursive",
                        "textTransform": "none",
                        "color": "#fff",
                        "transform": "rotate(-3deg) translate(20px, -20px)",
                        "display": "inline-block",
                        "lineHeight": "16px"
                    }
                },
                "content": "Camarillo"
            },
            {
                "id": "block2",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                    }
                }
            },
            {
                "id": "p3",
                "tagName": "p",
                "appendTo": "block2",
                "attributes": {
                    "style": {
                        "fontSize": "40px",
                        "lineHeight": "42px",
                        "fontWeight": "500",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "left",
                        "textTransform": "uppercase",
                        "color": "#FFFFFF"
                    }
                },
                "content": "22-25 October"
            },
            // {
            //     "id": "p3_1",
            //     "tagName": "p",
            //     "appendTo": "block2",
            //     "attributes": {
            //         "style": {
            //             "fontSize": "40px",
            //             "lineHeight": "42px",
            //             "fontWeight": "400",
            //             "fontFamily": "'Saira Condensed', sans-serif",
            //             "textAlign": "left",
            //             "textTransform": "uppercase",
            //             "color": "#FFFFFF"
            //         }
            //     },
            //     "content": "12:00 - 3:00Pm"
            // },
            {
                "id": "p4",
                "tagName": "p",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                        "fontSize": "26px",
                        "lineHeight": "26px",
                        "fontWeight": "400",
                        letterSpacing: "-1px",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "left",
                        "textTransform": "uppercase",
                        "color": "#FFFFFF"
                    }
                },
                "content": "Grapple Mastery – <br /> Advanced Techniques and <br /> Strategies in BJJ"
            },
            {
                "id": "block3",
                "tagName": "div",
                "appendTo": "column2",
                "attributes": {
                    "style": {
                    }
                }
            },
            {
                "id": "p5",
                "tagName": "p",
                "appendTo": "block3",
                "attributes": {
                    "style": {
                        "fontSize": "40px",
                        "lineHeight": "40px",
                        "fontWeight": "500",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "left",
                        "textTransform": "uppercase",
                        "color": "#F20018"
                    }
                },
                "content": "GI Style"
            },
            {
                "id": "p6",
                "tagName": "p",
                "appendTo": "block3",
                "attributes": {
                    "style": {
                        "fontSize": "20px",
                        "fontWeight": "400",
                        "fontFamily": "'Saira Condensed', sans-serif",
                        "textAlign": "left",
                        "textTransform": "uppercase",
                        "color": "#fff"
                    }
                },
                "content": "Copenhagen, Denmark"
            },
            {
                "id": "bg-image",
                "tagName": "img",
                "appendTo": "card-editor",
                "attributes": {
                    "src": require('../../../assets/img/template-13--bg.png'),
                    "alt": "Background Image",
                    "style": {
                        "width": "100%",
                        "height": "100%",
                        "position": "absolute",
                        "zIndex": -1,
                        "inset": 0
                    }
                }
            }
        ]
    }
]

