import React from 'react'

const CheckboxSwitch = ({ text, checked = true, textClassName = "", ...props }) => {
    return (
        <label className="switch-btn">
            <div className="switch-group">
                <input type="checkbox" checked={checked} hidden={true} {...props} />
                <div className="switcher">
                    <span className="switch-status">
                        <span className="switch-status-rounded"></span>
                    </span>
                </div>
            </div>
            {text && <p className={`switch-text ${textClassName}`}>{text}</p>}
        </label>
    )
}

export default CheckboxSwitch
