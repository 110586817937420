import { useEffect, useState } from 'react';
import Input from '../../../components/ui/formElements/Input';
import moment from 'moment';
import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";
import Button from '../../../components/ui/buttons/Button';
import TimePicker from '../../../components/ui/formElements/TimePicker';
import { deepCopy } from '../../../helpers/utils';


const convertTimeToDate = (date, time) => {
    if (typeof time !== "string" || !time.length) return null;
    const [hours, minutes] = time.split(":");
    if (!hours || !minutes) return null;
    return moment(date).set({ "hours": +hours, "minutes": +minutes }).toDate()
};

const formatEvents = (data) => {
    return data
}

const ScheduleDates = ({ startAt, endAt, data, handleChange, errors, isErrorsShown, handleInit }) => {
    const [events, setEvents] = useState(formatEvents(data))

    const generateDateRange = (startAt, endAt) => {
        const startDate = new Date(new Date(startAt).setHours(0, 0, 0));
        const endDate = new Date(new Date(endAt).setHours(0, 0, 0));
        const dateArray = [];

        for (let dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
            dateArray.push(new Date(dt).toISOString());
        }
        return dateArray;
    };

    const dates = generateDateRange(startAt, endAt);

    useEffect(() => {
        if (!data || data?.length !== dates?.length) {
            const initialData = dates.map(() => [{ time: '', name: '' }]);
            handleInit(initialData);
        }
    }, [dates, data]);

    const handleEventAdd = (dayIndex) => {
        const newEvent = { time: '', name: '' };
        const updatedData = deepCopy(data);
        if (!updatedData[dayIndex]) {
            updatedData[dayIndex] = [];
        }
        updatedData[dayIndex].push(newEvent);

        handleChange(updatedData);
    };

    const handleEventDelete = (dayIndex, eventIndex) => {
        const updatedData = deepCopy(data);
        if (updatedData[dayIndex].length > 1) {
            updatedData[dayIndex].splice(eventIndex, 1);
            handleChange(updatedData);
        }
    };

    const handleEventChange = (dayIndex, eventIndex, field, value) => {
        const updatedData = deepCopy(data);
        updatedData[dayIndex][eventIndex][field] = value;
        handleChange(updatedData);
    };



    useEffect(() => {
        setEvents(formatEvents(data))
    }, [data])
    return (
        <>
            {
                dates.map((date, dayIndex) => (
                    <div className="camp-schedule" key={dayIndex}>
                        <div className="camp-schedule__date">
                            <span className="camp-schedule__number-day">Day {dayIndex + 1}</span>
                            <span className="camp-schedule__day">{moment(date).format("MMM D")}</span>
                        </div>

                        <div className="row g-16 mb-24">
                            {events?.[dayIndex] && events[dayIndex].map((event, eventIndex) => (
                                <div className="col-12" key={eventIndex}>
                                    <div className="add-widget--item row gutters-form">
                                        <div className="add-widget-control">
                                            <TimePicker
                                                wrapperClassName='w-full'
                                                placeholderText="Select Time"
                                                selected={convertTimeToDate(date, event?.time)}
                                                onChange={(time) => handleEventChange(dayIndex, eventIndex, 'time', moment(time).format("hh:mm"))}
                                                error={isErrorsShown && errors[dayIndex]?.[eventIndex]?.time}
                                            />
                                        </div>
                                        <div className="add-widget-control">
                                            <Input
                                                wrapperClasses={"input--lg"}
                                                inputClasses={"input--solid"}
                                                value={event.name}
                                                onChange={(e) => handleEventChange(dayIndex, eventIndex, 'name', e.target.value)}
                                                placeholder={"Type event..."}
                                                error={isErrorsShown && errors[dayIndex]?.[eventIndex]?.name}
                                            />
                                        </div>
                                        <div className="add-widget-control tw-actions">
                                            {
                                                eventIndex > 0 ?
                                                    <Button
                                                        onClick={() => handleEventDelete(dayIndex, eventIndex)}
                                                        className="btn btn--outline-default btn--square btn--md rounded-full"
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                    :
                                                    <Button
                                                        onClick={() => handleEventAdd(dayIndex)}
                                                        className="btn btn--outline-default btn--square btn--md rounded-full"
                                                    >
                                                        <PlusIcon />
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        </>
    );
};


export default ScheduleDates