import { useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";
import Button from '../components/ui/buttons/Button';
import { getImageSrc } from '../helpers/utils';
import { getUser } from "../redux/slices/applicationSlice";
import { addUnreadMessage, changeStatus, setNewMessage, setUnreadMessages, upChatToTop, updateChat } from "../redux/slices/chatsSlice";
import { setUnreadNotificationsCount } from '../redux/slices/notificationsSlice';
import {
    getChatSocket,
    setOnlineOneUser,
    setOnlineUsers,
    setSocket,
    setUserOffline
} from "../redux/slices/socketChatSlice";
import { getAllChatsFetch } from "../redux/thunks/chatsThunks";
import { readNotificationsFetch } from '../redux/thunks/notificationsThunks';
import { ReactComponent as ChevronNextIcon } from '../assets/img/ico-chevron--next.svg'


const SocketProvider = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const chatSocket = useSelector(getChatSocket)

    useEffect(() => {
        const token = localStorage.getItem('authToken')

        const req = async () => {
            const socket = io('https://bjj.webstaginghub.com', {
                path: '/socket',
                transports: ['websocket'],
                auth: {
                    token
                }
            });

            dispatch(getAllChatsFetch())

            dispatch(setSocket(socket))

            socket.on('chat:newMessage', data => {
                dispatch(setNewMessage(data))
            });

            socket.on('chat:newMessage', data => {
                dispatch(upChatToTop(data))
            });

            socket.on('chat:onlineChatUsers', data => {
                dispatch(setOnlineUsers(data))
            });

            socket.on('chat:userOffline', data => {
                dispatch(setUserOffline(data))
            });

            socket.on('chat:userOnline', data => {
                dispatch(setOnlineOneUser(data))
            });

            socket.on('chat:unreadMessagesCount', data => {
                dispatch(setUnreadMessages(data))
            });

            socket.on('chat:readedMessages', data => {
                dispatch(addUnreadMessage(data))
            });

            socket.on('chat:eventUpdate', data => {
                dispatch(changeStatus(data))
            });
            socket.on('chat:updated', data => {
                dispatch(updateChat(data))
            });

            socket.on('notifications:unreaded', data => {
                dispatch(setUnreadNotificationsCount(data))
            });

            socket.on('notifications:new', data => {
                dispatch(setUnreadNotificationsCount(data.length))

                const handleRead = (id, instructorId) => {
                    navigate(`/instructors/${instructorId}`)
                    dispatch(readNotificationsFetch({ ids: [id] }))
                }
                if (data.length) {
                    data.forEach(item => {
                        const content = (
                            <div className='col-group gap--sm'>
                                <div className='row-group gap--sm'>
                                    <div className='image-wrapper avatar'>
                                        <img src={getImageSrc(item?.payload?.photo)} alt="instructor" />
                                    </div>
                                    <h6>{item?.payload?.fullName}</h6>
                                </div>
                                <p className='color-text'>
                                    {item.message}
                                </p>
                                <Button className={"btn--primary btn--sm"} onClick={() => handleRead(item.id, item?.payload?.instructorId)}>
                                    <span className="info">See Profile</span>
                                    <span className="ico ico--20">
                                        <ChevronNextIcon />
                                    </span>
                                </Button>
                            </div>
                        )
                        toast(content, {
                            hideProgressBar: true
                        })
                    })
                }
            });
        }

        if (token && !chatSocket) {
            req()
        }

    }, [user?.id]);


    return (
        <Outlet />
    )
}

export default SocketProvider