import moment from "moment"
import { Link, useOutletContext } from "react-router-dom"
import { teachingStyles, trainingStyle } from "../../../constants"
import { convertDatesToTimeSlot, convertObjectToFormData, uuidv4 } from "../../../helpers/utils"
import { CardDetails } from "./CardDetails"
import { Step } from "./Step"
import DropDown from "../../../components/ui/DropDown"
import TimePicker from "../../../components/ui/formElements/TimePicker"
import {ReactComponent as DoneIcon} from '../../../assets/img/done.svg'
import DatePicker from "../../../components/ui/formElements/DatePicker"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSeminarFormData } from "../../../redux/slices/planningSlice"
import { convertDatesToFormData } from "../../planning/parts/EventDatesSelect"
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks"

const TimeSlot = ({field, seminarId, timeSlot, index, handleChangeTimeSlot, isErrorsShown, events}) => {
    const [isOpen, setIsOpen] = useState(false)
    console.log(isOpen)
    const dispatch = useDispatch()
    const handleCheck = async () => {
        setIsOpen(false)
        console.log(convertDatesToFormData(events))
        const body = new FormData()
        convertObjectToFormData({
            [field]: convertDatesToFormData(events)
        }, body, false)
        console.log(body)
        const res = await dispatch(updateSeminarFetch({ seminarId, body })).unwrap()
        console.log(res)
    }
return(
    <p key={index} className='color-text'>
    {
        timeSlot?.startTime && timeSlot?.endTime && !isOpen ?
    `${moment(timeSlot.startTime).format("hh:mm")} - ${moment(timeSlot.endTime).format("hh:mm")}`
    :
    <DropDown
        labelButton={<button onClick={() => setIsOpen(!isOpen)} className="fw-500 text-14 text-normal style-normal color-secondary">Set time</button>}
        position='left'
        dropDownListClasses={'time-input-menu'}
    >
        <div className="schedule-group-item__time-group" key={index}>
            <div className="time-input-box-wrapper">
                <TimePicker
                    wrapperClassName="w-88"
                    placeholderText="0:00pm"
                    selected={timeSlot?.startTime}
                    error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.startTime}
                    onChange={time => handleChangeTimeSlot(index, "startTime", time)}

                />
                <span className="input-delimiter"></span>
                <TimePicker
                    wrapperClassName="w-88"
                    placeholderText="0:00pm"
                    selected={timeSlot?.endTime}
                    error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.endTime}
                    onChange={time => handleChangeTimeSlot(index, "endTime", time)}
                />
                <button className="done-button" onClick={handleCheck}>
                    <DoneIcon />
                </button>
                {/* {
                    index === 0 ?
                        <button type='button' onClick={handleAddTimeSlot} className="btn btn--outline-default btn--square btn--md rounded-full">
                            <PlusIcon />
                        </button>
                        :
                        <button type='button' onClick={() => handleRemoveTimeSlot(index)} className="btn btn--outline-default btn--square btn--md rounded-full">
                            <DeleteIcon />
                        </button>
                } */}
            </div>
        </div>
    </DropDown>
    }
    </p>
)}

const EventDateTimeInput = ({ field, seminarId, event, handleChange, excludeDates, children, eventId, errors, isErrorsShown, events }) => {
    const [date, setDate] = useState(event?.date)

    const [timeSlots, setTimeSlots] = useState([
        ...event.timeSlots
    ])

    const handleAddTimeSlot = () => {
        const timeSlot = { startTime: '', endTime: '', date }
        setTimeSlots(prev => ([...prev, timeSlot]))
    };

    const handleRemoveTimeSlot = (index) => {
        const newTimeSlots = timeSlots.filter((_, i) => index !== i);
        setTimeSlots(newTimeSlots);
        const data = {
            date,
            timeSlots: newTimeSlots
        }
        handleChange && handleChange(eventId, data)

    };

    const handleChangeTimeSlot = (index, key, value) => {
        const newEvents = timeSlots.map((timeSlot, i) => {
            if (i === index) {
                return {
                    ...timeSlot,
                    [key]: value,
                }
            } else {
                return timeSlot
            }
        })
        setTimeSlots(newEvents)
        const data = {
            ...event,
            date,
            timeSlots: newEvents
        }
        handleChange && handleChange(eventId, data)
    }

    const handleChangeDate = (date) => {
        const data = {
            ...event,
            date: date,
            timeSlots
        }
        setDate(date)
        handleChange && handleChange(eventId, data)
    }
    const [open, setOpen] = useState(false)
    return (
        <div className="flex justify-between">
             <p className='fw-500'>{
                date
                    ? moment(date).format("DD MMM, YYYY")
                    : <>
                        <button onClick={() => setOpen(!open)} className="fw-500 text-14 color-secondary">Set date</button>
                        <DatePicker
                            open={open}
                            inputClassName="hidden-input"
                            placeholderText="Select Event Date"
                            selected={date}
                            onChange={date => handleChangeDate(date)}
                            excludeDates={excludeDates}
                            error={isErrorsShown && errors?.date}
                            minDate={moment().toDate()}
                        />
                    </>
                    }
            </p>
            <div className="col-group gap--md">
                {timeSlots.map((timeSlot, index) => <TimeSlot field={field} seminarId={seminarId} events={events} timeSlot={timeSlot} index={index} handleChangeTimeSlot={handleChangeTimeSlot} isErrorsShown={isErrorsShown}/>
                )}
                <button onClick={handleAddTimeSlot} className="fw-500 text-14 color-secondary">Add Time</button>
            </div>
        </div>
    );
};


export const StepLocationDate = ({ seminarDetails, currentStatus, status, link }) => {
    const instructorCut = () => {
        return seminarDetails.sharedProfit ? +seminarDetails.minimalPriceTicket * (seminarDetails.sharedProfit / 100) : 0
    }
    const academyRevenue = () => {
        return seminarDetails.sharedProfit ? +seminarDetails.minimalPriceTicket * ((100 - seminarDetails.sharedProfit) / 100) : 0
    }
    // const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    // console.log(formData)
    // const [events, setEvents] = useState(convertDatesToTimeSlot(dates));
    
    const [open, setOpen] = useState(false)
    console.log(convertDatesToTimeSlot(seminarDetails.seminarDates))
    // const [formData, setFormData] = useState([]);
    // const formData = useState({
    //     availableDates: []
    // })
    // const dates = formData.availableDates
    const [events, setEvents] = useState([]);
    const [errors, setErrors] = useState([])
    console.log(events)
    const addEvent = () => {
        const newEvent = {
            date: '',
            id: uuidv4(),
            timeSlots: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        };
        const updatedEvents = [...events, newEvent];
        setEvents(updatedEvents);
        // handleChange(convertDatesToFormData(updatedEvents))
    };
    useEffect(() => {
        const events = convertDatesToTimeSlot(seminarDetails?.seminarDates)
        if(events.length >= 1) {
            setEvents(convertDatesToTimeSlot(seminarDetails?.seminarDates))
        } else {
            addEvent()
        }
        
    }, [seminarDetails])
   

    const updateEventById = (eventId, newEvent) => {
        const updatedEvents = events.map((event, index) => {
            if (index === eventId) {
                return newEvent;
            }
            return event;
        });
        setEvents(updatedEvents);
        // handleChange(convertDatesToFormData(updatedEvents))
    };
    

    const [privateEvents, setPrivateEvents] = useState([]);
    const [privateErrors, setPrivateErrors] = useState([])
    console.log(privateEvents)
    const addPrivateEvent = () => {
        const newEvent = {
            date: '',
            id: uuidv4(),
            timeSlots: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        };
        const updatedEvents = [...privateEvents, newEvent];
        setPrivateEvents(updatedEvents);
        // handleChange(convertDatesToFormData(updatedEvents))
    };
    useEffect(() => {
        const events = convertDatesToTimeSlot(seminarDetails?.privateSessionDates)
        console.log(events)
        if(events.length >= 1) {
            setPrivateEvents(convertDatesToTimeSlot(seminarDetails?.privateSessionDates))
        } else {
            addPrivateEvent()
        }
        
    }, [seminarDetails])
   

    const updatePrivateEventById = (eventId, newEvent) => {
        const updatedEvents = privateEvents.map((event, index) => {
            if (index === eventId) {
                return newEvent;
            }
            return event;
        });
        setPrivateEvents(updatedEvents);
        // handleChange(convertDatesToFormData(updatedEvents))
    };
    return (
        <Step currentStatus={currentStatus} status={status} label="Location & Date">
            <CardDetails
                label={"Location"}
                isEdited={seminarDetails?.lastEdited?.includes("address") || seminarDetails?.lastEdited?.includes("location")}
            >
                <p className='color-text'>
                    {[seminarDetails?.location, seminarDetails?.address, seminarDetails?.continent, seminarDetails?.country, seminarDetails?.city].filter(Boolean).join(", ") || "Empty"}
                </p>
            </CardDetails>
            <CardDetails
                label={"Dates & Time"}
                isEdited={seminarDetails?.lastEdited?.includes("seminarDates")}
            >
                {
                        events.map((event, index) => (
                            <EventDateTimeInput
                                event={event}
                                eventId={index}
                                handleChange={updateEventById}
                                // excludeDates={excludeDates}
                                errors={errors?.length ? errors[index] : {}}
                                // isErrorsShown={isErrorsShown}
                                events={events}
                                seminarId={seminarDetails?.id}
                                field='availableDates'
                            >
                                {
                                    events.length > 1 ?
                                        <button type='button' onClick={() => removeEvent(index)} className="btn btn--outline-default btn--square btn--md rounded-full">
                                            {/* <DeleteIcon /> */}
                                        </button>
                                        :
                                        null
                                }
                            </EventDateTimeInput>
                        ))
                       
                }
            </CardDetails>
            <CardDetails
                label={"Private lessons Time"}
                isEdited={seminarDetails?.lastEdited?.includes("privateSessionDates")}
            >
                {
                        privateEvents.map((event, index) => (
                            <EventDateTimeInput
                                event={event}
                                eventId={index}
                                handleChange={updatePrivateEventById}
                                // excludeDates={excludeDates}
                                errors={privateErrors?.length ? privateErrors[index] : {}}
                                // isErrorsShown={isErrorsShown}
                                events={privateEvents}
                                seminarId={seminarDetails?.id}
                                field='privateSessionDates'
                            >
                                {
                                    privateEvents.length > 1 ?
                                        <button type='button' onClick={() => removeEvent(index)} className="btn btn--outline-default btn--square btn--md rounded-full">
                                            {/* <DeleteIcon /> */}
                                        </button>
                                        :
                                        null
                                }
                            </EventDateTimeInput>
                        ))
                       
                }
            </CardDetails>

        </Step>
    )
}