import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getInstructorsAllFetch = createAsyncThunk(
    '/get-all-instructors/',
    async (query = "", { rejectWithValue }) => {
        try {
            const res = await api.instructors.getInstructorsAll(query);
            return res
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getInstructorInfoFetch = createAsyncThunk(
    '/get-instructor-info/',
    async (payload = null, { rejectWithValue }) => {
        try {
            const res = await api.instructors.getInstructorInfo(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const findInstructorsFetch = createAsyncThunk(
    '/find-instructors/',
    async (q, { rejectWithValue }) => {
        try {
            const res = await api.instructors.findInstructor(q);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
