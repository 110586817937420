import React from 'react'
import HeaderDefault from '../components/headers/HeaderDefault'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'

const SecondaryLayout = () => {
    return (
        <>
            <HeaderDefault className="header--white" />
            <div className="wrapper header-fixed">
                <main className="content">
                    <Outlet />
                </main>
                <Footer />
            </div>
        </>
    )
}

export default SecondaryLayout