import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser } from '../../redux/slices/applicationSlice'
import ModalTemplate from './ModalTemplate'

const PlanningModal = ({ open, handleClose }) => {
    const user = useSelector(getUser)
    
    return (
        <ModalTemplate open={open} onClick={handleClose}>
            <div className="modal__body">
                <form className="form form--lg" method="post">
                    <div className="form-header">
                        <div className="heading-block text-center">
                            <h3 className="heading">Plan seminar or plan Camp?</h3>
                            <p className="text--md desk-heading">
                                Select option that you like
                            </p>
                        </div>
                    </div>
                    <div className="form-body">
                        <div className="row gutters-form">
                            <div className="col-12">
                                <ul className="sign-list">
                                    <li className="sign-list--item">
                                        <Link to={"/plan-seminar"} onClick={handleClose} className="btn btn btn--xl btn-border justify-start text-left">
                                            <div className="poster-check"><img src={require("../../assets/img/bg-plan-seminar.png")} /></div>
                                            <div className="heading-block">
                                                <p className="text--lg poster-text">Plan Seminar</p>
                                                <p className="text-sm poster-deskription">
                                                    Select option if you want to create seminar
                                                </p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="sign-list--item">
                                        <Link to={"/plan-camp"} onClick={handleClose} className="btn btn btn--xl btn-border justify-start text-left">
                                            <div className="poster-check"><img src={require("../../assets/img/bg-plan-camp.png")} /></div>
                                            <div className="heading-block">
                                                <p className="text--lg poster-text">Plan Camp</p>
                                                <p className="text-sm poster-deskription">
                                                    Select option if you want to create camp
                                                </p>
                                            </div>
                                        </Link>
                                    </li>
                                    {
                                        user?.role === "instructor" ?
                                            <li className="sign-list--item">
                                                <Link to={"/plan-seminar?tour=true"} onClick={handleClose} className="btn btn btn--xl btn-border justify-start text-left">
                                                    <div className="poster-check"><img src={require("../../assets/img/bg-plan-tour.png")} /></div>
                                                    <div className="heading-block">
                                                        <p className="text--lg poster-text">Plan Tour</p>
                                                        <p className="text-sm poster-deskription">
                                                            Select option if you want to create a tour
                                                        </p>
                                                    </div>
                                                </Link>
                                            </li>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    )
}

export default PlanningModal