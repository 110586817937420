import React, { forwardRef } from 'react'

const Input = forwardRef(({ label, placeholder, defaultValue, type = 'text', inputClasses, wrapperClasses, value, error, ...props }, ref) => {
    return (
        <div className={`form-group ${wrapperClasses}`}>
            {label && <label className="label">{label}</label>}
            <div className='input-wrapper'>
                <input
                    className={`input ${error ? "input--error" : ""} ${inputClasses}`}
                    type={type}
                    placeholder={placeholder}
                    value={value || ""}
                    defaultValue={defaultValue}
                    ref={ref}
                    {...props}
                />
            </div>
            {error && <p className='error-input'>{error}</p>}
        </div>
    )
})

export default Input
