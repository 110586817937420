import React from 'react'
import { PaginationTemplate } from '../../../components/parts/PaginationTemplate'

const SeminarOverviewRequsts = () => {
    return (
        <div className="form-item">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="tabs tab-seminars">
                        <div className="tabs__header flex items-center gap-24 pb-24">
                            <h6 className="heading">Requests:</h6>
                        </div>
                        <div className="tabs__body">

                            <div className="wrapp-table">
                                <div className="request-items">
                                    <div className="request-item">
                                        <div className="request-item__user">
                                            <div className="ico ico--md img-wrapper">
                                                <img src={require("../../../assets/img/user-avatar-1.png")} alt="" />
                                            </div>
                                            <div className="col-group gap-4">
                                                <h6 className="heading">Wade Warren</h6>
                                                <p className="color-text">25 Feb - 04 Mar, 2024</p>
                                            </div>
                                        </div>
                                        <div className="request-item__details">
                                            <div className="col-group gap--xs">
                                                <span className="label">Total Price</span>
                                                <p className="fw-500 text--sm">$1,300</p>
                                            </div>
                                            <div className="col-group gap--xs">
                                                <span className="label">People</span>
                                                <p className="fw-500 text--sm">1</p>
                                            </div>
                                            <div className="col-group gap--xs">
                                                <span className="label">teaching</span>
                                                <p className="fw-500 text--sm">NoGi</p>
                                            </div>
                                            <div className="col-group gap--xs">
                                                <span className="label">Location</span>
                                                <p className="fw-500 text--sm">BJJ New Gym</p>
                                            </div>
                                        </div>
                                        <div className="request-item__action">
                                            <a href="#" className="btn btn--sm btn--square Linejoin=notification" count="1">
                                                <span className="ico">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_728_13209)">
                                                            <path d="M8.25 9.625H13.75" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M8.25 12.375H13.75" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M6.86918 18.1423C8.60214 19.1453 10.6407 19.4838 12.6048 19.0947C14.5689 18.7056 16.3245 17.6156 17.5442 16.0277C18.764 14.4398 19.3646 12.4626 19.2342 10.4646C19.1038 8.46654 18.2512 6.58415 16.8353 5.16833C15.4195 3.75252 13.5371 2.89991 11.5391 2.76949C9.54111 2.63906 7.56384 3.23971 5.97598 4.45945C4.38812 5.67919 3.29804 7.43477 2.90897 9.39887C2.5199 11.363 2.8584 13.4015 3.86137 15.1345L2.78629 18.3443C2.74589 18.4654 2.74003 18.5954 2.76936 18.7197C2.79869 18.844 2.86205 18.9576 2.95234 19.0479C3.04263 19.1382 3.15629 19.2016 3.28057 19.2309C3.40485 19.2602 3.53484 19.2544 3.65598 19.214L6.86918 18.1423Z" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_728_13209">
                                                                <rect width="22" height="22" fill="white"></rect>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                            <a href="#" className="btn btn--sm">Show Details</a>
                                            <a href="#" className="btn btn--sm btn--primary">Approve</a>
                                            <a href="#" className="btn btn--sm btn--default">Decline</a>
                                        </div>
                                    </div>
                                </div>
                                <PaginationTemplate />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeminarOverviewRequsts
