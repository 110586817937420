import React, { useRef, useState } from 'react'
import Input from '../../components/ui/formElements/Input';
import Button from '../../components/ui/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import InputPass from '../../components/ui/formElements/InputPass';
import api from "../../api";
import { useDispatch } from "react-redux";
import { setToken, } from "../../redux/slices/applicationSlice.js";
import { getUserFetch } from "../../redux/thunks/applicationThunks.js";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

const InstructorSingUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentStep, setCurrentStep] = useState(1);
    
    const test = false;

    const [data, setData] = useState(test ? {
        fullName: "Test Instructor",
        email: "xataxe8317@nweal.com",
        phone: "+321456789123",
        birthday: new Date().toISOString(),
        password: "xataxe8317@nweal.com",
        photo: null
    } : {
        fullName: "",
        email: "",
        phone: "",
        birthday: new Date().toISOString(),
        password: "",
        photo: null
    });

    const [photo64, setPhoto64] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");

    const steps = [
        {
            title: 'WELCOME TO Plan Seminars',
            description: 'Let’s start, tell us a bit about yourself',
            bullet: true
        },
        {
            title: 'when’s your birthday',
            description: 'Tell us how old are you',
            bullet: true
        },
        {
            title: 'Set your password',
            description: 'Create password for your account',
            bullet: true
        },
    ];

    const stepsHandler = () => {
        if(currentStep === 1 && !data.fullName.length){
            toast.error("ACADEMY NAME is required")
            return;
        }

        if(currentStep === 2 && !data.email.length){
            toast.error("EMAIL is required")
            return;
        }

        if(currentStep === 2 && !data.phone.length){
            toast.error("PHONE is required")
            return;
        }

        if (currentStep < steps.length) {
            setCurrentStep(prev => prev + 1)
        }
    }

    const toBase64 = async(file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async(file) => {
        setData({
            ...data,
            photo: file
        });

        let res = await toBase64(file);
        setPhoto64(res);
    }

    const signUp = async() => {
        if(data.password !== confirmPassword){
            toast.error(`"Confirm Password" is not equal to "Password"`);
            return;
        }

        var formData = new FormData();

        Object.entries(data).forEach(item => {
            if(item[0] === "birthday"){
                formData.append(item[0], new Date(item[1]).toISOString());
            } else {
                formData.append(item[0], item[1]);
            }
        });

        let response = await api.auth.signupInstructor(formData);

        if (response?.success) {
            if(response?.token){
                localStorage.setItem('authToken', response.token);

                dispatch(setToken(response.token));
                dispatch(getUserFetch(response.token));

                navigate("/settings/my-profile");
                
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
		} else {
            toast.error(response.message || "Something went wrong");
        }
    }

    return (
        <div className="container">
            <div className="steps mb-36">
                {steps.map((item, index) => (
                    item.bullet && <span key={index} className={`steps__item ${(index + 1) === currentStep ? 'active' : ''}`}></span>
                ))}
            </div>
            {steps.map((item, index) => (
                <React.Fragment key={index}>
                    {(index + 1) === currentStep &&
                        <div className="heading-block text-center mb-36">
                            <h3 className="heading">{item.title}</h3>
                            <p className="desk-heading">{item.description}</p>
                        </div>
                    }
                </React.Fragment>
            ))}
            <form className="form form--md">
                {currentStep === 1 &&
                    <>
                        <label className="form-group input--file">
                            <input type="file" id="fileInput" accept='image/*' onChange={(e)=>{
                                if(e.target.files[0]){
                                    changePhoto(e.target.files[0]);
                                }
                            }} />
                            <div className="preview overflow-hidden">
                                {
                                    data.photo && photo64 ? <img src={photo64} alt="" /> :
                                    <span className="ico ico--sm">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_100_11347)">
                                                <path d="M16 13V21" stroke="#67676C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12 17H20" stroke="#67676C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M10 8L11.7037 5.445C11.795 5.30819 11.9186 5.196 12.0636 5.11838C12.2086 5.04076 12.3705 5.0001 12.535 5H19.465C19.6295 5.0001 19.7914 5.04076 19.9364 5.11838C20.0814 5.196 20.205 5.30819 20.2962 5.445L22 8H26C26.5304 8 27.0391 8.21071 27.4142 8.58579C27.7893 8.96086 28 9.46957 28 10V24C28 24.5304 27.7893 25.0391 27.4142 25.4142C27.0391 25.7893 26.5304 26 26 26H6C5.46957 26 4.96086 25.7893 4.58579 25.4142C4.21071 25.0391 4 24.5304 4 24V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H10Z" stroke="#67676C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_100_11347">
                                                    <rect width="32" height="32" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                }
                            </div>
                            <p className="label">Add a photo</p>
                        </label>
                        <Input
                            wrapperClasses={'form-group input--lg'}
                            inputClasses={'input--solid'}
                            label={'Full Name'}
                            placeholder={'e.g. Jane Smith, Wade Warren...'}
                            value={data.fullName}
                            onInput={(e)=>{
                                setData({
                                    ...data,
                                    fullName: e.target.value
                                })
                            }}
                        />
                        <Input
                            wrapperClasses={'form-group input--lg'}
                            inputClasses={'input--solid'}
                            label={'Email'}
                            placeholder={'Enter your email...'}
                            value={data.email}
                            onInput={(e)=>{
                                setData({
                                    ...data,
                                    email: e.target.value
                                })
                            }}
                        />
                        <Input
                            wrapperClasses={'form-group input--lg'}
                            inputClasses={'input--solid'}
                            label={'Phone'}
                            placeholder={'Enter your phone...'}
                            value={data.phone}
                            onInput={(e)=>{
                                setData({
                                    ...data,
                                    phone: e.target.value
                                })
                            }}
                        />
                    </>
                }
                {currentStep === 2 &&
                    <>
                        <div className="row">
                            <div className="col-md-4">
                                {/* <Input
                                    wrapperClasses={'form-group input--lg'}
                                    inputClasses={'input--solid'}
                                    label={'Day'}
                                    placeholder={'11'}
                                /> */}
                                <div className={`form-group form-group input--lg`}>
                                    <label className="label">Day</label>
                                    <div className="day-datepicker">
                                        <DatePicker 
                                            dateFormat="dd"
                                            className={`input input--solid`} 
                                            selected={data.birthday} 
                                            onChange={(date) => {
                                                setData({
                                                    ...data,
                                                    birthday: date
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className={`form-group form-group input--lg`}>
                                    <label className="label">Month</label>
                                    <div className="month-datepicker">
                                        <DatePicker 
                                            showMonthYearPicker
                                            dateFormat="MM"
                                            className={`input input--solid`} 
                                            selected={data.birthday} 
                                            onChange={(date) => {
                                                setData({
                                                    ...data,
                                                    birthday: date
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className={`form-group form-group input--lg`}>
                                    <label className="label">Year</label>
                                    <div className="month-datepicker">
                                        <DatePicker 
                                            showYearPicker
                                            dateFormat="yyyy"
                                            className={`input input--solid`} 
                                            selected={data.birthday} 
                                            onChange={(date) => {
                                                setData({
                                                    ...data,
                                                    birthday: date
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {currentStep === 3 &&
                    <>
                        
                        
                        <InputPass 
                            formGroupClass={'input--lg input--icon-right'}
                            placeholder={'Password'}
                            inputClass={'input--solid w-full'}
                            forgotPass = {false}
                            label={'Password'}
                            value={data.password}
                            onInput={(e)=>{
                                setData({
                                    ...data,
                                    password: e.target.value
                                })
                            }}
                        />
                        <InputPass 
                            formGroupClass={'input--lg input--icon-right'}
                            placeholder={'Confirm Password'}
                            inputClass={'input--solid w-full'}
                            forgotPass = {false}
                            label={'Confirm Password'}
                            value={confirmPassword}
                            onInput={(e)=>{
                                setConfirmPassword(e.target.value)
                            }}
                        />
                    </>
                }
                {currentStep === steps.length ?
                    <a href="#" onClick={(e)=>{
                        e.preventDefault();
                        signUp();
                    }} className="btn btn--primary btn--lg w-full fs-20 mt-12">
                        <span className="info">Create Account</span>
                    </a>
                    :
                    <Button className="btn btn--primary btn--lg w-full fs-20 mt-12" onClick={stepsHandler}>
                        <span className="info">Continue</span>
                        <span className="ico">
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.25 3.375L12.875 9L7.25 14.625" stroke="currentColor" strokeWidth="2" strokeLinecap="square"></path>
                            </svg>
                        </span>
                    </Button>
                }
            </form>
        </div>
    )
}

export default InstructorSingUp
