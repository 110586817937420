import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from '../../components/ui/PageLoader';
import { getAllChatsFetch, getChatByIdFetch, getChatMessagesFetch } from '../../redux/thunks/chatsThunks';
import { AllChats } from "./parts/AllChats";
import { CurrentChat } from "./parts/CurrentChat";
import { clearCurrentChatData } from "../../redux/slices/chatsSlice";
import DiscussionHelperModal from '../../components/modals/DiscussionHelperModal';

export const Chat = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [isDiscussionHelperModalOpen, setIsDiscussionHelperModalOpen] = useState(true)

    const { chatId } = useParams();
    const {
        isLoading: isChatsLoading,
        isMessagesLoading,
        currentChatData,
        isCurrentChatDataLoading,
        messages,
    } = useSelector(state => state.chats)

    useEffect(() => {
        if (!chatId) return
        dispatch(getChatMessagesFetch({ chatId }))
        dispatch(getChatByIdFetch({ chatId }))
    }, [chatId, navigate])


    useEffect(() => {
        dispatch(getAllChatsFetch())
        return () => {
            dispatch(clearCurrentChatData())
        }
    }, [])


    if (isChatsLoading) return <PageLoader />;

    const hasUserMesssage = messages?.some(msg => !msg.isSystemMessage)
    
    return (
        <div className="chat-page">
            <AllChats />
            <CurrentChat />
            {
                chatId &&
                currentChatData &&
                isDiscussionHelperModalOpen &&
                !isMessagesLoading &&
                messages &&
                !currentChatData?.academyAccept &&
                currentChatData?.seminar?.status === "pending" &&
                !isCurrentChatDataLoading &&
                !hasUserMesssage &&
                <DiscussionHelperModal open={isDiscussionHelperModalOpen} handleClose={() => setIsDiscussionHelperModalOpen(false)} />
            }
        </div>
    )
}
