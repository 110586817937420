import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReactComponent as FaceboockIcon } from '../../assets/img/facebook-grey-icon.svg'
import { ReactComponent as InstagrammIcon } from '../../assets/img/instagramm-grey-icon.svg'
import { ReactComponent as LocationIcon } from '../../assets/img/location-icon-grey.svg'
import { ReactComponent as YoutybeIcon } from '../../assets/img/youtube-grey-icon.svg'
import { ReactComponent as FanaticsIcon } from '../../assets/img/fanatics-icon-grey.svg'

import MapBox from "../../components/parts/mapBox/MapBox"
import SeminarsLockedAt from '../../components/parts/SeminarsLockedAt'
import PageLoader from '../../components/ui/PageLoader'
import { getFullLocation, getImageSrc } from '../../helpers/utils'
import { getAcademyByIdFetch } from '../../redux/thunks/academyGeneralInfoThunks'
import { ReactComponent as VerifiedIcon } from '../../assets/img/icon-verified.svg'
import { Country } from 'country-state-city'
import VideoPlayer from '../../components/parts/cards/VideoPlayer'
import Fancybox from '../../components/ui/Fancybox'


const AcademySingle = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { academy, isLoading } = useSelector(state => state.academyGeneralInfoApp)

    useEffect(() => {
        if (!id) return;
        dispatch(getAcademyByIdFetch(id))
    }, [id])

    if (isLoading) {
        return <PageLoader />
    }

    const academyGeneralInfo = academy?.generalAcademyInfo
    const coordinates = {
        coordinates: [academyGeneralInfo?.longitude, academyGeneralInfo?.latitude]
    }


    if (!academy) {
        return <h1>No found</h1>
    }

    const location = getFullLocation([
        Country.getCountryByCode(academyGeneralInfo.country)?.name || academyGeneralInfo.country,
        academyGeneralInfo.city,
        academyGeneralInfo.addressLine,
    ])

    return (
        <>
            <section className="intro">
                <div className="intro__top img-wrapper">
                    <img src={getImageSrc(academy?.academyProfile?.cover)} alt="" />
                </div>
                <div className="intro__bottom">
                    <div className="container">
                        <div className="intro__content">
                            <div className="intro__image img-wrapper">
                                <img src={getImageSrc(academy?.academyProfile?.photo)}
                                    alt={academy?.academyProfile?.name} />
                            </div>
                            <div className="intro__row">
                                <div className="intro__details">
                                    <div className="row-group gap--xs">
                                        <h3 className="heading">{academy?.academyProfile?.name}</h3>
                                        <span className="ico">
                                            <VerifiedIcon />
                                        </span>
                                    </div>
                                    <ul className="dot-list">
                                        <li>
                                            <div className="row-group gap--xs">
                                                <span className="ico">
                                                    <LocationIcon />
                                                </span>
                                                <span>{location}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="intro__actions">
                                    <div className="intro__socials">
                                        <a href={academyGeneralInfo?.youtubeURL} className="btn btn--md btn--square">
                                            <span className="ico">
                                                <YoutybeIcon />
                                            </span>
                                        </a>
                                        <a href={academyGeneralInfo?.facebookURL} className="btn btn--md btn--square">
                                            <span className="ico">
                                                <FaceboockIcon />
                                            </span>
                                        </a>
                                        <a href={academyGeneralInfo?.instagramURL} className="btn btn--md btn--square">
                                            <span className="ico">
                                                <InstagrammIcon />
                                            </span>
                                        </a>
                                        <a href={academyGeneralInfo?.fanaticsURL} className="btn btn--md btn--square">
                                            <span className="ico">
                                                <FanaticsIcon />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <div className="double-section pb-42 mb-42 pt-42 flex gap-80">
                        <div className="double-section__content">
                            <div className="col-group ">
                                <h4 className="heading mb-32">About Us</h4>
                                <p className="text-description mb-42 border-bottom pb-42">
                                    {academy?.academyProfile?.about || "Not specified"}
                                </p>
                                <h4 className="heading mb-32">Academy Details</h4>

                                <div className="flex flex-col gap-24">
                                    <div className="flex items-center justify-between gap-10 fs-18">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-material-symbols_temple-buddhist.svg").default} alt="buddhist" />
                                            </div>
                                            <span className="color-grey">Year of Foundation:</span>
                                        </div>
                                        <div>{academyGeneralInfo?.yearOfFoundation || "Not specified"}</div>
                                    </div>

                                    <div className="flex items-center justify-between gap-10 fs-18">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-mdi_crown.svg").default} />
                                            </div>
                                            <span className="color-grey">Academy Owner:</span>
                                        </div>
                                        <a href="#" className="color-secondary underline">
                                            {academyGeneralInfo?.academyOwner || "Not specified"}
                                        </a>
                                    </div>

                                    <div className="flex items-center justify-between gap-10 fs-18">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-bxs-user-check.svg").default} />
                                            </div>
                                            <span className="color-grey">Person in Charge:</span>
                                        </div>
                                        <a href="#" className="color-secondary underline">
                                            {academyGeneralInfo?.personInCharge || "Not specified"}
                                        </a>
                                    </div>

                                    <div className="flex items-center justify-between gap-10 fs-18">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-judo.svg").default} alt="judo" />
                                            </div>
                                            <span className="color-grey">Max. Seminar Capacity:</span>
                                        </div>
                                        <p>{academyGeneralInfo?.maxSeminarCapacity || "Not specified"}</p>
                                    </div>

                                    <div className="flex items-center justify-between gap-10 fs-18 pb-42">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-judo-2.svg").default} alt="judo" />
                                            </div>
                                            <span className="color-grey">Number of Students:</span>
                                        </div>
                                        <p>{academyGeneralInfo?.numberOfStudents || "Not specified"}</p>
                                    </div>
                                </div>

                                <hr className="hr-horisontal mb-42" />

                                <h4 className="heading mb-32">Contacts</h4>

                                <div className="flex flex-col gap-24">
                                    <div className="flex items-center justify-between gap-10 fs-18">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-bxs-phone-call.svg").default} />
                                            </div>
                                            <span className="color-grey">Phone Number:</span>
                                        </div>
                                        <p>{academy?.phone || "Not specified"}</p>
                                    </div>

                                    <div className="flex items-center justify-between gap-10 fs-18">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/icon-email-mail.svg").default} />
                                            </div>
                                            <span className="color-grey">Academy’s Email :</span>
                                        </div>
                                        <p>{academy?.email || "Not specified"}</p>
                                    </div>

                                    <div className="flex items-center justify-between gap-10 fs-18 pb-42">
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img src={require("../../assets/img/bx-globe-2.svg").default} alt="globe" />
                                            </div>
                                            <span className="color-grey">Website:</span>
                                        </div>
                                        {
                                            academyGeneralInfo?.website ?
                                                <a href={academyGeneralInfo.website} className="color-secondary">{academyGeneralInfo?.website}</a>
                                                :
                                                <p>Not specified</p>
                                        }
                                    </div>
                                </div>
                                <hr className="hr-horisontal mb-42" />
                            </div>
                            {
                                academy?.generalAcademyInfo?.gallery?.length ?
                                    <Fancybox>
                                        <div className="form-item">
                                            <div className="row gutters-form-3">
                                                <div className="col-12">
                                                    <div className="heading-row">
                                                        <div className="flex-ico-ss-text">
                                                            <h4 className="heading">Gallery</h4>
                                                        </div>
                                                        {
                                                            academy?.generalAcademyInfo?.gallery?.length > 5 ?
                                                                <div className="flex-group">
                                                                    <button
                                                                        className="btn btn--md"
                                                                        type='button'
                                                                        data-fancybox-trigger="gallery"
                                                                        data-fancybox-index='0'
                                                                    >
                                                                        <span>Show All</span>
                                                                    </button>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className='gallery-row'>
                                                        {academy?.generalAcademyInfo?.gallery.slice(0, 5).map((item, index) => (
                                                            <a data-fancybox="gallery" className={`${index > 4 ? 'hidden' : 'gallery-grid'}`} href={getImageSrc(item?.url || item?.file)} key={item.id}>
                                                                <div className='gallery-thumb'>
                                                                    {item?.mimetype?.includes("video") ?
                                                                        <VideoPlayer url={item?.url} />
                                                                        :
                                                                        <img src={getImageSrc(item?.url)} alt="" />
                                                                    }
                                                                </div>
                                                            </a>))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fancybox>
                                    :
                                    null
                            }
                        </div>

                        <aside className="double-section__aside">
                            <h4 className="heading mb-36">Location</h4>
                            <p className="color-grey mb-36">{location}</p>
                            <div style={{ height: '293px' }} className='h-[293px]'>
                                <MapBox mainPoints={[coordinates]} newCenter={coordinates.coordinates} noEvents />
                            </div>
                        </aside>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <hr className="hr-horisontal mt-42" />
                    <h4 className="heading mb-32 pt-42">Affiliations</h4>
                    <ul className="settings-list affiliations border-bottom pb-42 mb-42">
                        {
                            Array.from({ length: 3 }).map((_, index) => (
                                <li className="settings-list--item" key={index}>
                                    <div className="card-bordered card-row items-center">
                                        <div className="logo-44x44">
                                            <img src={require("../../assets/img/logo-44x44.png")} />
                                        </div>
                                        <div className="card-row--item justify-between flex-auto overflow--x-hidden">
                                            <div className="card-row-group overflow--x-hidden">
                                                <div className="heading-block heading-nowrap flex-auto">
                                                    <h6 className="heading">Alliance Jiu Jitsu </h6>
                                                    <p className="desk-heading --sm-heading">
                                                        Los Angeles, California
                                                    </p>
                                                </div>
                                                <div className="flex-group justify-end flex-group--xs flex-nowrap">
                                                    <a href="#" className="btn radius">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g>
                                                                <path d="M9 4.5L16.5 12L9 19.5" stroke="#02020A"
                                                                    strokeWidth="1.4" strokeLinecap="square" />
                                                            </g>
                                                            <defs>
                                                                <clipPath>
                                                                    <rect width="24" height="24" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </section>
            <SeminarsLockedAt
                sectionClasses=''
                sectionTitle={'ACADEMY PLANNED SEMINARS'}
                swiperClasses={'pb-42 mb-42 border-bottom'}
            />
        </>
    )
}

export default AcademySingle
