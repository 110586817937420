import { capitalize } from "lodash"
import { trainingStyle } from "../../../constants"
import { getTeachStyle } from "../../../helpers/utils"
import { CardDetails } from "./CardDetails"
import { Step } from "./Step"


export const StepDetails = ({ seminarDetails, currentStatus, status }) => {

    const teachingStyles = getTeachStyle(seminarDetails.teachingStyles).join(", ")

    const instructorCut = () => {
        return seminarDetails.sharedProfit ? +seminarDetails.minimalPriceTicket * (seminarDetails.sharedProfit / 100) : 0
    }
    const academyRevenue = () => {
        return seminarDetails.sharedProfit ? +seminarDetails.minimalPriceTicket * ((100 - seminarDetails.sharedProfit) / 100) : 0
    }

    return (
        <Step currentStatus={currentStatus} status={status} label="Seminar Details">
            <CardDetails
                label={"Seminar Information"}
                isEdited={
                    seminarDetails?.lastEdited?.includes("languages") ||
                    seminarDetails?.lastEdited?.includes("traningStyle") ||
                    seminarDetails?.lastEdited?.includes("targetAudience") ||
                    seminarDetails?.lastEdited?.includes("maxGymCapacity") ||
                    seminarDetails?.lastEdited?.includes("allowUnder18") ||
                    seminarDetails?.lastEdited?.includes("minAttendance")
                }
            >
                <div className="row-group justify-between">
                    <p className='fw-500'>Language</p>
                    <p className='color-text'>{seminarDetails?.languages?.length ? seminarDetails?.languages[0] : null}</p>
                </div>
                <div className="row-group justify-between">
                    <p className='fw-500'>Gi or NoGi </p>
                    <p className='color-text'>{trainingStyle.find(item => item.key === seminarDetails?.traningStyle)?.title}</p>
                </div>
                <div className="row-group justify-between">
                    <p className='fw-500'>Target Audience</p>
                    <p className='color-text'>{seminarDetails?.targetAudience ? capitalize(seminarDetails?.targetAudience) : "Everyone"}</p>
                </div>
                <div className="row-group justify-between">
                    <p className='fw-500'>Max Gym Capacity</p>
                    <p className='color-text'>{seminarDetails?.maxGymCapacity}</p>
                </div>
                <div className="row-group justify-between">
                    <p className='fw-500'>Under the Age of 18</p>
                    <p className='color-text'>{seminarDetails?.allowUnder18 ? "Allowed" : "Not  Allowed"}</p>
                </div>
                <div className="row-group justify-between">
                    <p className='fw-500'>Minimum Attendance</p>
                    <p className='color-text'>{seminarDetails?.minAttendance}</p>
                </div>
            </CardDetails>
            <CardDetails
                label={"Teaching Styles"}
                isEdited={seminarDetails?.lastEdited?.includes("teachingStyles")}
            >
                {
                    teachingStyles
                        ? <p className="color-text">{teachingStyles}</p>
                        : <p className="color-text mx-auto">No Teaching Styles</p>

                }
            </CardDetails>
            {
                seminarDetails?.sharedProfit ?
                    <CardDetails
                        label={"Price Information"}
                        isEdited={
                            seminarDetails?.lastEdited?.includes("sharedProfit") ||
                            seminarDetails?.lastEdited?.includes("minimalPriceTicket")
                        }

                    >
                        <div className="row-group justify-between">
                            <p className='fw-500'>Shared Profit</p>
                            <p className='color-text'>{seminarDetails?.sharedProfit}</p>
                        </div>
                        <div className="row-group justify-between">
                            <p className='fw-500'>Min Ticket Price</p>
                            <p className='color-text'>{seminarDetails?.minimalPriceTicket}</p>
                        </div>
                        <hr className='hr my-3' />
                        <div className="row-group justify-between">
                            <p className='color-text'>Instructor Cut:</p>
                            <p className='fw-500'>{instructorCut()}</p>
                        </div>
                        <div className="row-group justify-between">
                            <p className='color-text'>Academy Potential Revenue:</p>
                            <p className='fw-500'>{academyRevenue()}</p>
                        </div>
                    </CardDetails>
                    :
                    <CardDetails
                        label={"Price range for seminar"}
                        isEdited={
                            seminarDetails?.lastEdited?.includes("seminarMinPrice") ||
                            seminarDetails?.lastEdited?.includes("seminarMaxPrice")
                        }
                    >
                        <div className="row-group justify-between">
                            <p className='fw-500'>Min price</p>
                            <p className='color-text'>{seminarDetails?.seminarMinPrice}</p>
                        </div>
                        <div className="row-group justify-between">
                            <p className='fw-500'>Max price</p>
                            <p className='color-text'>{seminarDetails?.seminarMaxPrice}</p>
                        </div>
                    </CardDetails>
            }
        </Step>
    )
}