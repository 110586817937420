const base = 'api/requests';

export const requests = (instance) => ({
    async getRequests(query) {
        const { data } = await instance.get(`${base}?${query}`);
        return data;
    },
    async getRequestsPrivateLessons(query = "") {
        const { data } = await instance.get(`${base}/private-lessons?${query}`);
        return data;
    },
    async getRequestsAcademyProcess(query) {
        const { data } = await instance.get(`${base}/academy-process`);
        return data;
    },
    async createUpdateTerms({ requestId, body }) {
        const { data } = await instance.post(`${base}/academy-request/${requestId}`, body);
        return data;
    },
})