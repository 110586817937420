import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getAcademyGeneralInfoFetch = createAsyncThunk(
    '/get-academy-general-info/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academyGeneralInfo.getAcademyGeneralInfo();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getAllAcademiesFetch = createAsyncThunk(
    '/get-all-academies',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academyGeneralInfo.getAllAcademies(payload);
            return res
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getAcademyByIdFetch = createAsyncThunk(
    '/get-academy-by-id',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.academyGeneralInfo.getAcademyById(id);
            if (res.success) {
                return res.data
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);