import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getUser } from '../../../redux/slices/applicationSlice';

const ProfileSidebar = () => {
    const user = useSelector(getUser);

    return (
        <div className="settings-col --menu-settings">
            <aside className="sidebar-settings settings-gap">
                <div className="sidebar-header heading-row">
                    <h3 className="heading">Settings</h3>
                </div>
                <div className="sidebar-body">

                    {user &&
                        <ul className="menu-list">
                            <li className="menu-list--item">
                                <NavLink to={'/settings/my-profile'}
                                    className={({ isActive, isPending }) =>
                                        classNames("btn btn--sm setting-link", {
                                            "--active": isActive,
                                        })
                                    }
                                >
                                    <span className="ico ico--sm rounded-full border-ico">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8562 3.39385C11.8813 4.41898 11.8813 6.08103 10.8562 7.10616C9.83103 8.13129 8.16898 8.13129 7.14385 7.10616C6.11872 6.08103 6.11872 4.41898 7.14385 3.39385C8.16898 2.36872 9.83103 2.36872 10.8562 3.39385" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M3 13.8749V14.6249C3 15.0389 3.336 15.3749 3.75 15.3749H14.25C14.664 15.3749 15 15.0389 15 14.6249V13.8749C15 11.6054 12.036 10.1309 9 10.1309C5.964 10.1309 3 11.6054 3 13.8749Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span className="setting-link-text">{user.role === 'academy' ? 'My Academy' : 'My profile'}</span>
                                </NavLink>
                            </li>

                            {(user.role === 'academy' || user.role === 'instructor') &&
                                <li className="menu-list--item">
                                    <NavLink to={'/settings/general-information'}
                                        className={({ isActive, isPending }) =>
                                            classNames("btn btn--sm setting-link", {
                                                "--active": isActive,
                                            })
                                        }

                                    >
                                        <span className="ico ico--sm rounded-full border-ico">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9" cy="8.99988" r="6.75281" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></circle>
                                                <path d="M8.24963 11.6261H9.98236" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.11921 11.6263V8.4375H8.25635" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.07494 6.18375C9.07494 6.28735 8.99096 6.37133 8.88736 6.37133C8.78377 6.37133 8.69979 6.28735 8.69979 6.18375C8.69979 6.08015 8.78377 5.99617 8.88736 5.99617" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M8.88745 5.99621C8.99104 5.99621 9.07502 6.08019 9.07502 6.18379" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span className="setting-link-text">General Information</span>
                                    </NavLink>
                                </li>
                            }

                            <li className="menu-list--item">
                                <NavLink to={'/settings/security'}
                                    className={({ isActive, isPending }) =>
                                        classNames("btn btn--sm setting-link", {
                                            "--active": isActive,
                                        })
                                    }
                                >
                                    <span className="ico ico--sm rounded-full border-ico">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.2732 7.00608C11.2732 6.85083 11.1472 6.72558 10.9919 6.72558C10.8367 6.72633 10.7107 6.85233 10.7107 7.00758C10.7107 7.16283 10.8367 7.28883 10.9919 7.28808C11.1472 7.28808 11.2732 7.16208 11.2732 7.00683" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.37425 11.0886L8.60175 11.8626H7.22775V12.9643H6.129V14.3413L5.0985 15.3748H2.625V12.8961L6.91725 8.59406C6.31275 7.02356 6.63675 5.17706 7.9005 3.91031C9.6105 2.19656 12.3833 2.19656 14.0933 3.91031C15.8033 5.62406 15.8033 8.40281 14.0933 10.1173C12.8168 11.3953 10.9507 11.7178 9.37425 11.0886Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span className="setting-link-text">Security</span>
                                </NavLink>
                            </li>

                            {(user.role === 'academy' || user.role === 'instructor') &&
                                <li className="menu-list--item">
                                    <NavLink to={'/settings/payout-methods'}
                                        className={({ isActive, isPending }) =>
                                            classNames("btn btn--sm setting-link", {
                                                "--active": isActive,
                                            })
                                        }
                                    >
                                        <span className="ico ico--sm rounded-full border-ico">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.5 15.75H3C2.586 15.75 2.25 15.414 2.25 15V10.5C2.25 10.086 2.586 9.75 3 9.75H4.5C4.914 9.75 5.25 10.086 5.25 10.5V15C5.25 15.414 4.914 15.75 4.5 15.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.75 12.7503H11.4998C11.8245 12.7503 12.1403 12.6453 12.3998 12.4503L14.2118 11.0913C14.664 10.7523 15.297 10.7973 15.6968 11.197V11.197C16.1408 11.641 16.1408 12.3603 15.6968 12.8035L14.142 14.3583C13.7235 14.7768 13.1895 15.0625 12.609 15.1788L10.4115 15.6183C9.97575 15.7053 9.5265 15.6948 9.09525 15.5868L7.10775 15.0903C6.87 15.0303 6.62625 15.0003 6.381 15.0003H5.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.75 12.75H10.8638C11.4915 12.75 12 12.2415 12 11.6137V11.3865C12 10.8652 11.6453 10.4107 11.1398 10.2847L9.42075 9.855C9.141 9.78525 8.8545 9.75 8.5665 9.75V9.75C7.87125 9.75 7.191 9.9555 6.61275 10.3417L5.25 11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.8766 3.86659C13.7079 4.69782 13.7079 6.04551 12.8766 6.87674C12.0454 7.70798 10.6977 7.70798 9.86647 6.87674C9.03523 6.04551 9.03523 4.69782 9.86647 3.86659C10.6977 3.03536 12.0454 3.03536 12.8766 3.86659" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M10.9733 3.28125C10.9035 2.8575 10.71 2.45025 10.383 2.12325C9.552 1.29225 8.20425 1.29225 7.37325 2.12325C6.54225 2.95425 6.54225 4.302 7.37325 5.133C7.89075 5.6505 8.60775 5.84475 9.276 5.718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span className="setting-link-text">Payout Methods</span>
                                    </NavLink>
                                </li>
                            }

                            {user.role === 'instructor' &&
                                <>
                                    <li className="menu-list--item">
                                        <NavLink to={'/settings/location-availability'}
                                            className={({ isActive, isPending }) =>
                                                classNames("btn btn--sm setting-link", {
                                                    "--active": isActive,
                                                })
                                            }
                                        >
                                            <span className="ico ico--sm rounded-full border-ico">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9 9.75V9.75C7.75725 9.75 6.75 8.74275 6.75 7.5V7.5C6.75 6.25725 7.75725 5.25 9 5.25V5.25C10.2428 5.25 11.25 6.25725 11.25 7.5V7.5C11.25 8.74275 10.2428 9.75 9 9.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9 15.75C9 15.75 3.75 11.4375 3.75 7.5C3.75 4.6005 6.1005 2.25 9 2.25C11.8995 2.25 14.25 4.6005 14.25 7.5C14.25 11.4375 9 15.75 9 15.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span className="setting-link-text">Location Availability</span>
                                        </NavLink>
                                    </li>
                                    <li className="menu-list--item">
                                        <NavLink to={'/settings/private-session'}
                                            className={({ isActive, isPending }) =>
                                                classNames("btn btn--sm setting-link", {
                                                    "--active": isActive,
                                                })
                                            } >
                                            <span className="ico ico--sm rounded-full border-ico">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.54812 9.59514L8.4485 5.10527L9.43141 5.59673C9.79636 5.77894 10.2399 5.66204 10.4676 5.32361L11.3259 4.03533C11.5402 3.71334 11.4979 3.28493 11.2247 3.01115L10.1442 1.9307C9.64324 1.42962 8.84286 1.39318 8.29843 1.84667C5.74201 3.97681 3.83016 6.77755 2.77763 9.93428L2.32745 11.2743C2.10288 11.9513 2.3464 12.6956 2.9277 13.1089L4.07643 13.9297C6.29325 15.5135 9.16405 15.8594 11.6936 14.8473L12.5054 16.471" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M11.6935 9.97552C10.2186 8.99254 8.29741 8.99254 6.82251 9.97552" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span className="setting-link-text">Private Session Info</span>
                                        </NavLink>
                                    </li>
                                </>
                            }

                            {user.role === 'student' &&
                                <li className="menu-list--item">
                                    <NavLink to={'/settings/billing'}
                                        className={({ isActive, isPending }) =>
                                            classNames("btn btn--sm setting-link", {
                                                "--active": isActive,
                                            })
                                        }
                                    >
                                        <span className="ico ico--sm rounded-full border-ico">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.7527 7.12379V4.87285C15.7527 3.83689 14.9129 2.99707 13.8769 2.99707H4.12285C3.08689 2.99707 2.24707 3.83689 2.24707 4.87285V5.99832" stroke="#02020A" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M15.7525 10.876V13.1269C15.7525 14.1629 14.9127 15.0027 13.8767 15.0027H9.75" stroke="#02020A" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M1.49658 9.4502V14.5523C1.49744 15.2152 2.84029 15.7528 4.49783 15.7528C6.15538 15.7528 7.49823 15.2152 7.49908 14.5523V9.4502" stroke="#02020A" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.49823 9.4505C7.49823 10.1133 6.15452 10.651 4.49698 10.651C2.83943 10.651 1.49658 10.1133 1.49658 9.4505C1.49658 8.7868 2.84114 8.25 4.49783 8.25C6.15452 8.25 7.49823 8.78765 7.49908 9.4505" stroke="#02020A" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M1.49707 12.001C1.49707 12.6638 2.83992 13.2015 4.49747 13.2015C6.15501 13.2015 7.49871 12.6638 7.49871 12.001" stroke="#02020A" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.8768 7.12402H15.7525C16.1669 7.12402 16.5029 7.45995 16.5029 7.87434V10.1253C16.5029 10.5397 16.1669 10.8756 15.7525 10.8756H13.8768C12.8408 10.8756 12.001 10.0358 12.001 8.9998V8.9998C12.001 7.96384 12.8408 7.12402 13.8768 7.12402V7.12402Z" stroke="#02020A" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span className="setting-link-text">Billing Information</span>
                                    </NavLink>
                                </li>
                            }

                        </ul>
                    }
                </div>
            </aside>
        </div>
    )
}

export default ProfileSidebar
