import React from 'react'

const CheckboxEl = ({wrapperClasses, defaultChecked, label,...props}) => {
  return (
    <label className={`check-btn ${wrapperClasses}`}>
        <input type="checkbox" hidden={true} defaultChecked={defaultChecked} {...props}/>
        <span className="check order-1"></span>
        {label && <p className="check-text">{label}</p>}
    </label>
  )
}

export default CheckboxEl
