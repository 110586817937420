import { pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import InviteInstructorModal from '../../../components/modals/InviteInstructorModal'
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch'
import Input from '../../../components/ui/formElements/Input'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import { belts, trainingStyle } from '../../../constants'
import { checkErrors, convertObjectToFormData, getImageSrc } from '../../../helpers/utils'
import { getUser } from '../../../redux/slices/applicationSlice'
import { updateCampFetch } from '../../../redux/thunks/planCampThuncks'

const SeminarInformation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { campId, chatId } = useParams()
    const user = useSelector(getUser)
    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [inviteInstructorModalOpen, setInviteInstructorModalOpen] = useState(false)
    const [instructors, setInstructors] = useState(preview?.instructors)
    const [editedFields, setEditedFields] = useState([])

    const handleSubmit = async () => {
        setIsErrorsShown(true)
        console.log(errors);
        if (!errors?.hasRequiredError) {
            if (campId) {
                const body = new FormData()
                const dataToUpdate = pick(formData, editedFields)
                convertObjectToFormData(dataToUpdate, body)
                const res = await dispatch(updateCampFetch({ campId, body })).unwrap()
                if (res) {
                    navigate(`/chat/${chatId}`)
                }
            } else {
                navigate("../design-text")
            }
        }
    }
    const handleStepChange = (name, value) => {
        handleChange(name, value)
        setEditedFields(prev => [...prev, name])
    }

    const specificBeltOptions = belts.map(({ key, title }) => ({ value: key, label: title }))
    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({ value: key, label: title }))

    const handleAddInstructors = (data) => {
        setInstructors(data)
        handleStepChange("invited_instructors", data.map(item => item?.user?.id))
        setInviteInstructorModalOpen(false)
        handlePreviewChange("instructors", data)
    }
    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);
        handleStepChange("invited_instructors", updatedInstructors.map(item => item?.user?.id))
        setInstructors(updatedInstructors);
        handlePreviewChange("instructors", updatedInstructors)
    };

    useEffect(() => {
        setErrorConfig({
            requiredFields: [
                "traningStyle", "maxGymCapacity", "specificBelt",
                ...(user?.role === "academy" ? [] : []),
                ...(user?.role === "instructor" ? ["priceFrom", "priceTo"] : [])
            ]
        })
    }, [formData, user])
    return (
        <>
            <h6 className="heading mb-32">My Terms</h6>
            <div className="row gx-16 py-24 mb-42">
                {
                    user?.role === "instructor" ?
                        <div className="col-12">
                            <div className="form-group input--lg mb-16">
                                <label className="label">Price range for Camp</label>
                                <div className="row-group w-full gap-10">
                                    <div className="form-group flex-auto input--lg">
                                        <Input
                                            wrapperClasses={"input--currency"}
                                            inputClasses="input--solid"
                                            type="text"
                                            placeholder="3000"
                                            value={formData.priceFrom}
                                            error={isErrorsShown && errors?.priceFrom}
                                            onChange={(e) => handleStepChange("priceFrom", e.target.value)}
                                        />
                                    </div>
                                    <span className="input-delimiter"></span>
                                    <div className="form-group flex-auto input--lg">
                                        <Input
                                            wrapperClasses={"input--currency"}
                                            inputClasses="input--solid"
                                            type="text"
                                            placeholder="6000"
                                            value={formData.priceTo}
                                            error={isErrorsShown && errors?.priceTo}
                                            onChange={(e) => handleStepChange("priceTo", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        label={"Gi or Nogi"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                        options={traningStyleOptions}
                        value={formData.traningStyle}
                        error={isErrorsShown && errors?.traningStyle}
                        onChange={(option) => handleStepChange("traningStyle", option.value)}
                        placeholder={"Gi or Nogi"}
                    />
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    {/* <SelectOne
                        label={"Max Gym capacity"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                        options={[
                            { value: "100", label: "100" },
                            { value: "200", label: "200" },
                        ]}
                        value={formData.maxGymCapacity}
                        error={isErrorsShown && errors?.maxGymCapacity}
                        onChange={(option) => handleStepChange("maxGymCapacity", option.value)}
                        placeholder={"Max Gym capacity"}
                    /> */}
                    <Input
                        label={"Max Gym capacity"}
                        wrapperClasses={"input--lg"}
                        inputClasses="input--solid"
                        type="text"
                        placeholder="0"
                        value={formData.maxGymCapacity}
                        error={isErrorsShown && errors?.maxGymCapacity}
                        onChange={(e) => handleStepChange("maxGymCapacity", e.target.value)}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        label={"Specific Belt for instructor"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                        options={specificBeltOptions}
                        value={formData.specificBelt}
                        error={isErrorsShown && errors?.specificBelt}
                        onChange={(option) => handleStepChange("specificBelt", option.value)}
                        placeholder={"Specific Belt for instructor"}
                    />
                </div>
                <CheckboxSwitch
                    text="Do you allow students under the age of 18 to attend your seminar/camp?"
                    checked={user?.role === "instructor" ? formData?.instructor?.allowUnder18 === "1" : formData?.allowUnder18 === "1"}
                    onChange={() =>
                        handleStepChange(user?.role === "instructor" ? "instructor.allowUnder18" : "allowUnder18",
                            (user?.role === "instructor" ? formData?.instructor?.allowUnder18 : formData?.allowUnder18) === "0" ? "1" : "0")
                    }
                />
            </div>
            {
                user?.role === "instructor" ?
                    <>
                        <hr className='hr-horisontal mb-42' />
                        <div className='mb-42'>
                            <h6 className="heading mb-32">Invite Additional Instructor <span className="color-grey">(optional)</span></h6>
                            {
                                instructors?.length ?
                                    <ul className="invite-instructors-list mb-32">
                                        {
                                            instructors.map((instructor, index) => (
                                                <li key={index} className="invite-instructor__item">
                                                    <div className="invite-instructor__img">
                                                        <img src={getImageSrc(instructor.photo)} alt={instructor.fullName} />
                                                    </div>
                                                    <div className="invite-instructor__group-info">
                                                        <span className="invite-instructor__name">{instructor.fullName}</span>
                                                        <span className="invite-instructor__email color-grey fs-14">{instructor?.user?.email}</span>
                                                    </div>
                                                    <button onClick={() => handleRemoveInstructor(index)} className="btn">
                                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g>
                                                                <path d="M18.75 5.75L5.25 19.25" stroke="#BDBDBD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M18.75 19.25L5.25 5.75" stroke="#BDBDBD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            </g>
                                                            <defs>
                                                                <clipPath>
                                                                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    :
                                    null
                            }
                            <button onClick={() => setInviteInstructorModalOpen(true)} className="btn btn--sm btn--primary" data-modal="#invite-instructors">
                                <span className="info">Send Invite</span>
                                <span className="ico">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_910_51633)">
                                            <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_910_51633">
                                                <rect width="14" height="14" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </>
                    : null
            }
            <div className="page-action-group">
                <Link to={".."} className="btn btn--default btn--sm">Cancel</Link>
                <button onClick={handleSubmit} className="btn btn--primary btn--sm">
                    {
                        campId ?
                            <span className="info">Apply Changes</span>
                            :
                            <>
                                <span className="info">Continue</span>
                                <span className="ico">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                    </svg>
                                </span>
                            </>

                    }
                </button>
            </div>
            {
                inviteInstructorModalOpen ?
                    <InviteInstructorModal
                        open={inviteInstructorModalOpen}
                        handleClose={() => setInviteInstructorModalOpen(false)}
                        onSubmit={handleAddInstructors}
                        data={instructors}
                    />
                    :
                    null
            }
        </>
    )
}

export default SeminarInformation