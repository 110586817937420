import React, { useEffect, useState } from 'react'

import moment from 'moment/moment'
import DatePicker from '../../../components/ui/formElements/DatePicker'
import TimePicker from '../../../components/ui/formElements/TimePicker'
import { checkErrors, convertDatesToTimeSlot, uuidv4 } from '../../../helpers/utils';

import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg"
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg"


const covertDateFormat = ({ date, startTime, endTime }) => {
    return {
        date: date ? moment(date).format("yyyy-MM-DD") : null,
        startTime: startTime ? moment(startTime).format("hh:mm") : null,
        endTime: endTime ? moment(endTime).format("hh:mm") : null,
    }
}
export const convertDatesToFormData = (eventsArray) => {
    return eventsArray.reduce((convertedArray, event) => {
        const { date, timeSlots } = event;
        const convertedTimeSlots = timeSlots.map(timeSlot => {
            return covertDateFormat({ ...timeSlot, date });
        });
        return [...convertedArray, ...convertedTimeSlots];
    }, []);
};

const EventDateTimeInput = ({ event, handleChange, excludeDates, children, eventId, errors, isErrorsShown }) => {
    const [date, setDate] = useState(event?.date)

    const [timeSlots, setTimeSlots] = useState([
        ...event.timeSlots
    ])

    const handleAddTimeSlot = () => {
        const timeSlot = { startTime: '', endTime: '', date }
        setTimeSlots(prev => ([...prev, timeSlot]))
    };

    const handleRemoveTimeSlot = (index) => {
        const newTimeSlots = timeSlots.filter((_, i) => index !== i);
        setTimeSlots(newTimeSlots);
        const data = {
            date,
            timeSlots: newTimeSlots
        }
        handleChange && handleChange(eventId, data)

    };

    const handleChangeTimeSlot = (index, key, value) => {
        const newEvents = timeSlots.map((timeSlot, i) => {
            if (i === index) {
                return {
                    ...timeSlot,
                    [key]: value,
                }
            } else {
                return timeSlot
            }
        })
        setTimeSlots(newEvents)
        const data = {
            ...event,
            date,
            timeSlots: newEvents
        }
        handleChange && handleChange(eventId, data)
    }

    const handleChangeDate = (date) => {
        const data = {
            ...event,
            date: date,
            timeSlots
        }
        setDate(date)
        handleChange && handleChange(eventId, data)
    }
    
    return (
        <div className="seminars-list-item">
            <div className="seminars-list-item__body-content">
                <div className="schedule-group">
                    <div className="schedule-group-item">
                        <div className="schedule-group-item__date">
                            <DatePicker
                                placeholderText="Select Event Date"
                                selected={date}
                                onChange={date => handleChangeDate(date)}
                                excludeDates={excludeDates}
                                error={isErrorsShown && errors?.date}
                                minDate={moment().toDate()}
                            />
                        </div>
                        <div className="schedule-group-item__time">
                            {timeSlots.map((timeSlot, index) => (
                                <div className="schedule-group-item__time-group" key={index}>
                                    <div className="input-date-time-group">
                                        <TimePicker
                                            placeholderText="0:00pm"
                                            selected={timeSlot?.startTime}
                                            error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.startTime}
                                            onChange={time => handleChangeTimeSlot(index, "startTime", time)}

                                        />
                                        <span className="input-delimiter"></span>
                                        <TimePicker
                                            placeholderText="0:00pm"
                                            selected={timeSlot?.endTime}
                                            error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.endTime}
                                            onChange={time => handleChangeTimeSlot(index, "endTime", time)}
                                        />
                                        {
                                            index === 0 ?
                                                <button type='button' onClick={handleAddTimeSlot} className="btn btn--outline-default btn--square btn--md rounded-full">
                                                    <PlusIcon />
                                                </button>
                                                :
                                                <button type='button' onClick={() => handleRemoveTimeSlot(index)} className="btn btn--outline-default btn--square btn--md rounded-full">
                                                    <DeleteIcon />
                                                </button>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

const EventDatesSelect = ({ dates = [], handleChange, isErrorsShown }) => {
    const [events, setEvents] = useState(convertDatesToTimeSlot(dates));
    const [errors, setErrors] = useState([])

    const addEvent = () => {
        const newEvent = {
            date: '',
            id: uuidv4(),
            timeSlots: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        };
        const updatedEvents = [...events, newEvent];
        setEvents(updatedEvents);
        handleChange(convertDatesToFormData(updatedEvents))
    };

    const removeEvent = (index) => {
        const updatedEvents = events.filter((_, i) => i !== index);
        handleChange(convertDatesToFormData(updatedEvents))
        setEvents(updatedEvents);
    };
    const updateEventById = (eventId, newEvent) => {
        const updatedEvents = events.map((event, index) => {
            if (index === eventId) {
                return newEvent;
            }
            return event;
        });
        setEvents(updatedEvents);
        handleChange(convertDatesToFormData(updatedEvents))
    };
    const excludeDates = events.map(event => event?.date).filter(event => event)

    useEffect(() => {
        const errors = events.map(date => {
            return checkErrors(date)
        })
        setErrors(errors)

    }, [events])

    useEffect(() => {
        if (!events?.length) addEvent()
    }, [events])


    useEffect(() => {
        setEvents(convertDatesToTimeSlot(dates))
    }, [dates])

    return (
        <div className="seminars-list seminars-list--planning mb-18">
            {events.map((event, index) => (
                <React.Fragment key={event.id}>
                    <EventDateTimeInput
                        event={event}
                        eventId={index}
                        handleChange={updateEventById}
                        excludeDates={excludeDates}
                        errors={errors?.length ? errors[index] : {}}
                        isErrorsShown={isErrorsShown}
                    >
                        {
                            events.length > 1 ?
                                <button type='button' onClick={() => removeEvent(index)} className="btn btn--outline-default btn--square btn--md rounded-full" style={{marginTop: 5}}>
                                    <DeleteIcon />
                                </button>
                                :
                                null
                        }
                    </EventDateTimeInput>
                </React.Fragment>
            ))}
            <div className="seminars-list-item">
                <div className="seminars-list-item__body-content">
                    <button type='button' onClick={addEvent} className="btn btn--sm btn--primary">
                        <span className="ico ico--20">
                            <PlusIcon />
                        </span>
                        <span className="info">Add Event Date</span>
                    </button>
                </div>
            </div>
        </div>
    );


};

export default EventDatesSelect