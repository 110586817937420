const base = 'api/notifications';

export const notifications = (instance) => ({
    async getAllNotifications(query = "sort_by=isReaded&sort_as=asc") {
        const { data } = await instance.get(`${base}?${query}`);
        return data;
    },
    async readNotifications(body) {
        const { data } = await instance.post(`${base}/read`, body);
        return data;
    },
    async deleteNotification(id) {
        const { data } = await instance.delete(`${base}/${id}`);
        return data;
    },
})