import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const ModalTemplate = ({ open, onClick, modalSize = 'modal__dialog--xs', children }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        document.body.classList.add('lock')
        return () => {
            document.body.classList.remove('lock')
        }
    }, [])

    useEffect(() => {
        setShow(open);
    }, [open])

    return createPortal(
        <div className={`modal modal-1 ${open && show ? 'modal--show' : ''}`} onClick={onClick}>
            <div className={`modal__dialog ${modalSize}`} >
                <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                    <button type="button" className="btn btn-close btn--square" onClick={onClick}>
                        <span className="ico">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 7L7 25" stroke="currentColor" strokeWidth="1.4" strokeLinecap="square" strokeLinejoin="round"></path>
                                <path d="M25 25L7 7" stroke="currentColor" strokeWidth="1.4" strokeLinecap="square" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                    </button>
                    {children}
                </div>
            </div>
        </div>
        , document.body)
}

export default ModalTemplate