import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api'; // Adjust the import path as necessary
import { toast } from 'react-toastify';

export const getAllCampsFetch = createAsyncThunk(
    '/plan-camps/',
    async (qwery, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getAllCamps(qwery);
            if (res.success) {
                toast.success(res.message)
                return res;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getSingleCampFetch = createAsyncThunk(
    '/plan-camp-single/',
    async (clapmID, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getSingleCamp(clapmID);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getOnlyMyCampsFetch = createAsyncThunk(
    '/plan-camp-single/',
    async (paiload = null, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getOnlyMyCamps();
            if (res.success) {
                toast.success(res.message)
                return res;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const createCampFetch = createAsyncThunk(
    '/create-plan-camp/',
    async (paiload = null, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.createCamp(paiload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
            res.errors.forEach(error => {
                toast.error(error.message)
            })
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const instructorJoinToPlancampFetch = createAsyncThunk(
    '/instructor-join-to-plancamp/',
    async (clapmID, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.instructorJoinToPlancamp(clapmID);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const addInstructorToCampFetch = createAsyncThunk(
    '/add-instructor-join-to-plancamp/',
    async (clapmID, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.addInstructorToCamp(clapmID);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const updateCampFetch = createAsyncThunk(
    '/update-clapm/',
    async (paiload, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.updateCamp(paiload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            } else {
                toast.error(res.message);
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getPlanCampCreatedByAcademyFetch = createAsyncThunk(
    '/get-plan-camp-created-by-academy/',
    async (paiload, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getPlanCampCreatedByAcademy();
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getPlanCampInstrcutorsActiveFetch = createAsyncThunk(
    '/get-plan-camp-instrcutors-active/',
    async (clapmId, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getPlanCampInstrcutorsActive(clapmId);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getPlancampInstructorsAllFetch = createAsyncThunk(
    '/get-plancamp-instructors-all/',
    async (clapmId, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getPlancampInstructorsAll(clapmId);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getPlancampInstructorsWaitingContractFetch = createAsyncThunk(
    '/get-plancamp-instructors-waiting-contract/',
    async (clapmId, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getPlancampInstructorsWaitingContract(clapmId);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const getPlancampRequestsFetch = createAsyncThunk(
    '/get-plancamp-requests/',
    async (clapmId, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getPlancampRequests(clapmId);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const sendRequestCampHostingFetch = createAsyncThunk(
    '/sendRequestCampHostingFetch',
    async ({ plancampId }, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.sendRequestCampHosting({ plancampId });
            if (res?.success) {
                toast.success(res.message || "Request send successfully!")
            } else {
                toast.error(res?.message || "Something went wrong")
            }
            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const sendCampRequestByInstructorFetch = createAsyncThunk(
    '/sendCampRequestByInstructorFetch',
    async ({ plancampId }, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.sendCampRequestByInstructor({ plancampId });
            if (res?.success) {
                toast.success(res.message || "Request send successfully!")
            } else {
                toast.error(res?.message || "Something went wrong")
            }
            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const acceptInstructorCampRequestFetch = createAsyncThunk(
    '/acceptInstructorCampRequestFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.acceptInstructorCampRequest(payload);
            if (res?.success) {
                toast.success(res.message || "Request send successfully!")
            } else {
                toast.error(res?.message || "Something went wrong")
            }
            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const acceptCampRequestFetch = createAsyncThunk(
    '/acceptCampRequestFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.acceptCampRequest(payload);
            if (res?.success) {
                toast.success(res.message || "Request send successfully!")
            } else {
                toast.error(res?.message || "Something went wrong")
            }
            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const inviteInstructorCampRequestFetch = createAsyncThunk(
    '/inviteInstructorCampRequestFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.inviteInstructorCampRequest(payload);
            if (res?.success) {
                toast.success(res.message || "Request send successfully!")
            } else {
                toast.error(res?.message || "Something went wrong")
            }
            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);

export const rejectCampRequestFetch = createAsyncThunk(
    '/rejectCampRequestFetch',
    async ({ requestId }, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.rejectCampRequest({ requestId });
            if (res?.success) {
                toast.success(res.message || "Request send successfully!")
            } else {
                toast.error(res?.message || "Something went wrong")
            }
            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);

export const getCampsMyAvailableToInstructorFetch = createAsyncThunk(
    '/getCampsMyAvailableToInstructorFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.planCamp.getCampsMyAvailableToInstructor(payload);
            if (res?.success) {
                return res;
            } else {
                toast.error(res?.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response?.data);
        }
    }
);