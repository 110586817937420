const base = '/api/cooperate-teams/';

export const cooperateTeam = (instance) => ({

    async getAllCooperateTeams() {
        const { data } = await instance.get(`${base}/my`);
        return data;
    },

    async addCooperateTeam(payload) {
        const { data } = await instance.post(`${base}`, payload);
        return data;
    },

    async updateCooperateTeam(payload, id) {
        const { data } = await instance.put(`${base}/${id}`, payload);
        return data;
    },

    async deleteCooperateTeam(id) {
        const { data } = await instance.delete(`${base}/${id}`);
        return data;
    },


})