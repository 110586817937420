import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { getUser } from '../redux/slices/applicationSlice';
import { useSelector } from 'react-redux';

export const MySeminarsLayout = () => {
    const user = useSelector(getUser);
    return (
        <section>
            <div className="container">
                <div className="my-seminars-page__header">
                    <div className="heading-links">
                        <NavLink to={'/my-seminars'} className="heading">
                            <h3 className='heading'>My Seminars</h3>
                        </NavLink>
                        <NavLink to={'/requests'} className="heading">
                            <h3 className='heading'>
                                {user && user.role === 'student'? 'Private Lessons Requests' : 'Requests' }
                            </h3> 
                        </NavLink>
                        {user && user.role === 'instructor' && 
                            <NavLink to={'/requested-lessons'} className="heading "> 
                                <h3 className='heading'>Requested Private Lessons</h3>
                            </NavLink>
                        }
                    </div>
                </div>
                <Outlet />
            </div>
        </section>
    )
}
