import { isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { Placeholder } from 'react-select/animated'

const SelectMulty = ({ options, wrapperClasses, arrowType = 'arrow', onChange, value, label, placeholder = '', error }) => {

    const findValue = (values) => {
        if (isArray(values))
            return values.map(value => {
                return typeof value === "object" ? value : options.find(option => option.value === value)
            });
    }
    const [selectedValue, setSelectedValue] = useState(findValue(value))

    useEffect(() => {
        setSelectedValue(findValue(value))
    }, [value])


    const handleChange = (option) => {
        setSelectedValue(option)
        onChange && onChange(option)
    }
    let selectComponents = {
        ClearIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueContainer: ({ children, ...props }) => {
            return (
                <components.MultiValueContainer {...props} className="select-multi-value-container" >
                    {children}
                </components.MultiValueContainer>
            )
        },
        MultiValue: ({ children, ...props }) => {
            return (
                <components.MultiValue {...props} className="select-multi-value" >
                    {children}
                </components.MultiValue>
            )
        },
        MultiValueRemove: (props) => {
            return (
                <components.MultiValueRemove {...props} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M14.0625 3.9375L3.9375 14.0625" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.0625 14.0625L3.9375 3.9375" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </components.MultiValueRemove>
            )
        },
        DropdownIndicator: (props) => {
            return (
                <components.DropdownIndicator {...props}>
                    {arrowType === 'arrow' ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                            <path d="M1.33325 1.1665L4.99992 4.83317L8.66659 1.1665" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> :
                        arrowType === 'carret' ?
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0967 4.35656C10.0683 4.28804 10.0203 4.22946 9.95862 4.18824C9.89696 4.14702 9.82446 4.12502 9.7503 4.125H2.2503C2.17608 4.12494 2.10352 4.1469 2.0418 4.1881C1.98008 4.22931 1.93197 4.28789 1.90356 4.35645C1.87515 4.42501 1.86773 4.50046 1.88222 4.57324C1.89672 4.64602 1.93248 4.71287 1.98498 4.76531L5.73498 8.51531C5.76981 8.55018 5.81117 8.57784 5.85669 8.59671C5.90222 8.61558 5.95101 8.62529 6.0003 8.62529C6.04958 8.62529 6.09837 8.61558 6.1439 8.59671C6.18942 8.57784 6.23078 8.55018 6.26561 8.51531L10.0156 4.76531C10.068 4.71284 10.1037 4.646 10.1182 4.57324C10.1326 4.50048 10.1251 4.42508 10.0967 4.35656Z" fill="currentColor" />
                            </svg>
                            : null
                    }

                </components.DropdownIndicator>
            )
        },
        Placeholder: () => null,
        Input: ({ children, ...props }) => {
            return (
                <components.Input {...props} className='select-multi-input-wrapper' placeholder={placeholder}>
                </components.Input>
            )
        },

    }
    let classNames = {
        control: (e) => {
            return `select-selection ${e.menuIsOpen ? 'select-selection-focused' : ''} ${error ? "input--error" : ""}`
        },
        singleValue: (e) => {
            return e.getValue()?.value ? 'aaa has-value' : 'aaa';
        },
        valueContainer: () => 'bbb',
        menu: () => 'ccc select2-dropdown select2-dropdown--below',
        container: () => 'ddd',
        menuList: () => 'menulist',
        option: (e) => {
            return `select-list-item ${e.isSelected ? 'select-list-item__selected' : ''}`

        },
        dropdownIndicator: () => 'select-indicator'
    }
    return (
        <div className={`form-group ${wrapperClasses}`}>
            {label && <label className="label">{label}</label>}
            <Select
                menuPlacement="auto"
                options={options}
                placeholder={placeholder}
                defaultValue={selectedValue}
                value={selectedValue}
                classNames={classNames}
                components={{ ...selectComponents }}
                onChange={handleChange}
                isMulti
            >

            </Select>
        </div>
    )
}

export default SelectMulty
