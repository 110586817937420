import React, { useEffect, useState } from 'react';
import api from '../../../api';

import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";

import Button from '../../../components/ui/buttons/Button';
import Input from '../../../components/ui/formElements/Input';
import SelectMulty from '../../../components/ui/formElements/SelectMulty';
import Textarea from '../../../components/ui/formElements/Textarea';
import { deepCopy } from '../../../helpers/utils';

const PopularQuestions = ({ handleChange, data, errors, isSeminar = false }) => {
    const [questionsOptions, setQuestionsOptions] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedQuestionsFormData, setSelectedQuestionsFormData] = useState(data || []);

    const handleSelectedQuestionsChange = (index, { question, answer }) => {
        const updatedQuestions = deepCopy(selectedQuestionsFormData);
        updatedQuestions[index].answer = answer;
        updatedQuestions[index].question = question;
        setSelectedQuestionsFormData(updatedQuestions);
        handleChange(updatedQuestions);
    };

    const handleSelectQuestions = (options) => {
        if (!options?.length) return;

        const copyQuestions = deepCopy(selectedQuestionsFormData);
        const filteredCopyQuestions = copyQuestions.filter(question => {
            const isNotPersonal = questionsOptions.some(opt => opt.label === question.question);
            return !isNotPersonal || options.some(opt => opt.label === question.question);
        });

        const newQuestions = options
            .filter(option => !filteredCopyQuestions.some(q => q.question === option.label))
            .map(opt => ({ question: opt.label, answer: "" }));

        const updatedQuestions = [...filteredCopyQuestions, ...newQuestions];
        setSelectedQuestionsFormData(updatedQuestions);
        setSelectedQuestions(options);
        handleChange(updatedQuestions);
    };

    const handleAddQuestions = () => {
        const updatedQuestions = deepCopy(selectedQuestionsFormData);
        updatedQuestions.push({ question: "", answer: "" });
        setSelectedQuestionsFormData(updatedQuestions);
        handleChange(updatedQuestions);
    };

    const handleDeleteQuestion = (index) => {
        const updatedQuestions = selectedQuestionsFormData.filter((_, i) => i !== index);
        setSelectedQuestionsFormData(updatedQuestions);
        handleChange(updatedQuestions);
    };

    useEffect(() => {
        (async function getAllFaqQuestions() {
            const res = await api.faqQuestions.getAllFaqQuestions();
            if (res.success && res?.data?.length) {
                setQuestionsOptions(res.data.map(({ id, title }) => ({ label: title, value: id })));
            }
        })();
    }, []);

    useEffect(() => {
        setSelectedQuestionsFormData(data || []);
    }, [data]);

    useEffect(() => {
        setSelectedQuestions(questionsOptions.filter(opt =>
            selectedQuestionsFormData.some(q => q.question === opt.label)
        ));
    }, [selectedQuestionsFormData, questionsOptions]);

    return (
        <>
            {isSeminar ? <h6 className="heading">Seminar FAQ</h6>
            : <div className="form-group input--l mb-32">
                <label className="label">Camp FAQ</label>
            </div>}

            <div className="form-group input--lg mb-32">
                <SelectMulty
                    label={`Select questions for ${isSeminar ? 'semianar' : 'camp'} FAQ`}
                    wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                    options={questionsOptions}
                    value={selectedQuestions}
                    onChange={handleSelectQuestions}
                    placeholder={"Select questions"}
                />
            </div>

            {selectedQuestionsFormData.map((item, index) => (
                <div className="question-item" key={index}>
                    {!questionsOptions.some(option => option.label === item.question) && (
                        <div className='flex justify-between gap--sm items-center'>
                            <Input
                                wrapperClasses={"input--lg flex-auto mb-8"}
                                inputClasses={"input--solid"}
                                value={item.question}
                                onChange={(e) => handleSelectedQuestionsChange(index, { question: e.target.value, answer: item.answer })}
                                placeholder="Type question here..."
                                error={errors?.[index]?.question}
                            />
                            <Button className="btn btn--outline-default btn--square btn--md rounded-full" onClick={() => handleDeleteQuestion(index)}>
                                <DeleteIcon />
                            </Button>
                        </div>
                    )}
                    <Textarea
                        label={questionsOptions.some(option => option.label === item.question) ? item.question : ""}
                        wrapperClasses={"input--lg mb-32"}
                        textareaClasses={"input--solid"}
                        style={{ minHeight: 140 }}
                        value={item.answer || ''}
                        onChange={(e) => handleSelectedQuestionsChange(index, { question: item.question, answer: e.target.value })}
                        placeholder={"Please write answer here..."}
                        error={errors?.[index]?.answer}
                    />

                </div>
            ))}

            <hr className="hr mb-32" />

            <Button className="btn btn--primary btn--sm mb-32" onClick={handleAddQuestions}>
                <span className="ico">
                    <PlusIcon />
                </span>
                <span className="info">Add Personal Question</span>
            </Button>
        </>
    );
};

export default PopularQuestions;
