import { Country } from 'country-state-city';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getDateRange, getImageSrc, getTrainingStyle } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import Button from '../../ui/buttons/Button';
import DropDown from '../../ui/DropDown';

import { ReactComponent as LocationIcon } from "../../../assets/img/icon-decor-city.svg"
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots.svg"


const CardSeminar = ({ data = {}, canCancel = false, onCancel = () => { }, linkPrefix = "" }) => {
    return (
        <div className="card-seminar">
            <CardImageBlock data={data} />
            <CardBody data={data} />
            <CardFooter data={data} canCancel={canCancel} onCancel={onCancel} linkPrefix={linkPrefix} />
        </div>
    )
}


function DatesRange({ data }) {
    const dateArray = data?.seminarDates
        ? getDateRange(data?.seminarDates)?.split(',') || []
        : getDateRange([data.startAt, data.endAt], true)?.split(',') || []

    if (!dateArray?.length) return

    return (
        <span className="card-seminar__info-date">
            {dateArray[0]}, &nbsp;
            <span className='color-primary'>{dateArray[1]}</span>
        </span>
    )
}

function CardImageBlock({ data }) {
    const trainingStyle = getTrainingStyle(data?.traningStyle)

    return (
        <div className="card-seminar__img-block">
            <div className="card-seminar__img-info">
                <DatesRange data={data} />
                <span className="card-seminar__gi ml-auto">
                    {trainingStyle[0]?.icon}
                    <span>{trainingStyle[0]?.title}</span>
                </span>
            </div>
            <div className="card-seminar__img">
                <img src={getImageSrc(data?.cover)} alt="cover" />
            </div>
            {
                data.status !== "active" && (
                    <div className='card-seminar__label'>
                        Seeking {data?.user?.role === "academy" ? "Instructor" : "Academy"}
                    </div>
                )
            }
        </div>
    )
}

function CardBody({ data }) {
    const getLocation = (data) => {
        if (!data?.location) {
            return "Location not specified"
        }

        const location = data?.location ? Country.getCountryByCode(data?.location).name : data?.location;
        return `${location}, ${data?.address} `
    };
    const academyProfile = data?.userAcademy?.academyProfile

    return (
        <div className="card-seminar__body">
            <div className="card-seminar__group-city">
                <div className="ico">
                    <LocationIcon />
                </div>
                <span className='truncate'>
                    {getLocation(data)}
                </span>
            </div>

            <h4 className="heading line-clamp-2">{data?.seminarName || data?.name}</h4>

            {academyProfile && (
                <div className="card-user-block mb-18">
                    <div className="ico ico--xs">
                        <img src={getImageSrc(academyProfile?.photo)} alt="avatar" />
                    </div>
                    <div className="card-user-block__name">
                        {academyProfile?.name || "Name not specified"}
                    </div>
                </div>
            )}

            {!!data?.instructors?.length && (
                <div className="card-seminar__group-tags">
                    {data?.instructors?.map(item => <span key={item.id} className="underline">{item.user.instructorProfile.fullName}</span>)}
                </div>
            )}

        </div>
    )
}

function Actions({ onCancel, data }) {
    return (
        <DropDown
            dropDownClasses='ml-auto'
            labelButton={(
                <Button
                    onClick={onCancel}
                    className="btn btn--square btn--md radius"
                >
                    <DotsIcon />
                </Button>
            )}
        >
            <Button
                onClick={() => {
                    onCancel(data.id)
                }}
                className="btn btn--danger btn--md radius w-full"
            >
                Cancel Event
            </Button>
        </DropDown>
    )
}

function CardFooter({ data, canCancel, onCancel, linkPrefix }) {
    const user = useSelector(getUser)
    const location = useLocation();
    const currentPath = location.pathname;

    const isPending = data.status === 'pending'
    const isClient = user?.role !== "academy" && user?.role !== "instructor"

    const [basePath, id] = currentPath.split('/').filter(Boolean).slice(-2);
    const newUrl = id && id !== "camps" ? currentPath.replace(id, data?.id) : data?.id?.toString();


    const handleBook = () => {
        console.log('TODO: book');
    }

    return (
        <div className="card-seminar__footer">
            {data?.minimalPriceTicket && (
                <div className="price-group">
                    <span className="color-grey mr-8">from</span>
                    <span className="price-group__price">
                        ${data?.minimalPriceTicket}
                    </span>
                </div>
            )}
            <div className="row-group gap--xs">
                {
                    isClient && !isPending ?
                        <Button onClick={handleBook} className="btn btn--primary btn--md radius">
                            Book Now
                        </Button>
                        :
                        <Link to={linkPrefix + newUrl} className="btn btn--primary btn--md radius">
                            View Seminar
                        </Link>
                }
                {canCancel && <Actions onCancel={onCancel} data={data} />}
            </div>
        </div>
    )
}

export default CardSeminar
