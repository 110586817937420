import {Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {getImageSrc} from "../../../helpers/utils";
import React from "react";


export const SinglePhotoSwiper = ({gallery = []}) => {
    
    
    console.log(gallery, 'gallery')
    
    if (!gallery.length) return null
    
    return (
        <div className="relative">

            <Swiper
                modules={[Navigation, Pagination]}
                // navigation={{
                //     nextEl: '.swiper-arrow-next',
                //     prevEl: '.swiper-arrow-prev'
                // }}
                spaceBetween={0}
                slidesPerView={1}

                pagination={{
                    clickable: true,
                    el: '.swiper-pagination-single',
                }}
                className={`swiper-current-seminars ${''}`}
            >
                {gallery && gallery.length ? gallery.map(el => <SwiperSlide key={el.id}>
                    <img style={{width: '100%'}} src={getImageSrc(el.url)} name={el.originalname}/>
                </SwiperSlide>) : null}

            </Swiper>

            <div className="swiper-pagination swiper-pagination-single"></div>


        </div>
    )
}