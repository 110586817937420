import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getAcademySeminarsAllFetch = createAsyncThunk(
    'academySeminars/getAcademySeminarsAll',
    async (_, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.getAcademySeminarsAll();
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const getAcademySeminarsByIdFetch = createAsyncThunk(
    'academySeminars/getAcademySeminarsById',
    async (seminarId, { rejectWithValue }) => {
        
        try {
            const res = await api.academySeminars.getAcademySeminarsById(seminarId);
            console.log(res)
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const createAcademySeminarFetch = createAsyncThunk(
    'academySeminars/createAcademySeminar',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.createAcademySeminar(payload);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const updateAcademySeminarFetch = createAsyncThunk(
    'academySeminars/updateAcademySeminar',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.updateAcademySeminar(payload);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const deleteAcademySeminarFetch = createAsyncThunk(
    'academySeminars/deleteAcademySeminar',
    async (seminarId, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.deleteAcademySeminar(seminarId);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const uploadAcademySeminarPhotosFetch = createAsyncThunk(
    'academySeminars/uploadAcademySeminarPhotos',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.uploadAcademySeminarPhotos(payload);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const deleteAcademySeminarPhotoByIdFetch = createAsyncThunk(
    'academySeminars/deleteAcademySeminarPhotoById',
    async (photoId, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.deleteAcademySeminarPhotoById(photoId);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const addAcademySeminarDateFetch = createAsyncThunk(
    'academySeminars/addAcademySeminarDate',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.addAcademySeminarDate(payload);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const updateAcademySeminarDateFetch = createAsyncThunk(
    'academySeminars/updateAcademySeminarDate',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.updateAcademySeminarDate(payload);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);

export const deleteAcademySeminarDateFetch = createAsyncThunk(
    'academySeminars/deleteAcademySeminarDate',
    async (dateId, { rejectWithValue }) => {
        try {
            const res = await api.academySeminars.deleteAcademySeminarDate(dateId);
            return res.data;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response?.data);
        }
    }
);
