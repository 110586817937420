import ModalTemplate from "./ModalTemplate";
import React, { useEffect, useState } from "react";
import Input from '../ui/formElements/Input'
import InputMask from 'react-input-mask';
import AirDatepicker from 'air-datepicker';
import api from "../../api";

export const AddAnOverrideModal = ({open, handleClose, overrideAdded, rescheduleEdit}) => {
    const shortedFullDate = (value) => {
        let valueFormattedDate = new Date(String(value));
        valueFormattedDate.setHours(23);

        return valueFormattedDate.toISOString().slice(0, 10)
    };

    const [calendar, setCalendar] = useState(null);

    const [rescheduledForm, setRescheduledForm] = useState({
        days: rescheduleEdit ? [{
            ...rescheduleEdit,
            date: new Date(rescheduleEdit.date)
        }] : []
    });

    const [currentDate, setCurrentDate] = useState(rescheduleEdit ? new Date(rescheduleEdit.date) : null);

    const [fromPicker, setFromPicker] = useState(null);

    const activeDateData = currentDate ? rescheduledForm?.days?.find(item => item?.date && (shortedFullDate(item?.date) === shortedFullDate(currentDate) )) : null;

    const changeTime = (index, field, value) => {
        setRescheduledForm(prev => {
            return {
                days: [...prev.days].map(itemM => shortedFullDate(itemM?.date) === shortedFullDate(activeDateData?.date) ? 
                { date: itemM.date, times: itemM.times?.map((itemTime, itemTimeIndex) => itemTimeIndex === index ? {...itemTime, [field]: value} : itemTime ) } : itemM )
            }
        })
    }

    const selectDate = (dataAttr) => {
        let { date, formattedDate } = dataAttr;


        if(formattedDate.length){
            for (let index = 0; index < formattedDate.length; index++) {
                let currentFormattedDate = new Date(formattedDate[index]);

                currentFormattedDate.setHours(23);

                if(!rescheduledForm.days.map((day) => shortedFullDate(day.date))?.includes(shortedFullDate(currentFormattedDate))){
                    setRescheduledForm((prev)=>{
                        return {
                            days: [...prev.days, {
                                date: new Date(formattedDate[index]),
                                times: []
                            }]
                        }
                    })
                } else {
                    let formattedDateMap = formattedDate?.map(item => {
                        let currentFormattedDate2 = new Date(item);
                        currentFormattedDate2.setHours(23);

                        return shortedFullDate(currentFormattedDate2);
                    });

                    setRescheduledForm({
                        days: [...rescheduledForm.days].filter(day => { return formattedDateMap?.includes(shortedFullDate(day.date)) } ) 
                    });
                }
            }
        } else {
            setRescheduledForm({ days: [] });
        }

        setCurrentDate(date?.pop() || new Date());
    }

    const initCalendar = () => {
        const locale = {
            days: ['Sunday', 'Monday', 'Tuesday', 
            'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: ['January', 'February', 'March', 'April', 
            'May', 'June', 'July', 'August', 'September', 
            'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 
            'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'dd MMM yyyy',
            timeFormat: 'hh:mm aa',
            firstDay: 0
        }

        var minDate = new Date();
        minDate.setDate(minDate.getDate());

        let calendarInited = new AirDatepicker('.picker-calendar', {
            startDate: rescheduleEdit ? new Date(rescheduledForm?.days?.[0]?.date) : minDate,
            selectedDates: rescheduledForm?.days?.map(item => new Date(String(item?.date))),
            locale, 
            inline: true, 
            dateFormat: 'yyyy/MM/dd',
            minDate, 
            multipleDates: true,
            onSelect({ date, formattedDate }) {
                setFromPicker({ date, formattedDate })
            },
        });

        setCalendar(calendarInited);
    }

    const addOrUpdateOverride = async() => {
        const payloadToSave = {
            days: rescheduledForm.days.map(item => ({
                ...item,
                date: shortedFullDate(item.date)
            }))
        };

        const res = await api.instructorPrivateSessionInfo.addOrUpdateRescheduledDates(payloadToSave);

        if(res){
            if(overrideAdded){
                overrideAdded();
            }
        }
    }

    useEffect(() => {
        if(!calendar){
            initCalendar();
        }
    }, []);

    useEffect(() => {
        if(fromPicker?.formattedDate && fromPicker?.date){
            selectDate(fromPicker);
        }
    }, [fromPicker]);
    
    return (
        <>
            <ModalTemplate open={open} onClick={handleClose} modalSize="modal__dialog--sm">
                <div className="overide-widget">
                    <div className="overide-item">
                        <div className="overide-block">
                            <div className="overide-block--header">
                                <div className="heading-block">
                                    <h6 className="heading">
                                        Select the dates <br />
                                        you want to override
                                    </h6>
                                </div>
                            </div>
                            <div className="overide-block--body">
                                <div className="picker-calendar--wrapper custom-calendar">
                                    <input type="text" hidden className="picker-calendar" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overide-item">
                        <div className="overide-block">
                            <div className="overide-block--header">
                                <div className="heading-block">
                                    <h6 className="heading">
                                        Specify the time <br />
                                        when you are free
                                    </h6>
                                </div>
                            </div>
                            <div className="overide-block--body">
                                {
                                    activeDateData ? <>
                                    <div className="table-times mb-24">
                                        <ul className="times-list">
                                            {
                                                activeDateData?.times?.length ? 
                                                activeDateData?.times?.map((item, index)=>{
                                                    return <li className="times-list--item" key={index}>
                                                        <div className="times--item-col flex-auto">
                                                            <div className="times-control">
                                                                <div className="form-group w-full select--outline select--outline-bg --select-noarrow">
                                                                    <InputMask 
                                                                        mask="99:99" wrapperClasses={'w-full input--solid'}
                                                                        value={item.startTime} onInput={(e)=>{
                                                                            changeTime(index, "startTime", e.target.value.slice(0, 5))
                                                                        }}
                                                                    >
                                                                        {(inputProps) => <Input {...inputProps} inputClasses="px-16px" />}
                                                                    </InputMask>
                                                                </div>
                                                                <div className="times-control-split">
                                                                    <img src={require("../../assets/img/time-split-ico.svg").default} />
                                                                </div>
                                                                <div className="form-group w-full select--outline select--outline-bg --select-noarrow">
                                                                    <InputMask 
                                                                        mask="99:99" wrapperClasses={'w-full input--solid'}
                                                                        value={item.endTime} onInput={(e)=>{
                                                                            changeTime(index, "endTime", e.target.value.slice(0, 5))
                                                                        }}
                                                                    >
                                                                        {(inputProps) => <Input {...inputProps} inputClasses="px-16px" />}
                                                                    </InputMask>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="times--item-col">
                                                            <div className="times-actions">
                                                                <button
                                                                    type="button" className="btn btn--square btn--md rounded-full btn-border"
                                                                    onClick={(e)=>{
                                                                        e.preventDefault();
                                                                        if(index === activeDateData?.times?.length-1){
                                                                            setRescheduledForm(prev => {
                                                                                return {
                                                                                    days: [...prev.days].map(itemM => shortedFullDate(itemM?.date) === shortedFullDate(activeDateData?.date) ? 
                                                                                    { date: itemM.date, times: [...itemM.times, {
                                                                                            startTime: "09:00",
                                                                                            endTime: "12:00"
                                                                                        }]
                                                                                    } : itemM)
                                                                                }
                                                                            })
                                                                        } else {
                                                                            setRescheduledForm(prev => {
                                                                                return {
                                                                                    days: [...prev.days].map(itemM => shortedFullDate(itemM?.date) === shortedFullDate(activeDateData?.date) ? 
                                                                                    { date: itemM.date, times: itemM.times?.filter((itemF, itemFIndex) => itemFIndex !== index) } : itemM )
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        index === activeDateData?.times?.length-1 ? 
                                                                        <span className="ico">
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g>
                                                                                    <path d="M3.125 10H16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                    <path d="M10 3.125V16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_550_16199">
                                                                                        <rect width="20" height="20" fill="white"></rect>
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </span> : 
                                                                        <span className="ico">
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clipRule="evenodd" d="M13.4482 17.4997H6.54818C5.67568 17.4997 4.95068 16.8263 4.88568 15.9555L4.13818 5.83301H15.8332L15.1107 15.9513C15.0482 16.8238 14.3224 17.4997 13.4482 17.4997V17.4997Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M10.0002 9.16699V14.167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M3.3335 5.83366H16.6668" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M14.1668 5.83333L13.3227 3.58167C13.0785 2.93083 12.4568 2.5 11.7618 2.5H8.2385C7.5435 2.5 6.92183 2.93083 6.67766 3.58167L5.8335 5.83333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M12.8585 9.16699L12.5002 14.167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M7.1418 9.16699L7.50013 14.167" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </span>
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }) : !activeDateData?.isUnavailable ? <li className="times-list--item">
                                                    <div className="times--item-col">
                                                        <div className="times-actions">
                                                            <button
                                                                type="button" className="btn btn--square btn--md rounded-full btn-border"
                                                                onClick={(e)=>{
                                                                    e.preventDefault();
                                                                    if(currentDate){
                                                                        setRescheduledForm(prev => {
                                                                            return {
                                                                                days: [...prev.days].map(itemM => shortedFullDate(itemM?.date) === shortedFullDate(activeDateData?.date) ? 
                                                                                { date: itemM.date, times: [...itemM.times, {
                                                                                        startTime: "09:00",
                                                                                        endTime: "12:00"
                                                                                    }]
                                                                                } : itemM)
                                                                            }
                                                                        })
                                                                    }
                                                                }} 
                                                            >
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <path d="M3.125 10H16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M10 3.125V16.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_550_16199">
                                                                            <rect width="20" height="20" fill="white"></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li> : null
                                            }
                                        </ul>
                                    </div>
                                    <label className="switch-btn">
                                        <input
                                            type="checkbox" hidden
                                            onChange={(e)=>{
                                                if(e.target.checked){
                                                    setRescheduledForm(prev => {
                                                        return {
                                                            days: [...prev.days].map(itemM => shortedFullDate(itemM?.date) === shortedFullDate(activeDateData?.date) ? { date: itemM.date, isUnavailable: true } : itemM)
                                                        }
                                                    })
                                                } else {
                                                    setRescheduledForm(prev => {
                                                        return {
                                                            days: [...prev.days].map(itemM => shortedFullDate(itemM?.date) === shortedFullDate(activeDateData?.date) ? { date: itemM.date, times: [] } : itemM)
                                                        }
                                                    })
                                                }
                                            }}
                                            checked={activeDateData?.isUnavailable}
                                        />
                                        <div className="switcher">
                                            <span className="switch-status">
                                                <span className="switch-status-rounded"></span>
                                            </span>
                                        </div>
                                        <p className="switch-text">Mark unavailable (all day)</p>
                                    </label>
                                </> : null }
                            </div>
                            <div className="overide-block--footer justify-end">
                                <div className="flex-group">
                                    <a href="#" className="btn btn--sm" onClick={handleClose}>
                                        <span>Cancel</span>
                                    </a>
                                    <button
                                        type="button" className="btn btn--sm btn--primary"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            addOrUpdateOverride();
                                        }}
                                    >
                                        <span>{ rescheduleEdit ? 'Save to Override' : 'Add to Override' }</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalTemplate>
        </>
    )
}