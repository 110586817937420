import React, { useEffect, useState } from 'react'

import SeminarSingleDefaultAsside from '../parts/SeminarSingleDefaultAsside'
import GalleryImages from '../parts/GalleryImages'
import BreadCrumds from '../../../components/parts/BreadCrumds'
import SeminarSinglePendingAsside from '../parts/SeminarSinglePendingAsside'
import SinglePageInstructorsBlock from '../parts/SinglePageInstructorsBlock'
import SinglaPageSummary from '../parts/SinglaPageSummary'
import SinglePageTitleBlock from '../parts/SinglePageTitleBlock'
import SinglePageTeachSyles from '../parts/SinglePageTeachSyles'
import SeminarsLockedAt from '../../../components/parts/SeminarsLockedAt'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PageLoader from '../../../components/ui/PageLoader'
import { getAllSeminarsWithFiltersFetch, getSeminarsById } from '../../../redux/thunks/seminarsThunks'
import { getSeminarDetailes } from '../../../redux/slices/seminarsSlice'
import SinglePageMapBlock from '../parts/SinglePageMapBlock'
import { Country } from 'country-state-city'
import { getFullLocation } from '../../../helpers/utils'
import { getHistoryFetch } from '../../../redux/thunks/historyThunks'
import {SinglePhotoSwiper} from "../parts/SinglePhotoSwiper";

const SeminarSingle = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const pageData = useSelector(getSeminarDetailes);
    const { seminars, isLoading } = useSelector(state => state.seminars)
    const { history, isLoading: isHistoryLoading } = useSelector(state => state.history)

    useEffect(() => {
        dispatch(getSeminarsById(id))
        dispatch(getAllSeminarsWithFiltersFetch())
        dispatch(getHistoryFetch("seminar"))
    }, [dispatch, id])
    if (isLoading) return <PageLoader />

    return (
        <>
            <section className="pt-28 pb-42 custom-seminar-single custom-seminar-show-swiper-block">
                <div className="container">
                    <BreadCrumds breadcrumbs={[
                        {
                            label: 'Seminars',
                            link: '/seminars'
                        },
                        {
                            label: String(pageData?.seminarName).toLocaleLowerCase()
                        },
                    ]}/>
                </div>
            </section>
            <div className='custom-seminar-show-swiper-block'>
                {(pageData?.photos && pageData?.photos.length > 0) ?
                    <SinglePhotoSwiper gallery={pageData?.photos}/>
                    : null
                }
            </div>


            <section className="pt-28 pb-42 custom-seminar-single">
                <div className="container">
                    <div className='custom-seminar-mobile-hide-block'>
                        <BreadCrumds breadcrumbs={[
                            {
                                label: 'Seminars',
                                link: '/seminars'
                            },
                            {
                                label: String(pageData?.seminarName).toLocaleLowerCase()
                            },
                        ]}/>
                    </div>


                    <div className='flex flex-col'>

                            <SinglePageTitleBlock
                                title={pageData?.seminarName}
                                traningStyle={pageData?.traningStyle}
                                allowUnder18={pageData?.allowUnder18}
                                location={getFullLocation([Country.getCountryByCode(pageData?.location)?.name, pageData?.address])}
                                status={pageData?.status}
                            />


                        <div className='custom-seminar-mobile-hide-block'>
                            {(pageData?.photos && pageData?.photos.length > 0) ?
                                <GalleryImages gallery={pageData?.photos} coverId={pageData?.coverId}/>
                                : null
                            }
                        </div>

                    </div>


                </div>
            </section>
            <section className="pb-42">
                <div className="container">
                    <div className="border-bottom pb-42">
                        <div className="row gx-80">
                            <div className="col-lg-7 single-sem-first">

                                {pageData?.teachingStyles &&
                                    <SinglePageTeachSyles teachingStyles={pageData?.teachingStyles}/>
                                }
                                <SinglaPageSummary summary={pageData?.summary}/>
                                {
                                    pageData?.status === 'pending' &&
                                    pageData?.user?.role === 'academy' &&
                                    <SinglePageMapBlock/>
                                }


                                {pageData?.user?.role !== 'academy' &&
                                    <SinglePageInstructorsBlock data={pageData?.instructors}/>}

                            </div>
                            <div className="col-lg-5 single-sem-second">
                                {pageData?.status === 'pending' ?
                                    <SeminarSinglePendingAsside pageData={pageData}/>
                                    :
                                    <SeminarSingleDefaultAsside pageData={pageData}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                !isHistoryLoading && (
                    <SeminarsLockedAt
                        data={history}
                        sectionTitle={'Seminars you looked at'}
                    />
                )
            }
        </>
    )
}

export default SeminarSingle
