import React, { useState } from 'react'
import ReactTimePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

const TimePicker = ({ inputClassName = "", wrapperClassName = "", onChange, selected, error, ...props }) => {
    const [selectedTime, setSelectedTime] = useState(selected)

    const adjustTime = (minutes) => {
        if (selectedTime) {
            const newTime = new Date(selectedTime)
            newTime.setMinutes(newTime.getMinutes() + minutes)
            // setSelectedTime(newTime)
            // onChange && onChange(newTime)
            handleChange(newTime)
        } else {
            const newTime = new Date(new Date().setHours(9, 0))
            handleChange(newTime)
            // onChange && onChange(newTime)
        }
    }

    const handleChange = (time) => {
        setSelectedTime(time)
        if (onChange) {
            onChange(time)
        }
    }

    return (
        <div className={`form-group input--lg ${wrapperClassName}`}>
            <div className="time-picker-wrapper">
                <span>
                    <ReactTimePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className={`input input--solid ${inputClassName} ${error ? "input--error" : ""}`}
                        {...props}
                        selected={selectedTime}
                        onChange={handleChange}
                        icon={
                            <div className='flex flex-col justify-center' style={{
                                height: "var(--input-height)",
                                right: 12,
                                padding: 0,
                            }}>
                                <button type="button" onClick={() => adjustTime(-30)} className="time-adjust-button">
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.58657 6.36953C1.61021 6.42664 1.65026 6.47545 1.70164 6.5098C1.75302 6.54415 1.81344 6.56249 1.87524 6.5625L8.12524 6.5625C8.18708 6.56255 8.24755 6.54425 8.29899 6.50991C8.35042 6.47558 8.39051 6.42675 8.41419 6.36962C8.43786 6.31249 8.44405 6.24962 8.43197 6.18897C8.41989 6.12832 8.39009 6.07261 8.34634 6.02891L5.22134 2.90391C5.19231 2.87485 5.15785 2.8518 5.11991 2.83607C5.08197 2.82035 5.04131 2.81225 5.00024 2.81225C4.95918 2.81225 4.91851 2.82035 4.88057 2.83607C4.84264 2.8518 4.80817 2.87485 4.77915 2.90391L1.65415 6.02891C1.61046 6.07263 1.58072 6.12834 1.56869 6.18897C1.55666 6.2496 1.56288 6.31244 1.58657 6.36953Z" fill="#02020A" />
                                    </svg>
                                </button>
                                <button type="button" onClick={() => adjustTime(30)} className="time-adjust-button">
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_5159_230993)">
                                            <path d="M8.41343 3.63047C8.38979 3.57336 8.34974 3.52455 8.29836 3.4902C8.24698 3.45585 8.18656 3.43751 8.12476 3.4375H1.87476C1.81292 3.43745 1.75245 3.45575 1.70101 3.49009C1.64958 3.52442 1.60949 3.57325 1.58581 3.63038C1.56214 3.68751 1.55595 3.75038 1.56803 3.81103C1.58011 3.87168 1.60991 3.92739 1.65366 3.97109L4.77866 7.09609C4.80769 7.12515 4.84215 7.1482 4.88009 7.16393C4.91803 7.17965 4.95869 7.18775 4.99976 7.18775C5.04083 7.18775 5.08149 7.17965 5.11943 7.16393C5.15736 7.1482 5.19183 7.12515 5.22085 7.09609L8.34585 3.97109C8.38954 3.92737 8.41928 3.87166 8.43131 3.81103C8.44334 3.7504 8.43712 3.68756 8.41343 3.63047Z" fill="#02020A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_5159_230993">
                                                <rect width="10" height="10" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        }
                        showIcon
                    />
                </span>
            </div>
        </div>
    )
}

export default TimePicker
