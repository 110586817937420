import React from 'react'
import { ReactComponent as SearchIcon } from '../../assets/img/search-loupe.svg'

export const FirstScreenSearchSection = ({imageBg, description, placeholder, title, searchValue, onChange}) => {
    return (
        <section className="first-screen">
            <div className="firts-screen-bg">
                <img src={imageBg} alt="" />
                <div className="firts-screen-bg-effect"></div>
            </div>
            <div className="container">
                <div className="first-screen-body first-screen-body--center">
                    <div className="section-heading">
                        <h1 className="heading fs-48">{title}</h1>
                        <p className="small-paragraph">{description}</p>
                    </div>
                    <form className="first-screen-search">
                        <div className="form-group input--lg input--icon-left">
                            <div className="input-wrapper">
                                <span className="ico">
                                    <SearchIcon />
                                </span>
                                <input className="input input--solid" value={searchValue} type="text"
                                    placeholder={placeholder}  onChange={onChange}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
