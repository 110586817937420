import { useEffect, useState } from 'react'

import { Country } from 'country-state-city'
import { pick } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import DatePicker from '../../../components/ui/formElements/DatePicker'
import { convertObjectToFormData } from '../../../helpers/utils'
import { getUser } from '../../../redux/slices/applicationSlice'
import { updateCampFetch } from '../../../redux/thunks/planCampThuncks'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch'
import { ReactComponent as InfoIcon } from "../../../assets/img/question-circle.svg"
import Input from '../../../components/ui/formElements/Input'
import { getAcademyGeneralInfo } from '../../../redux/slices/academyGeneralInfoSlice'


const TimeAndDate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector(getUser)
    const { chatId, campId } = useParams()

    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [editedFields, setEditedFields] = useState([])
    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map(item => ({ value: item.name.toLowerCase(), label: item.name })) || [];

    const [isAcademyLocation, setIsAcademyLocation] = useState(false)

    const getIncludedDates = (date) => {
        if (!date) {
            return null
        }
        const includedDates = new Set()
        const currentDate = new Date(date);
        for (let i = 0; i <= 7; i++) {
            if (i === 0) {
                continue;
            }
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + i);
            includedDates.add(newDate);
        }
        return Array.from(includedDates);

    }
    const handleSubmit = async () => {
        setIsErrorsShown(true)

        if (!errors.hasRequiredError) {
            if (campId) {
                const body = new FormData()
                const dataToUpdate = pick(formData, editedFields)
                convertObjectToFormData(dataToUpdate, body)
                const res = await dispatch(updateCampFetch({ campId, body })).unwrap()
                if (res) {
                    navigate(`/chat/${chatId}`)

                }
            } else {
                navigate("seminar-info")
            }
        }
    }

    const handleStepChange = (name, value) => {
        handleChange(name, value)
        setEditedFields(prev => [...prev, name])
    }

    useEffect(() => {
        setErrorConfig({
            requiredFields: [
                "startAt",
                "endAt",
            ]
        })
    }, [formData])

    const academyInfo = useSelector(getAcademyGeneralInfo)
    useEffect(() => {
        if (user?.role === "academy" && academyInfo && isAcademyLocation) {
            handleChange("address", academyInfo?.addressLine)
            handleChange("location", academyInfo?.country)
            handlePreviewChange("location", `${academyInfo?.country}, ${academyInfo.city} ${academyInfo?.addressLine}`)
        }
    }, [user, academyInfo, isAcademyLocation])

    return (
        <>
            <h6 className="heading mb-36 max-w-238">Camp Location</h6>
            <div className="row gutters-form mb-36">
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        label={"Country"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                        options={locationOptions}
                        value={formData.location}
                        error={isErrorsShown && errors.location}
                        onChange={(option) => handleStepChange("location", option.value)}
                        placeholder={"Select Location"}
                        disabled={isAcademyLocation}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                    <Input
                        label="Address (academy by deafult)"
                        wrapperClasses={"input--lg"}
                        inputClasses={"input--solid"}
                        value={formData.address}
                        error={isErrorsShown && errors?.address}
                        onChange={(e) => handleStepChange("address", e.target.value)}
                        placeholder={"Enter location here..."}
                        disabled={isAcademyLocation}
                    />
                </div>
                {
                    user?.role === "academy" && (
                        <div className="col-12">
                            <div className="option-group ">
                                <div className="row-group">
                                    <CheckboxSwitch
                                        text="The seminar will take place at a third party location."
                                        checked={!isAcademyLocation}
                                        onChange={() => {
                                            handleStepChange("location", null)
                                            handleStepChange("address", "")
                                            setIsAcademyLocation(!isAcademyLocation)
                                        }}
                                    />
                                    <span className="popover ico ms-2" data-tippy-content="Content">
                                        <InfoIcon />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <hr className='hr-horisontal mb-36' />
            <h6 className="heading mb-36 max-w-238">Camp DAte</h6>

            <div className="row gutters-form">

                <div className="col-md-6 col-sm-6 col-12">
                    <div className="schedule-group-item__date">
                        <DatePicker
                            label="Start Date"
                            selected={formData?.startAt ? new Date(formData?.startAt) : null}
                            wrapperClassName="w-full"
                            inputClassName='input-date'
                            onChange={(date) => handleStepChange("startAt", moment(date).format("YYYY-MM-DD"))}
                            error={isErrorsShown && errors.startAt}
                            placeholderText="Select Date"
                            minDate={moment().toDate()}
                            includeDates={formData.endAt ? getIncludedDates(formData.endAt) : null}
                        />
                    </div>
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <div className="schedule-group-item__date">
                        <DatePicker
                            label="End Date"
                            selected={formData?.endAt ? moment(formData?.endAt).toDate() : null}
                            wrapperClassName="w-full"
                            inputClassName='input-date'
                            onChange={(date) => handleStepChange("endAt", moment(date).format("YYYY-MM-DD"))}
                            error={isErrorsShown && errors.endAt}
                            placeholderText="Select Date"
                            minDate={moment().toDate()}
                            includeDates={formData.startAt ? getIncludedDates(formData.startAt) : null}
                        />
                    </div>
                </div>
            </div>

            <div className="page-action-group">
                <Link to={"/"} className="btn btn--default btn--sm">Cancel</Link>
                <button onClick={handleSubmit} className="btn btn--primary btn--sm">
                    {
                        campId ?
                            <span className="info">Apply Changes</span>
                            :
                            <>
                                <span className="info">Continue</span>
                                <span className="ico">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                    </svg>
                                </span>
                            </>

                    }
                </button>
            </div>
        </>
    )
}

export default TimeAndDate