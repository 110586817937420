import ModalTemplate from "./ModalTemplate";
import React, {useEffect, useState} from "react";
import Input from "../ui/formElements/Input";
import SelectOne from "../ui/formElements/SelectOne";
import api from "../../api";

export const AddPastInstructors = ({open, handleClose, data, mode, instructorData, onSuccess}) => {

    const {id, belts} = data

    const [formData, setFormData] = useState({
        fullName: '',
        belt: null,
    });
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (instructorData) {
            setFormData({fullName: instructorData.fullName, belt: {value: instructorData.belt, label: instructorData.belt}})
        }
    }, []);

    const save = async (e) => {
        e.preventDefault()

        let anyErrors = false

        Object.entries(formData).forEach(([key, value]) => {
           if (!value) {
               anyErrors = true
               setErrors(prevState => ({...prevState, [key]: true}));
           }
        })

        if (anyErrors) return

        setIsLoading(true)

        if (mode==='edit') {
            const res = await api.pastInstructors.updatePastInstructors({
                "fullName": formData.fullName,
                "belt": formData.belt.value
            }, instructorData.id)

            if (res.success) {
                onSuccess && onSuccess({
                    action: 'edit',
                    instructor: res.data
                })

                handleClose && handleClose()
            }

        } else {
            const res = await api.pastInstructors.addPastInstructors({
                "fullName": formData.fullName,
                "belt": formData.belt.value
            })

            if (res.success) {
                onSuccess && onSuccess({
                    action: 'create',
                    instructor: res.data
                })

                handleClose && handleClose()
            }
        }
        setIsLoading(false)
    }

    const deleteInstructor = async (e) => {
        e.preventDefault()
        const res = await api.pastInstructors.deletePastInstructors(instructorData.id)

        if (res.success) {
            onSuccess && onSuccess({
                action: 'delete',
                instructor: { id: instructorData.id }
            })

            handleClose && handleClose()
        }
    }

    
    return (
        <>
            <ModalTemplate open={open} onClick={handleClose}>
                <div className="modal__body">
                    <form className="form form--lg">
                        <div className="form-header">
                            <div className="heading-block">
                                <h3 className="heading">{mode==='create'?'Add New Instructor':'Update Instructor'}</h3>
                                {/*<p className="text--md desk-heading">*/}
                                {/*    Select one of options below to create account*/}
                                {/*</p>*/}
                            </div>
                        </div>

                        <div className="form-body">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <div className="add-widget-control">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            inputClasses={'input--solid'}
                                            placeholder={'Rafael Mendes'}
                                            label={"Full Name"}
                                            value={formData.fullName}
                                            onInput={(e)=>{
                                                setFormData(prevState => ({...prevState, fullName: e.target.value}))
                                                setErrors(prevState => ({...prevState, fullName: false}))
                                            }}
                                        />
                                    </div>
                                    {errors.fullName && <p className='error-input'>Enter a full name</p>}
                                </div>

                                <div className="col-12">
                                    <div className="add-widget-control" >
                                        <SelectOne
                                            options={belts}
                                            wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                            label={'Academy'}
                                            value={formData.belt}
                                            onChange={(val) => {
                                                setFormData(prevState => ({...prevState, belt: val}))
                                                setErrors(prevState => ({...prevState, belt: false}))
                                            }}
                                        />
                                    </div>
                                    {errors.belt && <p className='error-input'>Choose a belt</p>}
                                </div>

                            </div>
                        </div>

                        <div className="form-footer">

                            <div className={`flex-group  ${mode==='edit'?'justify-between':'justify-end'}`}>
                                {mode === 'edit' && <button onClick={deleteInstructor} className={`btn btn--danger btn--sm ${isLoading ? 'disabled' : ''}`}><span>Delete</span>
                                </button>}
                                <button onClick={save} className={`btn btn--primary btn--sm ${isLoading ? 'disabled' : ''}`}><span>Save</span>
                                </button>
                            </div>
                        </div>

                    </form>

                </div>


            </ModalTemplate>
        </>
    )
}