import React from 'react'

const SeminarOverviewPrices = () => {
    return (
        <div className="form-header">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="intro__col">
                        <div className="intro__row__item">
                            <div className="seo-board">
                                <div className="seo-board--item">
                                    <p className="seo-board--title">Instructor earned</p>
                                    <div className="seo-board-val"><p>$123.00</p></div>
                                </div>
                                <div className="seo-board--item">
                                    <p className="seo-board--title">Academy earned</p>
                                    <div className="seo-board-val"><p>$123.00</p></div>
                                </div>
                                <div className="seo-board--item">
                                    <p className="seo-board--title">Tickets sold</p>
                                    <div className="seo-board-val"><p>123</p></div>
                                </div>
                                <div className="seo-board--item">
                                    <p className="seo-board--title">Total earned</p>
                                    <div className="seo-board-val"><p>$123.00</p></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeminarOverviewPrices
