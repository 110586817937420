import React, { useState, useEffect } from 'react'
import FQA from '../components/parts/FQA'
import WhyChoseUs from '../components/parts/WhyChoseUs'
import TopInstructors from '../components/parts/TopInstructors'
import SeminarsSliderSection from '../components/parts/SeminarsSliderSection'
import HowPlatformWork from '../components/parts/HowPlatformWork'
import api from "../api";
import PageLoader from "../components/ui/PageLoader"
import { useDispatch, useSelector } from "react-redux";
import { getAuthorized, getUser, setAuthModalOpen } from "../redux/slices/applicationSlice";
import { getImageSrc } from '../helpers/utils'
import SingUpModal from '../components/modals/singUpModals/SingUpModal'

import { ReactComponent as ChevronNextIcon } from "../assets/img/ico-chevron--next.svg"
import AuthProvider, { AuthModals } from '../components/modals/singUpModals/AuthProvider'
import PlanningModal from '../components/modals/PlanningModal'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
    const [pageData, setPageData] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [planModalOpen, setPlanModalOpen] = useState(false)
    const user = useSelector(getUser)
    const isAuthorized = useSelector(getAuthorized)


    const getPageData = async () => {
        let res = await api.pages.getPagesByPath("/");
        setPageData(res.data);
    }

    useEffect(() => {
        getPageData();
    }, []);


    const headText = () => {
        if (!isAuthorized) {
            return `Plan your <span style="color:#00F4F1;">Seminar</span> today`
        }
        if (user && user.role !== 'student') {
            return `Plan your <span style="color:#00F4F1;">Seminar</span> today`
        } else {
            return `Check out the upcoming seminars!`
        }
    }

    const buttonActionText = () => {
        if (!isAuthorized) {
            return `Sign Up`
        }
        if (user && user.role !== 'student') {
            return `Plan Seminar`
        } else {
            return `Upcoming Seminars`
        }
    }

    const heroButtonData = (() => {
        // if (!isAuthorized) {
        //     return {
        //         action: () => dispatch(setAuthModalOpen(AuthModals.singUp)),
        //         text: `Sign Up`
        //     }
        // }
        if (user && user.role !== 'student') {
            return {
                action: () => setPlanModalOpen(true),
                text: `Plan Seminar`
            }
        } else {
            return {
                action: () => navigate("/instructors"),
                text: pageData?.blocks?.[0]?.blockPresets?.[2]?.value || `All instructors`
            }
        }
    })()

    return (
        pageData ? (
            <>
                <section className="first-screen first-screen-home">
                    <div className="firts-screen-bg">
                        <img src={getImageSrc(pageData?.blocks?.[0]?.blockPresets?.[3]?.value, require('../assets/img/first-screen-bg-1.jpg'))} alt="hero" />
                        <div className='home-bg-gradient'></div>
                        <div className="firts-screen-bg-effect"></div>
                    </div>
                    <div className="container">
                        <div className="first-screen-body mw-600">
                            <h1 className="heading mb-42 max-sm-text-center" dangerouslySetInnerHTML={{ __html: headText() || `Book Your <br /><span className="color-primary">Seminar</span> Today` }}></h1>
                            <p
                                className="first-screen-body__description mb-60"
                                dangerouslySetInnerHTML={{ __html: pageData?.blocks?.[0]?.blockPresets?.[1]?.value || `We make booking Plan Seminars easier for both students and academies. On the platform, BJJ academies will be able to send a request/book fighters in accordance to their time schedule and location.` }}
                            />
                            <button onClick={heroButtonData.action} className="btn btn--primary btn--lg max-md-w-full">
                                {heroButtonData.text}
                                <span className="ico ico-14">
                                    <ChevronNextIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </section>
                <HowPlatformWork pageData={pageData} />
                <SeminarsSliderSection sectionTitle={'Upcoming Seminars'} />
                <TopInstructors pageData={pageData} />
                <WhyChoseUs blockData={pageData?.blocks?.[3]} />
                <FQA blockData={pageData?.blocks?.[4]} />
                <AuthProvider />
                {
                    planModalOpen && <PlanningModal open={planModalOpen} handleClose={() => setPlanModalOpen(false)} />
                }
            </>
        ) : <PageLoader />
    )
}

export default HomePage
