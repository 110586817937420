import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api'; // Adjust the import path as necessary
import { toast } from 'react-toastify';

export const getMyContractsFetch = createAsyncThunk(
    '/contracts',
    async (payload = null, { rejectWithValue }) => {
        try {
            const res = await api.contracts.getMyContracts();
            if(res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
export const signToContractFetch = createAsyncThunk(
    '/contracts',
    async (singId, { rejectWithValue }) => {
        try {
            const res = await api.contracts.signToContract(singId);
            if(res.success) {
                toast.success(res.message)
                return res.data;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);