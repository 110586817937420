import { useEffect, useState } from 'react'
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch'

import { City, Country } from 'country-state-city'
import { pick } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ReactComponent as InfoIcon } from "../../../assets/img/question-circle.svg"
import Input from '../../../components/ui/formElements/Input'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import { convertObjectToFormData } from '../../../helpers/utils'
import { getAcademyGeneralInfo } from '../../../redux/slices/academyGeneralInfoSlice'
import { getUser } from '../../../redux/slices/applicationSlice'
import { updateSeminarFetch } from '../../../redux/thunks/seminarsThunks'
import DateRangeSelect from '../parts/DateRangeSelect'
import SpecificDatesSelect from '../parts/SpecificDatesSelect'
import { countryToContinent } from '../../../constants'
import EventDatesSelect from '../parts/EventDatesSelect'


const TimeAndDate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { chatId, seminarId } = useParams()
    const user = useSelector(getUser)
    const { seminarDetails } = useSelector(state => state.seminars)
    const [isAcademyLocation, setIsAcademyLocation] = useState(user?.role === "academy" ? seminarDetails?.isAcademyLocation || true : false)
    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [editedFields, setEditedFields] = useState([])

    const handleSubmit = async () => {
        setIsErrorsShown(true)
        console.log(errors);

        if (!errors.hasRequiredError) {
            if (seminarId) {
                const body = new FormData()
                const dataToUpdate = pick(formData, editedFields)
                convertObjectToFormData(dataToUpdate, body, false)
                if (editedFields.includes("country")) {
                    body.set("country", Country.getCountryByCode(formData.country))
                }
                const res = await dispatch(updateSeminarFetch({ seminarId, body })).unwrap()
                if (res) {
                    // navigate(`seminar-info`)
                    navigate(`/chat/${chatId}`)
                }
            } else {
                navigate("seminar-info")
            }
        }
    }

    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const optionAll = [{ label: "All", value: "all" }]
    const continentOptions = [
        { label: "Asia", value: "Asia" },
        { label: "Africa", value: "Africa" },
        { label: "North America", value: "North America" },
        { label: "South America", value: "South America" },
        { label: "Antarctica", value: "Antarctica" },
        { label: "Europe", value: "Europe" },
        { label: "Oceania", value: "Oceania" }
    ];


    const countryOptions = Country.getAllCountries()
        ?.filter(item => {
            if (countryToContinent[item.isoCode] === formData?.continent) {
                return item
            } else if (formData?.continent === "all") {
                return item
            }
        })
        ?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const cityOptions = (formData?.country === "all" ? [] : City.getCitiesOfCountry(formData?.country))
        ?.map(item => ({ value: item.name, label: item.name })) || [];

    useEffect(() => {
        setErrorConfig({
            exceptions: [
                // ...(!formData?.isAvailablePrivateLesson ?
                //     ["privateSessionDates"] : [])
            ],
            requiredFields: [
                "availableDates",
                ...((!isAcademyLocation && user?.role === "academy") ? ["address", "location"] : []),
                ...(user?.role === "instructor" ? ["continent"] : []),
                // ...(formData?.isAvailablePrivateLesson ?
                //     ["privateSessionDates"]
                //     : []
                // ),
            ],
            validators: {
                availableDates: (value) => {
                    if (!value.length) return false
                    let isValid = true
                    value.forEach(item => {
                        if (!item.date) {
                            isValid = false
                        }
                    })
                    return isValid
                }
            }
        })
    }, [formData, user])

    const academyInfo = useSelector(getAcademyGeneralInfo)
    useEffect(() => {
        if (user?.role === "academy" && academyInfo && isAcademyLocation) {
            handleChange("address", academyInfo?.addressLine)
            handleChange("location", academyInfo?.country)
            handlePreviewChange("location", `${academyInfo?.country}, ${academyInfo.city} ${academyInfo?.addressLine}`)
        }
    }, [user, academyInfo, isAcademyLocation])

    useEffect(() => {
        setIsAcademyLocation(user?.role === "academy" ? seminarDetails?.isAcademyLocation || true : false)
    }, [user, seminarDetails])

    const handleStepChange = (name, value) => {
        handleChange(name, value)
        setEditedFields(prev => [...prev, name])
        if (name === "country") {
            handlePreviewChange("location", `${Country.getCountryByCode(value)?.name || value} ${formData.city}`)
        }
        if (name === "city") {
            handlePreviewChange("location", `${Country.getCountryByCode(formData.country)?.name || formData.country} ${value}`)
        }
    }

    const [isInterval, setIsInterval] = useState(true);
    return (
        <>
            <div className="mb-42">
                <div className="row gx-16 py-24 mb-10 ">
                    {
                        user?.role === "academy" ?
                            <>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        label={"Country"}
                                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                        options={locationOptions}
                                        value={formData.location}
                                        error={isErrorsShown && errors.location}
                                        onChange={(option) => handleStepChange("location", option.value)}
                                        placeholder={"Select Location"}
                                        disabled={isAcademyLocation}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        label="Address (academy by deafult)"
                                        wrapperClasses={"input--lg"}
                                        inputClasses={"input--solid"}
                                        value={formData.address}
                                        error={isErrorsShown && errors?.address}
                                        onChange={(e) => handleStepChange("address", e.target.value)}
                                        placeholder={"Enter location here..."}
                                        disabled={isAcademyLocation}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className='col-12'>
                                    <h4 className='row-group'>
                                        Set your location Availability
                                        <span className="popover ico ms-2" data-tippy-content="Content">
                                            <InfoIcon />
                                        </span>
                                    </h4>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        label="Continent"
                                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                        inputClasses={"input--solid"}
                                        value={formData?.continent}
                                        options={[...optionAll, ...continentOptions]}
                                        error={isErrorsShown && errors?.continent}
                                        onChange={(option) => handleStepChange("continent", option?.value)}
                                        placeholder={"Enter location here..."}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        label="Country"
                                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                        inputClasses={"input--solid"}
                                        value={formData?.country}
                                        options={[...optionAll, ...countryOptions]}
                                        error={isErrorsShown && errors?.country}
                                        onChange={(option) => handleStepChange("country", option?.value)}
                                        placeholder={"Enter location here..."}
                                        disabled={!formData?.continent}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        label="City / State"
                                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                        inputClasses={"input--solid"}
                                        value={formData?.city}
                                        options={[...optionAll, ...cityOptions]}
                                        error={isErrorsShown && errors?.city}
                                        onChange={(option) => handleStepChange("city", option?.value)}
                                        placeholder={"Enter location here..."}
                                        disabled={!formData?.country}
                                    />
                                </div>

                            </>

                    }
                </div>
                {
                    user?.role === "academy" ?
                        <div className="option-group ">
                            <div className="row-group">
                                <CheckboxSwitch
                                    text="The seminar will take place at a third party location."
                                    checked={!isAcademyLocation}
                                    onChange={() => {
                                        handleStepChange("location", null)
                                        handleStepChange("address", "")
                                        setIsAcademyLocation(!isAcademyLocation)
                                    }}
                                />
                                <span className="popover ico ms-2" data-tippy-content="Content">
                                    <InfoIcon />
                                </span>
                            </div>
                        </div>
                        : null
                }
            </div>
            <hr className='hr mb-42' />
            <div className="row-group mb-36">
                <CheckboxSwitch
                    checked={isInterval}
                    onChange={() => setIsInterval(!isInterval)}
                />
                <h6 className="heading" style={{ marginLeft: 16 }}>Set Date Interval</h6>
                <span className="popover ico ms-2" data-tippy-content="Content">
                    <InfoIcon />
                </span>
            </div>
            {
                seminarId
                    ? <EventDatesSelect
                        dates={formData.availableDates}
                        handleChange={(dates) => handleStepChange("availableDates", dates)}
                        isErrorsShown={isErrorsShown}
                    />
                    :
                    <DateRangeSelect
                        disabled={!isInterval}
                        handleChange={(dates) => handleStepChange("availableDates", dates)}
                        errors={errors}
                        isErrorsShown={isErrorsShown}
                    />
            }

            {
                !seminarId && (
                    <>
                        <div className="row-group mb-36 mt-42">
                            <CheckboxSwitch
                                checked={!isInterval}
                                onChange={() => setIsInterval(!isInterval)}
                            />
                            <h6 className="heading" style={{ marginLeft: 16 }}>OR Set Specific Dates</h6>
                            <span className="popover ico ms-2" data-tippy-content="Content">
                                <InfoIcon />
                            </span>
                        </div>
                        <SpecificDatesSelect
                            disabled={isInterval}
                            dates={formData.availableDates}
                            handleChange={(dates) => handleStepChange("availableDates", dates)}
                            errors={errors}
                            isErrorsShown={isErrorsShown}
                        />
                    </>

                )
            }
            {/* <EventDatesSelect dates={formData.availableDates} handleChange={(dates) => handleStepChange("availableDates", dates)} errors={errors} isErrorsShown={isErrorsShown} /> */}

            <hr className='hr mb-42 mt-42' />
            <div className="option-group mb-36">
                <div className="row-group">
                    <CheckboxSwitch
                        text={user?.role === "instructor"
                            ? "I want to do private sessions before/after the seminar"
                            : "Is instructor allowed to do private sessions before/after the seminar?"
                        }
                        checked={formData.isAvailablePrivateLesson}
                        onChange={() => handleStepChange("isAvailablePrivateLesson", !formData.isAvailablePrivateLesson)}
                    />
                    <span className="popover ico ms-2" data-tippy-content="Content">
                        <InfoIcon />
                    </span>
                </div>
            </div>

            {
                seminarId && formData.isAvailablePrivateLesson ?
                    <EventDatesSelect
                        dates={formData.privateSessionDates}
                        handleChange={(dates) => handleStepChange("privateSessionDates", dates)}
                        errors={errors}
                        isErrorsShown={isErrorsShown}
                    />
                    : null
            }
            <div className="page-action-group mobile-actions">
                <Link to={seminarId ? `/chat/${chatId}` : "/"} className="btn btn--default btn--sm">Cancel</Link>
                <button onClick={handleSubmit} className="btn btn--primary btn--sm">
                    {
                        seminarId ?
                            <span className="info">Apply Changes</span>
                            :
                            <>
                                <span className="info">Continue</span>
                                <span className="ico">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                    </svg>
                                </span>
                            </>

                    }
                </button>
            </div>
        </>
    )
}

export default TimeAndDate