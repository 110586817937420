import ModalTemplate from './ModalTemplate'

import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as CheckIcon } from "../../assets/img/ico-check-editor.svg"
import Template from '../../pages/editor/templates/Template'
import { templates } from '../../pages/editor/templates/variants'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentTemplate } from '../../redux/slices/editorSlice'

const SelectTemplateModal = ({ open, handleClose }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [selectedTemplate, setSelectedTemplate] = useState(null)


    const handleSubmit = () => {
        if (!selectedTemplate) return
        dispatch(setCurrentTemplate(selectedTemplate))
        navigate("/template-editor", {
            state: {
                previousLocation: location.pathname
            },
        });
        handleClose && handleClose()
    }


    return (
        <ModalTemplate open={open} onClick={handleClose} modalSize='modal__dialog--xl'>
            <div className="modal__body p-0">
                <form className="form">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">Select Cover Template</h3>
                            <p className="text--md desk-heading">
                                Select one of ready cover templates to use
                            </p>
                        </div>
                        <button type='button' onClick={handleSubmit} className={`btn btn--primary btn--md gap-10 ${!selectedTemplate ? "disabled" : ""}`}>
                            <span className="info">Submit Template</span>
                            <span className="ico ico-18">
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M7.25 3.375L12.875 9L7.25 14.625"
                                            stroke="currentColor" strokeWidth="2"
                                            strokeLinecap="square"></path>
                                    </g>
                                    <defs>
                                        <clipPath>
                                            <rect width="18" height="18"
                                                fill="white" transform="translate(0.5)"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="form-body cards-templates-container modal__body scroll-custom">
                        <div className="row g-16 gutters-form-6">
                            {
                                templates.map(template => (
                                    <div
                                        key={template.templateId}
                                        className="col-md-4 col-sm-6 col-12 flex justify-center"
                                        style={{
                                            aspectRatio: "1.32244897959",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <label className="editor-checkbox" style={{ transform: "scale(0.46)" }}>
                                            <input type="radio"
                                                hidden
                                                className='hidden'
                                                name="template"
                                                checked={selectedTemplate?.templateId === template.templateId}
                                                onChange={() => setSelectedTemplate(template)}
                                            />
                                            <span className="ico ico-checked">
                                                <CheckIcon />
                                            </span>
                                            <div className="editor-checkbox--overlay"></div>
                                            <Template template={template} />
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate >
    )
}

export default SelectTemplateModal