import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/ui/Loader'
import { getUser } from '../../../redux/slices/applicationSlice'

import classNames from 'classnames'
import { ReactComponent as ChevronRightIcon } from "../../../assets/img/ico-chevron--next.svg"
import { ReactComponent as SearchIcon } from "../../../assets/img/ico-search-loupe.svg"
import { getDateRange, getImageSrc } from '../../../helpers/utils'
import { getCampsMyAvailableToInstructorFetch, inviteInstructorCampRequestFetch } from '../../../redux/thunks/planCampThuncks'
import { getSeminarsMyAvailableToInstructorFetch } from '../../../redux/thunks/seminarsThunks'
import moment from 'moment'


const InviteCamp = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(getUser);


    const [campsShownCount, setCampsShownCount] = useState(5)

    const [selectedCamp, setSelectedCamp] = useState(null)


    const { camps, isLoading: isCampsLoading } = useSelector(state => state.planCamps)

    const [shownCamps, setShownCamps] = useState(camps)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        setShownCamps(
            camps?.filter(camp =>
                camp?.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [camps, searchTerm]);


    const handleSubmit = async () => {
        if (!selectedCamp) {
            toast.error("Select at least one camp");
        }
        const res = await dispatch(inviteInstructorCampRequestFetch({ plancampId: selectedCamp, instructorId: id })).unwrap()
        if (res?.chatId) {
            navigate(`/chat/${res?.chatId}`);
        }

    };

    const handleCampSelect = (campId) => {
        setSelectedCamp(campId)
    }

    const getCover = (photos) => photos?.find(item => item.isCover)?.url

    useEffect(() => {
        if (!id) return;
        dispatch(getCampsMyAvailableToInstructorFetch(id))
    }, [])
    return (
        <>
            <div className="form-body">
                <div className="form-items-list">
                    <div className="form-item">
                        <div className="row gutters-form-4">
                            <div className="col-12">
                                <div className="heading-row">
                                    <div className="flex-ico-ss-text">
                                        <h6 className="heading">
                                            Select camp(s) or create new to invite instructor:
                                        </h6>
                                    </div>
                                    <div className="flex-group">
                                        {
                                            user?.role === "academy" &&
                                            <Link to={"/plan-camp"} className="btn btn--primary btn--sm">
                                                <span className="info">Create New Planned Camp</span>
                                            </Link>
                                        }
                                        {
                                            user?.role === "instructor" &&
                                            <Link to={"/plan-camp/instructor"} className="btn btn--primary btn--sm">
                                                <span className="info">Create New Planned Camp</span>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group input--md input--icon-left">
                                    <div className="input-wrapper">
                                        <span className="ico color-text">
                                            <SearchIcon />
                                        </span>
                                        <input
                                            className="input input--solid"
                                            type="text" placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                {
                                    !isCampsLoading && shownCamps?.length ?
                                        <ul className="row gutters-form">
                                            {
                                                [...shownCamps].splice(0, campsShownCount).map(camp => (
                                                    <li className="col-12" key={camp.id}>
                                                        <label className="select-check">
                                                            <input
                                                                type="checkbox"
                                                                // checked={selectedCampsIds.includes(camp.id)}
                                                                checked={selectedCamp === camp.id}
                                                                onChange={() => handleCampSelect(camp.id)}
                                                                hidden
                                                                className='hidden'
                                                            />
                                                            <div className="select-seminar-label">
                                                                <div className="seminar-label-item">
                                                                    <span className="check"></span>
                                                                </div>
                                                                <div className="seminar-label-item">
                                                                    <div className="image-wrapper --small-thumb">
                                                                        <img src={getImageSrc(getCover(camp.photos))} />
                                                                    </div>
                                                                </div>
                                                                <div className="seminar-label-item">
                                                                    <div className="seminar-label-info">
                                                                        <p className="text-sm color-text">{camp.instructorLocation}</p>
                                                                        <h6 className="heading">{camp.name}</h6>
                                                                    </div>
                                                                    <p className="text-sm">
                                                                        {moment(camp?.startAt).format("DD MMM, YYYY")}
                                                                        {" "}
                                                                        -
                                                                        {" "}
                                                                        {moment(camp?.endAt).format("DD MMM, YYYY")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        : isCampsLoading ?
                                            <Loader />
                                            :
                                            <p>Not found</p>
                                }
                            </div>
                            {
                                shownCamps?.length > campsShownCount ?
                                    <div className="col-12">
                                        <button onClick={() => setCampsShownCount(prev => prev + 5)} type="button"
                                            className="btn btn--sm radius btn-light w-full">
                                            <span>Show More</span>
                                        </button>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-footer">
                <div className="flex-group justify-between">
                    <Link to={-1} className="btn btn--default btn--sm">Cancel</Link>
                    <button onClick={handleSubmit} type="button" disabled={!selectedCamp} className={classNames("btn btn--primary btn--sm", { "disabled": !selectedCamp })}>
                        <span className="info">Send Invite</span>
                        <span className="ico">
                            <ChevronRightIcon />
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default InviteCamp