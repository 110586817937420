import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthModalOpen } from '../../../redux/slices/applicationSlice'
import ForgotPass from './ForgotPass'
import LoginModal from './LoginModal'
import RestorePass from './RestorePass'
import SingUpModal from './SingUpModal'

export const AuthModals = {
    login: "login",
    singUp: "singUp",
    fogrotPassword: "fogrotPassword",
    resetPassword: "resetPassword",
}

const AuthProvider = () => {
    const dispatch = useDispatch()
    const openModal = useSelector(state => state.application.authModalOpen)

    const isLoginOpen = openModal === AuthModals.login
    const isSingUpOpen = openModal === AuthModals.singUp
    const isFogrotPasswordOpen = openModal === AuthModals.fogrotPassword
    const isResetPasswordOpen = openModal === AuthModals.resetPassword

    const handleOpen = (name) => {
        dispatch(setAuthModalOpen(name))
    }
    const handleClose = () => {
        dispatch(setAuthModalOpen(null))
    }

    return (
        <>
            {isLoginOpen && <LoginModal
                open={isLoginOpen}
                handleClose={handleClose}
                forgotPassHandler={() => {
                    handleOpen(AuthModals.fogrotPassword)
                }}
                singUpHandler={() => {
                    handleOpen(AuthModals.singUp)
                }}
            />}
            {isSingUpOpen && <SingUpModal open={isSingUpOpen} handleClose={handleClose} loginHandler={() => {
                handleOpen(AuthModals.login)
            }} />}
            {isFogrotPasswordOpen && <ForgotPass open={isFogrotPasswordOpen} handleClose={handleClose} loginHandler={() => {
                handleOpen(AuthModals.login)
            }} />}
            {isResetPasswordOpen && <RestorePass open={isResetPasswordOpen} handleClose={handleClose} loginHandler={handleClose} />}
        </>
    )
}

export default AuthProvider