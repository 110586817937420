import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api'; // Adjust the import path as necessary
import { toast } from 'react-toastify';

export const getHistoryFetch = createAsyncThunk(
    '/getHistoryFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.history.getHistory(payload);
            if (res.success) {
                return res;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);

export const clearHistoryFetch = createAsyncThunk(
    '/clearHistoryFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.history.clearHistory(payload);
            if (res.success) {
                toast.success(res.message);
                return res;
            }
        } catch (err) {
            toast.error(err);
            return rejectWithValue(err?.response?.data);
        }
    }
);
