import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getUserFetch = createAsyncThunk(
    '/get-user/',
    async (payload = null, { rejectWithValue }) => {
        try {
            const res = await api.users.getUser(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getInstructorGeneralInfoFetch = createAsyncThunk(
    '/getInstructorGeneralInfoFetch/',
    async (payload = null, { rejectWithValue }) => {
        try {
            const res = await api.profiles.getInstructorGeneralInfo(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updateUserFetch = createAsyncThunk(
    '/update-user/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.users.updateUser(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);