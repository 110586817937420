import { Country } from 'country-state-city';
import React, { useEffect, useState } from 'react';
import AccordeonTypeArrow from '../../components/ui/accordeons/AccordeonTypeArrow';
import SelectOne from '../../components/ui/formElements/SelectOne';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/ui/PageLoader';
import { getAllAcademiesFetch } from '../../redux/thunks/academyGeneralInfoThunks';
import { Link } from 'react-router-dom';
import MapBox from "../../components/parts/mapBox/MapBox";

const AcademiesPage = () => {
    const dispatch = useDispatch()
    const { academies, isLoading } = useSelector(state => state.academyGeneralInfoApp)

    const [country, setCountry] = useState("")

    const allCountries = Country.getAllCountries();
    const countries = allCountries?.map(item => ({ value: item.name, label: item.name })) || [];

    const [activeAcademy, setActiveAcademy] = useState(null)
    const [academiesData, setAcademiesData] = useState([])

    useEffect(() => {
        if (!academies.length) return
        setAcademiesData(academies.map(academy => ({
            id: academy.id,
            addressLine: academy?.info?.addressLine,
            title: academy?.name,
            coordinates: [academy?.info?.longitude, academy?.info?.latitude],
        })))
    }, [academies]);

    useEffect(() => {
        dispatch(getAllAcademiesFetch(`append_info=true&${country ? "country=" + country : ""}`))
    }, [country])

    if (isLoading) {
        return <PageLoader />
    }

    const mainPoints = activeAcademy ? [{ coordinates: activeAcademy.coordinates }] : []

    const secondaryPoints = academiesData.map(el => {
        if (el.id === activeAcademy?.id) {
            return false
        } else {
            return {
                coordinates: [...el.coordinates]
            }
        }
    }).filter(el => el)

    const newCenter = activeAcademy ? { coordinates: activeAcademy.coordinates } : null

    return (
        <div className="academies-page">
            <aside className="academies-page__sidebar academies-sidebar">
                <div className="academies-sidebar__header">
                    <h3 className="heading mb-36">Explore Academies</h3>
                    <div className="col-group gap--xl">

                        <SelectOne
                            options={countries}
                            placeholder={'All countries'}
                            onChange={(option) => setCountry(option.value)}
                            value={country}
                            wrapperClasses={'select--outline input--md'}
                        />
                        <div className="form-group input--md input--icon-left">
                            <div className="input-wrapper">
                                <span className="ico">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10.137" cy="10.137" r="6.47345" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                        <path d="M18.3365 18.3365L14.7141 14.7141" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <input className="input input--solid" type="text" placeholder="Search academies..." />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="academies-sidebar__body">
                    {
                        academies.length ?
                            academiesData.map(academy => (
                                <AccordeonTypeArrow
                                    key={academy.id}
                                    onClick={(val, isActive) => {
                                        if (isActive) {
                                            setActiveAcademy(val)
                                        } else {
                                            setActiveAcademy(null)
                                        }
                                    }}
                                    objData={academy}
                                    isActive={activeAcademy?.id === academy.id}
                                    title={academy?.title}
                                >
                                    <p className="color-grey">{academy.addressLine}</p>
                                    <Link to={`${academy?.id}`} className="btn btn--md btn--primary">
                                        <span>Go to Profile</span>
                                    </Link>
                                </AccordeonTypeArrow>
                            ))
                            :
                            <h1>No found</h1>
                    }
                </div>
            </aside>
            <div className="academies-page__map map">
                <MapBox mainPoints={mainPoints} newCenter={newCenter?.coordinates} secondaryPoints={secondaryPoints} />
            </div>
        </div>
    )
}

export default AcademiesPage
