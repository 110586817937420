import { pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import InviteInstructorModal from '../../../components/modals/InviteInstructorModal'
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch'
import DatePicker from '../../../components/ui/formElements/DatePicker'
import Input from '../../../components/ui/formElements/Input'
import SelectMulty from '../../../components/ui/formElements/SelectMulty'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import { belts, languages, teachingStyles, trainingStyle } from '../../../constants'
import { checkErrors, convertObjectToFormData, getImageSrc } from '../../../helpers/utils'
import { getUser } from '../../../redux/slices/applicationSlice'
import { updateSeminarFetch } from '../../../redux/thunks/seminarsThunks'

const SeminarInformation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { seminarId, chatId } = useParams()
    const user = useSelector(getUser)
    const { seminarDetails, isLoading } = useSelector(state => state.seminars)
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [formData, handleChange, errors, handlePreviewChange, preview, setErrorConfig] = useOutletContext();
    const [isSharedProfit, setIsSharedProfit] = useState(formData.sharedProfit || false)
    const [editedFields, setEditedFields] = useState([])

    const handleStepChange = (name, value) => {
        handleChange(name, value)
        setEditedFields(prev => [...prev, name])
    }

    const handleSubmit = async () => {
        setIsErrorsShown(true)
        console.log(errors);
        if (!errors?.hasRequiredError) {
            if (seminarId) {
                const body = new FormData()
                const dataToUpdate = pick(formData, editedFields)
                convertObjectToFormData(dataToUpdate, body)
                const res = await dispatch(updateSeminarFetch({ seminarId, body })).unwrap()
                if (res) {
                    navigate(`/chat/${chatId}`)
                }
            } else {
                navigate("../design-text")
            }
        }
    }
    const teachingStyleOptions = teachingStyles.map(({ key, title }) => ({ value: key, label: title }))
    const specificBeltOptions = belts.map(({ key, title }) => ({ value: key, label: title }))
    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({ value: key, label: title }))
    const languageOptions = languages.map(({ title, key }) => ({ value: key, label: title }))
    const sharedProfitOptions = [
        {
            label: "Instructor 95% - 5% Academy",
            value: 95
        },
        {
            label: "Instructor 90% - 10% Academy",
            value: 90
        },
        {
            label: "Instructor 80% - 20% Academy",
            value: 80
        },
        {
            label: "Instructor 70% - 30% Academy",
            value: 70
        },
        {
            label: "Instructor 60% - 40% Academy",
            value: 60
        },
        {
            label: "Instructor 50% - 50% Academy",
            value: 50
        },
    ]
    const [inviteInstructorModalOpen, setInviteInstructorModalOpen] = useState(false)
    const [instructors, setInstructors] = useState(preview?.instructors)

    const handleAddInstructors = (data) => {
        setInstructors(data)
        handleStepChange("instructors", data.map(item => item?.user?.id))
        setInviteInstructorModalOpen(false)
        handlePreviewChange("instructors", data)
    }
    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);
        handleStepChange("instructors", updatedInstructors.map(item => item?.user?.id))
        setInstructors(updatedInstructors);
        handlePreviewChange("instructors", updatedInstructors)
    };
    // const instructorCut = () => {
    //     return formData.sharedProfit ? +formData.minimalPriceTicket * (formData.sharedProfit / 100) : 0
    // }
    // const academyRevenue = () => {
    //     return formData.sharedProfit ? +formData.minimalPriceTicket * ((100 - formData.sharedProfit) / 100) : 0
    // }

    useEffect(() => {
        setErrorConfig({
            exceptions: [
                ...(isSharedProfit ? ["seminarMinPrice", "seminarMaxPrice"] : []
                ),
            ],
            requiredFields: [
                "traningStyle", "targetAudience",
                ...(user?.role === "academy" ? ["maxGymCapacity", "specificBelt"] : []),
                ...(isSharedProfit ?
                    ["sharedProfit"]
                    :
                    ["seminarMinPrice", "seminarMaxPrice"]
                )
            ]
        })
    }, [formData, isSharedProfit])

    useEffect(() => {
        if (!seminarDetails || isLoading) return
        setIsSharedProfit(seminarDetails?.sharedProfit)

    }, [seminarDetails, isLoading])


    function calculateMinAttendance(ticketPrice, seminarMinPrice) {
        if (!ticketPrice || ticketPrice <= 0) return 0;
        if (!seminarMinPrice || seminarMinPrice <= 0) return 0
        const minAttendanceRequirement = Math.ceil(seminarMinPrice / ticketPrice);
        handleStepChange("minAttendanceRequirement", minAttendanceRequirement)
        return minAttendanceRequirement
    }


    useEffect(() => {
        calculateMinAttendance(formData.minimalPriceTicket, formData.seminarMinPrice)
    }, [formData.minimalPriceTicket, formData.seminarMinPrice])


    return (
        <>
            {/* <h6 className="heading mb-32">My Terms</h6> */}
            <div className="row gx-16 py-24 mb-42">
                <div className="col-12">
                    <div className="form-group input--lg mb-16">
                        <label className="label">Price range for seminar</label>
                        <div className="row-group w-full gap-10 mobile-range">
                            <div className="form-group flex-auto input--lg">
                                <Input
                                    wrapperClasses={"input--currency"}
                                    inputClasses="input--solid"
                                    type="text"
                                    placeholder="3000"
                                    disabled={isSharedProfit}
                                    value={formData.seminarMinPrice}
                                    error={isErrorsShown && errors.seminarMinPrice}
                                    onChange={(e) => handleStepChange("seminarMinPrice", e.target.value)}
                                />
                            </div>
                            <span className="input-delimiter"></span>
                            <div className="form-group flex-auto input--lg">
                                <Input
                                    wrapperClasses={"input--currency"}
                                    inputClasses="input--solid"
                                    type="text"
                                    placeholder="4000"
                                    disabled={isSharedProfit}
                                    value={formData.seminarMaxPrice}
                                    error={isErrorsShown && errors.seminarMaxPrice}
                                    onChange={(e) => handleStepChange("seminarMaxPrice", e.target.value)}

                                />
                            </div>
                        </div>
                    </div>
                    {!isSharedProfit && <p className='color-text text-14 mb-16'>Seminar pricing varies based on duration. Please adjust parameters based on your seminar duration.</p>}
                    <CheckboxSwitch
                        text="Set “Shared Profit” for seminar"
                        checked={isSharedProfit}
                        onChange={() => {
                            if (!isSharedProfit) {
                                handleStepChange("seminarMinPrice", "")
                                handleStepChange("seminarMaxPrice", "")
                            } else {
                                // handleStepChange("minimalPriceTicket", "")
                                handleStepChange("sharedProfit", "")
                            }
                            setIsSharedProfit(!isSharedProfit)
                        }}
                    />
                </div>
                <div className="col-12">
                    <div className="row gx-16 mb-32">
                        {
                            seminarId && (
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="form-group flex-auto input--lg">
                                        <label className="label">Minimal Price</label>
                                        <Input
                                            wrapperClasses={"form-group flex-auto input--lg input--currency"}
                                            inputClasses="input--solid"
                                            type="text"
                                            value={formData.minimalPriceTicket}
                                            error={isErrorsShown && errors?.minimalPriceTicket}
                                            onChange={(e) => handleStepChange("minimalPriceTicket", e.target.value)}
                                            placeholder={"100.00"}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-md-6 col-sm-6 col-12">
                            <SelectOne
                                label={"Shared Profit (Instructor / Academy)"}
                                wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                options={sharedProfitOptions}
                                value={formData.sharedProfit}
                                error={isErrorsShown && errors?.sharedProfit}
                                onChange={(option) => handleStepChange("sharedProfit", option.value)}
                                placeholder={"Specific Shared Profit"}
                                disabled={!isSharedProfit}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr className="hr" />
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        label={"Gi or Nogi"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                        options={traningStyleOptions}
                        value={formData.traningStyle}
                        error={isErrorsShown && errors?.traningStyle}
                        onChange={(option) => handleStepChange("traningStyle", option.value)}
                        placeholder={"Gi or Nogi"}
                    />
                </div>
                {
                    user?.role === "academy" && (
                        <>
                            <div className="col-md-6 col-sm-6 col-12">
                                <SelectOne
                                    label={"Specific Belt for instructor"}
                                    wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                    options={specificBeltOptions}
                                    value={formData.specificBelt}
                                    error={isErrorsShown && errors?.specificBelt}
                                    onChange={(option) => handleStepChange("specificBelt", option.value)}
                                    placeholder={"Specific Belt for instructor"}
                                />
                            </div>
                            <div className="col-12">
                                <SelectMulty
                                    label={"Preferred Language"}
                                    wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                                    options={languageOptions}
                                    value={formData.languages}
                                    error={isErrorsShown && errors?.languages}
                                    onChange={(options) => handleStepChange("languages", options.map(option => option.value))}
                                    placeholder={"Select preferred languages"}
                                />
                            </div>
                        </>
                    )
                }
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        label={"Target Audience"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full"}
                        options={[
                            { value: "any", label: "Everyone" },
                            { value: "female", label: "Female Only" },
                            { value: "male", label: "Male Only" },
                            { value: "kids", label: "Kids Only" },
                        ]}
                        value={formData.targetAudience}
                        error={isErrorsShown && errors?.targetAudience}
                        onChange={(option) => handleStepChange("targetAudience", option.value)}
                        placeholder={"Select target audience"}
                    />
                </div>
                {/* <div className="col-md-6 col-sm-6 col-12"></div> */}
                {
                    user?.role === "academy" && (
                        <div className="col-md-6 col-sm-6 col-12">
                            <Input
                                label={"Max Gym capacity"}
                                wrapperClasses={"input--lg"}
                                inputClasses="input--solid"
                                type="text"
                                placeholder="0"
                                value={formData.maxGymCapacity}
                                error={isErrorsShown && errors?.maxGymCapacity}
                                onChange={(e) => handleStepChange("maxGymCapacity", e.target.value)}
                            />
                        </div>
                    )
                }
                {
                    seminarId && (
                        <>
                            <div className="col-md-6 col-sm-6 col-12">
                                <Input
                                    label={"Minimum Attendance Requirement"}
                                    wrapperClasses={"input--lg"}
                                    inputClasses="input--solid"
                                    type="text"
                                    placeholder="0"
                                    // value={formData.minAttendanceRequirement}
                                    value={formData.minAttendanceRequirement}
                                    readOnly
                                    error={isErrorsShown && errors?.minAttendanceRequirement}
                                    // onChange={(e) => handleStepChange("minAttendanceRequirement", e.target.value)}
                                    onChange={() => { }}

                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-12">
                                <DatePicker
                                    label={"Minimum Attendance Deadline"}
                                    error={isErrorsShown && errors?.minAttendanceDeadline}
                                    placeholderText="Select Event Date"
                                    readOnly
                                    selected={formData.minAttendanceDeadline}
                                    // selected={formData.minAttendanceDeadline}
                                    onChange={() => { }}
                                    // onChange={date => handleStepChange("minAttendanceDeadline", date)}
                                    // minDate={new Date()}
                                    dateFormat="dd.MM.yyyy"
                                />

                            </div>
                        </>
                    )

                }
                <CheckboxSwitch
                    text="Do you allow students under the age of 18 to attend your seminar/camp?"
                    checked={formData.allowUnder18}
                    onChange={() => handleStepChange("allowUnder18", !formData.allowUnder18)}
                />

                <div className="col-12">
                    <hr className="hr" />
                </div>
                <div className="col-12">
                    <SelectMulty
                        label={user?.role === "instructor" ? "What do you want to teach?" : "Preferred Teaching Style"}
                        wrapperClasses={"select--outline select--outline-bg input--lg w-full my-18"}
                        options={teachingStyleOptions}
                        value={formData.teachingStyles}
                        error={isErrorsShown && errors?.teachingStyles}
                        onChange={(options) => handleStepChange("teachingStyles", options.map(option => option.value))}
                        placeholder={"Add teaching style..."}
                    />
                </div>
                {/* {
                    !isSharedProfit ?
                        <>
                            <div className="col-12">
                                <hr className="hr" />
                            </div>
                            <div className="col-12">
                                <div className="row g-16">
                                    <div className="col-12 mb-42">
                                        <ul className="order-list">
                                            <li className="order-list--item">
                                                <p className="order-list--text">Instructor Cut:</p>
                                                <p className="order-list--value">$ {instructorCut()}</p>
                                            </li>
                                            <li className="order-list--item">
                                                <p className="order-list--text">Academy Potential Revenue:</p>
                                                <p className="order-list--value">$ {academyRevenue()}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </>
                        :
                        null
                } */}
                <div className="col-12">
                    {
                        user?.role === "instructor" ?
                            <>
                                <hr className='hr-horisontal mb-42' />
                                <div className='mb-42'>
                                    <h6 className="heading mb-32">Invite Additional Instructor <span className="color-grey">(optional)</span></h6>
                                    {
                                        instructors?.length ?
                                            <ul className="invite-instructors-list mb-32">
                                                {
                                                    instructors.map((instructor, index) => (
                                                        <li key={index} className="invite-instructor__item">
                                                            <div className="invite-instructor__img">
                                                                <img src={getImageSrc(instructor.photo)} alt={instructor.fullName} />
                                                            </div>
                                                            <div className="invite-instructor__group-info">
                                                                <span className="invite-instructor__name">{instructor.fullName}</span>
                                                                <span className="invite-instructor__email color-grey fs-14">{instructor?.user?.email}</span>
                                                            </div>
                                                            <button onClick={() => handleRemoveInstructor(index)} className="btn">
                                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <path d="M18.75 5.75L5.25 19.25" stroke="#BDBDBD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M18.75 19.25L5.25 5.75" stroke="#BDBDBD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath>
                                                                            <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            :
                                            null
                                    }
                                    <button onClick={() => setInviteInstructorModalOpen(true)} className="btn btn--sm btn--primary" data-modal="#invite-instructors">
                                        <span className="info">Send Invite</span>
                                        <span className="ico">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_910_51633)">
                                                    <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_910_51633">
                                                        <rect width="14" height="14" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </>
                            : null
                    }
                </div>
            </div>
            <div className="page-action-group mobile-actions">
                <Link to={".."} className="btn btn--default btn--sm">Cancel</Link>
                <button onClick={handleSubmit} className="btn btn--primary btn--sm">
                    {
                        seminarId ?
                            <span className="info">Apply Changes</span>
                            :
                            <>
                                <span className="info">Continue</span>
                                <span className="ico">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 2.625L9.625 7L5.25 11.375" stroke="#02020A" strokeWidth="1.6" strokeLinecap="square" />
                                    </svg>
                                </span>
                            </>

                    }
                </button>
            </div>
            {
                inviteInstructorModalOpen ?
                    <InviteInstructorModal
                        open={inviteInstructorModalOpen}
                        handleClose={() => setInviteInstructorModalOpen(false)}
                        onSubmit={handleAddInstructors}
                        data={instructors}
                    />
                    :
                    null
            }
        </>
    )
}

export default SeminarInformation