import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    socket: null,
    onlineUsers: [],
};

const socketChat = createSlice({
    name: 'socketChat',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload;
        },
        setOnlineUsers: (state, action) => {
            const arr = action.payload.map(el => el.userId)
            state.onlineUsers = arr
        },
        setOnlineOneUser: (state, action) => {
            state.onlineUsers.push(action.payload.userId)
        },
        setUserOffline: (state, action) => {
            state.onlineUsers = state.onlineUsers.filter(el => el !== action.payload.userId)
        },

    },
    extraReducers: (builder) => {
    }
});

export const {
    setSocket,
    setOnlineUsers,
    setUserOffline,
    setOnlineOneUser,
} = socketChat.actions;

export const getChatSocket = (state) => state.socketChat.socket;

export const getChatOnlineUsers = (state) => state.socketChat.onlineUsers;

export default socketChat.reducer;
