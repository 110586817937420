import React from 'react'
import { getUser } from '../../redux/slices/applicationSlice';
import { useSelector } from 'react-redux';
import { MySeminarsInstructorsAndAcademies } from './MySeminarsInstructorsAndAcademies';
import { MySeminarsStudent } from './studentProfilePages/MySeminarsStudent';

export const MySeminars = () => {
    const user = useSelector(getUser);
    return (
        <div className="my-seminars-page__body">
            {user && user.role === 'student' ?
                <MySeminarsStudent />
                : <MySeminarsInstructorsAndAcademies />}
        </div>
    )
}
