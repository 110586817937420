import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import { toggle } from "../../../helpers/utils";
import RangeSlider from "./RangeSlider";

const OutlineEditor = ({ currentStyles, handleChange }) => {
    const outlineStyles = [
        { label: "Hidden", value: "hidden" },
        { label: "Dotted", value: "dotted" },
        { label: "Dashed", value: "dashed" },
        { label: "Solid", value: "solid" },
        { label: "Double", value: "double" },
        { label: "Groove", value: "groove" },
        { label: "Ridge", value: "ridge" },
        { label: "Inset", value: "inset" },
        { label: "Outset", value: "outset" },
    ]

    return (
        <div>
            <label className="label-row">
                <label className="label">Outline</label>
                <CheckboxSwitch
                    checked={!!currentStyles?.outlineWidth}
                    value={"3px"}
                    onChange={({ target }) => handleChange("outlineWidth", toggle(currentStyles?.outlineWidth, target.value))}
                />
            </label>
            {currentStyles?.outlineWidth && (
                <div className="py-4 border-bottom">
                    <div className="row gutters-form-7">
                        <div className="col-12">
                            <div className="flex gap-12">
                                <label className="picker-custom btn btn--square btn--sm">
                                    <input
                                        type="color"
                                        value={currentStyles?.outlineColor || currentStyles.color || "#ffffff"}
                                        onChange={({ target }) => handleChange("outlineColor", target.value)}
                                    />
                                </label>
                                <SelectOne
                                    wrapperClasses={"select--outline select--outline-bg select--outline-2 input--sm flex-auto"}
                                    options={outlineStyles}
                                    value={currentStyles?.outlineStyle}
                                    onChange={(option) => handleChange("outlineStyle", option.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <RangeSlider
                                label="Width"
                                value={currentStyles?.outlineWidth}
                                onChange={(value) => handleChange("outlineWidth", value)}
                                min={0}
                                max={10}
                                step={1}
                                postfix={"px"}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OutlineEditor;
