const usersBase = 'api/users';
const relationshipBase = 'api/relationship-levels';

export const users = (instance) => ({
    async getUser(token = null) {
        const { data } = await instance.get(`${usersBase}/me`, token ? {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        } : {});
        return data;
    },
    async updateUser(payload) {
        const { data } = await instance.put(`${usersBase}/me`, payload);
        return data;
    },

    async getAllInstructors() {
        const { data } = await instance.get(`${usersBase}/instructors`)
        return data;
    },

})