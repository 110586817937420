import React from 'react'
import { Link } from 'react-router-dom'
import { CardDetails } from './CardDetails'
import { Step } from './Step'

const StepBooking = ({ currentStatus, status, data, link, canEdit = true }) => {
    return (
        <Step
            currentStatus={currentStatus}
            status={status}
            label="Booking Specific Terms"
        >
            <CardDetails
                label={"Check terms"}
                isEdited={!data}
                afterLabel={
                    data && canEdit ?
                        <Link to={link} className="btn btn--xs color-secondary text-normal text-14 fw-500">
                            <span className="ico ico-14 gap-4">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_309_18943)">
                                        <path d="M5.25 11.8126H2.625C2.50897 11.8126 2.39769 11.7665 2.31564 11.6844C2.23359 11.6024 2.1875 11.4911 2.1875 11.3751V8.93109C2.18755 8.81521 2.23358 8.70409 2.31547 8.6221L9.05953 1.87804C9.14157 1.79605 9.25281 1.75 9.36879 1.75C9.48477 1.75 9.59601 1.79605 9.67805 1.87804L12.122 4.32038C12.204 4.40242 12.2501 4.51366 12.2501 4.62964C12.2501 4.74562 12.204 4.85686 12.122 4.9389L5.25 11.8126Z" stroke="#9747FF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.8125 11.8125H5.25" stroke="#9747FF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.4375 3.5L10.5 6.5625" stroke="#9747FF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_309_18943">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span className="info">Edit</span>
                        </Link>
                        : null
                }
            >
                {data
                    ? data
                    : canEdit ? <Link to={link} className='fw-500 text-14 color-secondary'>Add Booking Specific Terms</Link>
                        : null
                }
            </CardDetails>
        </Step>
    )
}

export default StepBooking