import { throttle } from 'lodash';
import { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

const RangeSlider = ({
    value = 0,
    label = "",
    onChange = () => { },
    min = 0,
    max = 100,
    step = 1,
    postfix = "",
    defaultValue = value,
}) => {
    const [range, setRange] = useState(parseFloat(value));
    const [start, setStart] = useState(parseFloat(value))


    useEffect(() => {
        setRange(parseFloat(value || defaultValue));
        setStart(parseFloat(value || defaultValue));
    }, [value]);

    const throttledOnChange = throttle((value) => {
        onChange && onChange(value + postfix);
    }, 100)

    const handleChange = (value) => {
        setRange(value)
        setStart(value)
        // throttledOnChange(value)
    };

    const handleChangeComplete = (value) => {
        onChange && onChange(value + postfix);
    }

    return (
        <div className="form-group select--outline select--outline-bg select--outline-2 input--sm ui-slider-wrapper flex-auto">
            <div className="label-row">
                <label className="label">{label}</label>
                <p className="ui-slider-val">{range}</p>
            </div>
            <div className="noUiSlider-slider-wrap">
                <Slider
                    min={min}
                    max={max}
                    step={step}
                    value={start}
                    onChange={handleChange}
                    onChangeComplete={handleChangeComplete}
                />
            </div>
        </div>
    );
};

export default RangeSlider