import Color from "color";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { toggle } from "../../../helpers/utils";
import RangeSlider from "./RangeSlider";


const backgroundRanges = [
    { label: "Padding Top", name: "paddingTop", step: 1, min: 0, max: 100, postfix: "px", defaultValue: 0 },
    { label: "Padding Left", name: "paddingLeft", step: 1, min: 0, max: 100, postfix: "px", defaultValue: 0 },
    { label: "Padding Right", name: "paddingRight", step: 1, min: 0, max: 200, postfix: "px", defaultValue: 0 },
    { label: "Padding Bottom", name: "paddingBottom", step: 1, min: 0, max: 200, postfix: "px", defaultValue: 0 },
    { label: "Margin Top", name: "marginTop", step: 1, min: 0, max: 100, postfix: "px", defaultValue: 0 },
    { label: "Margin Left", name: "marginLeft", step: 1, min: 0, max: 100, postfix: "px", defaultValue: 0 },
    { label: "Margin Right", name: "marginRight", step: 1, min: 0, max: 200, postfix: "px", defaultValue: 0 },
    { label: "Margin Bottom", name: "marginBottom", step: 1, min: 0, max: 200, postfix: "px", defaultValue: 0 },
];

const BackgroundEditor = ({ currentStyles, handleChange }) => {
    const getBackgroundOpacity = (color) => {
        return Color(color).alpha();
    };

    const adjustOpacity = (color, opacity) => {
        return Color(color).alpha(opacity).hsl().string();
    };

    const rgbaToHex = (rgba) => {
        return Color(rgba).hex();

    };

    return (
        <div>
            <label className="label-row">
                <label className="label">Background</label>
                <CheckboxSwitch
                    checked={!!currentStyles?.backgroundColor}
                    value={"#ffffff"}
                    onChange={({ target }) => handleChange("backgroundColor", toggle(currentStyles?.backgroundColor, target.value))}
                />
            </label>
            {currentStyles?.backgroundColor && (
                <div className="py-4 border-bottom">
                    <div className="row gutters-form-7">
                        <div className="col-12">
                            <div className="flex gap-12">
                                <label className="picker-custom btn btn--square btn--sm">
                                    <input
                                        type="color"
                                        value={rgbaToHex(currentStyles?.backgroundColor) || "#ffffff"}
                                        onChange={({ target }) => handleChange("backgroundColor", target.value)}
                                    />
                                </label>
                                <RangeSlider
                                    label="Opacity"
                                    value={getBackgroundOpacity(currentStyles?.backgroundColor)}
                                    onChange={(value) => handleChange("backgroundColor", adjustOpacity(currentStyles?.backgroundColor, value))}
                                    min={0}
                                    max={1}
                                    defaultValue={0}
                                    step={0.01}
                                />
                            </div>
                        </div>
                        {backgroundRanges.map((range) => (
                            <div className="col-12" key={range.name}>
                                <RangeSlider
                                    label={range.label}
                                    value={currentStyles?.[range.name]}
                                    onChange={(value) => handleChange(range.name, value)}
                                    min={range.min}
                                    max={range.max}
                                    step={range.step}
                                    defaultValue={range?.defaultValue}
                                    postfix={range?.postfix}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BackgroundEditor;
