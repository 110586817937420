import React, {useEffect, useState} from 'react'
import Input from '../../../components/ui/formElements/Input'
import Button from '../../../components/ui/buttons/Button'
import SelectOne from '../../../components/ui/formElements/SelectOne'
import api from "../../../api";
import {useSelector} from "react-redux";
import {getUser} from "../../../redux/slices/applicationSlice";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { Country }  from 'country-state-city';

const StudentMyProfile = ({ userData }) => {
    const [data, setData] = useState({
		email: userData.email,
		phone: userData.phone,
		...userData.profile
	});

	const [updateData, setUpdateData] = useState({});

	const [images64, setImages64] = useState({
		photo: null,
		cover: null,
	});

    const allCountries = Country.getAllCountries();
    const countries = allCountries?.map(item => ({ value: item.isoCode, label: item.name })) || [];

    const bely = [
        {value:'Purple Belt', label: 'Purple Belt'}
    ]
    
    // const user = useSelector(getUser)
    


    // const [formData, setFormData] = useState({
    //     'fullName': '',
    //     'country': '',
    //     'birthday': '',
    //     'phone': '',
    //     'bely': '',
    //     'gym': '',
    // })
    // const [errors, setErrors] = useState({})

    // useEffect(() => {
    //     console.log(user)
    //     setFormData(prev => ({
    //         ...prev,
    //         fullName: user.profile.fullName,
    //         phone: user.phone,
    //         country: user.profile.country,
    //         gym: user.profile.gym,
    //         bely: user.profile.bely,
    //         birthday: user.profile.birthday,
    //     }))
    // }, [])


    // const save = async (e) => {
    //     let localErr = false
    //     Object.entries(formData).forEach(([key, value]) => {
    //         if (!value) {
    //             localErr = true
    //             setErrors(prevState => ({ ...prevState, [key]: true }))
    //         }
    //     })

    //     if (localErr) return



    //     const res = await api.profiles.updateStudent({})
    // }

    const beltOptions = [
        "White Belt",
        "Blue Belt",
        "Purple Belt",
        "Brown Belt",
        "Black Belt",
        "Red/Black Belt (Coral Belt)",
        "Red/White Belt (Coral Belt)",
        "Red Belt"
    ].map(item => ({ value: item, label: item }));


    const onChangeData = (field, value) => {
		setData((prev) => {
			return {
				...prev,
				[field]: value
			}
		});

		setUpdateData((prev) => {
			return {
				...prev,
				[field]: value
			}
		});
	}

	const toBase64 = async(file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async(file, field) => {
        onChangeData(field, file);

        let res = await toBase64(file);
		
		setImages64((prev)=>{
			return {
				...prev,
				[field]: res
			}
		})
    }

    const save = async() => {
		if(updateData?.email || updateData?.phone){
			let updatePayload = {};

			["email", "phone"].forEach(item => {
				if(updateData[item]){
					updatePayload[item] = updateData[item];
				}
			})

			let updateUserRes = await api.users.updateUser(updatePayload);

			if(updateUserRes.success){
				toast.success(updateUserRes.message || "Updated");
			} else {
				toast.error(updateUserRes?.message || "Error");
			}
		}

		if(updateData?.country || updateData?.photo || updateData?.birthday || updateData?.fullName || updateData?.bely || updateData?.gym){
			var formData = new FormData();

			if(updateData?.birthday){
				formData.append("birthday", new Date(updateData?.birthday).toISOString());
			}

			["country", "photo", "fullName", "bely", "gym"].forEach(item => {
				if(updateData[item]){
					formData.append(item, updateData[item]);
				}
			})

			let updateInstructorRes = await api.profiles.updateStudent(formData);

			if(updateInstructorRes.success){
				toast.success(updateInstructorRes.message || "Updated");

				setUpdateData({})
			} else {
				toast.error(updateInstructorRes?.message || "Error");
			}
		}
	}


    return (
        <>
            <div className="settings-body settings-gap">
                <div className="sidebar-header heading-row">
                    <h3 className="heading">My Profile</h3>
                </div>
                <form className="form form-settings" method="post" onSubmit={(e)=>{e.preventDefault();}}>
                    <div className="form-header">
                        <div className="row gutters-form">
                            <div className="col-12">
                                <div className="upload-photo">
                                    <div className="photo-load">
                                        {/* <img src={require("../../../assets/img/user-photo.svg").default} /> */}
                                        {
                                            data?.photo ?
                                                <img src={images64?.photo ? images64?.photo : (process.env.REACT_APP_API_URL + data?.photo)} alt="" />
                                            : null
                                        }
                                    </div>
                                    <label className="upload-control">
                                        <input type="file" hidden={true} onChange={(e)=>{ changePhoto(e.target.files[0], "photo"); }} />
                                        <div className="btn btn--primary btn--sm radius">
                                            <span>Upload New Picture</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-body">
                        <div className="form-items-list">
                            <div className="form-item">
                                <div className="row gutters-form">
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            label={'Full Name'}
                                            inputClasses={'input--solid'}
                                            value={data?.fullName}
                                            onInput={(e)=>{
                                                onChangeData('fullName', e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <SelectOne
                                            options={countries}
                                            wrapperClasses={'select--outline select--outline-bg input--lg'}
                                            label={'Country'}
                                            value={countries?.find(item => item?.value === data.country) || { value: 'US', label: 'United States' }}
                                            onChange={(e)=>{
                                                onChangeData("country", e.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group input--lg">
                                            <label className="label">Date of Birth</label>
                                            <div className="row gutters-form-2">
                                                <div className="col-md-4 col-sm-4 col-12">
                                                    {/* <SelectOne
                                                        options={days}
                                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                        value={days[0]}
                                                    /> */}
                                                    <div className="day-datepicker">
                                                        <DatePicker 
                                                            dateFormat="dd"
                                                            className={`input input--solid`} 
                                                            selected={data.birthday} 
                                                            onChange={(date) => {
                                                                onChangeData('birthday', date);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-12">
                                                    {/* <SelectOne
                                                        options={months}
                                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                        value={months[0]}
                                                    /> */}
                                                    <div className="month-datepicker">
                                                        <DatePicker 
                                                            showMonthYearPicker
                                                            dateFormat="MM"
                                                            className={`input input--solid`} 
                                                            selected={data.birthday} 
                                                            onChange={(date) => {
                                                                onChangeData('birthday', date);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-12">
                                                    {/* <SelectOne
                                                        options={yesrs}
                                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                        value={yesrs[0]}
                                                    /> */}
                                                    <div className="month-datepicker">
                                                        <DatePicker 
                                                            showYearPicker
                                                            dateFormat="yyyy"
                                                            className={`input input--solid`} 
                                                            selected={data.birthday} 
                                                            onChange={(date) => {
                                                                onChangeData('birthday', date);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <SelectOne
                                            options={beltOptions}
                                            wrapperClasses={'select--outline  select--outline-bg input--lg'}
                                            label={'Belt'}
                                            value={beltOptions?.find(item => item?.value === data.bely) || { }}
                                            onChange={(e) => {
                                                onChangeData("bely", e.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            label={'Gym'}
                                            inputClasses={'input--solid'}
                                            value={'Arte Suave'}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            label={'Email'}
                                            inputClasses={'input--solid'}
                                            value={data.email}
                                            onInput={(e)=>{
                                                onChangeData('email', e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <Input
                                            wrapperClasses={'input--lg'}
                                            label={'Phone'}
                                            inputClasses={'input--solid'}
                                            value={data.phone}
                                            onInput={(e)=>{
                                                onChangeData('phone', e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-footer">
                        <div className="flex-group justify-end">
                            <Button onClick={save} className={`btn btn--primary btn--sm ${!Object.entries(updateData).length ? 'disabled' : ''}`}>
                                <span>Save Changes</span>
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default StudentMyProfile
