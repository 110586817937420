import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../../assets/css/mapBox.css'

const mapBoxToken = 'pk.eyJ1IjoibWF4aW1va29semluIiwiYSI6ImNsdGU5OW1lMzBlOTQyaXF1OHQ3a3FyZzQifQ.iHTE8f0GCRZQyZiAX8rfBw'
mapboxgl.accessToken = mapBoxToken

const MapBox = ({mainPoints, secondaryPoints, newCenter, getCoordinatesOnclick, noEvents=false}) => {
    const mapContainer = useRef(null);
    const [map, setMap] = useState(null);
    const [mainMarkers, setMainMarkers] = useState([]);
    const [secondaryMarkers, setSecondaryMarkers] = useState([]);

    useEffect(() => {
        if (map) return;
        const currentMap = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
            center: newCenter ? newCenter : [12.554729, 55.70651],
            zoom: 10
        });


        if (!noEvents) {
            currentMap.on('click', function(e) {
                const coordinates = e.lngLat;
                getCoordinatesOnclick && getCoordinatesOnclick({
                    lon: coordinates.lng,
                    lat: coordinates.lat
                })
            });
        }

        setMap(currentMap)
    }, []);

    useEffect(() => {
        if (map && newCenter) {
            map.setCenter(newCenter);
        }
    }, [newCenter]);


    useEffect(() => {
        if (!map || !mainPoints) return;

        mainMarkers.forEach(markerObj => {
            const element = markerObj.marker.getElement();
            element.removeEventListener('click', markerObj.clickHandler);
            markerObj.marker.remove();
        });

        const newMainMarkers = mainPoints.map(point => {
            const a = document.createElement('div');
            a.classList.add('map-main-point');

            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat(point.coordinates)
                .addTo(map);

            const clickHandler = () => {};

            if (!noEvents) {
                marker.getElement().addEventListener('click', clickHandler);
            }


            return { marker, point, clickHandler };

        });

        setMainMarkers(newMainMarkers);

    }, [map, mainPoints]);

    useEffect(() => {
        if (!map || !secondaryPoints) return;

        secondaryMarkers.forEach(markerObj => markerObj.marker.remove());

        const newSecondaryMarkers = secondaryPoints.map(point => {
            const a = document.createElement('div');
            a.classList.add('map-secondary-point');

            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat(point.coordinates)
                .addTo(map);

            return { marker, point };
        });

        setSecondaryMarkers(newSecondaryMarkers);
    }, [map, secondaryPoints]);



    return (
        <div ref={mapContainer} className="map-container"/>
    );
};

export default MapBox;